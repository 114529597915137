import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const ProfilePic = styled.div`
  width: 38px;
  height: 38px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
  cursor: pointer;
  position: relative;
  .dot {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => (props.online ? 'green' : 'black')};
  }
`;

export const Section = styled.div`
  .bg {
    background-image: ${(props) => (props.Color
    ? `linear-gradient(${props.Color[0]},${props.Color[1]})`
    : 'linear-gradient(#000, #000)')};
    height: 100vh;
  }
`;
export const ScrollableSection = styled.div`
  height: 100%;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow-y: auto;
  }
`;

export const Channel = styled.div`
  cursor: pointer;
  display: flex;
  padding: 10px 0px;
  border-bottom: 2px solid lightgrey;

  &:hover {
    background-color: black;
    color: white;
  }
  .last-message {
    color: grey;
    font-size: 13px;
  }
`;

export const CustomImageContainer = styled.li`
  position: relative;
  background-image: ${(props) => (props.image
    ? `url(https://res.cloudinary.com/liviit/image/upload/${props.image}) !important`
    : 'linear-gradient(#fff,#fff) !important')};
  .bg-img-custom {
    border-radius: 5px;
    background-size: cover !important;
  }

  #add-custom-img {
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 25px;
    width: 100%;
    background: transparent;
    z-index: 1000;
  }
  #add-custom-img:hover {
    transform: scale(0.9);
    font-weight: bold;
    background-color: #101010 !important;
    color: #fff;
    background: radial-gradient(
      ellipse at center,
      rgba(12, 11, 23, 0.4) 0%,
      rgba(86, 76, 132, 0.1) 60%,
      rgba(255, 255, 255, 0.2) 100%
    );
  }
`;

export const SidebarContainer = styled.div`
  background-image: ${(props) => props.theme};
  background-size: ${(props) => (props.theme ? 'cover !important' : 'auto !important')};
`;
