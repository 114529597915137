import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import Iframe from 'react-iframe';
import { getAllHubFiles } from 'src/redux/hubRedux';
import { Heading } from 'src/components/StyledComponents';

function HubFiles(props) {
  const { hubFiles } = useSelector((state) => state.HubsReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hubFiles.length) dispatch(getAllHubFiles(props.match.params.id));
  }, []);

  const isImage = (file) => (
    file.name.split('.').pop().toUpperCase() === 'JPG'
      || file.name.split('.').pop().toUpperCase() === 'PNG'
      || file.name.split('.').pop().toUpperCase() === 'JPEG'
  );

  return (
    <>
      {processing.status && processing.component === 'hub-dashboard-files' ? (
        <Loader show />
      ) : hubFiles.length ? (
        <div className="container-fluid">
          <div className="row">
            {hubFiles.map((file) => {
              if (isImage(file)) {
                return (
                  <div
                    className="col-12 my-2"
                    key={file.id}
                    onClick={() => window.open(file.path, '_blank').focus()}
                  >
                    <img
                      src={file.path}
                      alt="Error Loading in File"
                      width="100%"
                      height="100px"
                      className="mt-2"
                    />
                  </div>
                );
              }
              return (
                <div
                  className="col-12 my-2"
                  key={file.id}
                  onClick={() => window.open(file.path, '_blank').focus()}
                >
                  <Iframe
                    url={file.path}
                    width="100%"
                    height="100px"
                    display="initial"
                    position="relative"
                    id="preview-frame"
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Heading className="text-center h4 mt-4">
          This hub does not have any files.
        </Heading>
      )}
    </>
  );
}

export default HubFiles;
