import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get User Feeds
export const fetchUserFeeds = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}feeds`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Feed-Tab': payload,
      ...getHeaderData(),
    },
  });

  return response.data;
};

// post user feed
export const postUserFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}feeds/gallery-upload`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// post reaction on feed
export const postReactionOnFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}feeds/${payload[0]}/reactions`,
    payload[1],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// remove reaction from feed
export const deleteReactionOnFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.delete(
    `${baseUrl}feeds/${payload.id}/reactions/${payload.reaction}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// get comments on feed
export const fetchCommentsOnFeed = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}feeds/${payload.id}/comments`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// post comments on feed
export const postCommentsOnFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}feeds/${payload[0]}/comments`,
    payload[1],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// delete comment from feed
export const deleteCommentsOnFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.delete(
    `${baseUrl}feeds/${payload.id}/comments/${payload.commentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// get unseen feed count
export const fetchUnseenFeedCount = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}feeds/unseen-count`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// update last seen feed
export const updateLastSeenFeed = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(`${baseUrl}feeds/last-seen-feed`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
