import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { DropdownData } from 'src/utils/data';
import InputField from 'src/components/InputField';
import { Button } from 'src/components/Button';
import SelectMenu from 'src/components/DropDown';
import { Heading } from './styles';

function AddTaskModal({
  show,
  close,
  title,
  submit,
  value,
  setValue,
  count,
  setCount,
}) {
  return (
    <Modal
      show={show}
      onHide={() => close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pb-0" style={{ border: 'none' }} closeButton>
        <Heading className="font-weight-bold">{title}</Heading>
      </Modal.Header>
      <Modal.Body>
        <form className="w-100" onSubmit={submit}>
          <div className="form-group">
            <InputField
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="form-control form-control-lg"
              id="email"
              placeholder="Type here"
              required
              fontSize="18px"
            />
          </div>
          <Heading className="font-weight-bold">No. Of Check Counts</Heading>
          <SelectMenu
            selected={count}
            setSelected={setCount}
            options={DropdownData.countOptions}
          />

          <div className="text-center">
            <Button
              type="submit"
              variant="solid"
              padding="8px 30px"
              value="Ok"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

AddTaskModal.defaultProps = {
  value: '',
  show: false,
  title: '',
  count: '',
  submit: () => {},
  close: () => {},
  setValue: () => {},
  setCount: () => {},
};

AddTaskModal.propTypes = {
  value: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  count: PropTypes.number,
  submit: PropTypes.func,
  close: PropTypes.func,
  setValue: PropTypes.func,
  setCount: PropTypes.func,
};

export default AddTaskModal;
