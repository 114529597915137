import React from 'react';
import Navbar from 'src/components/Navbar';
import Card from 'src/components/Card';
import Footer from 'src/components/Footer';
import BannerIcon from 'src/assets/images/livit/Inner 2.png';
import LoyaltyIcon from 'src/assets/images/livit/Icon - Loyalty.png';
import InclusionIcon from 'src/assets/images/livit/Icon - Inclusion.png';
import VisionIcon from 'src/assets/images/livit/Icon - Vision.png';
import InnovationIcon from 'src/assets/images/livit/Icon - Innovation.png';
import IntegrityIcon from 'src/assets/images/livit/Icon - Integrity.png';
import TrustIcon from 'src/assets/images/livit/Icon - Trust.png';
import { Heading, Header, Name } from './styles';

function Philosophy() {
  const aboutCards = [
    {
      key: 1,
      title: 'Loyalty',
      about:
        'We have respect for everyone who uses this platform and will continue to deliver the added value and solutions that will earn theirs in return. the loyalty of our users ensures we will continually evolve in ways which benefit their lives',
      image: LoyaltyIcon,
    },
    {
      key: 2,
      title: 'Inclusion',
      about:
        'Our easy functionality means everyone is included, all age groups, all sections of the population. Everyone should feel Liviit is part of their life',
      image: InclusionIcon,
    },
    {
      key: 3,
      title: 'Vision',
      about:
        'We will continue to look to the future. Our functions and features will consistently reflect the latest technological breakthroughs',
      image: VisionIcon,
    },
    {
      key: 4,
      title: 'Innovation',
      about:
        'Liviit will always provide a viable alternative for those who desire a balance between their online life and the real world',
      image: InnovationIcon,
    },
    {
      key: 5,
      title: 'Integrity',
      about:
        'We will always achieve what we say we will in maintaining the highest level of security and safety',
      image: IntegrityIcon,
    },
    {
      key: 6,
      title: 'Trust',
      about:
        'Liviit is a trustworthy platform where online users can always feel secure',
      image: TrustIcon,
    },
  ];
  return (
    <>
      <Navbar />
      <div className="bg-white">
        <div className="row">
          <div className="col-sm-6 text-center">
            <Name>Our Philosophy</Name>
          </div>
          <div className="col-sm-6">
            <img src={BannerIcon} alt="banner" width="100%" />
          </div>
        </div>
        <div className="container text-center mt-4 pt-4">
          <Heading>Philosophy and core values</Heading>
          <Header>
            Liviit is a digital platform that has taken 10 years to develop. It
            was born out of a desire to increase online safety, security and
            inclusiveness and will create a significant change in how we use the
            internet and support apps.
            <br />
            <br />
            Our safety first approach is unique and important. A highly
            developed user management system means we can adhere to all the
            protection rules currently in place(COPA and GDPR) and continue to
            offer personal privacy and data security for everyone.
            <br />
            <br />
            The user friendly design ensures no one will be left out. We are
            committed to attracting people of all ages and offering the best
            solution for all users. Everyone has a desire to connect and share
            with each other and Liviit makes it possible.
          </Header>
          <div className="row mb-4">
            {aboutCards.map((card) => (
              <div key={card.key} className="col-md-6 col-sm-12 mt-5">
                <Card item={card} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Philosophy;
