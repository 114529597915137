import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const StyledButton = styled.button`
  background-color: ${(props) => (props.variant === 'solid' ? 'lightgray' : 'white')};
  color: ${(props) => (props.variant === 'solid' ? 'black' : themeColor)};
  border: ${(props) => (props.variant === 'solid' ? 'none' : `${themeColor} solid 2px`)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  width: ${(props) => (props.width ? props.width : null)};
  margin: ${(props) => (props.margin ? props.margin : null)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  font-weight: ${(props) => (props.variant === 'solid' ? 'bolder' : null)};
  &:hover {
    background-color: ${(props) => (props.variant === 'solid' ? 'lightgrey' : 'lightgray')};
    color: ${(props) => (props.variant === 'solid' ? 'black' : 'black')};
    border: ${(props) => (props.variant === 'solid'
    ? `${themeColor} solid 2px`
    : `${themeColor} solid 2px`)};
  }
  &:disabled {
    opacity: 0.35;
    color: themeColor;
  }
`;
