import { CometChat } from "@cometchat-pro/chat";

import * as enums from "../../../util/enums.js";

export class CallAlertManager {
  callListenerId = "incoming_call_" + new Date().getTime();

  attachListeners(callback) {
    CometChat.addCallListener(
      this.callListenerId,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          callback(enums.INCOMING_CALL_RECEIVED, call);
        },
        onIncomingCallCancelled: (call) => {
          callback(enums.INCOMING_CALL_CANCELLED, call);
        },
        onCallEnded: (call) => {
          callback(enums.CALL_ENDED, call);
        },
        onOutgoingCallRejected: (call) => {
          callback(enums.OUTGOING_CALL_REJECTED, call);
        },
      })
    );
  }
  incomingCall(callBack) {
    CometChat.addMessageListener(
      this.msgListenerId,
      new CometChat.MessageListener({
        onMessagesRead: (messageReceipt) => {
          callBack(enums.MESSAGE_READ, messageReceipt);
          CometChat.removeMessageListener(this.msgListenerId);
        },
      })
    );
  }

  removeReadListeners() {
    CometChat.removeMessageListener(this.msgListenerId);
  }

  removeListeners() {
    CometChat.removeCallListener(this.callListenerId);
  }
}
