import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatMessageActions, CometChatThreadedMessageReplyCount, CometChatReadReceipt } from "../";
import { CometChatMessageReactions } from "../Extensions";
import { CometChatAvatar } from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import { 
	messageContainerStyle,
	messageWrapperStyle,
	messageThumbnailStyle,
	messageDetailStyle,
	nameWrapperStyle,
	nameStyle,
	messageFileContainerStyle,
	messageFileWrapperStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	iconStyle
} from "./style";

import fileIcon from "./resources/file-upload.svg";

class CometChatReceiverFileMessageBubble extends React.Component {

	static contextType = CometChatContext;
	messageFrom = "receiver";

	constructor(props) {
		super(props);

		const message = Object.assign({}, props.message, { messageFrom: this.messageFrom });

		this.state = {
			message: message,
			isHovering: false
		}
	}

	componentDidUpdate(prevProps) {

		const previousMessageStr = JSON.stringify(prevProps.message);
		const currentMessageStr = JSON.stringify(this.props.message);

		if (previousMessageStr !== currentMessageStr) {

			const message = Object.assign({}, this.props.message, { messageFrom: this.messageFrom });
			this.setState({ message: message })
		}
	}

	handleMouseHover = () => {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState = (state) => {

		return {
			isHovering: !state.isHovering,
		};
	}

	render() {

		let avatar = null, name = null;
		if (this.state.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {

			avatar = (
				<div css={messageThumbnailStyle()} className="message__thumbnail">
					<CometChatAvatar user={this.state.message.sender} />
				</div>
			);

			name = (<div css={nameWrapperStyle(avatar)} className="message__name__wrapper">
				<span css={nameStyle(this.context)} className="message__name">{this.state.message.sender.name}</span>
				</div>);
		}

		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.state.message, "reactions");
		if (reactionsData) {

			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions {...this.props} message={this.state.message} reaction={reactionsData} />
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = (<CometChatMessageActions {...this.props} message={this.state.message} name={name} />);
		}

		return (
			<div 
			css={messageContainerStyle()} 
			className="receiver__message__container message__file"
			onMouseEnter={this.handleMouseHover}
			onMouseLeave={this.handleMouseHover}>
				<div css={messageWrapperStyle()} className="message__wrapper">
					{avatar}
					<div css={messageDetailStyle()} className="message__details">
						{name}
						{toolTipView}
						<div css={messageFileContainerStyle(this.props)} className="message__file__container">
							<div css={messageFileWrapperStyle(this.context)} className="message__file__wrapper">
								<a href={this.state.message.data.attachments[0].url} target="_blank" rel="noopener noreferrer">
									<i css={iconStyle(fileIcon, this.context)}></i>
									<label>{this.state.message.data.attachments[0].name}</label>
								</a>
							</div>
						</div>

						{messageReactions}

						<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
							<CometChatReadReceipt {...this.props} message={this.state.message} />
							<CometChatThreadedMessageReplyCount {...this.props} message={this.state.message} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

// Specifies the default values for props:
CometChatReceiverFileMessageBubble.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme
};

CometChatReceiverFileMessageBubble.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object
}

export { CometChatReceiverFileMessageBubble };