import React, { useState } from 'react';
import Loader from 'src/components/Loader';
import { Folder, Menu } from 'src/containers/Files/styles';
import { ScrollableSection } from 'src/components/StyledComponents';
import { useDispatch } from 'react-redux';
import { ModalButton, ModalUpload } from 'src/components/Modal';
import { VaultFolderData } from 'src/constants/offlineData';
import OpenFolderIcon from 'src/assets/images/livit/open-folder.png';
import ClosedFolderIcon from 'src/assets/images/livit/closed-folder.png';
import FileIcon from 'src/assets/images/livit/file.png';
import { FilePlus, FolderPlus, MoreVertical } from 'react-feather';

function Folders(props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [name, setName] = useState('');
  const [outerFolder, setOuterFolder] = useState('');
  const [innerFolder, setInnerFolder] = useState('');
  const [withFolder, setWithFolder] = useState({});
  const [fileLevel, setFileLevel] = useState('');
  const dispatch = useDispatch();

  const getOuterFolder = (folder) => {
    if (outerFolder.id !== folder.id) {
      if (folder.is_folder) {
        setOuterFolder(folder);
        props.setParentFolder(folder);
        props.getFolder(folder, 2);
      } else {
        props.getFolder(folder);
      }
    }
  };

  const getInnerFolder = (folder) => {
    if (folder.is_folder) {
      setInnerFolder(folder);
      props.getFolder(folder, 3);
    } else {
      props.getFolder(folder);
    }
  };

  const addFolder = async (e) => {
    e.preventDefault();
    let payload = {};
    if (withFolder.id) {
      payload = { id: withFolder.id, name };
    } else {
      payload = { name };
    }
    await dispatch(props.addFolder(payload));
    setWithFolder({});
    setModalShow(false);
    setName('');
  };

  const addDefaultSrc = (e) => {
    e.target.src = VaultFolderData[0].avatar;
  };

  const addFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file[0].file);
    formData.set('level', fileLevel);
    if (withFolder.id) {
      formData.set('folder_id', withFolder.id);
    }
    if (fileLevel === 3) {
      setInnerFolder(withFolder);
    }
    await dispatch(props.uploadFile(formData));
  };

  return (
    <>
      {props.state === 'parent' ? (
        <div className="card" style={{ height: '80vh' }}>
          <div className="card-header">
            <div className="row">
              <div className="col-sm-6">
                <h5 className="align-abjust">Folders</h5>
              </div>
              <div className="col-sm-6">
                <div className="pull-right right-header">
                  <FilePlus
                    className="mr-2 "
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setWithFolder({});
                      setFileLevel(1);
                      setModalShow2(true);
                    }}
                  />
                  <FolderPlus
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setWithFolder({});
                      setModalShow(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ScrollableSection display="initial">
              {props.processing.status
              && props.processing.component === 'user-files' ? (
                <Loader show />
                ) : props.folders.length ? (
                  <ul className="list-group">
                    {props.folders.map((data) => (
                      <Folder
                        className={`list-group-item ${
                          props.parentFolder
                          && props.parentFolder.id === data.id
                            ? 'active disable'
                            : null
                        }`}
                        key={data.id}
                      >
                        <div className="d-flex justify-content-between">
                          <div
                            className="d-flex"
                            onClick={() => getOuterFolder(data)}
                          >
                            <div style={{ width: '30px', height: '30px' }}>
                              <img
                                className="rounded"
                                src={
                                  data.is_folder
                                    ? outerFolder.id === data.id
                                      ? OpenFolderIcon
                                      : ClosedFolderIcon
                                    : FileIcon
                                }
                                alt="icon"
                                onError={addDefaultSrc}
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="my-auto ml-3">
                              {data.name.length > 15
                                ? `${data.name.substring(0, 12)}...`
                                : data.name}
                            </div>
                          </div>
                          <div className="add">
                            <Menu>
                              <MoreVertical className="option" />
                              <div
                                className="delete "
                                onClick={() => {
                                  data.is_folder
                                    ? dispatch(
                                      props.deleteFolder({
                                        id: data.id,
                                        level: 1,
                                      }),
                                    )
                                    : dispatch(
                                      props.deleteFile({
                                        id: data.id,
                                        level: 1,
                                      }),
                                    );
                                }}
                              >
                                Delete
                              </div>
                            </Menu>
                          </div>
                        </div>
                      </Folder>
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">No Files Found.</div>
                )}
            </ScrollableSection>
          </div>
        </div>
      ) : (
        <div className="card" style={{ height: '80vh' }}>
          <div className="card-header">
            <div className="row">
              <div className="col-sm-6">
                <h5 className="align-abjust">
                  {props.parentFolder ? props.parentFolder.name : 'Folder'}
                </h5>
              </div>
              <div className="col-sm-6">
                <div className="pull-right right-header">
                  {props.parentFolder ? (
                    <>
                      <FilePlus
                        className="mr-2 "
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setWithFolder(props.parentFolder);
                          setFileLevel(2);
                          setModalShow2(true);
                        }}
                      />
                      <FolderPlus
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setWithFolder(props.parentFolder);
                          setModalShow(true);
                        }}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ScrollableSection display="initial">
              {props.processing.status
              && props.processing.component === 'user-files-inner' ? (
                <Loader show />
                ) : props.outerFolderData.length ? (
                  <ul className="list-group">
                    {props.outerFolderData.map((data2) => (
                      <Folder
                        className={`list-group-item ${
                          props.selected && props.selected.id === data2.id
                            ? 'active disable'
                            : null
                        }`}
                        key={data2.id}
                      >
                        <div className="d-flex justify-content-between py-1">
                          <div
                            className="d-flex"
                            onClick={() => getInnerFolder(data2)}
                          >
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                              }}
                            >
                              <img
                                className="rounded"
                                src={
                                  data2.is_folder
                                    ? innerFolder.id === data2.id
                                      ? OpenFolderIcon
                                      : ClosedFolderIcon
                                    : FileIcon
                                }
                                alt="icon"
                                onError={addDefaultSrc}
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="my-auto ml-3">
                              {data2.name.length > 15
                                ? `${data2.name.substring(0, 12)}...`
                                : data2.name}
                            </div>
                          </div>
                          <div className="add">
                            <FilePlus
                              className={`mr-2 ${
                                data2.is_folder ? '' : 'd-none'
                              }`}
                              onClick={() => {
                                setWithFolder(data2);
                                setFileLevel(3);
                                setModalShow2(true);
                              }}
                            />

                            <Menu>
                              <MoreVertical className="option" />
                              <div
                                className="delete"
                                onClick={() => {
                                  data2.is_folder
                                    ? dispatch(
                                      props.deleteFolder({
                                        id: data2.id,
                                        level: 2,
                                        folder_id: props.parentFolder.id,
                                      }),
                                    )
                                    : dispatch(
                                      props.deleteFile({
                                        id: data2.id,
                                        level: 2,
                                        folder_id: props.parentFolder.id,
                                      }),
                                    );
                                }}
                              >
                                Delete
                              </div>
                            </Menu>
                          </div>
                        </div>
                        {props.innerFolderData.length ? (
                          <div
                            className={`inner-folder ml-3 pt-1 ${
                              innerFolder.id === data2.id ? 'd-block' : 'd-none'
                            }`}
                          >
                            {props.innerFolderData.map((data3) => (
                              <div
                                className="d-flex justify-content-between mt-1"
                                key={data3.id}
                              >
                                <div
                                  className="d-flex"
                                  onClick={() => getInnerFolder(data3)}
                                >
                                  <div
                                    style={{
                                      width: '30px',
                                      height: '100%',
                                    }}
                                  >
                                    <img
                                      className="rounded"
                                      src={FileIcon}
                                      alt="icon"
                                      onError={addDefaultSrc}
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>
                                  <div className="my-auto ml-3">
                                    {data3.name.length > 15
                                      ? `${data3.name.substring(0, 12)}...`
                                      : data3.name}
                                  </div>
                                </div>
                                <div className="add">
                                  <Menu>
                                    <MoreVertical className="option" />
                                    <div
                                      className="delete "
                                      onClick={() => {
                                        dispatch(
                                          props.deleteFile({
                                            id: data3.id,
                                            level: 3,
                                            folder_id: data2.id,
                                          }),
                                        );
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </Menu>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </Folder>
                    ))}
                  </ul>
                ) : null}
            </ScrollableSection>
          </div>
        </div>
      )}
      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Folder Name"
        submit={addFolder}
        value={name}
        setValue={setName}
      />
      <ModalUpload
        show={modalShow2}
        close={setModalShow2}
        size={1}
        addFile={addFile}
        accept={'.pdf,.docx,.xlsx,image/*'}
        title="Add your Files"
      />
    </>
  );
}

export default Folders;
