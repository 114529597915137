import React, { useState, useEffect, useCallback } from 'react';
import { MENUITEMS } from 'src/components/common/sidebar-component/menu';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { Button } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from 'src/redux/themeRedux';

function Bookmark() {
  const mainmenu = MENUITEMS;
  const tooltipOpen = false;
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(false);
  const [bookmarkItems, setBookmarkItems] = useState([]);
  const [targetName, setTargetName] = useState('');
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.ThemeReducer);

  const toggle = (target) => {
    if (!target) {
      setTargetName({
        ...targetName,
        target: {
          tooltipOpen: true,
        },
      });
    } else {
      setTargetName({
        ...targetName,
        target: {
          tooltipOpen: !target.tooltipOpen,
        },
      });
    }
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchValue('');
      setSearchResult([]);
      setOpenSearch(false);
      document.querySelector('.filled-bookmark').classList.remove('is-open');
      document
        .querySelector('.page-wrapper')
        .classList.remove('offcanvas-bookmark');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    const bookmarkItemsTemp = [];
    mainmenu.filter((menuItems) => {
      if (theme && theme.bookmarks) {
        if (theme.bookmarks.includes(menuItems.title)) {
          bookmarkItemsTemp.push(menuItems);
        }
      }

      return 0;
    });
    setBookmarkItems(bookmarkItemsTemp);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, mainmenu, theme]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setSearchValue(keyword);

    mainmenu.filter((menuItems) => {
      if (
        menuItems.title.toLowerCase().includes(keyword)
        && menuItems.type === 'link'
      ) {
        items.push(menuItems);
      }
      return null;
    });
    checkSearchResultEmpty(items);
    setSearchResult(items);
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      document.querySelector('.empty-bookmark').classList.add('is-open');
    } else {
      document.querySelector('.empty-bookmark').classList.remove('is-open');
    }
  };

  const addFix = () => {
    document.querySelector('.filled-bookmark').classList.add('is-open');
    document.querySelector('.page-wrapper').classList.add('offcanvas-bookmark');
  };

  const removeFix = () => {
    setSearchValue('');
    setSearchResult([]);
    document.querySelector('.filled-bookmark').classList.remove('is-open');
    document
      .querySelector('.page-wrapper')
      .classList.remove('offcanvas-bookmark');
  };

  const addToBookmark = (event, items) => {
    const index = bookmarkItems.indexOf(items);

    if (index === -1) {
      if (theme) {
        if (theme.bookmarks) {
          const payload = {
            theme: {
              bookmarks: [...theme.bookmarks, items.title],
            },
            platform: 'web',
          };
          dispatch(changeTheme(payload));
        } else {
          const payload = {
            theme: {
              bookmarks: [items.title],
            },
            platform: 'web',
          };
          dispatch(changeTheme(payload));
        }
      }

      event.currentTarget.classList.add('starred');
      setBookmarkItems([...bookmarkItems, items]);
    } else {
      event.currentTarget.classList.remove('starred');
      bookmarkItems.splice(index, 1);
      setBookmarkItems(bookmarkItems);
      const payload = {
        theme: {
          bookmarks: theme.bookmarks.filter(
            (bookmark) => bookmark !== items.title,
          ),
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    setSearchValue('');
  };

  const removeOffcanvas = () => {
    if (openSearch) {
      setSearchValue('');
      setSearchResult([]);
      document.querySelector('.filled-bookmark').classList.remove('is-open');
      document
        .querySelector('.page-wrapper')
        .classList.remove('offcanvas-bookmark');
    }
    setOpenSearch(!openSearch);
  };

  return (
    <div>
      <div className="col">
        <div className="bookmark pull-right">
          <ul>
            {bookmarkItems.map((items, index) => (
              <li key={index}>
                <Link
                  to={`${process.env.PUBLIC_URL}${items.path}`}
                  className="realname"
                >
                  <items.icon id={`${items[index]}`} />

                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={`${items[index]}`}
                    toggle={() => toggle(`${items[index]}`)}
                  >
                    {items.title}
                  </Tooltip>
                </Link>
              </li>
            ))}

            <li>
              {/* eslint-disable-next-line */}
              <a href="#" onClick={removeOffcanvas}>
                <Button
                  value={openSearch ? 'X' : 'Manage Shortcuts'}
                  variant="outline"
                  padding="2px 10px"
                  fontSize="14px"
                />
              </a>
              <form className="form-inline search-form">
                <div
                  className={`form-group form-control-search ${
                    openSearch ? 'open' : ''
                  }`}
                >
                  <input
                    className="form-control-plaintext "
                    type="text"
                    placeholder="search"
                    value={searchValue}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <div
                    className="Typeahead-menu filled-bookmark custom-scrollbar"
                    id="search-outer"
                  >
                    {searchValue
                      ? searchResult.map((data, index) => (
                        <div className="ProfileCard u-cf" key={index}>
                          <div className="ProfileCard-avatar">
                            <data.icon />
                          </div>
                          <div className="ProfileCard-details">
                            <div className="ProfileCard-realName">
                              <Link
                                to={`${process.env.PUBLIC_URL}${data.path}`}
                                className="realname"
                                onClick={() => removeFix()}
                              >
                                {data.title}
                              </Link>
                              <span className="pull-right">
                                <a href="##">
                                  <span
                                    className="mt-1"
                                    onClick={(e) => addToBookmark(e, data)}
                                  >
                                    {theme
                                        && theme.bookmarks
                                        && theme.bookmarks.includes(data.title)
                                      ? 'Remove'
                                      : 'Add'}
                                  </span>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                      : ''}
                  </div>
                  <div className="Typeahead-menu empty-bookmark">
                    <div className="tt-dataset tt-dataset-0">
                      <div className="EmptyMessage">
                        Opps!! There are no result found.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Bookmark;
