import { axiosInstance, baseUrl } from '.';

// get all the pages
export const fetchAllPages = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}pages${
        payload && payload.category ? `?category=${payload.category}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  }
};

export const fetchPagesWithPage = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/pages?page=${payload.page}&&${
        payload.category ? `category=${payload.category}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  }
};

// get page members
export const fetchPageMembers = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}pages/${id}/members`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

// delete admin pages
export const deleteAdminPages = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(`${baseUrl}admin/pages/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

// get all requested Pages for admin
export const fetchRequestedPages = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}admin/pending-approval/pages`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// post requested Pages by admin
export const postRequestedPages = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}admin/pending-approval/pages/${payload.id}`,
    payload.data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
