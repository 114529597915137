import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Main } from './styles';

const CustomToggle = React.forwardRef(({ placeholder, children, onClick }, ref) => (
  <span
    className="select w-100"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <span
      className={`mr-1 h6 text-capitalize ${
        placeholder ? 'border-bottom' : ''
      }`}
    >
      {children}
    </span>
    {!children
      && (placeholder ? (
        <span
          className={`mr-1 h6 text-muted ${
            placeholder ? 'border-bottom' : ''
          }`}
        >
          {placeholder}
        </span>
      ) : (
        <span className="mx-1" style={{ fontSize: '8px' }}>
          &#x25bc;
        </span>
      ))}
  </span>
));

const CustomMenu = React.forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledBy,
  }, ref) => (
    <div
      ref={ref}
      style={style}
      className={`${className} w-100`}
      aria-labelledby={labeledBy}
    >
      <ul className="list-unstyled">
        <span className="item">{children}</span>
      </ul>
    </div>
  ),
);

function SelectMenu(props) {
  return (
    <Main>
      <Dropdown className={props.visibility ? props.visibility : ''}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {props.selected?.value}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {props.options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => props.setSelected(option)}
              eventKey={index}
            >
              {option.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Main>
  );
}

export default SelectMenu;
