import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    color: #787878;
`;
export const ImageContainer = styled.div`
    width: 15%;
    height: 15%;
    border-radius: 50%;
    padding: 15px;
    box-shadow: -1px 1px 8px 1px;
    @media (max-width: 990px) {
    width: 20%;
  }
  @media (max-width: 760px) {
    width: 24%;
  }
`;
export const ContentContainer = styled.div`
    width: 80%;
    text-align: left;
    color: #787878;
    padding-left: 10px;
`;
export const Title = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: black;
`;
export const Content = styled.span`
    font-size: 16px;
`;
