// actions
export const getBannedWords = () => ({
  type: 'GET_BANNED_WORDS',
});
export const saveBannedWords = (payload) => ({
  type: 'SAVE_BANNED_WORDS',
  payload,
});
export const addNewBannedWord = (payload) => ({
  type: 'ADD_NEW_BANNED_WORD',
  payload,
});

export const updateBannedWord = (payload) => ({
  type: 'UPDATE_BANNED_WORD',
  payload,
});

export const removeBannedWord = (payload) => ({
  type: 'REMOVE_BANNED_WORD',
  payload,
});

// store
const initialState = {
  bannedWords: [],
};

// bannedWordsReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_BANNED_WORDS':
      return { ...state, bannedWords: action.payload };
    default:
      return { ...state };
  }
};
