import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'src/components/Button';
import { InputField } from 'src/components';
import { validateEmail } from 'src/utils/helper';
import { toast } from 'react-toastify';

function ForgetPasswordModal({
  show, onClose, onSubmit,
}) {
  // Local Sate
  const [resetEmail, setResetEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(resetEmail)) {
      onSubmit(resetEmail);
      setResetEmail('');
    } else {
      toast.error('Invalid email address provided');
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header style={{ border: 'none' }} closeButton>
          <div className="font-weight-bold h4">Enter your Email</div>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100 mt-2" onSubmit={onSubmit}>
            <div className="form-group">
              <InputField
                type="text"
                onChange={(e) => setResetEmail(e.target.value)}
                className="form-control form-control-lg"
                id="email"
                placeholder="Type here"
                required
                fontSize="18px"
              />
            </div>

            <div className="text-center">
              <Button
                type="submit"
                variant="solid"
                padding="8px 30px"
                value="Send Mail"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </Modal.Body>
    </Modal>
  );
}

ForgetPasswordModal.defaultProps = {
  show: false,
  onClose: () => {},
  onSubmit: () => {},
};

ForgetPasswordModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ForgetPasswordModal;
