import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { DropdownData } from 'src/utils/data';
import SelectMenu from 'src/components/DropDown';
import { Button } from 'src/components/Button/';
import { ModalUpload } from 'src/components/Modal';
import PlusButton from 'src/components/PlusButton';
import { Heading } from './styles';

function ReportModal({ show, close, report }) {
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [file, setFile] = useState([]);
  const [preview, setPreview] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (file.length) {
      Promise.all(
        file.map(() => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file.file);
        })),
      ).then(
        (images) => {
          setPreview(images);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [file]);

  const onReport = () => {
    const formData = new FormData();
    file.map((item) => {
      formData.append('attachment', item.file);
      return '';
    });
    formData.append('category', title.key);
    formData.append('description', body);
    report(formData);
    setTitle(null);
    setBody(null);
    setFile([]);
    setPreview([]);
  };

  return (
    <Modal
      show={show}
      onHide={() => close(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Heading className="font-weight-bold">
          Report
          {title}
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <SelectMenu
            selected={title}
            setSelected={setTitle}
            options={DropdownData.reportReasonOptions}
            placeholder="Title..."
          />
        </div>
        <div className="my-2">
          <textarea
            className="form-control form-control-sm my-2 w-100"
            rows="5"
            placeholder="Reason..."
            onChange={(e) => setBody(e.target.value)}
            value={body}
            required
          />
        </div>
        <div className="my-2 d-flex">
          <div className="mr-2">Attachments</div>
          <PlusButton onClick={() => setModalShow(true)} />
          {preview.length ? (
            <div className="d-flex mx-3 my-1 overflow-auto">
              {preview.map((src) => (
                <img
                  src={src}
                  alt="#Error"
                  width="100px"
                  height="100px"
                  className="mr-1"
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className="my-2 text-center">
          <Button
            variant="outlined"
            value="Report"
            onClick={onReport}
            dissabled={!(title && body)}
          />
        </div>
        <ModalUpload
          show={modalShow}
          close={setModalShow}
          size={1}
          accept={'image/*'}
          addFile={setFile}
          title="Add Your Images or Videos"
        />
      </Modal.Body>
    </Modal>
  );
}

ReportModal.defaultProps = {
  show: false,
  close: () => {},
  report: () => {},
};

ReportModal.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  report: PropTypes.func,
};

export default ReportModal;
