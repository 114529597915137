import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';
import Slider from 'react-slick';
import UserIcon from 'src/assets/images/livit/users.png';
import HubIcon from 'src/assets/images/livit/hubs.png';
import NetworkIcon from 'src/assets/images/livit/network.png';
import PagesIcon from 'src/assets/images/livit/pages.png';
import Card from 'src/components/AnalyticsCard';
import Breadcrumb from 'src/components/Breadcrumb';
import { getAllPages } from 'src/redux/pageRedux';
import { getAllNetworks } from 'src/redux/networkRedux';
import { getAllAdminHubs } from 'src/redux/hubRedux';
import {
  getAllAdminUsers,
  getUserStats,
  getOnlineUsers,
  getAdminLogs,
  loadMoreAdminLogs,
} from 'src/redux/usersRedux';
import Loader from 'src/components/Loader';
import {
  ADMIN_HUBS,
  ADMIN_NETWORKS,
  ADMIN_PAGES,
  ADMIN_USERS,
} from 'src/constants/routes';
import Map from 'src/components/GoogleMap';
import { GOOGLE_API_KEY } from 'src/constants/creds';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import LoadMore from 'src/components/LoadMore';
import {
  ProfilePic,
  ScrollableSection,
} from 'src/components/StyledComponents';
import { Chart, ArcElement } from 'chart.js';
import { Colors } from './styles';

Chart.register(ArcElement);

const position = localStorage.getItem('location-coords')
  ? JSON.parse(localStorage.getItem('location-coords'))
  : null;

function Overview(props) {
  const [onlineCount, setonlineCount] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [adminLogsData, setAdminLogsData] = useState([]);

  const dispatch = useDispatch();

  const { allHubs } = useSelector((state) => state.HubsReducer);
  const { metaDataNetworks } = useSelector((state) => state.NetworkReducer);
  const { metaDataPages } = useSelector((state) => state.PageReducer);
  const {
    pagination, allUsersStats, allOnlineUsers, adminLogs,
  } = useSelector(
    (state) => state.UsersReducer,
  );

  useEffect(() => {
    dispatch(getAllAdminHubs());
    dispatch(getAllNetworks());
    dispatch(getAllPages());
    dispatch(getAllAdminUsers());
    dispatch(getUserStats());
    dispatch(getAdminLogs());
    return () => setAdminLogsData([]);
  }, []);

  useEffect(() => {
    if (Object.keys(pagination).length) {
      const usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(100)
        .setStatus(CometChat.USER_STATUS.ONLINE)
        .build();
      usersRequest.fetchNext().then(
        (userList) => {
          setonlineCount(userList.length);
          const fetchedList = userList.map((user) => user.uid);
          if (fetchedList.length > 0) dispatch(getOnlineUsers({ users: fetchedList }));
        },
        (error) => {
          console.log('User list fetching failed with error:', error);
        },
      );
    }
  }, [pagination]);

  useEffect(() => {
    if (allOnlineUsers.length) {
      const tempMarkers = [];
      allOnlineUsers.forEach((member) => {
        if (member.last_login_details) {
          tempMarkers.push({
            id: member.id,
            lat: member.last_login_details.latitude,
            lng: member.last_login_details.longitude,
            name: member.name,
            username: member.username ? member.username : null,
            avatar: member.original_avatar
              ? member.original_avatar
              : ProfilePictureIcon,
            role: member.role ? member.role : null,
          });
        }
      });
      setMarkers(tempMarkers);
    }
  }, [allOnlineUsers]);

  useEffect(() => {
    if (adminLogs.data && adminLogs.data.length > 0) {
      adminLogs.meta.pagination.current_page === 1
        ? setAdminLogsData(adminLogs.data)
        : setAdminLogsData([...adminLogsData, ...adminLogs.data]);
    }
  }, [adminLogs]);

  const roleCountOptions = {
    maintainAspectRatio: true,
    elements: {
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
    legend: {
      display: true,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const roleCountData = {
    labels: [
      'Full Members',
      'Junior Members',
      'Managed Members',
      'Supervised Members',
    ],
    maintainAspectRatio: true,
    responsive: false,

    datasets: [
      Object.keys(allUsersStats).length
        ? {
          data: [
            allUsersStats.full_member,
            allUsersStats.junior_member,
            allUsersStats.managed_member,
            allUsersStats.supervised_member,
          ],
          backgroundColor: Colors,
          hoverBackgroundColor: Colors,
        }
        : {
          data: [28, 120, 102, 78],
          backgroundColor: Colors,
          hoverBackgroundColor: Colors,
        },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Breadcrumb parent="Overview" title="Admin" />
      <div className="conatiner-fluid">
        <div className="row">
          <div className="col-xl-7 xl-100">
            <div className="row">
              <div className="col-md-12 ecommerce-slider">
                <Slider {...settings}>
                  {Object.keys(allHubs).length ? (
                    <Card
                      heading="Total Hubs"
                      count={allHubs.meta.pagination.total}
                      icon={HubIcon}
                      show={() => props.navigate(ADMIN_HUBS)}
                    />
                  ) : (
                    <Loader show />
                  )}
                  {Object.keys(metaDataNetworks).length ? (
                    <Card
                      heading="Total Networks"
                      count={metaDataNetworks.total}
                      icon={NetworkIcon}
                      show={() => props.navigate(ADMIN_NETWORKS)}
                    />
                  ) : (
                    <Loader show />
                  )}
                  {Object.keys(metaDataPages).length ? (
                    <Card
                      heading="Total Pages"
                      count={metaDataPages.total}
                      icon={PagesIcon}
                      show={() => props.navigate(ADMIN_PAGES)}
                    />
                  ) : (
                    <Loader show />
                  )}
                  {Object.keys(pagination).length ? (
                    <Card
                      heading="Total Users"
                      count={pagination.total}
                      icon={UserIcon}
                      show={() => props.navigate({
                        pathname: ADMIN_USERS,
                        state: { state: 'user' },
                      })}
                    />
                  ) : (
                    <Loader show />
                  )}

                  <Card
                    heading="Total Online Users"
                    count={onlineCount}
                    icon={UserIcon}
                    show={() => props.navigate({
                      pathname: ADMIN_USERS,
                      state: { state: 'user-online' },
                    })}
                  />
                </Slider>
              </div>
              <div className="col-md-12">
                <div className="card h-100">
                  <div className="card-header">
                    <h5>Members</h5>
                  </div>
                  <div className="card-body charts-box">
                    <Doughnut
                      data={roleCountData}
                      options={roleCountOptions}
                      datasetKeyProvider={() => Math.random()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 xl-100">
            <div className="card h-100">
              <div className="card-header">
                <h5>Activities</h5>
              </div>
              <div className="card-body log-content">
                <ScrollableSection id="scroll-admin-logs">
                  {adminLogsData.length ? (
                    <LoadMore
                      data={adminLogsData}
                      getMore={() => dispatch(
                        loadMoreAdminLogs({
                          url: adminLogs.meta.pagination.links.next,
                        }),
                      )}
                      target="scroll-admin-logs"
                      meta={adminLogs.meta.pagination}
                    >
                      {adminLogsData.map((data, index) => (
                        <div className="d-flex w-100 my-3" key={index}>
                          {' '}
                          <ProfilePic style={{ width: '40px' }}>
                            <img
                              className="rounded-circle"
                              src={
                                  data.resource.avatar
                                    ? data.resource.avatar
                                    : ProfilePictureIcon
                                }
                              alt="#profile icon"
                              width="100%"
                              height="100%"
                            />
                          </ProfilePic>
                          <div className="w-75">
                            <span className="ml-1">{data.description}</span>
                          </div>
                          <div className="w-25 text-center">
                            <span className="pull-right">
                              {data.log_created_at}
                            </span>
                          </div>
                        </div>
                      ))}
                    </LoadMore>
                  ) : (
                    <Loader show />
                  )}
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-4">
            <div className="card" style={{ height: '720px' }}>
              <div className="card-header">
                <h5>Active Users Locations</h5>
              </div>
              <div className="card-body">
                <Map
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  markers={markers}
                  coords={position}
                  error={false}
                  center={{ latitude: 19.88, longitude: 50.48 }}
                  zoom={3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
