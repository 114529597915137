import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { loadMoreData } from 'src/api';
import {
  fetchAllHUbs,
  fetchAllAdminHubs,
  fetchHubsWithPage,
  fetchAdminHubMembers,
  fetchHubMembers,
  fetchHub,
  fetchHubFeeds,
  fetchHubTodos,
  postHubTodoList,
  updateHubTodoList,
  fetchHubTodoSublists,
  fetchHubTasks,
  postHubTask,
  updateHubTask,
  toggleHubTask,
  deleteHubTask,
  fetchHubVaultFiles,
  fetchHubVaultFolders,
  postHubVaultFolder,
  fetchHubRemainders,
  fetchHubRemaindersMonthly,
  fetchHubVaultFolderFiles,
  postFileInHubFolder,
  postReactionOnHubFeed,
  deleteReactionOnHubFeed,
  fetchCommentsOnHubFeed,
  postCommentsOnHubFeed,
  deleteCommentsOnHubFeed,
  updateHubDetails,
  deleteAdminHubs,
  fetchHubFolder,
  fetchAllHubFolders,
  postHubFolder,
  postHubFile,
  deleteHubFolder,
  deleteHubFile,
  postHubFeed,
  fetchHubPlaylists,
  postHubPlaylist,
  fetchHubPlaylistTracks,
  postTrackInHubPlaylist,
  updateHubPlaylist,
  deleteHubPlaylist,
  reorderHubPlaylistTracks,
  updateHubTheme,
} from 'src/api/hub';
import {
  saveHub,
  saveHubMembers,
  saveHubs,
  saveHubFeeds,
  saveHubTodoList,
  saveHubVaultFiles,
  saveHubVaultFolders,
  saveHubRemainders,
  saveComments,
  saveHubTodoSublist,
  saveHubTasks,
  saveAdminHubs,
  processHubs,
  saveDashboardHubs,
  saveAllHubFiles,
  saveAllHubFolders,
  saveHubFolderData2,
  saveHubFolderData3,
  saveHubPlaylists,
  saveHubPlayListTracks,
} from 'src/redux/hubRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getAllHubs() {
  try {
    yield put(processHubs({ status: true, component: 'user-hubs' }));
    const response = yield call(fetchAllHUbs);
    const hubs = response;
    for (let i = 0; i < hubs.length; i++) {
      const memberResponse = yield call(fetchHubMembers, hubs[i].id);
      hubs[i].members = memberResponse;
    }
    yield put(saveHubs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processHubs({ status: false, component: 'user-hubs' }));
    yield put(setError(false));
  }
}

function* getHubMembers(action) {
  try {
    yield put(processHubs({ status: true, component: 'hub-members' }));
    const response = yield call(fetchHubMembers, action.payload);
    yield put(saveHubMembers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processHubs({ status: false, component: 'hub-members' }));
    yield put(setError(false));
  }
}

function* getDashboardHubs() {
  try {
    yield put(processHubs({ status: true, component: 'user-hubs-dashboard' }));
    const response = yield call(fetchAllHUbs);
    yield put(saveDashboardHubs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processHubs({ status: false, component: 'user-hubs-dashboard' }));
    yield put(setError(false));
  }
}

function* getAllAdminHubs(action) {
  try {
    yield put(processing({ status: true, component: 'admin-hubs' }));
    const response = yield call(fetchAllAdminHubs, action.payload);
    yield put(saveAdminHubs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-hubs' }));
    yield put(setError(false));
  }
}
function* getAdminHubMembers(action) {
  try {
    yield put(processing({ status: true, component: 'admin-hub-members' }));
    const response = yield call(fetchAdminHubMembers, action.payload);
    yield put(saveHubMembers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-hub-members' }));
    yield put(setError(false));
  }
}

function* getHubDetails(action) {
  try {
    yield put(processing({ status: true, component: 'hub' }));
    const [
      hub,
      hubMembers,
      hubFeeds,
      hubRemainders,
      hubRemaindersMonthly,
      hubVaultFolders,
      hubTodos,
      hubVaultFiles,
    ] = yield all([
      call(fetchHub, action.payload),
      call(fetchHubMembers, action.payload),
      call(fetchHubFeeds, action.payload),
      call(fetchHubRemainders, action.payload),
      call(fetchHubRemaindersMonthly, action.payload),
      call(fetchHubVaultFolders, action.payload),
      call(fetchHubTodos, action.payload),
      call(fetchHubVaultFiles, action.payload),
    ]);
    yield put(saveHub(hub));
    yield put(saveHubMembers(hubMembers));
    yield put(saveHubFeeds(hubFeeds));
    yield put(saveHubRemainders(hubRemainders.concat(hubRemaindersMonthly)));
    yield put(saveHubVaultFolders(hubVaultFolders));
    yield put(saveHubTodoList(hubTodos));
    yield put(saveHubVaultFiles(hubVaultFiles));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub' }));
    yield put(setError(false));
  }
}

function* getHubVaultFolderFiles(action) {
  try {
    yield put(processing({ status: true, component: 'hub-vault' }));
    const response = yield call(fetchHubVaultFolderFiles, action.payload);
    yield put(saveHubVaultFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-vault' }));
    yield put(setError(false));
  }
}

function* getHubVaultAllFiles(action) {
  try {
    yield put(processing({ status: true, component: 'hub-vault' }));
    const response = yield call(fetchHubVaultFiles, action.payload);
    yield put(saveHubVaultFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-vault' }));
    yield put(setError(false));
  }
}

function* getMoreData(action) {
  try {
    yield put(processing({ status: true, component: 'user-feed' }));
    const response = yield call(loadMoreData, action.payload);
    if (action.payload.type === 'hub-feeds') {
      yield put(saveHubFeeds(response));
    }
    if (action.payload.type === 'hub-vault') {
      yield put(saveHubVaultFiles(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-feed' }));
    yield put(setError(false));
  }
}

function* getMoreHubsData(action) {
  try {
    yield put(processing({ status: true, component: 'admin-hubs-page' }));
    const response = yield call(fetchHubsWithPage, action.payload);
    yield put(saveAdminHubs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-hubs-page' }));
    yield put(setError(false));
  }
}

function* deleteHubForAdmin(action) {
  try {
    yield put(processing({ status: true, component: 'admin-hubs' }));
    yield call(deleteAdminHubs, action.payload.id);
    const response = yield call(fetchHubsWithPage, action.payload.page);
    const hubs = response.data;
    for (let i = 0; i < hubs.length; i++) {
      const memberResponse = yield call(fetchAdminHubMembers, hubs[i].id);
      hubs[i].members = memberResponse;
    }
    yield put(saveAdminHubs({ data: hubs, meta: response.meta }));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-hubs' }));
    yield put(setError(false));
  }
}

function* addNewReaction(action) {
  try {
    yield put(processing({ status: true, component: 'hub-feeds' }));
    yield call(postReactionOnHubFeed, action.payload);
    const response = yield call(fetchHubFeeds, action.payload[0]);
    yield put(saveHubFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-feeds' }));
    yield put(setError(false));
  }
}

function* removeReaction(action) {
  try {
    yield put(processing({ status: true, component: 'hub-feeds' }));
    yield call(deleteReactionOnHubFeed, action.payload);
    const response = yield call(fetchHubFeeds, action.payload.hub_id);
    yield put(saveHubFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-feeds' }));
    yield put(setError(false));
  }
}

function* getComments(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    const response = yield call(fetchCommentsOnHubFeed, action.payload);
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* addComment(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    yield call(postCommentsOnHubFeed, action.payload);
    const response = yield call(fetchCommentsOnHubFeed, {
      hub_id: action.payload[0],
      id: action.payload[1],
    });
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* removeComment(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    yield call(deleteCommentsOnHubFeed, action.payload);
    const response = yield call(fetchCommentsOnHubFeed, {
      hub_id: action.payload.hub_id,
      id: action.payload.id,
    });
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* updateHub(action) {
  try {
    yield call(updateHubDetails, action.payload);
    const hub = yield call(fetchHub, action.payload.id);
    yield put(saveHub(hub));
  } catch (error) {
    yield put(setError(true));
  }
}

function* getTodoList(action) {
  try {
    yield put(processing({ status: true, component: 'hub-todolist' }));
    const response = yield call(fetchHubTodos, action.payload);
    yield put(saveHubTodoList(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-todolist' }));
    yield put(setError(false));
  }
}

function* getTodoSublist(action) {
  try {
    yield put(processing({ status: true, component: 'hub-todosublist' }));
    const response = yield call(fetchHubTodoSublists, action.payload);
    yield put(saveHubTodoSublist(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-todosublist' }));
    yield put(setError(false));
  }
}
function* getTasks(action) {
  try {
    yield put(processing({ status: true, component: 'hub-tasks' }));
    const response = yield call(fetchHubTasks, action.payload);
    yield put(saveHubTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-tasks' }));
    yield put(setError(false));
  }
}

function* addNewList(action) {
  try {
    yield put(processing({ status: true, component: 'hub-todolist' }));
    yield call(postHubTodoList, action.payload);
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-todolist' }));
    yield put(setError(false));
  }
}

function* addNewTask(action) {
  try {
    yield put(processing({ status: true, component: 'hub-tasks' }));
    yield call(postHubTask, action.payload);
    const response = yield call(fetchHubTasks, action.payload);
    yield put(saveHubTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-tasks' }));
    yield put(setError(false));
  }
}

function* changeTasks(action) {
  try {
    yield put(processing({ status: true, component: 'hub-tasksc' }));
    yield call(updateHubTask, action.payload);
    const response = yield call(fetchHubTasks, {
      hub_id: action.payload[0],
      todo_id: action.payload[1],
    });
    yield put(saveHubTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-tasksc' }));
    yield put(setError(false));
  }
}

function* markHubTask(action) {
  try {
    yield put(processing({ status: true, component: 'hub-tasksc' }));
    yield call(toggleHubTask, action.payload);
    const response = yield call(fetchHubTasks, {
      hub_id: action.payload[0],
      todo_id: action.payload[1],
    });
    yield put(saveHubTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-tasksc' }));
    yield put(setError(false));
  }
}

function* updateList(action) {
  try {
    yield put(processing({ status: true, component: 'hub-todosublist' }));
    yield call(updateHubTodoList, action.payload);

    const response = yield call(fetchHubTodoSublists, {
      hub_id: action.payload[0],
      todo_id: action.payload[3],
    });
    yield put(saveHubTodoSublist(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-todosublist' }));
    yield put(setError(false));
  }
}

function* removeTask(action) {
  try {
    yield put(processing({ status: true, component: 'hub-tasksc' }));
    yield call(deleteHubTask, action.payload);
    const response = yield call(fetchHubTasks, {
      hub_id: action.payload[0],
      todo_id: action.payload[1],
    });
    yield put(saveHubTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-tasksc' }));
    yield put(setError(false));
  }
}

function* addVaultFolder(action) {
  try {
    yield put(processing({ status: true, component: 'hub-vault' }));
    yield call(postHubVaultFolder, action.payload);
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-vault' }));
    yield put(setError(false));
  }
}

function* getAllVaultFolders(action) {
  try {
    yield put(processing({ status: true, component: 'hub-vault' }));
    const response = yield call(fetchHubVaultFolders, action.payload);
    yield put(saveHubVaultFolders(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-vault' }));
    yield put(setError(false));
  }
}

function* addNewFile(action) {
  try {
    yield put(processing({ status: true, component: 'user-vault' }));
    yield call(postFileInHubFolder, action.payload);
    const response = yield call(fetchHubVaultFolderFiles, {
      id: action.payload[0],
      folderid: action.payload[1],
    });
    yield put(saveHubVaultFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-vault' }));
    yield put(setError(false));
  }
}

function* getAllHubFolders(action) {
  try {
    yield put(processing({ status: true, component: 'hub-files' }));
    const response = yield call(fetchAllHubFolders, action.payload);
    yield put(saveAllHubFolders(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-files' }));
    yield put(setError(false));
  }
}

function* getHubFolderFiles(action) {
  try {
    yield put(processing({ status: true, component: 'hub-folder' }));
    const response = yield call(fetchHubFolder, action.payload);
    if (action.payload.level === 2) yield put(saveHubFolderData2(response));
    else yield put(saveHubFolderData3(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-folder' }));
    yield put(setError(false));
  }
}

function* getOnlyHubFiles(action) {
  try {
    yield put(processing({ status: true, component: 'hub-dashboard-files' }));
    const response = yield call(fetchAllHubFolders, action.payload);
    const files = [];
    for (let i = 0; i < response.data.length; i++) {
      if (files.length > 5) {
        break;
      }
      if (!response.data[i].is_folder) {
        files.push(response.data[i]);
      } else {
        const OuterResponse = yield call(fetchHubFolder, {
          hub_id: action.payload,
          id: response.data[i].id,
        });
        for (let j = 0; j < OuterResponse.length; j++) {
          if (files.length > 5) {
            break;
          }
          if (!OuterResponse[j].is_folder) {
            files.push(OuterResponse[j]);
          } else {
            const InnerResponse = yield call(fetchHubFolder, {
              hub_id: action.payload,
              id: OuterResponse[j].id,
            });
            for (let k = 0; k < InnerResponse.length; k++) {
              if (files.length > 5) {
                break;
              } else {
                files.push(InnerResponse[k]);
              }
            }
          }
        }
      }
    }
    yield put(saveAllHubFiles(files));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-dashboard-files' }));
    yield put(setError(false));
  }
}

function* createHubFolder(action) {
  try {
    yield put(processing({ status: true, component: 'hub-files' }));
    yield call(postHubFolder, action.payload);
    if (action.payload.level === 2) {
      const response = yield call(fetchHubFolder, action.payload);
      yield put(saveHubFolderData2(response));
    } else {
      const response = yield call(fetchAllHubFolders, action.payload.hub_id);
      yield put(saveAllHubFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-files' }));
    yield put(setError(false));
  }
}

function* addNewHubFile(action) {
  try {
    yield put(processing({ status: true, component: 'hub-files' }));
    yield call(postHubFile, action.payload);
    if (action.payload.level === 2 || action.payload.level === 3) {
      const response = yield call(fetchHubFolder, action.payload);
      yield put(
        action.payload.level === 2
          ? saveHubFolderData2(response)
          : saveHubFolderData3(response),
      );
    } else {
      const response = yield call(fetchAllHubFolders, action.payload.hub_id);
      yield put(saveAllHubFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-files' }));
    yield put(setError(false));
  }
}

function* removeHubFolder(action) {
  try {
    yield put(processing({ status: true, component: 'hub-files' }));
    yield call(deleteHubFolder, action.payload);
    if (action.payload.level === 2) {
      const response = yield call(fetchHubFolder, {
        hub_id: action.payload.hub_id,
        id: action.payload.parent_id,
      });
      yield put(saveHubFolderData2(response));
    } else {
      const response = yield call(fetchAllHubFolders, action.payload.hub_id);
      yield put(saveAllHubFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-files' }));
    yield put(setError(false));
  }
}

function* removeHubFile(action) {
  try {
    yield put(processing({ status: true, component: 'hub-files' }));
    yield call(deleteHubFile, action.payload);
    if (action.payload.level === 2 || action.payload.level === 3) {
      const response = yield call(fetchHubFolder, {
        hub_id: action.payload.hub_id,
        id: action.payload.parent_id,
      });
      yield put(
        action.payload.level === 2
          ? saveHubFolderData2(response)
          : saveHubFolderData3(response),
      );
    } else {
      const response = yield call(fetchAllHubFolders, action.payload.hub_id);
      yield put(saveAllHubFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-files' }));
    yield put(setError(false));
  }
}

function* addNewHubFeed(action) {
  try {
    yield put(processing({ status: true, component: 'hub-feeds' }));
    yield call(postHubFeed, action.payload);
    const response = yield call(fetchHubFeeds, action.payload.id);
    yield put(saveHubFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-feeds' }));
    yield put(setError(false));
  }
}

function* getHubPlaylists(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist' }));
    const response = yield call(fetchHubPlaylists, action.payload);
    yield put(saveHubPlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist' }));
    yield put(setError(false));
  }
}

function* addHubPlaylist(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist' }));
    yield call(postHubPlaylist, action.payload);
    const response = yield call(fetchHubPlaylists, { id: action.payload[0] });
    yield put(saveHubPlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist' }));
    yield put(setError(false));
  }
}

function* removeHubPlaylist(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist' }));
    yield call(deleteHubPlaylist, action.payload);
    const response = yield call(fetchHubPlaylists, { id: action.payload.id });
    yield put(saveHubPlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist' }));
    yield put(setError(false));
  }
}

function* changeHubPlaylist(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist-tracks' }));
    yield call(updateHubPlaylist, action.payload);
    const response = yield call(fetchHubPlaylistTracks, {
      id: action.payload[0],
      p_id: action.payload[1],
    });
    yield put(saveHubPlayListTracks(response));
    const response2 = yield call(fetchHubPlaylists, { id: action.payload[0] });
    yield put(saveHubPlaylists(response2));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist-tracks' }));
    yield put(setError(false));
  }
}

function* getHubPlaylistTracks(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist-tracks' }));
    const response = yield call(fetchHubPlaylistTracks, action.payload);
    yield put(saveHubPlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist-tracks' }));
    yield put(setError(false));
  }
}

function* addTrackInHubPlaylist(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist-tracks' }));
    yield call(postTrackInHubPlaylist, action.payload);
    const response = yield call(fetchHubPlaylistTracks, {
      id: action.payload[0],
      p_id: action.payload[1],
    });
    yield put(saveHubPlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist-tracks' }));
    yield put(setError(false));
  }
}

function* reorderHubTracks(action) {
  try {
    yield put(processing({ status: true, component: 'hub-playlist-tracks' }));
    yield call(reorderHubPlaylistTracks, action.payload);
    const response = yield call(fetchHubPlaylistTracks, {
      id: action.payload[0],
      p_id: action.payload[1],
    });
    yield put(saveHubPlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-playlist-tracks' }));
    yield put(setError(false));
  }
}

function* changeHubTheme(action) {
  try {
    yield put(processing({ status: true, component: 'hub-theme' }));
    yield call(updateHubTheme, action.payload);
    const response = yield call(fetchHub, action.payload.id);
    yield put(saveHub(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'hub-theme' }));
    yield put(setError(false));
  }
}

export function* watchHubsSaga() {
  yield takeLatest('GET_ALL_HUBS', getAllHubs);
  yield takeLatest('GET_DASHBOARD_HUBS', getDashboardHubs);
  yield takeLatest('GET_ALL_ADMIN_HUBS', getAllAdminHubs);
  yield takeLatest('GET_HUB_MEMBERS', getHubMembers);
  yield takeLatest('GET_ADMIN_HUB_MEMBERS', getAdminHubMembers);
  yield takeLatest('GET_HUB_DETAILS', getHubDetails);
  yield takeLatest('GET_VAULT_FOLDER_FILES', getHubVaultFolderFiles);
  yield takeLatest('GET_VAULT_ALL_FILES', getHubVaultAllFiles);
  yield takeLatest('ADD_REACTION_HUB_FEED', addNewReaction);
  yield takeLatest('REMOVE_REACTION_HUB_FEED', removeReaction);
  yield takeLatest('GET_COMMENTS_HUB_FEED', getComments);
  yield takeLatest('ADD_COMMENT_HUB_FEED', addComment);
  yield takeLatest('DELETE_COMMENT_HUB_FEED', removeComment);
  yield takeLatest('LOAD_MORE_HUB_FEEDS', getMoreData);
  yield takeLatest('LOAD_MORE_HUBS', getMoreHubsData);
  yield takeLatest('UPDATE_HUB', updateHub);
  yield takeLatest('GET_HUB_TODO_LISTS', getTodoList);
  yield takeLatest('GET_HUB_TODO_SUBLIST', getTodoSublist);
  yield takeLatest('GET_HUB_TASKS', getTasks);
  yield takeLatest('ADD_NEW_HUB_LIST', addNewList);
  yield takeLatest('ADD_NEW_HUB_TASK', addNewTask);
  yield takeLatest('UPDATE_HUB_TASK', changeTasks);
  yield takeLatest('TOGGLE_HUB_TASK', markHubTask);
  yield takeLatest('UPDATE_HUB_LIST', updateList);
  yield takeLatest('DELETE_HUB_TASK', removeTask);
  yield takeLatest('ADD_HUB_FOLDER', addVaultFolder);
  yield takeLatest('GET_ALL_HUB_VAULT_FOLDERS', getAllVaultFolders);
  yield takeLatest('ADD_HUB_FILE', addNewFile);
  yield takeLatest('DELETE_HUB', deleteHubForAdmin);
  yield takeLatest('GET_ALL_HUB_FOLDERS', getAllHubFolders);
  yield takeLatest('GET_ONLY_HUB_FILES', getOnlyHubFiles);
  yield takeLatest('GET_HUB_FOLDER_DATA', getHubFolderFiles);
  yield takeLatest('CREATE_HUB_FOLDER', createHubFolder);
  yield takeLatest('UPLOAD_HUB_FILE', addNewHubFile);
  yield takeLatest('REMOVE_HUB_FOLDER', removeHubFolder);
  yield takeLatest('REMOVE_HUB_FILE', removeHubFile);
  yield takeLatest('ADD_HUB_FEED', addNewHubFeed);
  yield takeLatest('GET_HUB_PLAYLISTS', getHubPlaylists);
  yield takeLatest('CREATE_HUB_PLAYLIST', addHubPlaylist);
  yield takeLatest('UPDATE_HUB_PLAYLIST', changeHubPlaylist);
  yield takeLatest('GET_HUB_PLAYLIST_TRACKS', getHubPlaylistTracks);
  yield takeLatest('ADD_HUB_PLAYLIST_TRACKS', addTrackInHubPlaylist);
  yield takeLatest('DELETE_HUB_PLAYLIST', removeHubPlaylist);
  yield takeLatest('REORDER_HUB_TRACKS', reorderHubTracks);
  yield takeLatest('UPDATE_HUB_THEME', changeHubTheme);
}
