import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppLayout from 'src/components/app';
import { MAIN_ROUTE } from 'src/constants/routes';

export function PrivateRoute({ children }) {
  const token = useSelector((state) => state.AuthReducer.userToken);
  const jwt = localStorage.getItem('token');

  // Private route, redirect if not jwt and token
  if (!jwt && !token) {
    return (
      <Navigate to={MAIN_ROUTE} replace />
    );
  }

  return (
    <AppLayout>
      {children}
    </AppLayout>
  );
}

export default PrivateRoute;
