import React from 'react';
import { Heading } from 'src/components/StyledComponents';

function HubList(props) {
  if (
    props.hub
    && props.hubs
    && !Object.keys(props.hub).length
    && props.hubs.length
  ) {
    props.setHub(props.hubs[0]);
  }
  return props.state === 'category' ? (
    props.categories.length ? (
      <ul className="list-group mt-2">
        {props.categories.map((category, index) => (
          <li
            key={index}
            className={`list-group-item ${
              category === props.category ? 'active' : null
            }`}
            onClick={() => props.setCategory(category)}
          >
            <span>
              {category[0].toUpperCase() + category.slice(1, category.length)}
            </span>
          </li>
        ))}
      </ul>
    ) : (
      <Heading className="text-center h4 mt-4">
        You have not joined any hub.
      </Heading>
    )
  ) : props.hubs.length ? (
    <ul className="list-group mt-2">
      {props.hubs.map((hub, index) => (
        <li
          key={index}
          className={`list-group-item ${
            hub.id === props.hub.id ? 'active' : null
          }`}
          onClick={() => props.setHub(hub)}
        >
          <span>{hub.name}</span>
        </li>
      ))}
    </ul>
  ) : (
    <Heading className="text-center h4 mt-4">
      You either do not have any hub in this category or you have not joined any
      Hub.
    </Heading>
  );
}

export default HubList;
