import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ImageContainer } from 'src/containers/HubHome/styles';
import { Heading } from 'src/components/StyledComponents';
import Loader from 'src/components/Loader';
import FsLightbox from 'fslightbox-react';
import 'react-image-lightbox/style.css';

function Vault(props) {
  const [images, setImage] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { hubVaultFiles } = useSelector((state) => state.HubsReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    setImage(
      hubVaultFiles.filter((file) => (
        file.original_url.split('.').pop() === 'jpg'
          || file.original_url.split('.').pop() === 'jpeg'
          || file.original_url.split('.').pop() === 'png'
          || file.is_video
      )),
    );
  }, [hubVaultFiles]);

  return (
    <>
      {processing.status
      && (processing.component === 'hub'
        || processing.component === 'hub-vault') ? (
          <Loader show />
        ) : images.length ? (
          <div>
            {images.map((file, index) => (
              <ImageContainer
                key={file.id}
                dark={props.theme.layout_version === 'dark-only'}
              >
                <img
                  src={file.thumbnail}
                  alt="Error in Loading Feed"
                  onClick={() => {
                    setPhotoIndex(index + 1);
                    setToggler(!toggler);
                  }}
                />
              </ImageContainer>
            ))}
          </div>
        ) : (
          <Heading className="text-center h4 mt-4">
            This hub does not have any Files.
          </Heading>
        )}
      {images.length ? (
        <FsLightbox
          toggler={toggler}
          slide={photoIndex}
          sources={images.map((file) => {
            if (file.is_video) return `${file.original_url.slice(0, -3)}mp4`;
            return file.original_url;
          })}
        />
      ) : null}
    </>
  );
}

export default Vault;
