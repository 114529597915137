import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const CountBadge = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${themeColor};
  color: white;
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  margin-left: 15px;
  text-align: center;
  box-shadow: 0px 0px 12px lightgrey;
`;

export const Username = styled.span`
  color: ${themeColor};
`;

export const FeedMedia = styled.div`
  width: 99%;
  height: 565px;
  border: ${(props) => (props.dark ? 'none' : '1px solid whitesmoke')};
  border-radius: 5px;
  background-color: transparent;
  position: relative;
`;

export const TextAreaField = styled.textarea`
  width: 100%;
  &:focus {
    border-color: ${themeColor};
  }
`;

export const EmojiContainer = styled.div`
  background-color: lightgrey;
  border-radius: 8px;
  padding: 2px 2px 0px;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

export const EmojiAdder = styled.div`
  position: relative;
  .emoji {
    display: none;
  }
  &:hover {
    .emoji {
      position: absolute;
      display: flex;
      top: -65px;
      background: white;
      padding: 5px 8px;
      border-radius: 10px;
    }
  }
`;
