import { call, put, takeLatest } from 'redux-saga/effects';
import { loadMoreData } from 'src/api';
import {
  fetchAllVaultFiles,
  fetchAllVaultFolders,
  fetchFolderFiles,
  postVaultFolder,
  postFileInFolder,
} from 'src/api/vault';
import {
  saveAllVaultFolders,
  saveAllVaultFiles,
  saveVaultFolderFiles,
  processVault,
} from 'src/redux/vaultRedux';
import { setError } from 'src/redux/serviceRedux';

function* getAllVaultFiles() {
  try {
    yield put(processVault({ status: true, component: 'user-vault' }));
    const response = yield call(fetchAllVaultFiles);
    yield put(saveAllVaultFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processVault({ status: false, component: 'user-vault' }));
    yield put(setError(false));
  }
}

function* getAllVaultFolders() {
  try {
    yield put(processVault({ status: true, component: 'user-vault-folders' }));
    const response = yield call(fetchAllVaultFolders);
    yield put(saveAllVaultFolders(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processVault({ status: false, component: 'user-vault-folders' }));
    yield put(setError(false));
  }
}

function* getFolderFiles(action) {
  try {
    yield put(
      processVault({ status: true, component: 'user-vault-folder-files' }),
    );
    const response = yield call(fetchFolderFiles, action.payload);
    yield put(saveVaultFolderFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processVault({ status: false, component: 'user-vault-folder-files' }),
    );
    yield put(setError(false));
  }
}

function* addVaultFolder(action) {
  try {
    yield put(processVault({ status: true, component: 'user-vault-folders' }));
    yield call(postVaultFolder, action.payload);
    const response = yield call(fetchAllVaultFolders);
    yield put(saveAllVaultFolders(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processVault({ status: false, component: 'user-vault-folders' }));
    yield put(setError(false));
  }
}

function* addNewFile(action) {
  try {
    yield put(
      processVault({ status: true, component: 'user-vault-folder-files' }),
    );
    yield call(postFileInFolder, action.payload);
    const response = yield call(fetchFolderFiles, action.payload[0]);
    yield put(saveVaultFolderFiles(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processVault({ status: false, component: 'user-vault-folder-files' }),
    );
    yield put(setError(false));
  }
}

function* getMoreData(action) {
  try {
    yield put(processVault({ status: true, component: 'user-v' }));
    const response = yield call(loadMoreData, action.payload);
    if (action.payload.type === 'all') {
      yield put(saveAllVaultFiles(response));
    } else {
      yield put(saveVaultFolderFiles(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processVault({ status: false, component: 'user-v' }));
    yield put(setError(false));
  }
}

export function* watchVaultSaga() {
  yield takeLatest('GET_ALL_VAULT_FILES', getAllVaultFiles);
  yield takeLatest('GET_ALL_VAULT_FOLDERS', getAllVaultFolders);
  yield takeLatest('GET_FOLDER_FILES', getFolderFiles);
  yield takeLatest('ADD_FOLDER', addVaultFolder);
  yield takeLatest('ADD_FILE', addNewFile);
  yield takeLatest('LOAD_MORE_VAULT', getMoreData);
}
