import React from 'react';
import Iframe from 'react-iframe';
import { PreviewFile } from 'src/containers/Files/styles';
import PropTypes from 'prop-types';
import { SectionInfo } from 'src/containers/Files/Sections';

function Preview({ file }) {
  const isImage = () => {
    const { name } = file;
    return name.split('.').pop().toUpperCase() === 'JPG'
      || name.split('.').pop().toUpperCase() === 'PNG'
      || name.split('.').pop().toUpperCase() === 'JPEG';
  };
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-12 col-sm-12 h-50">
          <div
            className="py-2"
            style={{ borderTop: '4px solid lightgrey', height: '100%' }}
          >
            {file ? (
              <PreviewFile>
                {isImage() ? (
                  <img
                    src={file.path}
                    alt="Error Loading in File"
                    width="100%"
                    className="mt-2"
                  />
                ) : (
                  <Iframe
                    url={file.path}
                    width="100%"
                    height="100%"
                    display="initial"
                    position="relative"
                    id="preview-frame"
                  />
                )}
              </PreviewFile>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 d-sm-none d-md-inline">
          <SectionInfo file={file} />
        </div>
      </div>
    </div>
  );
}

Preview.propTypes = {
  file: PropTypes.objectOf({
    name: PropTypes.string,
    path: PropTypes.string,
  }),
};

Preview.defaultProps = {
  file: null,
};

export default Preview;
