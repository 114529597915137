import React, { useState, useEffect } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import InputField from 'src/components/InputField';
import { useSelector, useDispatch } from 'react-redux';
import { Search } from 'react-feather';
import { Button } from 'src/components/Button';
import {
  addNewBannedWord,
  getBannedWords,
  updateBannedWord,
  removeBannedWord,
} from 'src/redux/bannedWordsRedux';
import Breadcrumb from 'src/components/Breadcrumb';
import { Word, InputBox } from './styles';

function BannedWords() {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState('');
  const [editableWord, setEditableWord] = useState('');

  const { bannedWords } = useSelector((state) => state.BannedWordsReducer);

  useEffect(() => {
    dispatch(getBannedWords());
    if (bannedWords && bannedWords.length > 0) {
      bannedWords.sort((a, b) => (a.word > b.word ? 1 : -1));
    }
  }, []);

  const addWordKeyPressed = (e) => {
    if (e.key === 'Enter') {
      const text = e.target.value;
      if (text && text.length) {
        dispatch(addNewBannedWord({ word: text }));
      }
    }
  };

  const onSelect = (item) => {
    if (selectedId === item.id) {
      setSelectedId('');
      setEditableWord('');
    } else {
      setSelectedId(item.id);
      setEditableWord(item.word);
    }
  };

  const onSelectedWordChange = (e) => {
    setEditableWord(e.target.value);
  };

  const onDeleteWordPress = () => {
    dispatch(removeBannedWord({ id: selectedId }));
    setSelectedId('');
    setEditableWord('');
  };

  const onUpdateWordPress = () => {
    if (!editableWord) {
      alert('Please enter word!');
      return;
    }

    dispatch(updateBannedWord({ id: selectedId, word: editableWord }));
    setSelectedId('');
    setEditableWord('');
  };

  return (
    <>
      <Breadcrumb parent="Banned Words" title="Admin" />
      <div className="conatiner-fluid">
        <div className="row">
          <div className="col-md-9">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>
                  {bannedWords
                  && bannedWords.words
                  && bannedWords.words.length > 0
                    ? `${bannedWords.words.length} Banned names`
                    : ' '}
                </h5>
              </div>
              <div className="card-body">
                <ScrollableSection height="90%">
                  {bannedWords
                  && bannedWords.words
                  && bannedWords.words.length ? (
                      bannedWords.words.map((item, index) => (
                        <div key={index} className="container">
                          <div className="row">
                            <div className="col-1 px-4 py-2 font-weight-bold h4 text-dark">
                              {index === 0 ? (
                                <span>{item.word.charAt(0).toUpperCase()}</span>
                              ) : bannedWords.words[index].word.charAt(0)
                                !== bannedWords.words[index - 1].word.charAt(0) ? (
                                  <span>{item.word.charAt(0).toUpperCase()}</span>
                                ) : null}
                            </div>
                            <Word
                              onClick={() => onSelect(item)}
                              key={item.id}
                              className="d-flex justify-content-between pb-3 px-3 pt-2"
                            >
                              {item.word}
                            </Word>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="mt-4 pt-4 d-flex justify-content-center w-100">
                          <div style={{ textAlign: 'center' }}>
                            <Search size={80} alignmentBaseline="center" />
                            <div className="h4 mt-2 ml-4">
                              No Banned words Found
                            </div>
                          </div>
                      </div>
                    )}
                </ScrollableSection>
                <InputBox>
                  <InputField
                    className="form-control"
                    type="text"
                    placeholder="Add new word"
                    borderRadius="30px"
                    fontSize="16px"
                    padding="0px 0px 0px 50px"
                    onKeyUp={addWordKeyPressed}
                  />
                </InputBox>
              </div>
            </div>
          </div>
          {selectedId
            && bannedWords
            && bannedWords.words
            && bannedWords.words.some((item) => item.id === selectedId) && (
              <div className="col-md-3">
                <div className="card">
                  <div className="card-header">
                    <h5>Edit</h5>
                  </div>
                  <div className="card-body">
                    <InputField
                      className="form-control"
                      type="text"
                      value={editableWord}
                      borderRadius="30px"
                      fontSize="13px"
                      padding="0px 0px 0px 20px"
                      marginBottom="10px"
                      onChange={onSelectedWordChange}
                    />
                    <div className="row p-3 justify-content-between">
                      <Button
                        onClick={onDeleteWordPress}
                        variant="solid"
                        value="Delete"
                        padding="3px 12px"
                        fontSize="14px"
                      />

                      <Button
                        onClick={onUpdateWordPress}
                        variant="solid"
                        value="Update"
                        padding="3px 12px"
                        fontSize="14px"
                      />
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BannedWords;
