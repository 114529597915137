import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchBannedWords,
  postBannedWord,
  putBannedWord,
  delBannedWord,
} from 'src/api/bannedWords';
import { processing, setError } from 'src/redux/serviceRedux';
import { saveBannedWords } from 'src/redux/bannedWordsRedux';

function* getBannedWords() {
  try {
    yield put(processing({ status: true, component: 'banned-words' }));
    const response = yield call(fetchBannedWords);
    yield put(saveBannedWords(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'banned-words' }));
    yield put(setError(false));
  }
}

function* addNewBannedWord(action) {
  try {
    yield put(processing({ status: true, component: 'banned-words' }));
    yield call(postBannedWord, action.payload);
    const response = yield call(fetchBannedWords);
    yield put(saveBannedWords(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'banned-words' }));
    yield put(setError(false));
  }
}

function* updateBannedWord(action) {
  try {
    // eslint-disable-next-line
    yield put(processing({ status: true, component: "banned-words" }));
    yield call(putBannedWord, action.payload);
    const response = yield call(fetchBannedWords);
    yield put(saveBannedWords(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'banned-words' }));
    yield put(setError(false));
  }
}

function* removeBannedWord(action) {
  try {
    yield put(processing({ status: true, component: 'banned-words' }));
    yield call(delBannedWord, action.payload);
    const response = yield call(fetchBannedWords);
    yield put(saveBannedWords(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'banned-words' }));
    yield put(setError(false));
  }
}

export function* watchBannedWordsSaga() {
  yield takeLatest('GET_BANNED_WORDS', getBannedWords);
  yield takeLatest('ADD_NEW_BANNED_WORD', addNewBannedWord);
  yield takeLatest('UPDATE_BANNED_WORD', updateBannedWord);
  yield takeLatest('REMOVE_BANNED_WORD', removeBannedWord);
}
