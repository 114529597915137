import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { ProfilePic } from 'src/containers/Dashboard/styles';
import { Heading } from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';
import { HubCardModal } from 'src/components/Modal';

function Hubs() {
  const [showModal, setShowModal] = useState(false);
  const [hub, setHub] = useState({});
  const { dashboardHubs, hubProcessing } = useSelector(
    (state) => state.HubsReducer,
  );

  return hubProcessing.status
    && hubProcessing.component === 'user-hubs-dashboard' ? (
      <Loader show />
    ) : dashboardHubs && dashboardHubs.length ? (
      <div className="container-fluid">
        <div className="row">
          {dashboardHubs.map((data, index) => (
            <div
              key={index}
              onClick={() => {
                setHub(data);
                setShowModal(true);
              }}
              className="col-4"
              style={{ cursor: 'pointer' }}
            >
              <ProfilePic style={{ margin: 'auto' }}>
                <img
                  className="rounded-circle lazyloaded blur-up"
                  src={data.avatar ? data.avatar : ProfilePictureIcon}
                  onError={addDefaultSrc}
                  alt="#"
                  width="38px"
                  height="38px"
                />
              </ProfilePic>
              <div className="text-center mb-1">{data.alias.slice(0, 6)}</div>
            </div>
          ))}
        </div>
        <HubCardModal
          show={showModal}
          setShow={setShowModal}
          hub={hub}
        />
      </div>
    ) : (
      <Heading className="text-center h4 mt-4">You have No Hubs.</Heading>
    );
}

export default Hubs;
