import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Mail, Phone, Video } from 'react-feather';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { actionHandler, addDefaultSrc } from 'src/utils/helper';
import { USER_MESSAGES } from 'src/constants/routes';
import * as enums from 'src/CometChatWorkspace/src/util/enums';
import { defaultGradient } from 'src/utils/theme';
import { Button } from 'src/components/Button';
import { ProfilePic, Shape } from './styles';

function HubCardModal({ show, hub, setShow }) {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={() => {}}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="card">
          <Shape
            color={
              hub.theme
              && hub.theme.mobile
              && hub.theme.mobile.header_color
                ? hub.theme.mobile.header_color
                : defaultGradient
            }
          />
          <div
            className="text-center w-100"
            style={{ marginTop: '-50px', zIndex: '1' }}
          >
            <ProfilePic>
              <img
                className="rounded-circle lazyloaded"
                src={
                  hub.original_avatar
                    ? hub.original_avatar
                    : ProfilePictureIcon
                }
                alt="icon"
                width="100%"
                height="100%"
                onError={addDefaultSrc}
              />
            </ProfilePic>
            <div className="my-2 h5">
              {hub.name ? hub.name : ''}
            </div>
            <div className="my-2">
              {hub.alias ? `@${hub.alias}` : '@-'}
            </div>
            <div className="my-2">
              {hub.category
                ? hub.category[0].toUpperCase()
                  + hub.category.slice(1, hub.category.length)
                : ''}
            </div>
            {localStorage.getItem('impersonate-user') === null ? (
              <>
                <div className="text-center">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(`hub/${hub.id}`);
                    }}
                    value="View"
                  />
                </div>
                <div className="btn">
                  <Mail
                    className="mx-2"
                    onClick={() => {
                      navigate({
                        pathname: USER_MESSAGES,
                        state: {
                          contact: {
                            id: `hub_${hub.id.toString()}`,
                            type: 'group',
                          },
                        },
                      });
                    }}
                  />
                  <Phone
                    className="mx-2"
                    onClick={() => {
                      actionHandler(enums.ACTIONS.INITIATE_AUDIO_CALL, {
                        type: 'group',
                        id: `hub_${hub.id}`,
                      });
                      setShow(false);
                    }}
                  />
                  <Video
                    className="mx-2"
                    onClick={() => {
                      actionHandler(enums.ACTIONS.INITIATE_VIDEO_CALL, {
                        type: 'group',
                        id: `hub_${hub.id}`,
                      });
                      setShow(false);
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="text-center">
          <Button
            variant="solid"
            onClick={() => {
              setShow(false);
            }}
            value="Close"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

HubCardModal.defaultProps = {
  show: false,
  setShow: () => {},
  hub: '',
};

HubCardModal.propTypes = {
  show: PropTypes.bool,
  hub: PropTypes.string,
  setShow: PropTypes.func,
};

export default HubCardModal;
