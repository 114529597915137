import { axiosInstance } from 'src/api';
import { refreshNapsterToken } from 'src/api/audioPlayer';
import { refreshUserToken } from 'src/api/auth';
import { loginNapsterSuccess } from 'src/redux/audioPlayerRedux';
import { requestLogout } from 'src/redux/authRedux';

import Swal from 'sweetalert2';
import * as serviceWorker from './serviceWorker';

let isRefreshing = false;
let failedQueue = [];

export default function napster(dispatch) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('napster.member.refreshToken');
      const napsterTokenexpireTime = new Date(
        localStorage.getItem('napster_token_expire_in'),
      );
      const currentTime = new Date();

      if (
        refreshToken
            && napsterTokenexpireTime < currentTime
            && originalRequest.url.includes('api.napster.com')
      ) {
        return refreshNapsterToken(refreshToken).then((res) => {
          if (res) {
            dispatch(loginNapsterSuccess(res));

            return Promise.resolve(originalRequest);
          }
          console.log('error');
          return Promise.reject();
        });
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          refreshUserToken()
            .then((data) => {
              localStorage.setItem('token', data.token);
              localStorage.setItem(
                'expire_in',
                new Date(Date.now() + data.expires_in * 1000),
              );
              originalRequest.headers.Authorization = `Bearer ${data.token}`;
              processQueue(null, data.token);
              resolve(axiosInstance(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              Swal.fire({
                title: 'You are Inactive for long time.',
                text: 'Please Login again.',
                confirmButtonColor: 'black',
                icon: 'info',
              }).then(() => {
                dispatch(requestLogout());
                localStorage.clear();
                window.location.reload();
              });
              reject(err);
            })
            .finally(() => {
              setTimeout(() => { isRefreshing = false; }, 3000);
            });
        });
      }

      if (error.response.data.error_code === 'BLOCKED_BY_ADMIN') {
        Swal.fire({
          title: 'Account Blocked',
          // eslint-disable-next-line quotes
          text: "Your Account has been blocked by Admin. You will be Logged Out from Liviit Account.",
          confirmButtonColor: 'black',
          icon: 'info',
        }).then(() => {
          dispatch(requestLogout());
          localStorage.clear();
          window.location.reload();
        });
        setTimeout(() => {
          dispatch(requestLogout());
          localStorage.clear();
          window.location.reload();
        }, 5000);
      }
      return Promise.reject(error);
    },
  );

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  serviceWorker.unregister();
}
