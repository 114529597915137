import { axiosInstance, baseUrl, getHeaderData } from '.';

// get user details
export const fetchUserDetails = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.post(`${baseUrl}me`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data.user;
  }
};

// Get Contacts
export const fetchContacts = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}contacts`, {
    params: {
      search: payload,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data;
};

// Get Linked Accounts
export const fetchLinkedAccounts = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}linked-accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

// get user activities
export const fetchUserActivities = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}activities`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'User-Activity-Type': payload,
        ...getHeaderData(),
      },
    });
    return response.data;
  }
};

// Toggle favourite contacts
export const changeContact = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}contacts/${payload.email}/toggle-favourite`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// Toggle Block Contact
export const changeContactBlock = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}contacts/${payload.id}/toggle-block`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// store new Contact
export const postContact = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(`${baseUrl}contacts`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const handleNotificationAction = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.post(`${payload.url}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      // eslint-disable-next-line
      const res = await axiosInstance.put(
        `${baseUrl}activities/${payload.id}/mark-as-read`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
  }
};

// update user details
export const updateUserDetails = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.post(`${baseUrl}put-me`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data.user;
  }
};

// get weather
export const fetchWeather = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `https://api.openweathermap.org/data/2.5/onecall?lat=${payload.lat}&lon=${payload.lon}&units=metric&exclude=hourly,minutely&appid=a5fed9967acb50ac2021ac52e8f63e1c`,
      {},
    );
    return response.data;
  }
};

// update theme
export const updateTheme = async (payload) => {
  const token = localStorage.getItem('token');

  let response;
  if (payload instanceof FormData) {
    response = await axiosInstance.post(`${baseUrl}me/theme/assets`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    response = await axiosInstance.put(`${baseUrl}me/theme`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return response.data.data;
};

// Update Contact
export const updateContact = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}contacts/${payload.email}`,
    payload.update,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
