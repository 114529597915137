import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { ProfilePic } from 'src/containers/Dashboard/styles';
import { Heading } from 'src/components/StyledComponents';
import { CometChat } from '@cometchat-pro/chat';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { addDefaultSrc } from 'src/utils/helper';

function OnlineUsers() {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, contacts } = useSelector((state) => state.ProfileReducer);
  const { hubs } = useSelector((state) => state.HubsReducer);

  useEffect(() => {
    const getOnlineUser = async () => {
      await setLoading(true);
      if (
        Object.keys(user).length > 0
        && (contacts.length > 0 || hubs.length > 0)
      ) {
        const userIdList = [];

        await contacts.forEach((contact) => {
          if (!userIdList.includes(contact.id)) userIdList.push(contact.id);
        });
        await hubs.forEach((hub) => {
          hub.members.forEach((member) => {
            if (
              !userIdList.includes(member.user_id)
              && member.user_id !== user.id
            ) userIdList.push(member.user_id);
          });
        });

        const usersRequest = new CometChat.UsersRequestBuilder()
          .setLimit(userIdList.length)
          .setUIDs(userIdList)
          .setStatus(CometChat.USER_STATUS.ONLINE)
          .build();
        await usersRequest.fetchNext().then(
          (userList) => {
            setOnlineUsers(userList);
          },
          (error) => {
            console.log('User list fetching failed with error:', error);
          },
        );
        setLoading(false);
      }
    };
    getOnlineUser();
  }, [user, hubs]);

  return (
    <div className="container-fluid">
      <div className="row">
        {loading ? (
          <Loader show />
        ) : onlineUsers.length ? (
          onlineUsers.map((person) => (
            <div key={person.uid} className="col-4">
              <ProfilePic
                online={person.status === 'online'}
                style={{ margin: 'auto' }}
              >
                <img
                  src={person.avatar ? person.avatar : ProfilePictureIcon}
                  alt="icon"
                  width="100%"
                  className="rounded-circle"
                  height="36px"
                  onError={addDefaultSrc}
                />
                <div className="dot" />
              </ProfilePic>
              <div className="mb-1 text-center">
                {person.name.split(' ')[0]}
              </div>
            </div>
          ))
        ) : (
          <Heading className="text-center h4 mt-4 w-100">
            No user is online
          </Heading>
        )}
      </div>
    </div>
  );
}

export default OnlineUsers;
