import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import PlusButton from 'src/components/PlusButton';
import { Button } from 'src/components/Button';
import SelectMenu from 'src/components/DropDown';
import { ModalUpload } from 'src/components/Modal';
import { addUserFeed } from 'src/redux/feedsRedux';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { DropdownData } from 'src/utils/data';
import { TextAreaField } from './styles';

function AddFeedModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [preview, setPreview] = useState([]);
  const [sharingOption, setSharingOption] = useState(null);
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState('');
  const [age, setAge] = useState(null);

  const dispatch = useDispatch();

  const postFeed = () => {
    const formData = new FormData();
    files.map((item, index) => {
      formData.append(`files[${index}][file]`, item.file);
      return '';
    });
    if (caption) {
      formData.append('caption', caption);
    }

    if (sharingOption) {
      formData.append('share_only_with', sharingOption.key);
    }
    if (age) {
      formData.append('age_barrier', age.key);
    }
    dispatch(addUserFeed([formData, props.feedState]));
    setCaption('');
    setSharingOption(null);
    setAge(null);
    setFiles([]);
    setPreview([]);
    props.close(false);
  };

  useEffect(() => {
    if (files.length) {
      Promise.all(
        files.map((file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file.file);
        })),
      ).then(
        (images) => {
          setPreview(images);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [files]);

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="font-weight-bold h4">{props.title}</div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="w-100">
            <div>
              <TextAreaField
                className="form-control form-control-sm m-2"
                rows="3"
                placeholder="Something to share?"
                onChange={(e) => setCaption(e.target.value)}
                value={caption}
              />
            </div>
            <div className="d-flex justify-content-between m-2">
              <div>Sharing Options</div>
              <div>
                <SelectMenu
                  selected={sharingOption}
                  setSelected={setSharingOption}
                  options={DropdownData.contactsTypeOptions}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between m-2">
              <div>Viewing Age</div>
              <div>
                <SelectMenu
                  selected={age}
                  setSelected={setAge}
                  options={DropdownData.ageOptions}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between m-2">
              <div>Attachments</div>
              <PlusButton onClick={() => setModalShow(true)} />
            </div>

            {preview.length ? (
              <div className="d-flex m-2 overflow-auto">
                {preview.map((src, index) => (src.split('/')[0].split(':')[1] === 'image' ? (
                  <img
                    key={index}
                    src={src}
                    alt="#Error"
                    width="100px"
                    height="100px"
                    className="mr-1"
                  />
                ) : (
                  <ReactPlayer
                    key={index}
                    url={src}
                    light
                    width="100px"
                    height="100px"
                  />
                )))}
              </div>
            ) : null}

            <Button
              value="Add Post"
              variant="solid"
              Width="100%"
              onClick={postFeed}
              dissabled={
                files.length === 0
                || age === 'Select'
                || localStorage.getItem('impersonate-user') !== null
                || localStorage.getItem('view-user') !== null
              }
              margin="10px 0px"
            />
          </div>
          <ModalUpload
            show={modalShow}
            close={setModalShow}
            size={8}
            addFile={setFiles}
            title="Add Your Images or Videos"
          />
        </>
      </Modal.Body>
    </Modal>
  );
}

export default AddFeedModal;
