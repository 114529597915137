import { axiosInstance, baseUrl } from '.';

// get all users
export const fetchAllUsers = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}public/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
};

// get Searched Users
export const fetchSearchedUsers = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}${payload.type}/users?search=${payload.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  }
};
// Paginate
export const fetchUsersWithPage = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/users?page=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  }
};
export const fetchUsersStats = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/users/stats?type=role_based_member_count`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  }
};
// Email verification
export const userVerification = async (id) => new Promise((resolve, reject) => {
  axiosInstance
    .get(`${baseUrl}user/verify/${id}`)
    .then((responseObj) => {
      if (responseObj.status !== 200) {
        reject('Error');
      } else {
        resolve(responseObj.data.data);
      }
    })
    .catch((error) => {
      reject(error);
    });
});
// get all users for admin
export const fetchAllAdminUsers = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}admin/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
};
// get all online users for admin
export const fetchAllOnlineUsers = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}admin/users`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
};
// delete admin users
export const deleteAdminUsers = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(`${baseUrl}admin/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
export const fetchAdminLogs = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}admin/logs`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
};
export const deleteUser = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.delete(`${baseUrl}me`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
};
