import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAllPages,
  fetchPageMembers,
  deleteAdminPages,
  fetchRequestedPages,
  postRequestedPages,
  fetchPagesWithPage,
} from 'src/api/page';
import {
  savePages,
  saveRequestPages,
  savePageMembers,
} from 'src/redux/pageRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getAllPages(action) {
  try {
    yield put(processing({ status: true, component: 'user-pages' }));
    const response = yield call(fetchAllPages, action.payload);

    yield put(savePages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-pages' }));
    yield put(setError(false));
  }
}

function* getAdminPageMembers(action) {
  try {
    yield put(processing({ status: true, component: 'admin-page-members' }));
    const response = yield call(fetchPageMembers, action.payload);
    yield put(savePageMembers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-page-members' }));
    yield put(setError(false));
  }
}

function* loadMorePages(action) {
  try {
    yield put(processing({ status: true, component: 'admin-pages-page' }));
    const response = yield call(fetchPagesWithPage, action.payload);
    yield put(savePages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-pages-page' }));
    yield put(setError(false));
  }
}

function* deletePageForAdmin(action) {
  try {
    yield put(processing({ status: true, component: 'user-pages' }));
    yield call(deleteAdminPages, action.payload.id);
    const response = yield call(fetchAllPages);
    const pages = response.data;
    for (let i = 0; i < pages.length; i++) {
      const memberResponse = yield call(fetchPageMembers, pages[i].id);
      pages[i].members = memberResponse;
    }
    yield put(savePages({ pages, meta: response.meta.pagination }));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-pages' }));
    yield put(setError(false));
  }
}

function* getRequestPages() {
  try {
    yield put(processing({ status: true, component: 'request-page' }));
    const response = yield call(fetchRequestedPages);
    yield put(saveRequestPages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'request-page' }));
    yield put(setError(false));
  }
}
function* addRequestPages(action) {
  try {
    yield put(processing({ status: true, component: 'request-page' }));
    yield call(postRequestedPages, action.payload);
    const response = yield call(fetchRequestedPages);
    yield put(saveRequestPages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'request-page' }));
    yield put(setError(false));
  }
}

export function* watchPagesSaga() {
  yield takeLatest('GET_ALL_PAGES', getAllPages);
  yield takeLatest('LOAD_MORE_PAGES', loadMorePages);
  yield takeLatest('GET_ADMIN_PAGE_MEMBERS', getAdminPageMembers);
  yield takeLatest('DELETE_PAGE', deletePageForAdmin);
  yield takeLatest('GET_REQUEST_PAGES', getRequestPages);
  yield takeLatest('POST_REQUEST_PAGES', addRequestPages);
}
