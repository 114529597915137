import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import { ScrollableSection, Heading } from 'src/components/StyledComponents';
import { useSelector, useDispatch } from 'react-redux';
import { getRemainders } from 'src/redux/remaindersRedux';
import { getDashboardHubs } from 'src/redux/hubRedux';
import Loader from 'src/components/Loader';
import EventCard from 'src/components/EventCard';
import Breadcrumb from 'src/components/Breadcrumb';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import DetailModal from 'src/components/EventCard/detailModal';
import { CalendarSection } from './styles';
import { format } from './events';

const localizer = momentLocalizer(moment);
const allViews = Object.keys(Views).map((k) => Views[k]);

function Calender() {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('month');
  const [modalShow, setModalShow] = useState(false);
  const [event, setEvent] = useState({});
  const { userRemainders, reminderProcessing } = useSelector(
    (state) => state.RemaindersReducer,
  );
  const { dashboardHubs } = useSelector((state) => state.HubsReducer);
  const { user } = useSelector((state) => state.ProfileReducer);
  const showEvent = (event) => {
    const required_event = userRemainders.filter((r) => r.id === event.id);
    required_event[required_event.length - 1].start_time = event.start;
    required_event[required_event.length - 1].end_time = event.end;
    setEvent(required_event[required_event.length - 1]);
    setModalShow(true);
  };

  const getSrc = (event) => {
    if (event.hub_id !== null) {
      const hub = dashboardHubs.filter((hub) => hub.id === event.hub_id);
      return hub.length ? hub[0].avatar : ProfilePictureIcon;
    }
    return user.avatar ? user.avatar : ProfilePictureIcon;
  };
  const getName = (event) => {
    if (event.hub_id !== null) {
      const hub = dashboardHubs.filter((hub) => hub.id === event.hub_id);
      return hub.length ? hub[0].alias : '';
    }
    return user.avatar ? user.first_name : '';
  };
  const getTime = (date) => {
    const hrs = new Date(
      new Date(date).getTime()
        + (330 + new Date(date).getTimezoneOffset()) * 60000,
    ).getHours();
    const mins = new Date(
      new Date(date).getTime()
        + (330 + new Date(date).getTimezoneOffset()) * 60000,
    ).getMinutes();
    return `${hrs > 12 ? hrs - 12 : hrs}:${mins < 10 ? `0${mins}` : mins} ${
      hrs > 12 ? 'PM' : 'AM'
    }`;
  };

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    if (userRemainders.length === 0 && dashboardHubs.length === 0) {
      dispatch(getRemainders());
      dispatch(getDashboardHubs());
    }
  }, []);

  useEffect(() => {
    setEvents(format(userRemainders, dashboardHubs, user));
  }, [userRemainders, dashboardHubs, user]);

  let noEvents = true;

  return (
    <>
      <Breadcrumb parent="Calendar" title="Calendar" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Reminders</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  {reminderProcessing.status
                  && reminderProcessing.component === 'user-remainders' ? (
                    <Loader show />
                    ) : events.length ? (
                    // eslint-disable-next-line
                    events.map((remainder, index) => {
                        if (new Date(remainder.start) >= new Date()) {
                          noEvents = false;
                          return (
                            <EventCard
                              key={index}
                              event={userRemainders[index]}
                              hubs={dashboardHubs}
                              user={user}
                            />
                          );
                        }
                        if (noEvents && index === events.length - 1) {
                          return (
                            <Heading className="text-center h4 mt-4">
                              You have no Upcoming Events.
                            </Heading>
                          );
                        }
                      })
                    ) : (
                      <Heading className="text-center h4 mt-4">
                        You have no Upcoming Events.
                      </Heading>
                    )}
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-sm-9">
            <div className="card" style={{ height: '80vh' }}>
              <CalendarSection className="card-body" view={view}>
                <Calendar
                  localizer={localizer}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => {
                    showEvent(event);
                  }}
                  views={allViews}
                  events={userRemainders.length ? events : []}
                  eventOverlap
                  dragRevertDuration={500}
                  dragScroll
                  showMultiDayTimes
                  step={60}
                  startAccessor="start"
                  endAccessor="end"
                  onView={(view) => setView(view)}
                  titleAccessor={(e) => (
                    <div
                      className=" text-dark bg-white h-100"
                      style={{
                        border: '1px solid lightgrey',
                        borderRadius: '8px',
                      }}
                    >
                      <img
                        className="rounded-circle"
                        src={e.avatar ? e.avatar : ProfilePictureIcon}
                        height="20px"
                        width="20px"
                        alt="#Error"
                      />
                      {view !== 'week' || view !== 'work_week' ? (
                        <span className="rbc-event-custom-title">
                          {e.title}
                        </span>
                      ) : null}
                    </div>
                  )}
                />
              </CalendarSection>
            </div>
          </div>
        </div>
      </div>
      {modalShow ? (
        <DetailModal
          event={event}
          imgSrc={getSrc(event)}
          getName={getName(event)}
          getTime={getTime}
          days={days}
          show={modalShow}
          close={setModalShow}
          aextra="withextra"
        />
      ) : null}
    </>
  );
}

export default Calender;
