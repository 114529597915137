import React, { useState } from 'react';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { User, Settings, LogOut } from 'react-feather';
import {
  ADMIN_OVERVIEW,
  USER_DASHBOARD,
  USER_DIRECTORY,
  USER_PROFILE,
  USER_SETTINGS,
} from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { addDefaultSrc } from 'src/utils/helper';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { addContact } from 'src/redux/profileRedux';
import Swal from 'sweetalert2';
import InputField from 'src/components/InputField';
import { Button } from 'src/components/Button';

function UserMenu(props) {
  const dispatch = useDispatch();
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const inviteUser = (e) => {
    e.preventDefault();
    dispatch(addContact({ email: inviteEmail }));
    Swal.fire(
      'User Invited',
      `We have sent an email to "${inviteEmail}" user on your behalf. If a user already exists on Liviit, It will be added to your contacts.`,
      'success',
    );
    setInviteEmail('');
    setInviteModal(false);
  };
  return (
    <>
      <li className="onhover-dropdown">
        <div className="media align-items-center" style={{ width: '35px' }}>
          <img
            className="align-self-center pull-right rounded-circle blur-up lazyloaded"
            src={props.user.avatar ? props.user.avatar : ProfilePictureIcon}
            onError={addDefaultSrc}
            alt="header-user"
            width="100%"
            height="35px"
          />
          <div className="dotted-animation">
            <span className="animate-circle" />
            <span className="main-circle" />
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={USER_PROFILE}>
              <User />
              Profile
            </Link>
          </li>
          <li>
            <Link to={USER_SETTINGS}>
              <Settings />
              Settings
            </Link>
          </li>
          <li onClick={() => setInviteModal(true)}>
            <a href="#javascript">Invite</a>
          </li>
          <li>
            {!props.adminState ? (
              <Link to={USER_DIRECTORY}>Directory</Link>
            ) : null}
          </li>
          <li>
            <a href="#javascript">Contact Us</a>
          </li>
          {props.isAdmin ? (
            !props.adminState ? (
              <li>
                <a href={ADMIN_OVERVIEW}>
                  <Button
                    onClick={() => {
                      props.setHubState(false);
                      props.setadminState(false);
                      props.navigate(ADMIN_OVERVIEW);
                    }}
                    variant="solid"
                    value="Admin view"
                    padding="3px 12px"
                    fontSize="14px"
                  />
                </a>
              </li>
            ) : (
              <li>
                <a href={USER_DASHBOARD}>
                  <Button
                    onClick={() => {
                      props.setadminState(true);
                      props.navigate(USER_DASHBOARD);
                    }}
                    variant="solid"
                    value={`Return to ${props.user.first_name}`}
                    padding="3px 12px"
                    fontSize="12px"
                  />
                </a>
              </li>
            )
          ) : null}
          <li>
            <a onClick={() => props.logOut()} href="#logout">
            <LogOut />
              {' '}
              Log out
            </a>
          </li>
        </ul>
      </li>
      <Modal
        show={inviteModal}
        onHide={() => setInviteModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none' }} closeButton>
          <div className="font-weight-bold h4">
            Enter Email of User to be Invited
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100 mt-2" onSubmit={(e) => inviteUser(e)}>
            <div className="form-group">
              <InputField
                type="email"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                className="form-control form-control-lg"
                id="email"
                placeholder="Type here"
                required
                fontSize="18px"
              />
            </div>

            <div className="text-center">
              <Button
                type="submit"
                variant="solid"
                padding="8px 30px"
                value="Send Email"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserMenu;
