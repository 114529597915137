import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get All Vault Files
export const fetchAllVaultFiles = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}vaults/file`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data;
};

// Get All Vault Folders
export const fetchAllVaultFolders = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}vaults/folder`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });
  return response.data.data;
};

// Get all Folder Files
export const fetchFolderFiles = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}vaults/folder/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });
  return response.data;
};

// add a new folder to vault
export const postVaultFolder = async ({ name }) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}vaults/folder`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// store file in folder
export const postFileInFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}vaults/folder/${payload[0]}/upload`,
      payload[1],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};
