import React, { useState, useEffect } from 'react';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from 'src/components/Breadcrumb';
import { ModalUpload } from 'src/components/Modal';
import { SidebarImageContainer } from 'src/components/StyledComponents';
import { addAdminImages, getAdminImages } from 'src/redux/themeRedux';
import { Container } from './styles';

function AdminThemeCustomizer() {
  const [modalShow, setModalShow] = useState(false);
  const [currentImage, setCurrentImage] = useState();

  const dispatch = useDispatch();

  const { adminImages } = useSelector((state) => state.ThemeReducer);

  useEffect(() => {
    if (adminImages.length === 0) {
      dispatch(getAdminImages());
    }
  }, []);

  const changeImage = (files) => {
    const formData = new FormData();
    formData.append('type', 'sidebar_bg_image_preset');
    formData.append('file', files[0].file);
    formData.append('index', currentImage);
    dispatch(addAdminImages(formData));
  };
  return (
    <>
      <Breadcrumb parent="Theme Customizer" title="Admin" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-body">
                <Container>
                  <ul className="sidebar-bg-settings">
                    {adminImages.map((image, index) => (
                      <SidebarImageContainer
                        key={index}
                        image={image}
                        className={`bg-img-${index + 1}`}
                        data-attr={`sidebar-img${index + 1}`}
                        onClick={() => setCurrentImage(index)}
                      >
                        <div id="add-img">
                          <Plus
                            onClick={() => setModalShow(true)}
                            height="100%"
                            width="60%"
                          />
                        </div>
                      </SidebarImageContainer>
                    ))}
                  </ul>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalUpload
        show={modalShow}
        close={setModalShow}
        size={1}
        addFile={changeImage}
        accept={'image/*'}
        title="Select Your Image"
      />
    </>
  );
}

export default AdminThemeCustomizer;
