// actions
export const getAllPages = (payload) => ({
  type: 'GET_ALL_PAGES',
  payload,
});

export const loadMorePages = (payload) => ({
  type: 'LOAD_MORE_PAGES',
  payload,
});

export const savePages = (payload) => ({
  type: 'SAVE_ALL_PAGES',
  payload,
});

export const getPageMembers = (payload) => ({
  type: 'GET_ADMIN_PAGE_MEMBERS',
  payload,
});
export const savePageMembers = (payload) => ({
  type: 'SAVE_PAGE_MEMBERS',
  payload,
});

export const deletePage = (payload) => ({
  type: 'DELETE_PAGE',
  payload,
});

export const getRequestPages = () => ({
  type: 'GET_REQUEST_PAGES',
});
export const addRequestPages = (payload) => ({
  type: 'POST_REQUEST_PAGES',
  payload,
});
export const saveRequestPages = (payload) => ({
  type: 'SAVE_REQUEST_PAGES',
  payload,
});

const initialState = {
  pages: [],
  metaDataPages: {},
  pageMembers: [],
  requestPages: {
    data: [],
    meta: {},
  },
};

// pageReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_ALL_PAGES':
      return {
        ...state,
        pages: action.payload.data,
        metaDataPages: action.payload.meta.pagination,
      };
    case 'SAVE_PAGE_MEMBERS':
      return { ...state, pageMembers: action.payload };
    case 'SAVE_REQUEST_PAGES':
      return { ...state, requestPages: action.payload };
    default:
      return { ...state };
  }
};
