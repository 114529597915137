import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import EventCard from 'src/components/EventCard';

function Remainders() {
  const { hubRemainders, hubs } = useSelector((state) => state.HubsReducer);
  const { user } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  let noTodayEvents = true;
  let noUpcomingEvents = true;
  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  };
  return (
    <div className="mt-4">
      {processing.status && processing.component === 'hub' ? (
        <Loader show />
      ) : (
        <>
          <h6 className="text-center">Today</h6>
          {hubRemainders.length ? (
          // eslint-disable-next-line
              hubRemainders.map((remainder, index) => {
              if (isToday(new Date(remainder.end_time))) {
                noTodayEvents = false;
                return (
                  <EventCard
                    event={hubRemainders[index]}
                    hubs={hubs}
                    user={user}
                  />
                );
              }
              if (noTodayEvents && index === hubRemainders.length - 1) {
                return (
                  <div className="text-muted text-center mb-2">
                    <em>No Events</em>
                  </div>
                );
              }
            })
          ) : (
            <div className="text-muted text-center mb-2">
              <em>No Events</em>
            </div>
          )}
          <h6 className="text-center">Upcoming</h6>
          {hubRemainders.length ? (
          // eslint-disable-next-line
              hubRemainders.map((remainder, index) => {
              if (
                new Date(remainder.end_time) > new Date()
                  && !isToday(new Date(remainder.end_time))
              ) {
                noUpcomingEvents = false;
                return (
                  <EventCard
                    event={hubRemainders[index]}
                    hubs={hubs}
                    user={user}
                  />
                );
              }
              if (noUpcomingEvents && index === hubRemainders.length - 1) {
                return (
                  <div className="text-muted text-center">
                    <em>No Events</em>
                  </div>
                );
              }
            })
          ) : (
            <div className="text-muted text-center">
              <em>No Events</em>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Remainders;
