import React, { useEffect } from 'react';
import * as ROUTES from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Nav, Navbar } from 'react-bootstrap';
import NavbarIcon from 'src/assets/images/livit/navbar.jpg';
import LogoIcon from 'src/assets/images/livit/liviit-logo.png';
import { Container } from './styles';

function Header() {
  const navitems = [
    {
      key: 1,
      name: 'Home',
      path: 'https://www.liviit.com/home',
    },
    {
      key: 2,
      name: 'Download',
      path: 'https://www.liviit.com/download',
    },
    {
      key: 3,
      name: 'About',
      path: 'https://www.liviit.com/about',
    },
    {
      key: 4,
      name: 'Features',
      path: 'https://www.liviit.com/features',
    },
    {
      key: 5,
      name: 'Plans',
      path: ROUTES.PLANS,
    },

    {
      key: 6,
      name: 'icon',
      path: ROUTES.MAIN_ROUTE,
    },
  ];
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) navigate(ROUTES.USER_DASHBOARD);
  }, []);
  return (
    <div
      className="pt-4"
      style={{ background: `url(${NavbarIcon})`, height: '225px' }}
    >
      <div className="container">
        <Navbar expand="lg">
          <Navbar.Brand href={ROUTES.MAIN_ROUTE}>
            <div style={{ width: '130px', height: '54px' }}>
              <img alt="#" src={LogoIcon} width="100%" height="100%" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: '100%' }}>
              {navitems.map((item) => (
                <Nav.Link
                  className="pt-3 navItem"
                  href={item.path}
                  key={item.key}
                >
                  {item.name === 'icon' ? (
                    <span
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        fontFamily: 'Nunito',
                      }}
                    >
                      <i className="fas fa-user-lock" />
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        fontFamily: 'Nunito',
                      }}
                    >
                      {item.name}
                    </span>
                  )}
                </Nav.Link>
              ))}
              <Container className="pt-1">
                {token ? (
                  <Button
                    onClick={() => navigate(ROUTES.USER_DASHBOARD)}
                    value="Dashboard"
                    variant="outline"
                    margin="0 5px 0 0"
                  />
                ) : (
                  null
                )}
              </Container>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
