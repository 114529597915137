/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import ReactDoom from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

// Custom services
import Napster from 'src/services/napster';
import CometChat from 'src/services/cometChat';

// App
import store from './store';
import App from './app';
import { STRIPE_PUBLISHABLE_KEY } from './constants/creds';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function AppWithCallbackAfterRender() {
  useEffect(() => {
    console.log('Application Initialization completed successfully');
    // Initicate other services
    Napster(store.dispatch);
    CometChat();
  });

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <App />
        </Provider>
      </Elements>
    </div>
  );
}

const root = ReactDoom.createRoot(document.getElementById('root'));
root.render(<AppWithCallbackAfterRender />);
