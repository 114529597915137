import React, { useState, useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import PlusButton from 'src/components/PlusButton';
import { Heading, ScrollableSection } from 'src/components/StyledComponents';
import Loader from 'src/components/Loader';
import { ModalButton } from 'src/components/Modal';
import { PlusCircle, Trash2 } from 'react-feather';
import {
  DeleteModal,
  SelectModal,
} from 'src/containers/AudioPlayer/Sections/addTrackModals';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import {
  getTopTracks,
  createPlaylist,
  getPlaylists,
  removePlaylist,
  getPlaylistTracks,
  updatePlaylist,
  addPlaylistTracks,
  reorderTracks,
} from 'src/redux/audioPlayerRedux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from 'src/utils/helper';

function AdminPlaylist(props) {
  const [modalShow, setModalShow] = useState(false);
  const [trackModalShow, setTrackModalShow] = useState(false);
  const [nameModalShow, setNameModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTrackModal, setDeleteTrackModal] = useState(false);
  const [deletedPlaylist, setDeletedPlaylist] = useState({});
  const [deletedTrack, setDeletedTrack] = useState({});
  const [name, setName] = useState('');
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const dispatch = useDispatch();
  const {
    playlists, tracksProcessing, playlistTracks, tracks,
  } = useSelector(
    (state) => state.AudioPlayerReducer,
  );

  useEffect(() => {
    if (playlists.length === 0) {
      dispatch(getTopTracks());
      dispatch(getPlaylists('admin'));
    }
  }, []);

  useEffect(() => {
    if (selectedPlaylist) dispatch(getPlaylistTracks(selectedPlaylist.id));
  }, [selectedPlaylist]);

  const addPlaylist = async (e) => {
    e.preventDefault();
    await dispatch(createPlaylist({ name, is_global: true }));
    setModalShow(false);
    setName('');
  };

  const deletePlaylist = async () => {
    await dispatch(removePlaylist({ id: deletedPlaylist.id, is_global: true }));
    setDeleteModal(false);
  };

  const addTrack = (provider, file) => {
    if (provider === 'local') {
      const payload = new FormData();
      payload.append('files[0][file]', file[0].file);
      payload.set('provider', provider);
      payload.set('playlist_id', selectedPlaylist.id);
      dispatch(addPlaylistTracks([selectedPlaylist.id, payload]));
    } else if (file.length) {
      const tracks = file.map((track) => ({
        name: track.name,
        metadata: [
          {
            type: track.type,
            id: track.id,
            playbackSeconds: track.playbackSeconds,
            isStreamable: track.isStreamable,
            name: track.name,
            shortcut: track.shortcut,
            artistName: track.artistName,
            albumName: track.albumName,
            albumId: track.albumId,
            previewURL: track.previewURL,
          },
        ],
      }));
      const payload = {
        provider,
        tracks,
        playlist_id: selectedPlaylist.id,
      };

      dispatch(addPlaylistTracks([selectedPlaylist.id, payload]));
    }
  };

  const removeTrack = async () => {
    await dispatch(
      updatePlaylist([
        selectedPlaylist.id,
        { to_remove: [deletedTrack.id] },
        'admin',
      ]),
    );
    setDeleteTrackModal(false);
  };

  const changePlaylistName = async (e) => {
    e.preventDefault();
    await dispatch(updatePlaylist([selectedPlaylist.id, { name }, 'admin']));
    setName('');
    setNameModalShow(false);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = await reorder(
      playlistTracks,
      result.source.index,
      result.destination.index,
    );
    await dispatch(reorderTracks([selectedPlaylist.id, { sequence: items }]));
  };

  return (
    <>
      <Breadcrumb parent="Music" title="Music" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Playlists</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <PlusButton onClick={() => setModalShow(true)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    {tracksProcessing.status
                    && tracksProcessing.component === 'playlist' ? (
                      <Loader show />
                      ) : playlists.length ? (
                        playlists.map((playlist, index) => (
                          <li
                            key={index}
                            className={`list-group-item mt-1 ${
                              selectedPlaylist
                              && selectedPlaylist.id === playlist.id
                                ? 'active'
                                : null
                            }`}
                          >
                            <div
                              onClick={() => {
                                setSelectedPlaylist(playlist);
                              }}
                            >
                              {playlist.name}
                            </div>
                            <div className="d-inline">
                              <Trash2
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeletedPlaylist(playlist);
                                }}
                              />
                            </div>
                          </li>
                        ))
                      ) : (
                        <Heading className="text-center h4 mt-4">
                          No Global Playlist Exists, create one from here
                          {' '}
                          <PlusCircle
                            style={{ verticalAlign: 'bottom', cursor: 'pointer' }}
                            onClick={() => setModalShow(true)}
                          />
                        </Heading>
                      )}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {playlists.length && selectedPlaylist && selectedPlaylist.id ? (
              <div className="card" style={{ height: '80vh' }}>
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {selectedPlaylist && selectedPlaylist.id ? (
                        <h5
                          className="align-abjust"
                          onClick={() => {
                            setNameModalShow(true);
                            setName(selectedPlaylist.name);
                          }}
                        >
                          {selectedPlaylist.name}
                        </h5>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-6">
                      <div className="pull-right right-header">
                        {selectedPlaylist && selectedPlaylist.id ? (
                          <PlusButton onClick={() => setTrackModalShow(true)} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ScrollableSection>
                    {selectedPlaylist ? (
                      <ul className="list-group mt-2">
                        {tracksProcessing.status
                        && tracksProcessing.component === 'playlist-tracks' ? (
                          <Loader show />
                          ) : playlistTracks.length ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable
                                droppableId="droppable"
                                type="playlist"
                              >
                                {(provided) => (
                                  <ul
                                    className="list-group mt-2"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {playlistTracks.map((track, index) => (
                                      <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                      >
                                        {(provided) => (track.provider === 'local' ? (
                                          <li
                                            className={`list-group-item 
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className="d-flex">
                                              <div>
                                                <img
                                                  src={MusicDisc}
                                                  alt="#track art"
                                                  height="70px"
                                                  width="70px"
                                                />
                                              </div>
                                              <div className="ml-1">
                                                <h6>{track.name}</h6>
                                              </div>
                                            </div>
                                            <div className="py-2">
                                              <Trash2
                                                onClick={() => {
                                                  setDeleteTrackModal(true);
                                                  setDeletedTrack(track);
                                                }}
                                              />
                                            </div>
                                          </li>
                                        ) : (
                                          <li
                                            className={`list-group-item 
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className="d-flex">
                                              <div>
                                                <img
                                                  src={`https://api.napster.com/imageserver/v2/albums/${track.metadata[0].albumId}/images/70x70.jpg`}
                                                  alt="#track art"
                                                />
                                              </div>
                                              <div className="ml-1">
                                                <h6>
                                                  {track.metadata[0].name}
                                                </h6>
                                                <p>
                                                  {
                                                        track.metadata[0]
                                                          .artistName
                                                      }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="py-2">
                                              <Trash2
                                                onClick={() => {
                                                  setDeleteTrackModal(true);
                                                  setDeletedTrack(track);
                                                }}
                                              />
                                            </div>
                                          </li>
                                        ))}
                                      </Draggable>
                                    ))}
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          ) : (
                            <Heading className="text-center h4 mt-4">
                              No Tracks in this Playlist, Add one from here
                              {' '}
                              <PlusCircle
                                style={{
                                  verticalAlign: 'bottom',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setTrackModalShow(true)}
                              />
                            </Heading>
                          )}
                      </ul>
                    ) : (
                      <Heading className="text-center h4 mt-4">
                        Select or Create a Playlist to add Songs.
                      </Heading>
                    )}
                  </ScrollableSection>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Playlist Name"
        submit={addPlaylist}
        value={name}
        setValue={setName}
      />
      <ModalButton
        show={nameModalShow}
        close={setNameModalShow}
        title="Update Playlist Name"
        submit={changePlaylistName}
        value={name}
        setValue={setName}
      />
      <SelectModal
        modalShow={trackModalShow}
        modalClose={setTrackModalShow}
        uploadFile={addTrack}
        loading={tracksProcessing}
        tracks={tracks}
        {...props}
      />
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        delete={deletePlaylist}
        track={deletedPlaylist}
        type="playlist"
      />
      <DeleteModal
        deleteModal={deleteTrackModal}
        setDeleteModal={setDeleteTrackModal}
        delete={removeTrack}
        track={deletedTrack}
        type="track"
      />
    </>
  );
}

export default AdminPlaylist;
