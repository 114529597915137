import React from 'react';
import Navbar from 'src/components/Navbar';
import Card from 'src/components/Card';
import Footer from 'src/components/Footer';
import BannerIcon from 'src/assets/images/livit/Inner 1.png';
import FamilyIcon from 'src/assets/images/livit/Icon - Family.png';
import TeamsIcon from 'src/assets/images/livit/Icon - Teams.png';
import FriendsIcon from 'src/assets/images/livit/Icon - Friends.png';
import CommunityIcon from 'src/assets/images/livit/Icon - Community.png';
import SchoolIcon from 'src/assets/images/livit/Icon - School.png';
import WorkIcon from 'src/assets/images/livit/Icon - Work.png';
import { Heading, Name, Header } from './styles';

export const aboutCards = [
  {
    key: 1,
    title: 'Families',
    about:
      'Keep your family activities safely within the family. This hub is the place for all ages to interact, share images, make plans and store files. Online family fun can now be secure, private and shared together.',
    image: FamilyIcon,
  },
  {
    key: 2,
    title: 'Teams',
    about:
      'Here is a fantastic, completely private digital clubroom where your sporting team can meet and plan, connect with the coach and share strategies for your next big win.',
    image: TeamsIcon,
  },
  {
    key: 3,
    title: 'Friends',
    about:
      'Keep your family activities safely within the family. This hub is the place for all ages to interact, share images, make plans and store files. Online family fun can now be secure, private and shared together.',
    image: FriendsIcon,
  },
  {
    key: 4,
    title: 'Community',
    about:
      'Many and varied groups will benefit from this private and convenient online community hub that is simple to access and use. It will be a safe and useful space to communicate and plan activities and events ranging from the next book club meeting to fund raisers and annual festivals. ',
    image: CommunityIcon,
  },
  {
    key: 5,
    title: 'School',
    about:
      'Keep your family activities safely within the family. This hub is the place for all ages to interact, share images, make plans and store files. Online family fun can now be secure, private and shared together.',
    image: SchoolIcon,
  },
  {
    key: 6,
    title: 'Work',
    about:
      'The virtual office hub makes it simple and most importantly, secure to communicate and collaborate with work colleagues. You can take care of business or plan for pleasurable get togethers knowing all information is safely shared or stored.',
    image: WorkIcon,
  },
];

function About() {
  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col-sm-6 text-center">
          <Name>About Us</Name>
        </div>
        <div className="col-sm-6">
          <img src={BannerIcon} alt="banner" width="100%" />
        </div>
      </div>
      <div className="container text-center mt-4 pt-4">
        <Heading>Enjoy the best features of all the popular social</Heading>
        <Heading>media platforms rolled into one.</Heading>
        <Header>
          Liviit is easy for all ages to access and the most secure way to
          connect
        </Header>
        <Header>with friends, family and community.</Header>
        <div className="row">
          {aboutCards.map((card) => (
            <div key={card.key} className="col-sm-6 mt-5">
              <Card item={card} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
