import React, { useState, useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import InputField from 'src/components/InputField';
import { Button } from 'src/components/Button';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { updateUserDetails } from 'src/redux/profileRedux';
import { ScrollableSection } from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';
import DeleteUserConfirmationModal from 'src/components/Modal/DeleteUserConfirmationModal';
import AddAdminToLinkedAccountModal from 'src/components/Modal/AddAdminToLinkedAccountModal';
import { ProfilePic } from './styles';
import LinkedAccountModal from './linkedAccountModal';

function UserProfile(props) {
  // Hooks
  const dispatch = useDispatch();

  // Local state
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPublic, setIsPublic] = useState('');
  const [account, setAccount] = useState({});
  const [show, setShow] = useState(false);
  const [openDeleteUserConfirmationModal, setOpenDeleteUserConfirmationModal] = useState(false);
  const [openAddAdminToLinkedAccountModal, setOpenAddAdminToLinkedAccountModal] = useState(false);

  // Selector
  const { user, linkedAccounts } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name || '');
      setLastName(user?.last_name || '');
      setUsername(user?.username || '');
      setEmail(user?.email || '');
      setPhoneNumber(
        user.phone && user.phone.phone_number
          ? `${user.phone.dial_code} ${user.phone.phone_number}`
          : '',
      );
      setIsPublic(!!user.is_public);
    }
  }, [user]);

  const handleSubmit = () => {
    if (!edit) {
      setEdit(!edit);
    } else {
      dispatch(
        updateUserDetails({
          ...user,
          first_name: firstName,
          is_public: isPublic,
          last_name: lastName,
          username,
          email,
        }),
      );
      setEdit(false);
    }
  };

  const handleCancel = () => {
    setEdit(false);
    setFirstName(user.first_name ? user.first_name : '');
    setLastName(user.last_name ? user.last_name : '');
    setUsername(user.username ? user.username : '');
    setEmail(user.email ? user.email : '');
    setPhoneNumber(user.phone_number ? user.phone_number : '');
    setIsPublic(!!user.is_public);
  };

  const handleDeleteUser = () => {
    const hasMoreThanOneAdmin = linkedAccounts.every(
      (acc) => acc.masters.length > 1,
    );
    if (linkedAccounts?.length > 0 && !hasMoreThanOneAdmin) {
      setOpenAddAdminToLinkedAccountModal(true);
    } else {
      setOpenDeleteUserConfirmationModal(true);
    }
  };

  return (
    <>
      <Breadcrumb parent="Profile" title={user.username ? user.username : ''} />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Account Info</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up" />
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x" />
                    </a>
                  </div>
                </div>
                <div className="card-body" style={{ height: '68vh' }}>
                  {processing.status
                  && processing.component === 'user-profile' ? (
                    <Loader show />
                    ) : (
                      <form>
                        <div className="row mb-2">
                          <div className="col-auto">
                            <div style={{ width: '70px', height: '70px' }}>
                              <img
                                className="rounded-circle"
                                alt=""
                                src={
                                user.avatar ? user.avatar : ProfilePictureIcon
                              }
                                onError={addDefaultSrc}
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <h3 className="mb-1">
                              {user.first_name}
                              {' '}
                              {user.last_name}
                            </h3>
                            <p className="mb-4">
                              @
                              {user.username}
                            </p>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="form-label font-weight-bolder">
                            Email Address
                          </label>
                          <div>{user.email}</div>
                        </div>
                        <div className="form-group">
                          <label className="form-label font-weight-bolder">
                            Phone Number
                          </label>
                          <div>{user.phone_number}</div>
                        </div>
                        <div className="form-group">
                          <label className="form-label font-weight-bolder">
                            DOB
                          </label>
                          <div>{user.dob}</div>
                        </div>
                        <div className="form-group">
                          <label className="form-label font-weight-bolder">
                            Linked Accounts
                          </label>
                          <div className="bg-white" style={{ height: '220px' }}>
                            <ScrollableSection>
                              <div className="row m-0">
                                {linkedAccounts.length ? (
                                  <>
                                  {linkedAccounts.map((account) => (
                                    <div
                                      key={account.id}
                                      className="col-12 p-2"
                                      onClick={() => {
                                        setAccount(account);
                                        setShow(true);
                                      }}
                                    >
                                      <ProfilePic>
                                        <img
                                          src={
                                              account.avatar
                                                ? account.avatar
                                                : ProfilePictureIcon
                                            }
                                          alt="icon"
                                          width="100%"
                                          className="rounded-circle"
                                          height="100%"
                                          onError={addDefaultSrc}
                                        />
                                      </ProfilePic>
                                      <div className="mt-1">
                                        {account.first_name}
                                        {' '}
                                        {account.last_name}
                                      </div>
                                    </div>
                                  ))}
                                  </>
                                ) : (
                                <div>You have no Linked Accounts</div>
                                )}
                              </div>
                            </ScrollableSection>
                          </div>
                        </div>
                      </form>
                    )}
                    <div className="form-group">
                      <Button
                        variant="solid"
                        type="button"
                        className="btn-danger"
                        onClick={handleDeleteUser}
                        value="Delete Account"
                      />
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">User Details</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up" />
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x" />
                    </a>
                  </div>
                </div>
                <div className="card-body" style={{ height: '68vh' }}>
                  {processing.status
                  && processing.component === 'user-profile' ? (
                    <Loader show />
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">First Name</label>
                              <InputField
                                disabled={!edit}
                                className="form-control"
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setFirstName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Last Name</label>
                              <InputField
                                disabled={!edit}
                                className="form-control"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setLastName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Username</label>
                              <InputField
                                disabled={!edit}
                                className="form-control"
                                type="text"
                                placeholder="username"
                                value={username}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setUsername(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">Email</label>
                              <InputField
                                disabled={!edit}
                                className="form-control"
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setEmail(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Phone Number
                              </label>
                              <InputField
                                disabled
                                className="form-control"
                                type="text"
                                placeholder="Add Number"
                                value={phoneNumber}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group pt-3">
                              <input
                                disabled={!edit}
                                type="checkbox"
                                className="mt-4"
                                checked={isPublic}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setIsPublic(!isPublic);
                                }}
                                style={{ height: '20px', width: '30px' }}
                              />
                              {' '}
                              <label
                                className="form-label"
                                style={{ verticalAlign: 'middle' }}
                              >
                                Set Account as Public
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <div className={`d-${edit ? 'inline' : 'none'} mr-4`}>
                            <Button
                              value="Cancel"
                              onClick={() => handleCancel()}
                            />
                          </div>
                          <Button
                            variant="solid"
                            type="submit"
                            onClick={() => handleSubmit()}
                            value={edit ? 'Save' : 'Edit'}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {account.age && (
        <LinkedAccountModal
          show={show}
          setShow={setShow}
          user={account}
          {...props}
        />
      )}
      <DeleteUserConfirmationModal
        displayLoader={props.displayLoader}
        openDeleteUserConfirmationModal={openDeleteUserConfirmationModal}
        setOpenDeleteUserConfirmationModal={setOpenDeleteUserConfirmationModal}
      />
      <AddAdminToLinkedAccountModal
        openAddAdminToLinkedAccountModal={openAddAdminToLinkedAccountModal}
        setOpenAddAdminToLinkedAccountModal={
          setOpenAddAdminToLinkedAccountModal
        }
      />
    </>
  );
}

export default UserProfile;
