// actions
export const getAllFolders = () => ({
  type: 'GET_ALL_FOLDERS',
});

export const saveAllFolders = (payload) => ({
  type: 'SAVE_ALL_FOLDERS',
  payload,
});

export const uploadFileInFolder = (payload) => ({
  type: 'UPLOAD_FILE',
  payload,
});

export const addFolder = (payload) => ({
  type: 'CREATE_FOLDER',
  payload,
});

export const getFolderData = (payload) => ({
  type: 'GET_FOLDER_DATA',
  payload,
});

export const saveFolderData2 = (payload) => ({
  type: 'SAVE_FOLDER_DATA_2',
  payload,
});

export const saveFolderData3 = (payload) => ({
  type: 'SAVE_FOLDER_DATA_3',
  payload,
});

export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_FOLDERS',
  payload,
});

export const removeFolder = (payload) => ({
  type: 'REMOVE_FOLDER',
  payload,
});

export const removeFile = (payload) => ({
  type: 'REMOVE_FILE',
  payload,
});

export const getAllFiles = () => ({
  type: 'GET_ONLY_FILES',
});
export const saveAllFiles = (payload) => ({
  type: 'SAVE_ALL_FILES',
  payload,
});

export const processFiles = (payload) => ({
  type: 'PROCESSING_FILES',
  payload,
});

// store
const initialState = {
  folders: [],
  folderDataLevel2: [],
  folderDataLevel3: [],
  meta: {},
  files: [],
  filesProcessing: { status: false, component: '' },
};

// filesReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_ALL_FOLDERS':
      return {
        ...state,
        folders: action.payload.data,
        meta: action.payload.meta.pagination,
      };
    case 'SAVE_FOLDER_DATA_2':
      return { ...state, folderDataLevel2: action.payload };
    case 'SAVE_FOLDER_DATA_3':
      return { ...state, folderDataLevel3: action.payload };
    case 'SAVE_ALL_FILES':
      return { ...state, files: action.payload };
    case 'PROCESSING_FILES':
      return { ...state, filesProcessing: action.payload };
    default:
      return { ...state };
  }
};
