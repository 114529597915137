import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get All Stripe Plan Details
export const fetchPlans = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}stripe/plans`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });
  return response.data;
};

export const postSubscriptionPlan = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(`${baseUrl}stripe/subscribe`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });
};

export const postUpgradeSubscriptionPlan = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(`${baseUrl}stripe/plan-swap`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });
};
