import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Header = styled.div`
  color: ${themeColor};
  border-bottom: 2px solid ${themeColor};
`;

export const Paragraph = styled.p`
  font-size: 16px;
`;
