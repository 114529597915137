import { CometChat } from "@cometchat-pro/chat";

import * as enums from "../../../util/enums.js";

export class messageAlertManager {
  msgListenerId = "incoming_message_" + new Date().getTime();

  attachListeners(callback) {
    CometChat.addMessageListener(
      this.msgListenerId,
      new CometChat.MessageListener({
        onCustomMessageReceived: (customMessage) => {
          callback(enums.CUSTOM_MESSAGE_RECEIVED, customMessage);
        },
        onMessageEdited: (editedMessage) => {
          callback(enums.MESSAGE_EDITED, editedMessage);
        },
      })
    );
  }
  incomingCall(callBack) {
    CometChat.addMessageListener(
      this.msgReadListenerId,
      new CometChat.MessageListener({
        onMessagesRead: (messageReceipt) => {
          callBack(enums.MESSAGE_READ, messageReceipt);
          CometChat.removeMessageListener(this.msgReadListenerId);
        },
      })
    );
  }

  removeListeners() {
    CometChat.removeMessageListener(this.msgListenerId);
  }
}
