import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Heading } from 'src/components/StyledComponents';
import {
  upgradeSubscriptionPlan,
} from 'src/redux/subscriptionRedux';
import { Spinner } from 'react-bootstrap';
import { PlanPrice } from 'src/utils/data';
import { SubscriptionReducer } from 'src/selectors';
import { ProceedButton } from '../PaymentModal/styles';

function ConfirmPayment({
  showConfirmationModal,
  selectedSubscriptionPlan,
  setSelectedSubscriptionPlan,
  setShowConfirmationModal,
}) {
  const dispatch = useDispatch();
  const { plansProcessing } = useSelector(SubscriptionReducer);

  const isPaymentActive = plansProcessing.status
    && (plansProcessing.component === 'upgrade-subscription'
      || plansProcessing.component === 'cancel-subscription');

  const { name } = selectedSubscriptionPlan;

  const { dollar, cents } = PlanPrice[name];

  const handleProceed = () => {
    if (isPaymentActive) return;

    const selectedSubscriptionPlanId = selectedSubscriptionPlan?.id;

    dispatch(
      upgradeSubscriptionPlan({
        data: {
          plan_id: selectedSubscriptionPlanId === 'free' ? 'free' : selectedSubscriptionPlanId,
        },
        cb: () => {
          setShowConfirmationModal(false);
        },
      }),
    );
  };

  const handleDecline = () => {
    if (isPaymentActive) return;
    setShowConfirmationModal(false);
  };

  return (
    <Modal
      show={showConfirmationModal}
      size="md"
      onHide={() => {
        if (isPaymentActive) return;
        setShowConfirmationModal(false);
        setSelectedSubscriptionPlan(null);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Heading className="font-weight-bold">Confirm Payment</Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="h6">You have selected</div>
        <div className="h4">
          {`${selectedSubscriptionPlan?.name.toUpperCase()} A$${dollar}.${cents}/month`}
        </div>
        {!isPaymentActive && (
          <div className="mt-3">Do you want to proceed?</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isPaymentActive ? (
          <div className="d-flex w-100 justify-content-around">
            <ProceedButton isActive={isPaymentActive} onClick={handleDecline}>
              No
            </ProceedButton>
            <ProceedButton
              isPaymentActive={isPaymentActive}
              onClick={handleProceed}
            >
              Yes
            </ProceedButton>
          </div>
        ) : (
          <ProceedButton
            style={{ width: '100%' }}
            isActive={isPaymentActive}
            onClick={handleProceed}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span>
                {`${
                  selectedSubscriptionPlan.id === 'free'
                    ? 'Switching to free plan'
                    : 'Upgrading'
                }...`}
              </span>
              <Spinner
                className="ml-3"
                size="sm"
                animation="border"
                variant="light"
              />
            </div>
          </ProceedButton>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ConfirmPayment.propTypes = {
  showConfirmationModal: PropTypes.bool.isRequired,
  selectedSubscriptionPlan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    trial_days: PropTypes.number.isRequired,
  }).isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
  setSelectedSubscriptionPlan: PropTypes.func.isRequired,
};

export default ConfirmPayment;
