import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AlignLeft,
  Maximize,
  Bell,
  Mail,
  MoreHorizontal,
  PlayCircle,
} from 'react-feather';
import { goFull } from 'src/utils/helper';
import PlayerWidget from 'src/containers/AudioPlayer/Sections/playerWidget';
import UserMenu from './userMenu';
import SearchHeader from './searchHeader';
import Notifications from './notification';
import { HeaderContainer } from './styles';

function Header(props) {
  const { user } = useSelector((state) => state.ProfileReducer);
  const { theme } = useSelector((state) => state.ThemeReducer);
  const [customColor, setCustomColor] = useState(theme.color_primary);
  const { currentTrack } = useSelector((state) => state.AudioPlayerReducer);
  const [sidebar, setSidebar] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length) {
      if (localStorage.getItem('user-level') === 'admin') {
        setIsAdmin(true);
      }
    } else {
      setIsAdmin(false);
    }
    return () => {
      setIsAdmin(false);
    };
  }, [user]);

  useEffect(() => {
    setCustomColor(theme.color_primary);
  }, [theme]);

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector('.page-main-header').classList.remove('open');
      document.querySelector('.page-sidebar').classList.remove('open');
    } else {
      setSidebar(!sidebar);
      document.querySelector('.page-main-header').classList.add('open');
      document.querySelector('.page-sidebar').classList.add('open');
    }
  };

  return (
    <HeaderContainer
      isMobile={
        theme.useMobileTheme || props.hubState
          ? theme.useMobileTheme || props.hubState
          : false
      }
      darkMode={theme.layout_version === 'dark-only'}
      iconColor={customColor}
      isNewNotification={isNewNotification}
      isNewMessage={props.isNewMessage}
    >
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javascript" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                <SearchHeader />
              </li>
              {currentTrack.name
              && !props.navigate.location.pathname.includes('/music') ? (
                <li className="onhover-dropdown">
                  <PlayerWidget {...props} />
                  <PlayCircle />
                </li>
                ) : null}

              <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li>
              <li className="onhover-dropdown">
                <Notifications setIsNewNotification={setIsNewNotification} />
                <Bell />
                <span className={`${isNewNotification ? 'dot dot-n' : ''}`} />
              </li>
              <li>
                <a href="#javascript" onClick={props.showRightSidebar}>
                  <Mail />
                  <span
                    className={`${props.isNewMessage ? 'dot dot-m' : ''}`}
                  />
                </a>
              </li>
              <UserMenu
                isAdmin={isAdmin}
                logOut={props.logOut}
                setHubState={props.setHubState}
                displayLoader={props.displayLoader}
                setadminState={props.setadminState}
                adminState={props.adminState}
                user={user}
              />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                  <polygon points="12 15 17 21 7 21 12 15" />
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName" />
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
    </HeaderContainer>
  );
}
export default Header;
