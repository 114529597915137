import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Heading = styled.h1`
  color: ${themeColor};
  font-weight: bolder;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #787878;
`;
export const ErrorText = styled.span`
  font-size: 14px;
  color: ${themeColor};
`;

export const ProceedButton = styled.button`
  background: ${(props) => (props.disabled ? 'rgba(205, 10, 124, 0.4)' : 'rgba(205, 10, 124, 1)')};
  cursor: ${(props) => (props.isActive ? 'default !important' : 'pointer')};
  border-radius: 5px;
  border-color: unset;
  border-width: unset;
  border-style: unset;
  overflow: hidden;
  color: #ffffff !important;
  font-size: 14px;
  color: white;
  text-transform: none;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 5px;
  padding: 10px 40px 10px 40px;
`;

export const Error = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: red;
`;
