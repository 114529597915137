import React, { useEffect, useState } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllVaultFiles,
  getAllVaultFolders,
  getFolderFiles,
  addVaultFolder,
  addFileInFolder,
} from 'src/redux/vaultRedux';
import PlusButton from 'src/components/PlusButton';
import { ModalUpload } from 'src/components/Modal';
import Breadcrumb from 'src/components/Breadcrumb';
import Media from './Sections/vault';
import Folders from './Sections/folders';

function Vault() {
  const [selectedFolder, setSelectedFolder] = useState({ name: 'all' });
  const [modalShow, setModalShow] = useState(false);
  const {
    vaultFiles,
    folderFiles,
    vaultFolders,
    vaultProcessing,
    meta,
    foldermeta,
  } = useSelector((state) => state.VaultReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllVaultFiles());
    dispatch(getAllVaultFolders());
  }, []);

  const getFolder = (folder) => {
    if (folder.name === 'all') {
      dispatch(getAllVaultFiles());
      setSelectedFolder(folder);
    } else {
      dispatch(getFolderFiles(folder.id));
      setSelectedFolder(folder);
    }
  };

  const addFile = (files) => {
    const formData = new FormData();
    files.forEach((data, index) => {
      formData.append(`files[${index}][file]`, data.file);
    });
    formData.set('allow_sharing', true);

    const payload = [selectedFolder.id, formData];
    dispatch(addFileInFolder(payload));
  };

  return (
    <>
      <Breadcrumb parent="Vault" title="Vault" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Folders
              processing={vaultProcessing}
              folders={vaultFolders}
              getFolder={getFolder}
              addFolder={addVaultFolder}
              getFolders={getAllVaultFolders}
              selected={selectedFolder}
              vaultFiles={vaultFiles}
            />
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">
                      {selectedFolder.name.charAt(0).toUpperCase()
                        + selectedFolder.name.substring(
                          1,
                          selectedFolder.name.length,
                        )}
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      {localStorage.getItem('impersonate-user') === null && localStorage.getItem('view-user') === null ? (
                        <div>
                          {selectedFolder.name !== 'all' && (
                            <PlusButton onClick={() => setModalShow(true)} />
                          )}
                        </div>
                      ) : null }
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '72vh' }}>
                <ScrollableSection className="mt-2" id="scroll-vault">
                  <div>
                    <Media
                      processing={vaultProcessing}
                      vaultFiles={
                        selectedFolder.name === 'all' ? vaultFiles : folderFiles
                      }
                      meta={selectedFolder.name === 'all' ? meta : foldermeta}
                      selectedFolder={selectedFolder}
                      dispatch={dispatch}
                    />
                    <ModalUpload
                      show={modalShow}
                      close={setModalShow}
                      size={10}
                      addFile={addFile}
                    />
                  </div>
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vault;
