// Landing page Routes
export const MAIN_ROUTE = '/';
export const ABOUT_PAGE = '/about';
export const FEATURES_PAGE = '/features';
export const PHILOSOPHY_PAGE = '/philosophy';
export const CONTACT_US_PAGE = '/contact';
export const PLANS = '/plans';
export const T_C = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
export const VERIFICATION = '/user/verify/:id';

// User-Dashboard Routes
export const USER_DASHBOARD = '/dashboard';
export const USER_FEEDS = '/feeds';
export const USER_MESSAGES = '/messages';
export const USER_CALENDAR = '/calendar';
export const USER_LISTS = '/lists';
export const USER_FILES = '/files';
export const USER_VAULT = '/vault';
export const USER_CONTACTS = '/contacts';
export const USER_DIRECTORY = '/directory';
export const USER_PROFILE = '/profile';
export const USER_HUBS = '/user-hubs';
export const USER_NETWORKS = '/user-networks';
export const USER_MUSIC = '/music';
export const USER_SETTINGS = '/settings';

// Hubs Routes
export const USER_HUB = '/hub/:id';
export const USER_HUB_MESSAGES = '/hub/:id/messages';
export const USER_HUB_CALENDAR = '/hub/:id/calendar';
export const USER_HUB_LISTS = '/hub/:id/lists';
export const USER_HUB_VAULT = '/hub/:id/vault';
export const USER_HUB_CONTACTS = '/hub/:id/contacts';
export const USER_HUB_DIRECTORY = '/hub/:id/directory';
export const USER_HUB_FILES = '/hub/:id/files';
export const USER_HUB_MUSIC = '/hub/:id/music';
export const USER_HUB_LOCATION = '/hub/:id/location';

// Admin-Dashboard Routes
export const ADMIN_OVERVIEW = '/admin';
export const ADMIN_REPORTS = '/admin-reports';
export const ADMIN_USERS = '/admin-users';
export const ADMIN_HUBS = '/admin-hubs';
export const ADMIN_NETWORKS = '/admin-networks';
export const ADMIN_PAGES = '/admin-pages';
export const ADMIN_BANNED_WORDS = '/admin-bannedwords';
export const ADMIN_MUSIC = '/admin-music';
export const ADMIN_THEME = '/admin-theme';
