import styled from 'styled-components';

export const Container = styled.div`
  background-color: #2671e1;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  .terms:hover {
    cursor: pointer;
    color: #e4007b;
  }
`;

export const NavItem = styled.div`
  color: white;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const SocialIcon = styled.span`
  font-size: 30px;
  padding: 5px 12px;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 5px;
`;
