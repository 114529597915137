// actions
export const getAllUsers = () => ({
  type: 'GET_ALL_USERS',
});

export const getAllAdminUsers = () => ({
  type: 'GET_ALL_ADMIN_USERS',
});

export const getSearchedUsers = (payload) => ({
  type: 'GET_SEARCHED_USERS',
  payload,
});

export const getUsersWithPage = (payload) => ({
  type: 'GET_PAGE_USERS',
  payload,
});

export const saveUsers = (payload) => ({
  type: 'SAVE_ALL_USERS',
  payload,
});

export const saveAllAdminUsers = (payload) => ({
  type: 'SAVE_ALL_ADMIN_USERS',
  payload,
});
export const deleteUser = (payload) => ({
  type: 'DELETE_USER',
  payload,
});

export const deleteUserAccount = () => ({
  type: 'DELETE_USER_ACCOUNT',
});

export const getOnlineUsers = (payload) => ({
  type: 'GET_ONLINE_USERS',
  payload,
});

export const saveOnlineUsers = (payload) => ({
  type: 'SAVE_ONLINE_USERS',
  payload,
});
export const getUserStats = (payload) => ({
  type: 'GET_USER_STATS',
  payload,
});

export const saveUserStats = (payload) => ({
  type: 'SAVE_USER_STATS',
  payload,
});

export const getAdminLogs = (payload) => ({
  type: 'GET_ADMIN_LOGS',
  payload,
});

export const saveAdminLogs = (payload) => ({
  type: 'SAVE_ADMIN_LOGS',
  payload,
});

export const loadMoreAdminLogs = (payload) => ({
  type: 'LOAD_MORE_ADMIN_LOGS',
  payload,
});

export const verifyUser = (payload) => ({
  type: 'VERIFY_USER',
  payload,
});
export const verifiedUser = (payload) => ({
  type: 'VERIFIED_USER',
  payload,
});
// store
const initialState = {
  users: [],
  pagination: {},
  verificationDetail: null,
  allusers: [],
  allUsersStats: {},
  allOnlineUsers: [],
  adminLogs: [],
};

// profileReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_ALL_USERS':
      return {
        ...state,
        users: action.payload.data,
      };
    case 'SAVE_ALL_ADMIN_USERS':
      return {
        ...state,
        allusers: action.payload.data,
        pagination: action.payload.meta.pagination,
      };
    case 'SAVE_ONLINE_USERS':
      return {
        ...state,
        allOnlineUsers: action.payload.data,
      };
    case 'SAVE_USER_STATS':
      return {
        ...state,
        allUsersStats: action.payload.data,
      };
    case 'SAVE_ADMIN_LOGS':
      return {
        ...state,
        adminLogs: action.payload,
      };
    case 'VERIFY_USER':
      return {
        ...state,
        verificationDetail: null,
      };
    case 'VERIFIED_USER':
      return {
        ...state,
        verificationDetail: action.payload,
      };
    default:
      return { ...state };
  }
};
