/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import configDB from 'src/utils/customizer/config';
import { addDefaultSrc } from 'src/utils/helper';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import * as LinkedAccountModal from 'src/containers/UserProfile/linkedAccountModal';
import { ADMIN_USERS } from 'src/constants/routes';
import { Edit, Monitor, Smartphone } from 'react-feather';
import { ModalUpload } from 'src/components/Modal';
import Swal from 'sweetalert2';
import { updateHubTheme } from 'src/redux/hubRedux';
import { defaultGradient } from 'src/utils/theme';
import { ProfilePic, ScrollableSection, SidebarContainer } from './styles';
import { MENUITEMS, HUBMENUITEMS, ADMINMENUITEMS } from './menu';
import UserPanel from './userPanel';

function Sidebar(props) {
  const [statetheme, setStateTheme] = useState(defaultGradient);
  const linkedAccountState = !!localStorage.getItem('impersonate-user');
  const viewUserState = !!localStorage.getItem('view-user');
  const [show, setShow] = useState(false);
  const [account, setAccount] = useState(null);
  const { hub, hubTheme } = useSelector((state) => state.HubsReducer);
  const { user, linkedAccounts } = useSelector((state) => state.ProfileReducer);
  const { theme, adminImages } = useSelector((state) => state.ThemeReducer);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState(
    props.hubState ? HUBMENUITEMS(hub) : MENUITEMS,
  );
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();

  const { wrapper } = configDB.data.settings.sidebar;
  const layout = useSelector((state) => state.ThemeReducer.layout);

  useEffect(() => {
    if (props.hubState && Object.keys(hub).length > 0) {
      setStateTheme(
        hubTheme && hubTheme.mobile && hubTheme.mobile.header_color
          ? hubTheme.mobile.header_color
          : statetheme,
      );
      setMainMenu(HUBMENUITEMS(hub));
    } else {
      if (Object.keys(user).length > 0) {
        setStateTheme(
          user.theme && user.theme.mobile && user.theme.mobile.header_color
            ? user.theme.mobile.header_color
            : statetheme,
        );
      }
      setMainMenu(MENUITEMS);
    }
    if (props.adminState) setMainMenu(ADMINMENUITEMS);
  }, [user, hub, props.hubState, props.adminState]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    const currentUrl = window.location.pathname;

    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          }
          return false;
        });
        return subItems;
      });
      return items;
    });

    const timeout = setTimeout(() => {
      const elmnt = document.getElementById('myDIV');
      const menuWidth = elmnt.offsetWidth;
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
      }
    }, 500);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 310);
  };

  const setNavActive = (item) => {
    mainmenu.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          }
          return false;
        });
      }
      return menuItem;
    });
    item.active = !item.active;

    setMainMenu(props.hubState ? HUBMENUITEMS(hub) : MENUITEMS);
  };

  // Click Toggle menu
  const toggletNavActive = (item) => {
    item.active = !item.active;
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById('myDIV');
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  const sortedLinkedAccounts = (linkedAccounts) => {
    linkedAccounts.sort((a, b) => {
      const key1 = a.age;
      const key2 = b.age;
      if (key1 < key2) return 1;
      if (key1 > key2) return -1;
      return 0;
    });
    return linkedAccounts;
  };

  const setCustomBackground = (files) => {
    if (files.length) {
      if (files[0].file.size > 14648000) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Image Size',
          text: 'Image Size can not be greater than 14MB.',
        });
        return;
      }
      const formData = new FormData();
      formData.append('sidebar_bg_image', files[0].file);
      formData.append('color_sidebarbg', 'bg-img-custom');
      formData.append('platform', 'web');
      dispatch(updateHubTheme({ id: hub.id, data: formData }));
    }
  };

  const getSidebarTheme = () => {
    try {
      if (theme.layout_version === 'dark-only') {
        return "linear-gradient('#010101','#010101') !important";
      }
      if (props.hubState) {
        if (hubTheme && hubTheme.web && hubTheme.web.use_mobile_theme) {
          if (
            hubTheme.mobile.header_color[0] === '#ffffff'
              && hubTheme.mobile.header_color[1] === '#ffffff'
          ) {
            return `linear-gradient(${defaultGradient[0]},${defaultGradient[1]}) !important`;
          }
          return `linear-gradient(${hubTheme.mobile.header_color[0]},${hubTheme.mobile.header_color[1]}) !important`;
        }
        return hubTheme?.web?.sidebar_bg_image
          ? `url(https://res.cloudinary.com/liviit/image/upload/${hubTheme?.web?.sidebar_bg_image}) !important`
          : `linear-gradient(${defaultGradient[0]},${defaultGradient[1]}) !important`;
      }
      if (theme.useMobileTheme) {
        return `linear-gradient(${statetheme[0]},${statetheme[1]}) !important`;
      } if (
        theme.color_sidebarbg.includes('bg-img-custom')
            && theme.sidebar_bg_image
      ) {
        return `url(https://res.cloudinary.com/liviit/image/upload/${theme.sidebar_bg_image}) !important`;
      } if (
        theme.color_sidebarbg.includes('bg-img')
            && adminImages.length
      ) {
        return `url(https://res.cloudinary.com/liviit/image/upload/${
          adminImages[theme.color_sidebarbg.slice(-1) - 1]
        }) !important`;
      }
    } catch (err) {
      return `linear-gradient(${statetheme[0]},${statetheme[1]}) !important`;
    }
  };

  return (
    <>
      <SidebarContainer
        className={`page-sidebar ${
          !theme.useMobileTheme ? theme.color_sidebarbg : ''
        }`}
        theme={getSidebarTheme()}
      >
        <UserPanel
          hubState={props.hubState}
          linkedAccountState={linkedAccountState}
          viewUserState={viewUserState}
          accounts={linkedAccounts}
          hub={hub}
          bgColor={statetheme}
        />
        <div className="sidebar custom-scrollbar">
          <ul
            className="sidebar-menu"
            id="myDIV"
            style={
              wrapper === 'horizontal_sidebar'
                ? layout === 'rtl'
                  ? { marginRight: `${margin}px` }
                  : { marginLeft: `${margin}px` }
                : { margin: '0px' }
            }
          >
            <li
              className={`left-arrow ${
                layout === 'rtl'
                  ? hideLeftArrowRTL
                    ? 'd-none'
                    : ''
                  : hideLeftArrow
                    ? 'd-none'
                    : ''
              }`}
              onClick={
                wrapper === 'horizontal_sidebar' && layout === 'rtl'
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left" />
            </li>
            {mainmenu.map((menuItem, i) => (
              <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ''
                )}
                {menuItem.type === 'sub' ? (
                  <a
                    href="#javascript"
                    className="sidebar-header"
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>{props.t(menuItem.title)}</span>
                    <i className="fa fa-angle-right pull-right" />
                  </a>
                ) : (
                  ''
                )}
                {menuItem.type === 'link' ? (
                  <Link
                    to={menuItem.path ? menuItem.path : '#'}
                    className={`sidebar-header ${
                      menuItem.active ? 'active' : ''
                    }`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    {menuItem.icon && <menuItem.icon />}
                    <span>{props.t(menuItem.title)}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right" />
                    ) : (
                      ''
                    )}
                  </Link>
                ) : (
                  ''
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${
                      menuItem.active ? 'menu-open' : ''
                    }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: 'opacity 500ms ease-in' }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? 'active'
                              : ''
                            : ''
                        }
                      >
                        {childrenItem.type === 'sub' ? (
                          <a
                            href="#javascript"
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle" />
                            {props.t(childrenItem.title)}
                            {' '}
                            <i className="fa fa-angle-right pull-right" />
                          </a>
                        ) : (
                          ''
                        )}

                        {childrenItem.type === 'link' ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                            className={childrenItem.active ? 'active' : ''}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle" />
                            {props.t(childrenItem.title)}
                            {' '}
                          </Link>
                        ) : (
                          ''
                        )}
                        {childrenItem.children ? (
                          <ul
                            className={`sidebar-submenu ${
                              childrenItem.active ? 'menu-open' : 'active'
                            }`}
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li
                                  className={
                                    childrenSubItem.active ? 'active' : ''
                                  }
                                  key={key}
                                >
                                  {childrenSubItem.type === 'link' ? (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                      className={
                                        childrenSubItem.active ? 'active' : ''
                                      }
                                      onClick={() => toggletNavActive(childrenSubItem)}
                                    >
                                      <i className="fa fa-circle" />
                                      {props.t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                </li>
                              ),
                            )}
                          </ul>
                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ''
                )}
              </li>
            ))}
            {!props.hubState
            && !props.adminState
            && !viewUserState
            && linkedAccounts.length
            && !linkedAccountState ? (
              <li>
                <a
                  className="sidebar-header"
                  href="#javascript"
                  onClick={() => {}}
                >
                  Linked Accounts
                  <div className="w-100" style={{ height: '195px' }}>
                    <ScrollableSection className="container-fluid">
                      {sortedLinkedAccounts(linkedAccounts).map((account) => (
                        <div
                          key={account.id}
                          className="my-2"
                          onClick={() => {
                            setAccount(account);
                            setShow(true);
                          }}
                        >
                          <div className="d-flex">
                            <ProfilePic>
                              <img
                                src={
                                    account.avatar
                                      ? account.avatar
                                      : ProfilePictureIcon
                                  }
                                alt="#"
                                onError={addDefaultSrc}
                                className="rounded-circle lazyloaded blur-up"
                                width="100%"
                                height="100%"
                              />
                            </ProfilePic>
                            <div className="ml-2 pt-2">{`${account.first_name} ${account.last_name}`}</div>
                          </div>
                        </div>
                      ))}
                    </ScrollableSection>
                  </div>
                </a>
              </li>
              ) : null}
            {linkedAccountState ? (
              <li>
                <a
                  className="sidebar-header"
                  href="#javascript"
                  onClick={() => {
                    localStorage.removeItem('impersonate-user');
                    window.location.reload();
                  }}
                >
                  Return to User Dashboard
                </a>
              </li>
            ) : null}
            {viewUserState ? (
              <li>
                <a
                  className="sidebar-header"
                  href="#javascript"
                  onClick={() => {
                    localStorage.removeItem('view-user');
                    props.navigate(ADMIN_USERS);
                    window.location.reload();
                  }}
                >
                  Return to Admin Dashboard
                </a>
              </li>
            ) : null}
            <li
              className={`right-arrow ${
                layout === 'rtl'
                  ? hideRightArrowRTL
                    ? 'd-none'
                    : ''
                  : hideRightArrow
                    ? 'd-none'
                    : ''
              }`}
              onClick={
                wrapper === 'horizontal_sidebar' && layout === 'rtl'
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right" />
            </li>
          </ul>
        </div>
        {props.hubState
          && hub
          && (hub.user_role === 'creator' || hub.user_role === 'admin') && (
            <div className="hub-edit btn mt-2 w-100 d-flex justify-content-between ">
              {hubTheme?.web?.use_mobile_theme ? (
                <>
                  <Monitor
                    id="w-theme-tool"
                    className="shadow"
                    onClick={() => dispatch(
                      updateHubTheme({
                        id: hub.id,
                        data: {
                          theme: {
                            use_mobile_theme: false,
                          },
                          platform: 'web',
                        },
                      }),
                    )}
                  />
                  <UncontrolledTooltip placement="top" target="w-theme-tool">
                    Switch to Web Theme
                  </UncontrolledTooltip>
                </>
              ) : (
                <>
                  <Smartphone
                    id="m-theme-tool"
                    className="shadow"
                    onClick={() => dispatch(
                      updateHubTheme({
                        id: hub.id,
                        data: {
                          theme: {
                            use_mobile_theme: true,
                          },
                          platform: 'web',
                        },
                      }),
                    )}
                  />
                  <UncontrolledTooltip placement="top" target="m-theme-tool">
                    Switch to Mobile Theme
                  </UncontrolledTooltip>
                  <Edit onClick={() => setModalShow(true)} />
                </>
              )}
            </div>
        )}
      </SidebarContainer>
      {account && (
        <LinkedAccountModal
          show={show}
          setShow={setShow}
          user={account}
          {...props}
        />
      )}
      <ModalUpload
        show={modalShow}
        close={setModalShow}
        size={1}
        addFile={setCustomBackground}
        accept={'image/*'}
        title="Select Your Image"
      />
    </>
  );
}

export default translate(Sidebar);
