import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { loadMoreData } from 'src/api';
import {
  fetchUserDetails,
  fetchContacts,
  fetchLinkedAccounts,
  fetchUserActivities,
  changeContact,
  postContact,
  handleNotificationAction,
  updateUserDetails,
  fetchWeather,
  updateTheme,
  updateContact,
  changeContactBlock,
} from 'src/api/profile';
import {
  saveUserDetails,
  saveContacts,
  saveLinkedAccounts,
  saveUserActivities,
  saveNotifications,
  saveAllContacts,
  saveToggledContact,
} from 'src/redux/profileRedux';
import { saveTheme } from 'src/redux/themeRedux';
import { processing, setError, saveWeather } from 'src/redux/serviceRedux';

function* getUserDetails(action) {
  try {
    yield put(processing({ status: true, component: 'user-profile' }));
    const [user, contacts, linkedAccounts, activities] = yield all([
      yield call(fetchUserDetails),
      yield call(fetchContacts, action.payload),
      yield call(fetchLinkedAccounts),
      yield call(fetchUserActivities, 'user-log'),
    ]);
    yield put(saveUserDetails(user));
    yield put(saveContacts(contacts));
    yield put(saveLinkedAccounts(linkedAccounts));
    yield put(saveUserActivities(activities));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-profile' }));
    yield put(setError(false));
  }
}

function* toggleContact(action) {
  try {
    yield put(processing({ status: true, component: 'contacts-favourite' }));
    yield call(changeContact, action.payload);
    const response = yield call(fetchContacts, action.payload.email);
    yield put(saveToggledContact(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'contacts-favourite' }));
    yield put(setError(false));
  }
}

function* toggleContactBlock(action) {
  try {
    yield put(processing({ status: true, component: 'contacts-block' }));
    yield call(changeContactBlock, action.payload);
    if (action.payload.email) {
      const response = yield call(fetchContacts, action.payload.email);
      yield put(saveToggledContact(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'contacts-block' }));
    yield put(setError(false));
  }
}

function* changeContacts(action) {
  try {
    yield put(processing({ status: true, component: 'contacts' }));
    yield call(updateContact, action.payload);
    const response = yield call(fetchContacts);
    yield put(saveContacts(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'contacts' }));
    yield put(setError(false));
  }
}

function* addContact(action) {
  try {
    yield put(processing({ status: true, component: 'contacts' }));
    yield call(postContact, action.payload);
    const response = yield call(fetchContacts);
    yield put(saveContacts(response));
  } catch (error) {
    yield put(
      setError({
        status: true,
        message: 'Error in Adding this Contact!',
      }),
    );
  } finally {
    yield put(processing({ status: false, component: 'contacts' }));
    setError({
      status: false,
      message: '',
    });
  }
}

function* getContacts(action) {
  try {
    yield put(processing({ status: true, component: 'contacts' }));
    const response = yield call(fetchContacts, action.payload);
    yield put(saveContacts(response));
  } catch (error) {
    yield put(
      setError({
        status: true,
        message: 'Error!!',
      }),
    );
  } finally {
    yield put(processing({ status: false, component: 'contacts' }));
    setError({
      status: false,
      message: '',
    });
  }
}
function* handleNotification(action) {
  try {
    yield put(processing({ status: true, component: 'notifications' }));
    yield call(handleNotificationAction, action.payload);
    const response = yield call(fetchUserActivities, 'notification');
    yield put(saveNotifications(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'notifications' }));
    yield put(setError(false));
  }
}

function* getNotifications(action) {
  try {
    yield put(processing({ status: true, component: 'notifications' }));
    const response = yield call(fetchUserActivities, action.payload);
    yield put(saveNotifications(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'notifications' }));
    yield put(setError(false));
  }
}

function* getMoreActivities(action) {
  try {
    yield put(processing({ status: true, component: 'notification' }));
    const response = yield call(loadMoreData, action.payload);
    if (action.payload.type === 'notification') yield put(saveNotifications(response));
    else yield put(saveUserActivities(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'notification' }));
    yield put(setError(false));
  }
}

function* getMoreContacts(action) {
  try {
    yield put(processing({ status: true, component: 'contactss' }));
    const response = yield call(loadMoreData, action.payload);
    yield put(saveContacts(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'contactss' }));
    yield put(setError(false));
  }
}

function* getAllContacts(action) {
  try {
    yield put(processing({ status: true, component: 'allcontacts' }));
    const response = yield call(fetchContacts, action.payload);
    let { data } = response;
    let meta = response.meta.pagination;
    while (meta.current_page !== meta.total_pages) {
      const responseNext = yield call(loadMoreData, { url: meta.links.next });

      data = data.concat(responseNext.data);

      meta = responseNext.meta.pagination;
    }

    yield put(saveAllContacts(data));
  } catch (error) {
    yield put(
      setError({
        status: true,
        message: 'Error!!',
      }),
    );
  } finally {
    yield put(processing({ status: false, component: 'allcontacts' }));
    setError({
      status: false,
      message: '',
    });
  }
}

function* updateUserProfile(action) {
  try {
    const response = yield call(updateUserDetails, action.payload);
    yield put(saveUserDetails(response));
  } catch (error) {
    yield put(setError(true));
  }
}

function* getWeather(action) {
  try {
    yield put(processing({ status: true, component: 'weather' }));
    const response = yield call(fetchWeather, action.payload);
    yield put(saveWeather(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'weather' }));
    yield put(setError(false));
  }
}

function* changeTheme(action) {
  try {
    yield put(processing({ status: true, component: 'theme' }));
    const response = yield call(updateTheme, action.payload);
    yield put(saveTheme(response.theme.web));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'theme' }));
    yield put(setError(false));
  }
}

export function* watchProfileSaga() {
  yield takeLatest('GET_USER_DETAILS', getUserDetails);
  yield takeLatest('TOGGLE_CONTACT', toggleContact);
  yield takeLatest('UPDATE_CONTACT', changeContacts);
  yield takeLatest('ADD_CONTACT', addContact);
  yield takeLatest('GET_CONTACTS', getContacts);
  yield takeLatest('GET_NOTIFICATIONS', getNotifications);
  yield takeLatest('CHECK_NOTIFICATION', handleNotification);
  yield takeLatest('LOAD_MORE_NOTIFICATION', getMoreActivities);
  yield takeLatest('LOAD_MORE_CONTACTS', getMoreContacts);
  yield takeLatest('UPDATE_USER', updateUserProfile);
  yield takeLatest('GET_WEATHER', getWeather);
  yield takeLatest('CHANGE_THEME', changeTheme);
  yield takeLatest('GET_ALL_CONTACTS', getAllContacts);
  yield takeLatest('TOGGLE_CONTACT_BLOCK', toggleContactBlock);
}
