import React, { useState } from 'react';
import Loader from 'src/components/Loader';
import { ChatInput, ProfilePic } from './styles';

function Comments({
  close,
  feedId,
  comments,
  processing,
  add,
  remove,
  addDefaultSrc,
  userId,
}) {
  const [caption, setCaption] = useState('');
  return (
    <div>
      {processing.status && processing.component === 'feed-comment' ? (
        <Loader show />
      ) : (
        <div>
          <div
            className="w-100 text-right text-danger font-weight-bold btn m-0 px-1"
            onClick={() => close(feedId)}
          >
            X
          </div>
          {comments.length ? (
            comments.map((data) => (
              <div
                key={data.id}
                className="d-flex justify-content-between p-2"
              >
                <div className="d-flex">
                  <ProfilePic>
                    <img
                      className="rounded-circle lazyloaded blur-up"
                      src={
                          data.commented_by.avatar
                            ? data.commented_by.avatar
                            : ''
                        }
                      onError={addDefaultSrc}
                      alt="#"
                      width="30px"
                      height="30px"
                    />
                  </ProfilePic>
                  <div className="ml-1">
                    <span className="text-danger">
                      @
                      {data.commented_by.username}
                    </span>
                    <span className="ml-1">{data.comment}</span>
                  </div>
                </div>
                <div>
                  <span>{data.date_time}</span>
                  {userId === data.commented_by.id ? (
                    <span
                      onClick={() => remove(feedId, data.id)}
                      className="ml-1 text-danger"
                    >
                      <i className="fas fa-trash-alt" />
                    </span>
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <div className="h-25" />
          )}
        </div>
      )}
      <div className="d-flex p-2">
        <ChatInput
          type="text"
          placeholder="Write your message"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
        />
        <div
          className="ml-2 btn m-0"
          onClick={() => {
            setCaption('');
            add(feedId, caption);
          }}
          style={{ color: '#c50000' }}
        >
          <i className="fas fa-paper-plane" />
        </div>
      </div>
    </div>
  );
}

export default Comments;
