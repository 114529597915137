import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { Button } from 'src/components/Button';
import Modal from 'react-bootstrap/Modal';
import { ProfilePic } from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';

function HubInformation(props) {
  const { hubMembers } = useSelector((state) => state.HubsReducer);
  useEffect(() => {
    if (hubMembers.length) {
      hubMembers.sort((a, b) => {
        const textA = a.user_name.toUpperCase();
        const textB = b.user_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }, [hubMembers]);
  const [modalshow, setModalShow] = useState(false);

  return (
    <>
      {!Object.keys(props.hub).length ? (
        <Loader show />
      ) : (
        <div className="text-center">
          <div
            className="mx-auto"
            style={{ width: '60px', height: '60px', borderRadius: '50%' }}
          >
            <img
              className="img-60 rounded-circle lazyloaded blur-up"
              src={props.hub.avatar ? props.hub.avatar : ProfilePictureIcon}
              onError={addDefaultSrc}
              alt="#"
              width="100%"
              height="100%"
            />
          </div>
          <h6 className="mt-3 f-14 mb-0">
            {props.hub.name ? props.hub.name : '-'}
            {' '}
          </h6>
          <div>{props.hub.alias ? props.hub.alias : '-'}</div>
          <div>
            <div>
              {props.hub.category}
              -
              {props.hub.subcategory}
            </div>
            <div>{props.hub.classification}</div>
            <div>{props.hub.is_public === 1 ? 'Public' : 'Private'}</div>
          </div>
          <Button
            variant="solid"
            value="View"
            onClick={() => setModalShow(true)}
            padding="2px 25px"
            margin="5px"
          />
        </div>
      )}
      <Modal
        show={modalshow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: 'none' }} closeButton />
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              {hubMembers.map((person, index) => {
                if (person.user_role === 'creator') {
                  return (
                    <div className="col-12" key={index}>
                      <div className="card">
                        <div className="card-header">
         <div className="row">
                          <div className="col-sm-6 d-flex">
                            <ProfilePic>
                                <img
                                  className="rounded-circle"
                                  src={
                                    person.user_original_avatar
                                      ? person.user_original_avatar
                                      : ProfilePictureIcon
                                  }
                                  alt="#"
                                  width="100%"
                                  height="100%"
                                  onError={addDefaultSrc}
                                />
                            </ProfilePic>
                            <div className="ml-2">
                                <h6>{person.user_name}</h6>
                                <div>
                                  @
                                  {person.user_username}
                                </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="pull-right right-header">
                                <h6>{person.user_role.toUpperCase()}</h6>
                            </div>
                          </div>
         </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {hubMembers.map((person, index) => {
                if (person.user_role === 'admin') {
                  return (
                    <div className="col-12" key={index}>
                      <div className="card">
                        <div className="card-header">
         <div className="row">
                          <div className="col-sm-6 d-flex">
                            <ProfilePic>
                                <img
                                  className="rounded-circle"
                                  src={
                                    person.user_original_avatar
                                      ? person.user_original_avatar
                                      : ProfilePictureIcon
                                  }
                                  alt="#"
                                  width="100%"
                                  height="100%"
                                  onError={addDefaultSrc}
                                />
                            </ProfilePic>
                            <div className="ml-2">
                                <h6>{person.user_name}</h6>
                                <div>
                                  @
                                  {person.user_username}
                                </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="pull-right right-header">
                                <h6>{person.user_role.toUpperCase()}</h6>
                            </div>
                          </div>
         </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {hubMembers.map((person, index) => {
                if (person.user_role === 'member') {
                  return (
                    <div className="col-12" key={index}>
                      <div className="card">
                        <div className="card-header">
         <div className="row">
                          <div className="col-sm-6 d-flex">
                            <ProfilePic>
                                <img
                                  className="rounded-circle"
                                  src={
                                    person.user_original_avatar
                                      ? person.user_original_avatar
                                      : ProfilePictureIcon
                                  }
                                  alt="#"
                                  width="100%"
                                  height="100%"
                                  onError={addDefaultSrc}
                                />
                            </ProfilePic>
                            <div className="ml-2">
                                <h6>{person.user_name}</h6>
                                <div>
                                  @
                                  {person.user_username}
                                </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="pull-right right-header">
                                <h6>{person.user_role.toUpperCase()}</h6>
                            </div>
                          </div>
         </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {hubMembers.map((person, index) => {
                if (person.user_role === 'viewer') {
                  return (
                    <div className="col-12" key={index}>
                      <div className="card">
                        <div className="card-header">
         <div className="row">
                          <div className="col-sm-6 d-flex">
                            <ProfilePic>
                                <img
                                  className="rounded-circle"
                                  src={
                                    person.user_original_avatar
                                      ? person.user_original_avatar
                                      : ProfilePictureIcon
                                  }
                                  alt="#"
                                  width="100%"
                                  height="100%"
                                  onError={addDefaultSrc}
                                />
                            </ProfilePic>
                            <div className="ml-2">
                                <h6>{person.user_name}</h6>
                                <div>
                                  @
                                  {person.user_username}
                                </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="pull-right right-header">
                                <h6>{person.user_role.toUpperCase()}</h6>
                            </div>
                          </div>
         </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HubInformation;
