import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';

function Button({
  type, padding, margin, fontSize, className, width, value, disabled, variant, onClick,
}) {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      padding={padding}
      margin={margin}
      fontSize={fontSize}
      variant={variant}
      className={`btn btn-pill btn-lg ${
        className
      }`}
      disabled={disabled}
      width={width}
    >
      {value}
    </StyledButton>
  );
}

Button.defaultProps = {
  type: '',
  value: '',
  padding: null,
  margin: null,
  fontSize: '',
  width: null,
  variant: '',
  className: '',
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.number,
  variant: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
