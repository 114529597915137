import React from 'react';
import {
  Container,
  ImageContainer,
  ContentContainer,
  Title,
  Content,
} from './styles';

function Card(props) {
  return (
    <Container>
      <ImageContainer>
        <img
          src={props.item.image}
          alt="card"
          width="100%"
          style={{ borderRadius: '30%' }}
        />
      </ImageContainer>
      <ContentContainer>
        <Title>{props.item.title}</Title>
        <Content>{props.item.about}</Content>
      </ContentContainer>
    </Container>
  );
}

export default Card;
