import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchStreamToken,
  fetchFavouriteGroup,
  updateFavouriteGroup,
} from 'src/api';
import { saveToken, saveFavouriteGroup } from 'src/redux/chatRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getStreamToken() {
  try {
    yield put(processing({ status: true, component: 'chat' }));
    const response = yield call(fetchStreamToken);
    yield put(saveToken(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'chat' }));
    yield put(setError(false));
  }
}

function* getFavouriteGroup(action) {
  try {
    yield put(processing({ status: true, component: 'chat' }));
    const response = yield call(fetchFavouriteGroup, action.payload);
    yield put(saveFavouriteGroup(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'chat' }));
    yield put(setError(false));
  }
}

function* toggleFavouriteGroup(action) {
  try {
    yield put(processing({ status: true, component: 'chat' }));
    yield call(updateFavouriteGroup, action.payload[0]);
    const response = yield call(fetchFavouriteGroup, action.payload[1]);
    yield put(saveFavouriteGroup(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'chat' }));
    yield put(setError(false));
  }
}

export function* watchChatSaga() {
  yield takeLatest('GET_STREAM_TOKEN', getStreamToken);
  yield takeLatest('GET_FAV_GROUP', getFavouriteGroup);
  yield takeLatest('TOGGLE_FAV_GROUP', toggleFavouriteGroup);
}
