import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get List of User's Todo List
export const fetchUserTodoList = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}todos`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// Get Sublist of TodoLists
export const fetchTodoSublists = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}todos/${id}/child`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// Get Tasks of TodoList
export const fetchTasks = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}todos/${id}/tasks`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// Get Members of Todolist
export const fetchTodoListMembers = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}todos/${id}/members`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// Create A User's todoList
export const postTodoList = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(`${baseUrl}todos`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

// Update TodoList
export const updateTodoList = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}todos/${payload[0]}`,
      payload[1],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Add a task in todo list
export const postTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}todos/${payload.id}/tasks`,
      { tasks: payload.tasks, check_count: payload.check_count },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// toggle task status
export const toggleTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}todos/${payload[0]}/tasks/${payload[1]}/mark`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Update a Task -> payload[0] = list-id payload[1] = task-id payload[2] = updated fields
export const updateTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}todos/${payload[0]}/tasks/${payload[1]}`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Delete a Task
export const deleteTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}todos/${payload[0]}/tasks/${payload[1]}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Remove Member of TodoList
export const deleteTodoListMember = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.delete(
    `${baseUrl}todos/${payload.id}/members/${payload.userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// Reorder List
export const reorderList = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (payload[0] === 'folder') {
      await axiosInstance.put(
        `${baseUrl}todos/sequence`,
        payload[1],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
    if (payload[0] === 'tasks') {
      await axiosInstance.put(
        `${baseUrl}todos/${payload[2]}/tasks/sequence`,
        payload[1],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
    if (payload[0] === 'sublist') {
      await axiosInstance.put(
        `${baseUrl}todos/${payload[2]}/child/sequence`,
        payload[1],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
  }
};
