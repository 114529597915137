import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getTodoSublist, addNewList, addNewTask } from 'src/redux/todoRedux';
import Loader from 'src/components/Loader';
import { ScrollableSection } from 'src/components/StyledComponents';
import { ModalButton, AddTaskModal } from 'src/components/Modal';

function AddListModal(props) {
  const [state, setState] = useState('list');
  const [modalShow, setModalShow] = useState(false);
  const [selectedList, setSelectedList] = useState({});
  const [selectedSubList, setSelectedSubList] = useState({});
  const [name, setName] = useState('');
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const { userTodos, sublist, listProcessing } = useSelector(
    (state) => state.TodoReducer,
  );

  const addFolder = async (e) => {
    e.preventDefault();
    await dispatch(addNewList({ name }));
    setModalShow(false);
    setName('');
    props.close(false);
  };

  const addSublist = async (e) => {
    e.preventDefault();
    await dispatch(addNewList({ name, parent_todo_id: selectedList.id }));
    setModalShow(false);
    setName('');
    props.close(false);
  };

  const addTask = async (e) => {
    e.preventDefault();

    const payload = {
      id: selectedSubList.id,
      tasks: [name],
      check_count: count,
    };
    await dispatch(addNewTask(payload));
    setModalShow(false);
    setName('');
    setCount(1);

    props.close(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="font-weight-bold h4">{props.title}</div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="d-flex justify-content-around">
            <div
              className={
                state === 'list' ? 'h5 border-bottom font-weight-bold' : 'h6'
              }
              style={{ cursor: 'pointer' }}
              onClick={() => setState('list')}
            >
              Create List
            </div>
            <div
              className={
                state === 'sub-list'
                  ? 'h5 border-bottom font-weight-bold'
                  : 'h6'
              }
              style={{ cursor: 'pointer' }}
              onClick={() => setState('sub-list')}
            >
              Create Sublist
            </div>
            <div
              className={
                state === 'task' ? 'h5 border-bottom font-weight-bold' : 'h6'
              }
              style={{ cursor: 'pointer' }}
              onClick={() => setState('task')}
            >
              Create Task
            </div>
          </div>
          {state === 'list' && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '15vh' }}
            >
              <Button
                value="Click here to create a List"
                variant="solid"
                onClick={() => setModalShow(true)}
                padding="10px"
              />
              <ModalButton
                show={modalShow}
                close={setModalShow}
                title="Please Enter List Name"
                submit={addFolder}
                value={name}
                setValue={setName}
              />
            </div>
          )}
          {state === 'sub-list' && (
            <div className="card">
              <div className="card-body" style={{ height: '40vh' }}>
                <h6>Select a List in which you want to create a sublist.</h6>
                <div className="row h-100">
                  <div className="col-sm-6 h-100">
                    <ScrollableSection>
                      <ul className="list-group">
                        {' '}
                        {listProcessing.status
                        && listProcessing.component === 'user-todolist' ? (
                          <Loader show />
                          ) : userTodos.length ? (
                            userTodos.map((todo, index) => (
                              <li
                                className={`list-group-item ${
                                  selectedList.id === todo.id ? 'active' : null
                                }`}
                                key={index}
                                onClick={() => setSelectedList(todo)}
                              >
                                <div className="w-100">
                                  {todo.name.charAt(0).toUpperCase()
                                    + todo.name.substring(1, todo.name.length)}
                                </div>
                              </li>
                            ))
                          ) : null}
                      </ul>
                    </ScrollableSection>
                  </div>
                  <div className="col-sm-6 h-100">
                    {selectedList.id && (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <Button
                          value="Click here to create a SubList"
                          variant="solid"
                          onClick={() => setModalShow(true)}
                          padding="10px"
                        />
                        <ModalButton
                          show={modalShow}
                          close={setModalShow}
                          title="Please Enter List Name"
                          submit={addSublist}
                          value={name}
                          setValue={setName}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {state === 'task' && (
            <div className="card">
              <div className="card-body" style={{ height: '40vh' }}>
                <h6>
                  Select a List and a Sublist in which you want to add a Task.
                </h6>
                <div className="row h-100">
                  <div className="col-sm-4 h-100">
                    <ScrollableSection>
                      <ul className="list-group">
                        {' '}
                        {listProcessing.status
                        && listProcessing.component === 'user-todolist' ? (
                          <Loader show />
                          ) : userTodos.length ? (
                            userTodos.map((todo, index) => (
                              <li
                                className={`list-group-item ${
                                  selectedList.id === todo.id ? 'active' : null
                                }`}
                                key={index}
                                onClick={() => {
                                  setSelectedList(todo);
                                  dispatch(getTodoSublist(todo.id));
                                }}
                              >
                                <div className="w-100">
                                  {todo.name.charAt(0).toUpperCase()
                                    + todo.name.substring(1, todo.name.length)}
                                </div>
                              </li>
                            ))
                          ) : null}
                      </ul>
                    </ScrollableSection>
                  </div>
                  <div className="col-sm-4 h-100">
                    <ScrollableSection>
                      <ul className="list-group">
                        {' '}
                        {listProcessing.status
                        && listProcessing.component === 'user-todosublist' ? (
                          <Loader show />
                          ) : sublist.length ? (
                            sublist.map((todo, index) => (
                              <li
                                className={`list-group-item ${
                                  selectedSubList.id === todo.id
                                    ? 'active'
                                    : null
                                }`}
                                key={index}
                                onClick={() => {
                                  setSelectedSubList(todo);
                                }}
                              >
                                <div className="w-100">
                                  {todo.name.charAt(0).toUpperCase()
                                    + todo.name.substring(1, todo.name.length)}
                                </div>
                              </li>
                            ))
                          ) : null}
                      </ul>
                    </ScrollableSection>
                  </div>
                  <div className="col-sm-4 h-100">
                    {selectedSubList.id && (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <Button
                          value="Click here to add a Task"
                          variant="solid"
                          onClick={() => setModalShow(true)}
                          padding="10px"
                        />
                        <AddTaskModal
                          show={modalShow}
                          close={setModalShow}
                          title="Enter Task Name"
                          submit={addTask}
                          value={name}
                          setValue={setName}
                          showCount
                          count={count}
                          setCount={setCount}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}

export default AddListModal;
