import React, { useState } from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  InfoWindow,
  OverlayView,
} from 'react-google-maps';

import { MapContainer, OverlayContainer } from './styles';

function Map(props) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <MapContainer>
      {!props.error && (
        <GoogleMap
          defaultZoom={props.zoom ? props.zoom : 15}
          defaultCenter={
            props.coords
              ? { lat: props.coords.latitude, lng: props.coords.longitude }
              : { lat: -33.865143, lng: 151.2099 }
          }
          center={
            props.center
              ? { lat: props.center.latitude, lng: props.center.longitude }
              : { lat: props.coords.latitude, lng: props.coords.longitude }
          }
        >
          {props.markers.map((mrk) => (activeMarker === mrk.id ? (
            <InfoWindow
              key={mrk.id}
              position={{ lat: mrk.lat, lng: mrk.lng }}
              onCloseClick={() => setActiveMarker(null)}
            >
              <div>
                <div className="d-flex w-100 justify-content-center">
                  <img
                    className="mx-2"
                    src={mrk.avatar}
                    alt="user"
                    style={{
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                    }}
                  />
                </div>

                <div className="font-weight-bold">{mrk.name}</div>
                <p>{mrk.username ? `@${mrk.username}` : ''}</p>
              </div>
            </InfoWindow>
          ) : (
            <OverlayView
              key={mrk.id}
              position={{ lat: mrk.lat, lng: mrk.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <OverlayContainer>
                <div className="popup-tip-anchor">
                  <div className="popup-bubble-anchor">
                    <div className="popup-bubble-content">
                      <img
                        src={mrk.avatar}
                        width="30px"
                        height="30px"
                        onClick={() => handleActiveMarker(mrk.id)}
                        style={{ zIndex: '1000', borderRadius: '50%' }}
                        alt="user"
                      />
                    </div>
                  </div>
                </div>
              </OverlayContainer>
            </OverlayView>
          )))}
        </GoogleMap>
      )}
    </MapContainer>
  );
}

export default withScriptjs(withGoogleMap(Map));
