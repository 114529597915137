import React from 'react';
import Loader from 'src/components/Loader';
import { InfoSection } from 'src/containers/Networks/styles';
import { ProfilePic } from 'src/components/StyledComponents';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { addDefaultSrc } from 'src/utils/helper';
import { Button } from 'src/components/Button';
import { Globe } from 'react-feather';

function NetworkInfo(props) {
  if (!props.network) {
    return (
      <Loader show />
    );
  }

  return (
    <>
      {Object.keys(props.network).length ? (
        <InfoSection className="socialprofile">
          <div
            style={{
              backgroundImage: `linear-gradient(${
                props.network.theme ? props.network.theme[0] : '#000'
              },${props.network.theme ? props.network.theme[1] : '#000'})`,
              height: '200px',
              borderRadius: '8px',
            }}
          />

          <div className="avatar">
            <div>
              <Button
                variant="outline"
                value={
                  props.network.category[0].toUpperCase()
                  + props.network.category.slice(1, props.network.category.length)
                }
                padding="1px 8px"
                margin="1px 2px"
              />
            </div>
            <img
              src={
                props.network.original_avatar
                  ? props.network.original_avatar
                  : ProfilePictureIcon
              }
              alt="#"
              onError={addDefaultSrc}
              className="bg-white"
            />
            <div>
              <Button
                variant="outline"
                value={
                  props.network.classification[0].toUpperCase()
                  + props.network.classification.slice(
                    1,
                    props.network.classification.length,
                  )
                }
                padding="1px 8px"
                margin="1px 2px"
              />
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <div>
              <h2>{props.network.name}</h2>
              <h6 className="text-center">
                (@
                {props.network.alias}
                )
              </h6>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row text-center">
              <div className="col-6">
                <h5 className="text-dark">About</h5>
                <p className="font-size-larger">{props.network.bio}</p>
              </div>
              <div className="col-6">
                <h5 className="text-dark">Location</h5>
                <p>{props.network.location}</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div
              style={{
                boxShadow: '1px 5px 20px 0 rgb(0 0 0 / 30%)',
                cursor: 'pointer',
                padding: '20px',
              }}
            >
              <ProfilePic className="mx-auto">
                <img
                  className="rounded-circle"
                  src={props.network.member_original_avatar}
                  alt="#"
                  width="100%"
                  height="100%"
                />
              </ProfilePic>
              <div className="text-center mt-2">
                <h6>{props.network.member_type.toUpperCase()}</h6>
              </div>
            </div>
          </div>
        </InfoSection>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Globe size={40} alignmentBaseline="center" />
          <div className="h4 mt-2 ml-4">No Network</div>
        </div>
      )}
    </>
  );
}

export default NetworkInfo;
