import React, { useState, useEffect } from 'react';
import Loader from 'src/components/Loader';
import { ScrollableSection } from 'src/components/StyledComponents';
import PlusButton from 'src/components/PlusButton';
import { useDispatch } from 'react-redux';
import { ModalButton } from 'src/components/Modal';
import ClosedFolderIcon from 'src/assets/images/livit/closed-folder.png';

function Folders(props) {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.vaultFiles.length) {
      for (let i = 0; i < props.vaultFiles.length; i++) {
        if (props.vaultFiles[i].url.split('.').pop() === 'jpg') {
          setThumbnail(props.vaultFiles[i].url);
          break;
        }
      }
    }
  }, [props.vaultFiles]);

  const addFolder = async (e) => {
    e.preventDefault();
    await dispatch(props.addFolder({ name }));
    setModalShow(false);
    setName('');
    await dispatch(props.getFolders());
  };
  const addDefaultSrc = (e) => {
    e.target.src = ClosedFolderIcon;
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h5 className="align-abjust">Folders</h5>
            </div>
            <div className="col-sm-6">
              <div className="pull-right right-header">
                {localStorage.getItem('impersonate-user') === null
                && localStorage.getItem('view-user') === null ? (
                  <PlusButton onClick={() => setModalShow(true)} />
                  ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body" style={{ height: '72vh' }}>
          <ScrollableSection>
            <ul className="list-group">
              <li
                className={`list-group-item ${
                  props.selected.name === 'all' ? 'active disable' : null
                }`}
                onClick={() => props.getFolder({ name: 'all' })}
              >
                <div className="d-flex">
                  <div style={{ width: '50px', height: '50px' }}>
                    <img
                      className="rounded"
                      src={thumbnail}
                      alt="icon"
                      width="100%"
                      height="100%"
                      onError={addDefaultSrc}
                    />
                  </div>
                  <div className="my-auto">All</div>
                </div>
              </li>

              {props.processing.status
              && props.processing.component === 'user-vaultd' ? (
                <Loader show />
                ) : props.folders.length ? (
                  props.folders.map((data) => (
                    <li
                      className={`list-group-item ${
                        props.selected.id === data.id ? 'active disable' : null
                      }`}
                      key={data.id}
                      onClick={() => props.getFolder(data)}
                    >
                      <div className="d-flex">
                        <div style={{ width: '50px', height: '50px' }}>
                          <img
                            className="rounded"
                            src={
                              data.thumbnail ? data.thumbnail : ClosedFolderIcon
                            }
                            alt="icon"
                            width="100%"
                            height="100%"
                            onError={addDefaultSrc}
                          />
                        </div>
                        <div className="my-auto">
                          {data.name.charAt(0).toUpperCase()
                            + data.name.substring(1, data.name.length)}
                        </div>
                      </div>
                    </li>
                  ))
                ) : null}
            </ul>
          </ScrollableSection>
        </div>
      </div>
      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Folder Name"
        submit={addFolder}
        value={name}
        setValue={setName}
      />
    </>
  );
}

export default Folders;
