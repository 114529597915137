import { combineReducers } from 'redux';
import AuthReducer from './authRedux';
import ProfileReducer from './profileRedux';
import FeedsReducer from './feedsRedux';
import HubsReducer from './hubRedux';
import RemaindersReducer from './remaindersRedux';
import ServiceReducer from './serviceRedux';
import TodoReducer from './todoRedux';
import UsersReducer from './usersRedux';
import VaultReducer from './vaultRedux';
import NetworkReducer from './networkRedux';
import ChatReducer from './chatRedux';
import PageReducer from './pageRedux';
import BannedWordsReducer from './bannedWordsRedux';
import ThemeReducer from './themeRedux';
import FilesReducer from './filesRedux';
import AudioPlayerReducer from './audioPlayerRedux';
import ReportReducer from './reportRedux';
import SubscriptionReducer from './subscriptionRedux';

const reducers = combineReducers({
  AuthReducer,
  ProfileReducer,
  FeedsReducer,
  HubsReducer,
  RemaindersReducer,
  ServiceReducer,
  TodoReducer,
  UsersReducer,
  VaultReducer,
  NetworkReducer,
  ChatReducer,
  PageReducer,
  BannedWordsReducer,
  ThemeReducer,
  FilesReducer,
  AudioPlayerReducer,
  ReportReducer,
  SubscriptionReducer,
});

export default reducers;
