import React, { useState } from 'react';
import Loader from 'src/components/Loader';
import {
  Heading,
  ScrollableSection,
} from 'src/components/StyledComponents';
import PlusButton from 'src/components/PlusButton';
import { useDispatch } from 'react-redux';
import { ModalButton } from 'src/components/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Folders(props) {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const addFolder = async (e) => {
    e.preventDefault();
    await dispatch(props.addList({ name }));
    setModalShow(false);
    setName('');
    await dispatch(props.getLists());
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h5 className="align-abjust">Folders</h5>
            </div>
            <div className="col-sm-6">
              <div className="pull-right right-header">
                {localStorage.getItem('impersonate-user') === null
                && localStorage.getItem('view-user') === null ? (
                  <PlusButton onClick={() => setModalShow(true)} />
                  ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <ScrollableSection>
            {props.processing.status
            && props.processing.component === 'user-todolist' ? (
              <Loader show />
              ) : props.folders.length ? (
                <DragDropContext onDragEnd={props.onDragEnd}>
                  <Droppable droppableId="droppable" type="folders">
                    {(provided) => (
                      <ul
                        className="list-group"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {props.folders.map((todo, index) => (
                          <Draggable
                            key={todo.id}
                            draggableId={todo.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                className={`list-group-item ${
                                  props.selected.id === todo.id
                                    ? 'active'
                                    : null
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                onClick={() => props.getSublist(todo)}
                              >
                                <div className="w-50">
                                  {todo.name.charAt(0).toUpperCase()
                                    + todo.name.substring(1, todo.name.length)}
                                </div>
                                <div>
                                  {todo.child_todo.total}
                                  {' '}
                                  lists
                                </div>
                                <div {...provided.dragHandleProps}>
                                  <i className="fas fa-bars" />
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Heading className="text-center h4 mt-4">
                  You have No List.
                </Heading>
              )}
          </ScrollableSection>
        </div>
      </div>

      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Folder Name"
        submit={addFolder}
        value={name}
        setValue={setName}
      />
    </>
  );
}

export default Folders;
