import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'src/components/Navbar';
import Footer from 'src/components/Footer';
import Breadcrumb from 'src/components/Breadcrumb';
import TextField from 'src/components/InputField';
import { SUBSCRIPTION_PLANS, PlanPrice } from 'src/utils/data';
import { getPaymentPlans } from 'src/redux/subscriptionRedux';
import { Loader } from 'src/components';
import {
  ProfileReducer,
  SubscriptionReducer,
  ServiceReducer,
} from 'src/selectors';
import { SubscriptionContainer, UpgradeButton } from './styles';
import PaymentModal from './PaymentModal';
import ConfirmPayment from './ConfirmPaymentModal';

function Subscription() {
  const isLoggedIn = localStorage.getItem('token');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const { plans, plansProcessing } = useSelector(SubscriptionReducer);
  const { processing } = useSelector(ServiceReducer);
  const { user } = useSelector(ProfileReducer);
  const [userLoaded, setUserLoaded] = useState(
    () => !!user && Object.keys(user).length > 0,
  );

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);

  const stripePlan = user?.plan?.stripe_plan;

  useEffect(() => {
    dispatch(getPaymentPlans());
  }, []);

  useEffect(() => {
    if (!processing.status && processing.component === 'user-profile') {
      setUserLoaded(true);
    }
  }, [processing]);

  const isPlansLoading = (plansProcessing.status
      && plansProcessing.component === 'subscription-plans')
    || !userLoaded;

  return (
    <>
      {isLoggedIn ? <Breadcrumb parent="Settings" title="Plans" /> : <Navbar />}
      <SubscriptionContainer>
        <div className="container-fluid my-2">
          {isLoggedIn ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="h4">
                        Current Plan-:
                        <span className="ml-3 h6 pt-1">
                          Trial Plan
                          <Edit className="ml-2" />
                        </span>
                      </div>
                      <div>
                        <div className="h4">
                          Expires on-:
                          <span className="ml-3 h6 pt-1">30/4/2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <span className="h4">Activate with Offer Code</span>
                      <span className="ml-2">
                        <TextField
                          className="form-control"
                          type="text"
                          placeholder="Enter Offer Code here"
                          borderRadius="30px"
                          fontSize="13px"
                          padding="0px 0px 0px 20px"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="card">
            <div className="card-header">
              <h5>User Plans</h5>
            </div>
            <div className="card-body">
              <div className="container-fluid">
                {isPlansLoading ? (
                  <Loader show />
                ) : (
                  <>
                    <div className="row">
                      {[...plans, SUBSCRIPTION_PLANS.Starter]
                        .sort(
                          (a, b) => PlanPrice[a.name].dollar - PlanPrice[b.name].dollar,
                        )
                        .map((plan, index) => {
                          const { name, id } = plan;
                          const { features } = SUBSCRIPTION_PLANS[name];
                          const { dollar, cents } = PlanPrice[name];
                          const isLastItem = index === 3;
                          return (
                            <div key={id} className="col-md-3">
                              <div className="pricing-block card text-center">
                                <svg x="0" y="0" viewBox="0 0 360 220">
                                  <linearGradient
                                    id="gradient"
                                    x1="0%"
                                    y1="0%"
                                    x2="100%"
                                    y2="0%"
                                  >
                                    <stop offset="0%" stopColor="#fff" />
                                    <stop offset="100%" stopColor="#fff" />
                                  </linearGradient>
                                  <g>
                                    <path
                                      d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"
                                      style={{ fill: 'url(#gradient)' }}
                                    />
                                  </g>
                                  {id !== 'free' ? (
                                    <>
                                      <text
                                        transform={
                                          isLastItem
                                            ? 'matrix(1 0 0 1 69.7256 116.2686)'
                                            : 'matrix(1 0 0 1 109.7256 116.2686)'
                                        }
                                        fill="#000"
                                        fontSize="78.4489"
                                      >
                                        $
{dollar}
                                      </text>
                                      $
                                      {cents && (
                                        <text
                                          transform="matrix(0.9566 0 0 1 197.3096 83.9121)"
                                          fontSize="29.0829"
                                        >
                                          .
{cents}
                                        </text>
                                      )}
                                      <text
                                        transform="matrix(1 0 0 1 233.9629 115.5303)"
                                        fill="#000"
                                        fontSize="15.4128"
                                      >
                                        /Month
                                      </text>
                                    </>
                                  ) : (
                                    <text
                                      transform="matrix(1 0 0 1 100.7256 116.2686)"
                                      fill="#000"
                                      fontSize="78.4489"
                                    >
                                      Free
                                    </text>
                                  )}
                                </svg>
                                <div className="pricing-inner">
                                  <h3>{name}</h3>
                                  <ul className="pricing-inner">
                                    {features.map((item, i) => (
                                      <li key={i}>
                                        <h6>{item}</h6>
                                      </li>
                                    ))}
                                  </ul>
                                  {isLoggedIn ? (
                                    stripePlan === id
                                    || (id === 'free' && !stripePlan) ? (
                                      <button
                                        className="btn btn-primary btn-lg text-md"
                                        type="button"
                                        style={{ cursor: 'default' }}
                                      >
                                        Current Plan
                                      </button>
                                      ) : (
                                      <UpgradeButton
                                        onClick={() => {
                                          setSelectedSubscriptionPlan(plan);
                                          setShowPaymentModal(true);
                                        }}
                                      >
                                        {id === 'free'
                                          ? 'Free Plan'
                                          : stripePlan
                                            ? 'Upgrade'
                                            : 'Subscribe'}
                                      </UpgradeButton>
                                      )
                                  ) : (
                                    <UpgradeButton type="submit">
                                      Get Started
                                    </UpgradeButton>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isLoggedIn ? <Footer /> : null}
        {selectedSubscriptionPlan
          && (stripePlan ? (
            <ConfirmPayment
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              setSelectedSubscriptionPlan={setSelectedSubscriptionPlan}
              showConfirmationModal={showPaymentModal}
              setShowConfirmationModal={setShowPaymentModal}
            />
          ) : (
            <PaymentModal
              setSelectedSubscriptionPlan={setSelectedSubscriptionPlan}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              showPaymentModal={showPaymentModal}
              setShowPaymentModal={setShowPaymentModal}
            />
          ))}
      </SubscriptionContainer>
    </>
  );
}

export default Subscription;
