import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: 10%;
  height: 10%;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 5px 8px 0px #ccc;
  @media (max-width: 990px) {
    width: 20%;
  }
  @media (max-width: 760px) {
    width: 24%;
  }
`;
export const ImageView = styled.div`
  width: 18%;
  height: 18%;
  border-radius: 50%;
  padding: 15px;
  @media (max-width: 990px) {
    width: 20%;
  }
  @media (max-width: 760px) {
    width: 24%;
  }
`;
