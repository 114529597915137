import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { Shape, ScrollableSection } from 'src/components/StyledComponents';
import { useNavigate } from 'react-router';
import { Button } from 'src/components/Button';
import { useSelector } from 'react-redux';
import { addDefaultSrc } from 'src/utils/helper';
import { ProfilePic, StyledTable } from './styles';

function LinkedAccountModal(props) {
  const { theme } = useSelector((state) => state.ThemeReducer);
  const { user } = useSelector((state) => state.ProfileReducer);
  const navigate = useNavigate();
  const onView = () => {
    localStorage.setItem('impersonate-user', props.user.id);
    navigate('/dashboard');
    window.location.reload();
  };
  return (
    <Modal
      show={props.show}
      onHide={() => {}}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="card">
          <Shape
            height="120px"
            color={
              theme.useMobileTheme
                ? user.theme && user.theme.mobile
                  ? user.theme.mobile.header_color
                  : ['#000', '#000']
                : [theme.color_primary, theme.color_secondary]
            }
          />
          <div
            className="text-center w-100"
            style={{ marginTop: '-50px', zIndex: '1' }}
          >
            <ProfilePic
              style={{ margin: 'auto', width: '80px', height: '80px' }}
            >
              <img
                className="rounded-circle lazyloaded"
                src={props.user.avatar ? props.user.avatar : ProfilePictureIcon}
                alt="icon"
                width="100%"
                height="100%"
                onError={addDefaultSrc}
              />
            </ProfilePic>
          </div>
          <div style={{ height: '450px' }}>
            <ScrollableSection>
              <div className="container-fluid">
                <StyledTable>
                  <tbody>
                    <tr>
                      <td className="field">Name:</td>
                      <td className="value">
                        {props.user.first_name ? props.user.first_name : ''}
                        {' '}
                        {props.user.last_name ? props.user.last_name : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Username:</td>
                      <td className="value">
                        {props.user.username ? `@${props.user.username}` : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Email:</td>
                      <td className="value">
                        {props.user.email ? props.user.email : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Verified:</td>
                      <td className="value">
                        {props.user.confirmed ? 'Yes' : 'No'}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Role:</td>
                      <td className="value">
                        {props.user.roles
                          ? props.user.roles[
                            Object.keys(props.user.roles)[0]
                          ].split(' ')[0]
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Joined On:</td>
                      <td className="value">
                        {props.user.created_at ? props.user.created_at : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Last Login:</td>
                      <td className="value">
                        {props.user.last_login_details
                          ? props.user.last_login_details.date_time
                            ? props.user.last_login_details.date_time
                            : ''
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">IP Address:</td>
                      <td className="value">
                        {props.user.last_login_details
                          ? props.user.last_login_details.ip
                            ? props.user.last_login_details.ip
                            : ''
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Location:</td>
                      <td className="value">
                        {props.user.last_login_details
                          ? props.user.last_login_details.location
                            ? props.user.last_login_details.location
                            : ''
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Admins:</td>
                      <td>
                        {props.user.masters
                          && props.user.masters.map((person, index) => (
                            <div className="card" key={index}>
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-sm-12 d-flex">
                                    <ProfilePic style={{ margin: '0px 5px' }}>
                                      <img
                                        className="rounded-circle"
                                        src={
                                            person.original_avatar
                                              ? person.original_avatar
                                              : ProfilePictureIcon
                                          }
                                        alt="#"
                                        width="32px"
                                        height="32px"
                                        onError={addDefaultSrc}
                                      />
                                    </ProfilePic>
                                    <div className="ml-1">
                                      <h6>
                                        {`${person.first_name
                                        } ${
                                          person.last_name}`}
                                      </h6>
                                      <div>
                                        @
                                        {person.username}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </td>
                    </tr>
                  </tbody>
                </StyledTable>
              </div>
            </ScrollableSection>
          </div>
        </div>
        <div className="d-flex justify-content-around w-100">
          <Button
            variant="solid"
            onClick={() => {
              onView();
            }}
            dissabled={
              props.user.age > 13
              || props.user.roles[Object.keys(props.user.roles)[0]]
                !== 'Managed Member'
            }
            value="View Account"
          />
          <Button
            variant="solid"
            onClick={() => {
              props.setShow(false);
            }}
            value="Close"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LinkedAccountModal;
