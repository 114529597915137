import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Navbarlg = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  height: 45px;
`;

export const NavItem = styled.div`
  font-size: 14px;
  color: white;
  font-weight: ${(props) => (props.selected ? 'bolder' : 'normal')};
  text-decoration: ${(props) => (props.selected ? 'underline' : 'none')};
  text-decoration-color: ${(props) => (props.selected ? 'green' : 'none')};
  &:hover {
    color: black;
    font-weight: bolder;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: green;
  }
`;
export const LogoHeading = styled.div`
  font-size: 50px;
  color: ${themeColor};
  font-family: Chewy, cursive;
  padding: 0px 15px;
`;
