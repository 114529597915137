import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StyledWeatherCard } from 'src/containers/Dashboard/styles';
import { getWeather } from 'src/redux/serviceRedux';
import { Settings } from 'react-feather';
import Loader from 'src/components/Loader';
import { Heading } from 'src/components/StyledComponents';
import MapModal from './MapModal';

function Weather({ coords, isGeolocationAvailable, isGeolocationEnabled }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.ProfileReducer);
  const { weatherData } = useSelector((state) => state.ServiceReducer);
  const [weather, setWeather] = useState({ temp: null });
  const [index, setIndex] = useState(0);
  const [modalshow, setModalShow] = useState(false);
  const [state, setState] = useState('current');
  const [address, setAddress] = useState(localStorage.getItem('location'));
  const [co, setCo] = useState({});
  const [tempState, setTempState] = useState(localStorage.getItem('tempState'));

  useEffect(() => {
    if (Object.keys(user).length > 0 && coords) {
      if (!localStorage.getItem('location-coords')) {
        localStorage.setItem(
          'location-coords',
          JSON.stringify({
            latitude: coords.latitude,
            longitude: coords.longitude,
          }),
        );
      }
      if (state === 'current') {
        dispatch(
          getWeather({
            lon: coords.longitude,
            lat: coords.latitude,
          }),
        );
      }
      if (state === 'custom') dispatch(getWeather(co));
    }
  }, [user, state, coords, co]);

  useEffect(() => {
    if (Object.keys(weatherData).length > 0) setWeather(weatherData.daily[index]);
  }, [index, weatherData]);

  const formatDescription = (str) => str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-sm-8">
            <h5 className="align-abjust">Weather</h5>
          </div>
          <div className="col-sm-4">
            <div className="pull-right right-header d-flex">
              <div className="mr-2">
                <span
                  className="p-1 bg-light text-dark rounded-circle font-weight-bolder"
                  onClick={() => {
                    setTempState(tempState === 'C' ? 'F' : 'C');
                    localStorage.setItem(
                      'tempState',
                      tempState === 'C' ? 'F' : 'C',
                    );
                  }}
                  style={{
                    cursor: 'pointer',
                    boxShadow: '2px 2px 3px',
                    verticalAlign: 'super',
                  }}
                >
                  {tempState === 'C' ? '°F' : '°C'}
                </span>
              </div>
              <div>
                <Settings onClick={() => setModalShow(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledWeatherCard className="card-body" height="33vh" smheight="290px">
        {!isGeolocationAvailable ? (
          <div>
            Unable To fetch Weather!
            Your browser does not support Location Service.
          </div>
        ) : !isGeolocationEnabled ? (
          <Heading className="text-center h4 mt-4">
            Please allow Location access and refresh the page to get Weather
            details.
          </Heading>
        ) : coords ? (
          Object.keys(weatherData).length ? (
            <>
              <div className="text-center">
                <h5 className="pb-0">
                  {address || (user.last_login_details
                    ? user.last_login_details.location.split(',')[0]
                    : '')}
                </h5>
                <div>
                  <img
                    src={`https://openweathermap.org/img/wn/${weatherData.current.weather[0].icon}@2x.png`}
                    alt="#"
                    width="80px"
                  />
                  <span style={{ fontSize: '30px' }}>
                    {tempState === 'F'
                      ? `${(weatherData.current.temp * (9 / 5) + 32).toFixed(1)
                      }°F`
                      : `${weatherData.current.temp.toFixed(1)}°C`}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-center">
                  <h4>Max</h4>
                  <div>
                    {weather.temp
                      ? tempState === 'F'
                        ? (weather.temp.max * (9 / 5) + 32).toFixed(1)
                        : weather.temp.max.toFixed(1)
                      : ''}
                  </div>
                  <div>{tempState === 'F' ? '°F' : '°C'}</div>
                </div>
                <div className="col-4 text-center">
                  <h4>Min</h4>
                  <div>
                    {weather.temp
                      ? tempState === 'F'
                        ? (weather.temp.min * (9 / 5) + 32).toFixed(1)
                        : weather.temp.min.toFixed(1)
                      : ''}
                  </div>
                  <div>{tempState === 'F' ? '°F' : '°C'}</div>
                </div>
                <div className="col-4 text-center">
                  <div>
                    {weather.temp
                      && formatDescription(weather.weather[0].description)}
                  </div>
                </div>
              </div>
              <div className="day-forecast">
                <div className="row">
                  <div
                    className={`col-4 day text-center ${
                      index === 0 ? 'active' : ''
                    }`}
                    onClick={() => setIndex(0)}
                  >
                    <div>
                      {
                        new Date(weatherData.daily[0].dt * 1000)
                          .toString()
                          .split(' ')[0]
                      }
                    </div>
                    <div>
                      <img
                        src={`https://openweathermap.org/img/wn/${weatherData.daily[0].weather[0].icon}@2x.png`}
                        alt="#"
                        width="50px"
                        height="55px"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-4 day text-center ${
                      index === 1 ? 'active' : ''
                    }`}
                    onClick={() => setIndex(1)}
                  >
                    <div>
                      {
                        new Date(weatherData.daily[1].dt * 1000)
                          .toString()
                          .split(' ')[0]
                      }
                    </div>
                    <div>
                      <img
                        src={`https://openweathermap.org/img/wn/${weatherData.daily[1].weather[0].icon}@2x.png`}
                        alt="#"
                        width="50px"
                        height="55px"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-4 day text-center ${
                      index === 2 ? 'active' : ''
                    }`}
                    onClick={() => setIndex(2)}
                  >
                    <div>
                      {
                        new Date(weatherData.daily[2].dt * 1000)
                          .toString()
                          .split(' ')[0]
                      }
                    </div>
                    <div>
                      <img
                        src={`https://openweathermap.org/img/wn/${weatherData.daily[2].weather[0].icon}@2x.png`}
                        alt="#"
                        width="50px"
                        height="55px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`top-bg-whether ${
                Object.keys(user).length > 0 ? 'd-none' : ''
              }`}
            >
              <svg
                className="climacon climacon_cloudHailAltFill"
                id="cloudHailAltFill"
                version="1.1"
                viewBox="15 15 70 70"
              >
                <g className="climacon_iconWrap climacon_iconWrap-cloudHailAltFill">
                  <g className="climacon_wrapperComponent climacon_wrapperComponent-hailAlt">
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-left">
                      <circle cx="42" cy="65.498" r="2" />
                    </g>
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-middle">
                      <circle cx="49.999" cy="65.498" r="2" />
                    </g>
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-right">
                      <circle cx="57.998" cy="65.498" r="2" />
                    </g>
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-left">
                      <circle cx="42" cy="65.498" r="2" />
                    </g>
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-middle">
                      <circle cx="49.999" cy="65.498" r="2" />
                    </g>
                    <g className="climacon_component climacon_component-stroke climacon_component-stroke_hailAlt climacon_component-stroke_hailAlt-right">
                      <circle cx="57.998" cy="65.498" r="2" />
                    </g>
                  </g>
                  <g className="climacon_componentWrap climacon_componentWrap_cloud">
                    <path
                      className="climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                      d="M43.945,65.639c-8.835,0-15.998-7.162-15.998-15.998c0-8.836,7.163-15.998,15.998-15.998c6.004,0,11.229,3.312,13.965,8.203c0.664-0.113,1.338-0.205,2.033-0.205c6.627,0,11.998,5.373,11.998,12c0,6.625-5.371,11.998-11.998,11.998C57.168,65.639,47.143,65.639,43.945,65.639z"
                    />
                    <path
                      className="climacon_component climacon_component-fill climacon_component-fill_cloud"
                      fill="#FFFFFF"
                      d="M59.943,61.639c4.418,0,8-3.582,8-7.998c0-4.417-3.582-8-8-8c-1.601,0-3.082,0.481-4.334,1.291c-1.23-5.316-5.973-9.29-11.665-9.29c-6.626,0-11.998,5.372-11.998,11.999c0,6.626,5.372,11.998,11.998,11.998C47.562,61.639,56.924,61.639,59.943,61.639z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          )
        ) : (
          <div className="card">
            <Loader show />
          </div>
        )}
      </StyledWeatherCard>
      <MapModal
        modalshow={modalshow}
        setModalShow={setModalShow}
        state={state}
        setAddress={setAddress}
        setCods={setCo}
        setState={setState}
        tempState={tempState}
        setTempState={setTempState}
      />
    </div>
  );
}

export default Weather;
