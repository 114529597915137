// actions
export const getRemainders = () => ({
  type: 'GET_REMAINDERS',
});

export const addReminders = (payload) => ({
  type: 'ADD_REMINDERS',
  payload,
});

export const saveRemainders = (payload) => ({
  type: 'SAVE_REMAINDERS',
  payload,
});

export const processReminders = (payload) => ({
  type: 'PROCESSING_REMINDERS',
  payload,
});

// store
const initialState = {
  userRemainders: [],
  reminderProcessing: { status: false, component: '' },
};

// remaindersReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_REMAINDERS':
      return { ...state, userRemainders: action.payload };
    case 'PROCESSING_REMINDERS':
      return { ...state, reminderProcessing: action.payload };
    default:
      return { ...state };
  }
};
