import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get List of Networks
export const fetchNetworks = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}networks`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// get all networks for admin
export const fetchAllNetworks = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}admin/networks${
      payload && payload.category ? `?category=${payload.category}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const fetchNetworksWithPage = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/networks?page=${payload.page}&&${
        payload.category ? `category=${payload.category}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  }
};

// delete admin networks
export const deleteAdminNetworks = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}admin/networks/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// get all requested networks for admin
export const fetchRequestedNetworks = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}admin/pending-approval/networks`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// post requested Network by admin
export const postRequestedNetworks = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}admin/pending-approval/networks/${payload.id}`,
    payload.data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
