import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Loader from 'src/components/Loader';
import { Heading } from 'src/components/StyledComponents';
import { USER_LISTS } from 'src/constants/routes';

function Todos() {
  const navigate = useNavigate();
  const { userTodos, listProcessing } = useSelector(
    (state) => state.TodoReducer,
  );

  return (
    <>
      {listProcessing.status && listProcessing.component === 'user-todolist' ? (
        <Loader show />
      ) : userTodos.length ? (
        userTodos.map((todo) => (
          <div
            className="d-flex justify-content-between p-2"
            key={todo.id}
            style={{ borderBottom: '1px solid grey', cursor: 'pointer' }}
            onClick={() => navigate(USER_LISTS, {
              state: {
                item: todo,
              },
            })}
          >
            <div className="w-50">{todo.name}</div>
            <div>
              {todo.reset_frequency ? (
                <i className="fas fa-redo-alt" />
              ) : null}
            </div>
            <div>
              {todo.child_todo.total}
              {' '}
              lists
            </div>
          </div>
        ))
      ) : (
        <Heading className="text-center h4 mt-4">You have No List.</Heading>
      )}
    </>
  );
}

export default Todos;
