import styled from 'styled-components';
import { themeColor, cardHeading, cardHeadingSm } from 'src/utils/theme';

export const ScrollableSection = styled.div`
  height: ${(props) => (props.height ? props.height : '100%')};
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow-y: overlay;
  }
  li {
    cursor: pointer;
    border: none;
    display: ${(props) => (props.display ? props.display : 'flex')};
    justify-content: space-between;
    border-bottom: 4px solid lightgrey;
  }
  li:hover {
    background-color: lightgrey;
    color: white;
  }
  .list-group-item.active {
    background-color: lightgrey;
    border-color: lightgrey;
    border-bottom: 4px solid lightgrey;
  }
  .list-group-item.disable {
    background-color: lightgrey;
    border-color: lightgrey;
    border-bottom: 4px solid lightgrey;
    pointer-events: none;
  }
  .padding-small {
    padding: 10px 1px;
  }
`;

export const HScrollableSection = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  overflow-x: hidden;
  ::-webkit-scrollbar {
    height: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow-x: overlay;
  }
`;

export const ProfilePic = styled.div`
  width: ${(props) => (props.width ? props.width : '40px')};
  height: ${(props) => (props.height ? props.height : '40px')};
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
`;

export const Shape = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) => (props.height ? props.height : '250px')};
  &:before {
    border-radius: 100%;
    position: absolute;
    background-image: ${(props) => `linear-gradient(${props.color[0]},${props.color[1]})`};
    right: -100px;
    left: -100px;
    top: -250px;
    content: "";
    bottom: 0;
  }
`;

export const Heading = styled.div`
  color: ${themeColor};
  font-size: ${cardHeading};
  @media (max-width: 768px) {
    font-size: ${cardHeadingSm};
  }
`;

export const User = styled.div`
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

export const SidebarImageContainer = styled.li`
  background-image: ${(props) => (props.image
    ? `url(https://res.cloudinary.com/liviit/image/upload/${props.image}) !important`
    : 'linear-gradient(#fff,#fff) !important')};
`;
