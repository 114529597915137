import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchUserRemaindersMonthly,
  postUserReminders,
} from 'src/api/remainders';
import { saveRemainders, processReminders } from 'src/redux/remaindersRedux';
import { setError } from 'src/redux/serviceRedux';

function* getRemainders() {
  try {
    yield put(processReminders({ status: true, component: 'user-remainders' }));
    const response2 = yield call(fetchUserRemaindersMonthly);
    yield put(saveRemainders(response2));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processReminders({ status: false, component: 'user-remainders' }),
    );
    yield put(setError(false));
  }
}

function* addRemainders(action) {
  try {
    yield put(processReminders({ status: true, component: 'user-remainders' }));
    yield call(postUserReminders, action.payload);
    const response2 = yield call(fetchUserRemaindersMonthly);
    yield put(saveRemainders(response2));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processReminders({ status: false, component: 'user-remainders' }),
    );
    yield put(setError(false));
  }
}

export function* watchRemaindersSaga() {
  yield takeLatest('GET_REMAINDERS', getRemainders);
  yield takeLatest('ADD_REMINDERS', addRemainders);
}
