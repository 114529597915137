import { CometChat } from '@cometchat-pro/chat';
import { APP_ID } from 'src/constants/creds';

export default function cometChat() {
  const region = 'us';
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();

  CometChat.init(APP_ID, appSetting).then(
    () => {
      if (CometChat.setSource) {
        CometChat.setSource('ui-kit', 'web', 'reactjs');
      }
    },
    (error) => {
      console.log('CometChat:: Initialization failed with error:', error);
    },
  ).catch((error) => {
    console.log('CometChat:: Initialization failed with error:', error);
  });
}
