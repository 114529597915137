import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'src/components/Button';
import { ModalUpload } from 'src/components/Modal';
import PlusButton from 'src/components/PlusButton';
import { TextAreaField } from 'src/containers/HubHome/styles';
import { useDispatch } from 'react-redux';
import { addHubFeed } from 'src/redux/hubRedux';
import ReactPlayer from 'react-player';

function HubFeedModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [preview, setPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState('');

  const dispatch = useDispatch();

  const postFeed = () => {
    const formData = new FormData();
    files.map((item, index) => {
      formData.append(`files[${index}][file]`, item.file);
      return '';
    });
    if (caption) {
      formData.append('caption', caption);
    }
    dispatch(addHubFeed({ id: props.match.params.id, data: formData }));
    props.setIsAdded(true);
    setCaption('');
    setFiles([]);
    setPreview([]);
    props.setShow(false);
  };

  useEffect(() => {
    if (files.length) {
      Promise.all(
        files.map((file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file.file);
        })),
      ).then(
        (images) => {
          setPreview(images);
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [files]);
  return (
    <Modal
      show={props.show}
      onHide={() => {
        setCaption('');
        setFiles([]);
        setPreview([]);
        props.setShow(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pb-0" style={{ border: 'none' }} closeButton>
        <h5 className="font-weight-bold">SHARE</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          <div>
            <TextAreaField
              className="form-control form-control-sm my-2"
              rows="5"
              placeholder="Something to share?"
              onChange={(e) => setCaption(e.target.value)}
              value={caption}
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <div>Attachments</div>
            <PlusButton onClick={() => setModalShow(true)} />
          </div>

          {preview.length ? (
            <div className="d-flex my-1 overflow-auto">
              {preview.map((src, index) => (src.split('/')[0].split(':')[1] === 'image' ? (
                <img
                  key={index}
                  src={src}
                  alt="#Error"
                  width="100px"
                  height="100px"
                  className="mr-1"
                />
              ) : (
                <ReactPlayer
                  key={index}
                  url={src}
                  light
                  width="100px"
                  height="100px"
                />
              )))}
            </div>
          ) : null}

          <Button
            value="Add Post"
            variant="solid"
            Width="100%"
            onClick={postFeed}
            dissabled={files.length === 0}
            margin="10px 0px"
          />
        </div>
        <ModalUpload
          show={modalShow}
          close={setModalShow}
          size={8}
          addFile={setFiles}
          title="Add Your Images or Videos"
        />
      </Modal.Body>
    </Modal>
  );
}

export default HubFeedModal;
