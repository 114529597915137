import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Heading = styled.div`
  padding-top: 15px;
  font-size: 16px;
  border-bottom: ${(props) => (props.userState ? `2px solid ${themeColor}` : null)};
  cursor: pointer;
`;

export const ProfilePic = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
`;

export const Menu = styled.div`
  font-size: 22px;
  margin: 2px 15px;
  position: relative;
  cursor: pointer;
  color: ${themeColor};
`;

export const MenuSection = styled.div`
  padding: 10px;
  position: absolute;
  right: 1px;
  top: 30px;
  width: 200px;
  background-color: white;
  text-align: right;
  border-radius: 8px;
`;
export const NavItem = styled.div`
  cursor: pointer;
  display: ${(props) => (props.show ? 'inline' : 'none')};
  width: 14%;
  text-align: center;
  span {
    border-bottom: ${(props) => (props.currentTab ? `2px solid ${themeColor}` : null)};
  }
`;
export const ThemeText = styled.span`
  color: ${themeColor};
  font-weight: bolder;
`;
export const Icon = styled.div`
  cursor: pointer;
  svg {
    color: ${themeColor};
  }
`;

export const MainHeader = styled.div`
  .main-header-right .nav-right ul li svg {
    color: ${themeColor};
  }
  .main-header-right .nav-right > ul > li h6 {
    color: ${themeColor};
  }
  .main-header-right svg line {
    color: ${themeColor};
  }
  .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
    border-top: none;
    padding-top: 0;
  }
  .main-header-right .nav-right .profile-dropdown li:nth-child(2) {
    padding-bottom: 10px;
    border-bottom: 1px solid #eff0f1;
  }
  .main-header-right .nav-right .profile-dropdown li:nth-child(5) {
    padding-bottom: 10px;
    border-bottom: 1px solid #eff0f1;
  }
  .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
    border: 5px solid ${themeColor};
  }
  .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
    background-color: ${themeColor};
  }
  .main-header-right .nav-right ul li .dot {
    background-color: ${themeColor};
  }
  .main-header-right .nav-right .profile-dropdown li:hover a,
  .main-header-right .nav-right .profile-dropdown li:hover a svg {
    color: ${themeColor} !important;
  }
  .main-header-right .nav-right ul li .dot {
    right: 10px;
  }
  .main-header-right .nav-right > ul > li {
    padding: 0px 10px;
  }
  .main-header-right .nav-right .notification-dropdown {
    background-color: lightgray !important;
    border: 2px solid lightgrey !important;
  }
`;

export const CountBadge = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${themeColor};
  color: white;
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  margin-left: 15px;
  text-align: center;
  box-shadow: 0px 0px 12px lightgrey;
`;

export const CustomizerFilter = styled.div`
  display: flex;
  margin: 2px 8px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  div {
    text-align: center;
    border-right: 1px solid lightgrey;
    padding: 8px 15px;
  }
  div:nth-child(3) {
    border-right: none;
  }
`;
export const FilterButton = styled.div`
  color: ${(props) => (props.selected ? 'white' : 'black')};
  background-color: ${(props) => (props.selected ? `${themeColor}` : null)};
`;

export const ColorBar = styled.div`
  height: 20px;
  border: 1px solid black;
  background-image: ${(props) => (props.Color
    ? `linear-gradient(${props.Color[0]},${props.Color[1]})`
    : 'linear-gradient(#000, #000)')};
  padding: 0px 0px;
  margin: 0% 2%;
  max-width: 12%;
`;

export const DropDownButton = styled.div`
  padding: 4px 0px;
  cursor: pointer;
  background-color: ${themeColor};
  color: white;
  text-align: center;
  border-radius: 5px;
  width: 150px;
  @media (max-width: 576px) {
    width: 100px;
  }
  @media (max-width: 460px) {
    width: 74px;
  }
`;

export const SelectSection = styled.div`
  text-align: center;
  display: flex !important;
  justify-content: center;
`;

export const DropDownItem = styled.div`
  padding: 10px 0px;
  width: 150px;
  @media (max-width: 576px) {
    width: 100px;
  }
  @media (max-width: 460px) {
    width: 74px;
  }
  border: 1px solid lightgrey;
  &:hover {
    background-color: ${themeColor};
    color: white;
    cursor: pointer;
  }
`;

export const ScrollableSection = styled.div`
  height: 400px;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow-y: auto;
  }
`;

export const UserChatSection = styled.div`
  .str-chat {
    height: 400px;
  }
  .str-chat__channel-list-team__header {
    display: none;
  }
  .str-chat__channel-preview--active,
  .str-chat__avatar-fallback {
    background-color: ${themeColor};
  }
  .str-chat-channel.messaging .str-chat__main-panel {
    padding: 0px;
  }
  .str-chat__channel-list-team__main {
    background-color: white;
    padding: 0px;
    width: 100%;
  }
  .str-chat__header-livestream {
    padding: 5px 40px;
    min-height: 60px;
  }
  .str-chat__channel-preview-unread-count {
    color: white;
    background: ${themeColor};
  }
  .messaging.str-chat .str-chat__message,
  .messaging.str-chat .str-chat__message--me {
    width: 100%;
  }
  .str-chat-channel-list {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  svg {
    stroke: ${(props) => (props.isMobile
    ? props.darkMode
      ? 'white'
      : themeColor
    : props.iconColor)};
  }
  .dot-n {
    width: 8px !important;
    height: 8px !important;
    background-color: #c50000 !important;
    animation: ${(props) => (props.isNewNotification
    ? 'blink 1.5s infinite !important'
    : 'none !important')};
  }
  .dot-m {
    width: 8px !important;
    height: 8px !important;
    background-color: #c50000 !important;
    animation: ${(props) => (props.isNewMessage
    ? 'blink 1.5s infinite !important'
    : 'none !important')};
  }
  .main-circle {
    width: 12px !important;
    height: 12px !important;
    right: 3px !important;
    top: -13px !important;
    background-color: green !important;
  }
  .page-main-header .main-header-right .nav-right > ul > li h6 {
    color: ${(props) => (props.isMobile ? themeColor : props.iconColor)} !important;
  }
  .animate-circle {
    border: ${(props) => (props.isMobile
    ? '5px solid green'
    : `5px solid ${props.iconColor}`)} !important;
  }
`;
