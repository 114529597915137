import React from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';
import Bookmark from './bookmark';

function Breadcrumb(props) {
  const breadcrumb = props;

  return (
    <div className="container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col">
            <div className="page-header-left">
              <h3>{breadcrumb.parent}</h3>
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <Link to={ROUTES.USER_DASHBOARD}>
                    <Home />
                  </Link>
                </li>
                <li className="breadcrumb-item">{breadcrumb.parent}</li>
                <li className="breadcrumb-item active">{breadcrumb.title}</li>
              </ol>
            </div>
          </div>
          <Bookmark />
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
