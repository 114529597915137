import React from 'react';
import { InputField } from './styles';

function TextField(props) {
  return (
    <InputField
      type={props.type ? props.type : 'text'}
      placeholder={props.placeholder ? props.placeholder : null}
      onChange={props.onChange ? props.onChange : null}
      onKeyUp={props.onKeyUp ? props.onKeyUp : null}
      className={props.className ? props.className : null}
      id={props.id ? props.id : null}
      required={props.required ? props.required : false}
      borderRadius={props.borderRadius ? props.borderRadius : null}
      fontSize={props.fontSize ? props.fontSize : null}
      padding={props.padding ? props.padding : null}
      disabled={props.disabled ? props.disabled : false}
      {...props}
    />
  );
}

export default TextField;
