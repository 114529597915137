import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllHubFolders,
  getHubFolderData,
  getHubDetails,
  uploadHubFileInFolder,
  addHubFolder,
  removeHubFile,
  removeHubFolder,
  saveHubFolderData2,
  saveHubFolderData3,
} from 'src/redux/hubRedux';
import Breadcrumb from 'src/components/Breadcrumb';
import Folders from './Sections/folders';
import Preview from './Sections/preview';

function HubFiles(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [parentFolder, setParentFolder] = useState(null);
  const { hubFolders, hubFolderDataLevel2, hubFolderDataLevel3 } = useSelector(
    (state) => state.HubsReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (hubFolders.length === 0) {
      dispatch(getHubDetails(props.match.params.id));
      dispatch(getAllHubFolders(props.match.params.id));
    }
  }, []);

  const getFolder = (folder, level) => {
    if (level === 2) dispatch(saveHubFolderData2([]));
    if (level === 3) dispatch(saveHubFolderData3([]));
    if (folder.is_folder) {
      dispatch(
        getHubFolderData({
          level,
          id: folder.id,
          hub_id: props.match.params.id,
        }),
      );
    } else {
      setSelectedFile(folder);
    }
  };

  return (
    <>
      <Breadcrumb parent="Files" title="Files" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Folders
              processing={processing}
              folders={hubFolders}
              getFolder={getFolder}
              addFolder={addHubFolder}
              getFolders={getAllHubFolders}
              selected={selectedFile}
              outerFolderData={hubFolderDataLevel2}
              innerFolderData={hubFolderDataLevel3}
              uploadFile={uploadHubFileInFolder}
              deleteFolder={removeHubFolder}
              deleteFile={removeHubFile}
              state="parent"
              setParentFolder={setParentFolder}
              parentFolder={parentFolder}
              {...props}
            />
          </div>
          <div className="col-md-3">
            <Folders
              processing={processing}
              folders={hubFolders}
              getFolder={getFolder}
              addFolder={addHubFolder}
              getFolders={getAllHubFolders}
              selected={selectedFile}
              outerFolderData={hubFolderDataLevel2}
              innerFolderData={hubFolderDataLevel3}
              uploadFile={uploadHubFileInFolder}
              deleteFolder={removeHubFolder}
              deleteFile={removeHubFile}
              parentFolder={parentFolder}
              {...props}
            />
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Preview</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <i
                        className="fas fa-external-link-alt"
                        onClick={() => (selectedFile
                          ? window.open(selectedFile.path, '_blank').focus()
                          : console.log())}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Preview file={selectedFile} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubFiles;
