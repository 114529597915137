import React, { useState, useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { ScrollableSection } from 'src/components/StyledComponents';
import { getFilteredNetworks, getNetworkCategories } from 'src/utils/helper';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import { getNetworks } from 'src/redux/networkRedux';
import { ListContainer } from './styles';
import NetworkList from './Sections/networkList';
import NetworkInfo from './Sections/networkInfo';

function UserNetworks() {
  const [network, setNetwork] = useState({});
  const [category, setCategory] = useState('All');
  const dispatch = useDispatch();
  const { networks } = useSelector((state) => state.NetworkReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  useEffect(() => {
    if (networks.length === 0) {
      dispatch(getNetworks());
    }
  }, []);

  useEffect(() => {
    if (networks.length > 0) {
      networks.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setNetwork(networks[0]);
    }
  }, [networks]);

  return (
    <>
      <Breadcrumb parent="Networks" title="Network" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md -3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Networks</h5>
              </div>
              <ListContainer className="card-body">
                <ScrollableSection>
                  {processing.status && processing.component === 'network' ? (
                    <Loader show />
                  ) : (
                    <NetworkList
                      setCategory={setCategory}
                      category={category}
                      state="category"
                      categories={getNetworkCategories(networks)}
                    />
                  )}
                </ScrollableSection>
              </ListContainer>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>{category}</h5>
              </div>
              <ListContainer className="card-body">
                <ScrollableSection>
                  {processing.status && processing.component === 'network' ? (
                    <Loader show />
                  ) : (
                    <NetworkList
                      setNetwork={setNetwork}
                      network={network}
                      state="networks"
                      networks={getFilteredNetworks(networks, category)}
                    />
                  )}
                </ScrollableSection>
              </ListContainer>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-body">
                <ScrollableSection>
                  <NetworkInfo network={network} />
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserNetworks;
