import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

const Heading = styled.h2`
  font-weight: bolder;
`;
const Name = styled.h1`
  color: ${themeColor};
  font-family: system-ui;
  font-weight: 700;
  margin-top: 150px;
  font-size: xxx-large;
`;

export const Header = styled.span`
  font-size: 18px;
  color: #787878;
  display: block;
`;

export const TextAreaField = styled.textarea`
  width: 100%;
  &:focus {
    border-color: #2671e1;
    box-shadow: inset 0 1px 1px #2671e1, 0 0 8px #2671e1;
  }
`;

export { Heading, Name };
