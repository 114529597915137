import React, { useEffect, useState } from 'react';
import { themeColor } from 'src/utils/theme';
import { Cell } from 'src/containers/AdminDashboard/styles';
import {
  ScrollableSection,
  Heading,
  ProfilePic,
} from 'src/components/StyledComponents';
import InputField from 'src/components/InputField';
import { userData } from 'src/containers/AdminDashboard/data';
import { Button } from 'src/components/Button';
import Loader from 'src/components/Loader';
import {
  getAllAdminUsers,
  getOnlineUsers,
  getSearchedUsers,
  getUsersWithPage,
  deleteUser,
} from 'src/redux/usersRedux';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { addDefaultSrc } from 'src/utils/helper';
import { DeleteModal } from 'src/components/Modal';
import { Eye, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router';
import { USER_DASHBOARD } from 'src/constants/routes';
import { CometChat } from '@cometchat-pro/chat';
import LoadMore from 'src/components/LoadMore';
import Breadcrumb from 'src/components/Breadcrumb';

function AdminUsers(props) {
  const location = props?.location?.state;
  const [allUsersData, setallUsersData] = useState([]);
  const [showOnline, setShowOnline] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [loadOnlineUsers, setLoadOnlineUsers] = useState(false);

  const dispatch = useDispatch();
  const { allusers, pagination, allOnlineUsers } = useSelector(
    (state) => state.UsersReducer,
  );

  const { processing } = useSelector((state) => state.ServiceReducer);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllAdminUsers());
    return () => setallUsersData([]);
  }, []);

  useEffect(() => {
    if (allusers.length > 0) {
      pagination.current_page === 1
        ? setallUsersData(allusers)
        : setallUsersData([...allUsersData, ...allusers]);
    }
  }, [allusers]);

  useEffect(() => {
    if (location && location.state === 'user-online') setShowOnline(true);
  }, [location]);

  useEffect(() => {
    if (allOnlineUsers.length > 0) {
      setOnlineUsers(allOnlineUsers);
    }
  }, [allOnlineUsers]);

  const searchUsers = (e) => {
    setallUsersData([]);
    dispatch(getSearchedUsers({ value: e.target.value, type: 'admin' }));
  };

  const handleToggle = () => {
    setShowOnline(!showOnline);
  };

  useEffect(() => {
    const getOnlineUser = async () => {
      await setLoadOnlineUsers(true);
      const usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(50)
        .setStatus(CometChat.USER_STATUS.ONLINE)
        .build();
      await usersRequest.fetchNext().then(
        (userList) => {
          const fetchedList = userList.map((user) => user.uid);
          if (fetchedList.length > 0) dispatch(getOnlineUsers({ users: fetchedList }));
          else setOnlineUsers([]);
        },
        (error) => {
          console.log('User list fetching failed with error:', error);
        },
      );
      setLoadOnlineUsers(false);
    };
    if (showOnline) {
      getOnlineUser();
    }
  }, [showOnline]);

  const onUserClick = (user) => {
    const obj = {
      id: user.id,
      name: user.name,
      username: user.username,
      avatar: user.original_avatar,
      type: 'User',
    };
    setSelectedUser(obj);
    setShowDeleteModal(true);
  };

  const loadMoreUsers = () => {
    dispatch(getUsersWithPage(pagination.current_page + 1));
  };

  const onViewUserClick = (user) => {
    localStorage.setItem('view-user', JSON.stringify(user));
    navigate(USER_DASHBOARD);
    window.location.reload();
  };

  const onDelete = (id) => {
    dispatch(deleteUser({ id, page: pagination.current_page }));
  };

  return (
    <>
      <Breadcrumb parent="Admin" title="Users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <Heading>User Management</Heading>
                  <div className="ml-2">
                    <span className="h6" style={{ verticalAlign: 'super' }}>
                      Show Online Users
                    </span>
                    <Switch
                      onChange={() => handleToggle()}
                      checked={showOnline}
                    />
                  </div>
                  {!showOnline && (
                    <div className="form-group mb-0">
                      <InputField
                        className="form-control"
                        type="search"
                        placeholder="Search..."
                        onKeyUp={searchUsers}
                        borderRadius="30px"
                        fontSize="13px"
                        padding="0px 0px 0px 20px"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll-admin-users">
                  {processing.status
                  && processing.component === 'user-management' ? (
                    <Loader show />
                    ) : showOnline ? (
                      <>
                        {loadOnlineUsers ? (
                          <Loader show />
                        ) : onlineUsers.length ? (
                          <div className="table-responsive pb-2 h-100">
                            <table className="table">
                              <thead>
                                <tr style={{ fontSize: '10px' }}>
                                  <th scope="col" />
                                  <th scope="col">NAME</th>
                                  <th scope="col">MEMBER TYPE</th>
                                  <th scope="col">ADMIN</th>
                                  <th scope="col">EMAIL</th>
                                  <th scope="col">MOBILE NO</th>
                                  <th scope="col">Mobile No STATUS</th>
                                  <th scope="col">LAST LOGIN</th>
                                  <th scope="col">LOGGED IN AT</th>
                                  <th scope="col">LOCATION</th>
                                  <th scope="col">IP ADDRESS</th>
                                  <th scope="col">JOINED ON</th>
                                  <th scope="col">STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {onlineUsers.map((data, index) => (
                                  <tr key={index} style={{ fontSize: '12px' }}>
                                    <Cell>
                                      <ProfilePic>
                                        <img
                                          src={
                                            data.avatar
                                              ? data.avatar
                                              : userData[0].profilePicture
                                          }
                                          alt="icon"
                                          onError={addDefaultSrc}
                                          className="rounded-circle mx-auto"
                                          width="38px"
                                          height="38px"
                                        />
                                      </ProfilePic>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        <div>{data.name ? data.name : '-'}</div>
                                        <div style={{ fontSize: '10px' }}>
                                          {data.username ? data.username : '-'}
                                        </div>
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>{data.role ? data.role : '-'}</div>
                                    </Cell>
                                    <Cell>
                                      <div>{data.admin ? data.admin : '-'}</div>
                                    </Cell>
                                    <Cell>
                                      <div>{data.email ? data.email : '-'}</div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.phone.phone_number
                                          ? data.phone.phone_number
                                          : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.is_phone_verified
                                          ? 'Verified'
                                          : 'Not Verified'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.last_login_details
                                          ? data.last_login_details.date_time.split(
                                            ' ',
                                          )[0]
                                          : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.last_login_details
                                          ? data.last_login_details.date_time.split(
                                            ' ',
                                          )[1]
                                          : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.last_login_details
                                          ? data.last_login_details.location
                                          : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.last_login_details
                                          ? data.last_login_details.ip
                                          : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div>
                                        {data.joined ? data.joined : '-'}
                                      </div>
                                    </Cell>
                                    <Cell>
                                      <div style={{ color: themeColor }}>
                                        {data.verified
                                          ? 'Verified'
                                          : 'Not Verified'}
                                      </div>
                                    </Cell>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <Heading className="text-center h4 mt-4">
                            No User is Online.
                          </Heading>
                        )}
                      </>
                    ) : allUsersData.length ? (
                      <LoadMore
                        data={allUsersData}
                        getMore={() => loadMoreUsers()}
                        target="scroll-admin-users"
                        meta={pagination}
                      >
                        <div className="table-responsive pb-2 h-100">
                          <table className="table">
                            <thead>
                              <tr style={{ fontSize: '10px' }}>
                                <th scope="col" />
                                <th scope="col">NAME</th>
                                <th scope="col">MEMBER TYPE</th>
                                <th scope="col">ADMIN</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">MOBILE NO</th>
                                <th scope="col">MOBILE NO STATUS</th>
                                <th scope="col">LAST LOGIN</th>
                                <th scope="col">LOGGED IN AT</th>
                                <th scope="col">LOCATION</th>
                                <th scope="col">IP ADDRESS</th>
                                <th scope="col">JOINED ON</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">ACTION</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>
                              {allUsersData.map((data, index) => (
                                <tr key={index} style={{ fontSize: '12px' }}>
                                  <Cell>
                                    <ProfilePic>
                                      <img
                                        src={
                                          data.avatar
                                            ? data.avatar
                                            : userData[0].profilePicture
                                        }
                                        alt="icon"
                                        onError={addDefaultSrc}
                                        className="rounded-circle mx-auto"
                                        width="38px"
                                        height="38px"
                                      />
                                    </ProfilePic>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      <div>{data.name ? data.name : '-'}</div>
                                      <div style={{ fontSize: '10px' }}>
                                        {data.username ? data.username : '-'}
                                      </div>
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>{data.role ? data.role : '-'}</div>
                                  </Cell>
                                  <Cell>
                                    <div>{data.admin ? data.admin : '-'}</div>
                                  </Cell>
                                  <Cell>
                                    <div>{data.email ? data.email : '-'}</div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.phone && data.phone.phone_number
                                        ? data.phone.phone_number
                                        : '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.is_phone_verified
                                        ? 'Verified'
                                        : 'Not Verified'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.last_login_details
                                        ? data.last_login_details.date_time.split(
                                          ' ',
                                        )[0]
                                        : '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.last_login_details
                                        ? data.last_login_details.date_time.split(
                                          ' ',
                                        )[1]
                                        : '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.last_login_details
                                        ? data.last_login_details.location
                                        : '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {data.last_login_details
                                        ? data.last_login_details.ip
                                        : '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>{data.joined ? data.joined : '-'}</div>
                                  </Cell>
                                  <Cell>
                                    <div style={{ color: themeColor }}>
                                      {data.verified
                                        ? 'Verified'
                                        : 'Not Verified'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div className="d-flex">
                                      <Trash2
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => onUserClick(data)}
                                      />
                                      <Eye
                                        className="mx-2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => onViewUserClick(data)}
                                      />
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      <Button
                                        variant="solid"
                                        fontSize="12px"
                                        value="Hold"
                                        padding="5px 18px"
                                      />
                                    </div>
                                  </Cell>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </LoadMore>
                    ) : (
                      <Heading className="text-center h4 mt-4">
                        No User Found.
                      </Heading>
                    )}
                </ScrollableSection>

                <DeleteModal
                  show={showDeleteModal}
                  close={setShowDeleteModal}
                  obj={selectedUser}
                  deleteFn={onDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUsers;
