// actions
export const getUserFeeds = (payload) => ({
  type: 'GET_USER_FEEDS',
  payload,
});

export const addUserFeed = (payload) => ({
  type: 'ADD_FEED',
  payload,
});

export const saveUserFeeds = (payload) => ({
  type: 'SAVE_USER_FEEDS',
  payload,
});

export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_FEEDS',
  payload,
});

export const addReaction = (payload) => ({
  type: 'ADD_REACTION',
  payload,
});

export const removeReaction = (payload) => ({
  type: 'REMOVE_REACTION',
  payload,
});

export const clearFeeds = () => ({
  type: 'CLEAR_FEEDS',
});

export const getComments = (payload) => ({
  type: 'GET_COMMENTS',
  payload,
});
export const addComment = (payload) => ({
  type: 'ADD_COMMENT',
  payload,
});
export const deleteComment = (payload) => ({
  type: 'DELETE_COMMENT',
  payload,
});

export const saveComments = (payload) => ({
  type: 'SAVE_COMMENTS',
  payload,
});
export const processFeeds = (payload) => ({
  type: 'PROCESSING_FEEDS',
  payload,
});

export const getUnseenFeedCount = () => ({
  type: 'GET_UNSEEN_FEED_COUNT',
});
export const saveUnseenFeedCount = (payload) => ({
  type: 'SAVE_UNSEEN_FEED_COUNT',
  payload,
});

// store
const initialState = {
  userFeeds: [],
  meta: {},
  comments: [],
  unseenFeedCount: 0,
  feedProcessing: { status: false, component: '' },
};

// feedsReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_USER_FEEDS':
      return {
        ...state,
        userFeeds: action.payload.data,
        meta: action.payload.meta.pagination,
      };
    case 'CLEAR_FEEDS':
      return initialState;
    case 'SAVE_COMMENTS':
      return { ...state, comments: action.payload.data };
    case 'PROCESSING_FEEDS':
      return { ...state, feedProcessing: action.payload };
    case 'SAVE_UNSEEN_FEED_COUNT':
      return { ...state, unseenFeedCount: action.payload.data.unseen_count };

    default:
      return { ...state };
  }
};
