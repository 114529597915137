import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { MapPin } from 'react-feather';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import AutoComplete from './AutoComplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 85%;
`;

class MyGoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geoCoder: null,
      places: [],
      center: [],
      zoom: 12,
      address: '',
      draggable: true,
      lat: null,
      lng: null,
    };
  }

  componentDidMount() {
    this.setCurrentLocation();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.state !== prevProps.state
      && this.props.state === 'current'
    ) {
      this.setCurrentLocation();
      this._generateAddress();
    }
  }

  onMarkerInteraction = (mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
    this.props.setCods({
      lon: mouse.lng,
      lat: mouse.lat,
    });
  };

  onMarkerInteractionMouseUp = () => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.props.setCods({
          lon: position.coords.longitude,
          lat: position.coords.latitude,
        });
      });
    }
  }

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center,
      zoom,
    });
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this.props.setCods({
      lon: value.lng,
      lat: value.lat,
    });
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this.props.setCods({
      lon: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const formattedAddr = `${results[0].formatted_address.split(',')[
              results[0].formatted_address.split(',').length - 2
            ]
            }, ${
              results[0].formatted_address.split(',')[
                results[0].formatted_address.split(',').length - 1
              ]}`;
            this.setState({ address: results[0].formatted_address });
            this.props.setAddress(formattedAddr);
            localStorage.setItem('location', formattedAddr);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert(`Geocoder failed due to: ${status}`);
        }
      },
    );
  }

  render() {
    const { mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <>
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          onChildClick={() => console.log('child click')}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: 'AIzaSyDsZgkE-FA27pfP08xgf0dgeKI18QElP5o',
            libraries: ['places', 'geometry'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        />
        <Modal
          show={this.props.modalshow}
          onHide={() => this.props.setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header style={{ border: 'none' }} closeButton>
            <div className="d-flex">
              <div
                className="p-2 bg-light text-dark mr-3"
                onClick={() => this.props.setState('current')}
                style={
                  this.props.state === 'current'
                    ? { cursor: 'initial', boxShadow: '2px 2px 6px' }
                    : { cursor: 'pointer' }
                }
              >
                <MapPin />
                {' '}
                <span style={{ verticalAlign: 'super' }}>
                  UseCurrent Location
                </span>
              </div>
              <div
                className="p-2 bg-light text-dark"
                onClick={() => this.props.setState('custom')}
                style={
                  this.props.state === 'custom'
                    ? {
                      cursor: 'initial',
                      boxShadow: '2px 2px 6px',
                      verticalAlign: 'super',
                    }
                    : { cursor: 'pointer' }
                }
              >
                Choose Location
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              className="main-wrapper"
              style={
                this.props.state === 'current'
                  ? { pointerEvents: 'none' }
                  : { height: '60vh' }
              }
            >
              <Wrapper>
                {mapApiLoaded && (
                  <div>
                    {this.props.state === 'current' ? (
                      <h4>
                        {localStorage.getItem('location')
                          ? localStorage.getItem('location')
                          : ''}
                      </h4>
                    ) : (
                      <AutoComplete
                        map={mapInstance}
                        mapApi={mapApi}
                        addplace={this.addPlace}
                      />
                    )}
                  </div>
                )}
                <GoogleMapReact
                  center={this.state.center}
                  zoom={this.state.zoom}
                  draggable={this.state.draggable}
                  onChange={this._onChange}
                  onChildMouseDown={this.onMarkerInteraction}
                  onChildMouseUp={this.onMarkerInteractionMouseUp}
                  onChildMouseMove={this.onMarkerInteraction}
                  onChildClick={() => console.log('child click')}
                  onClick={this._onClick}
                  bootstrapURLKeys={{
                    key: 'AIzaSyDsZgkE-FA27pfP08xgf0dgeKI18QElP5o',
                    libraries: ['places', 'geometry'],
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                >
                  <Marker
                    text={this.state.address}
                    lat={this.state.lat}
                    lng={this.state.lng}
                  />
                </GoogleMapReact>
              </Wrapper>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default MyGoogleMap;
