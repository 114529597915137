import React, { useState, useEffect } from 'react';
import Loader from 'src/components/Loader';
import { getTasks } from 'src/redux/todoRedux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Heading } from 'src/components/StyledComponents';
import SublistModal from './subListModal';

function List(props) {
  const [sublistModal, setSublistModal] = useState(false);

  useEffect(() => {
    if (props.sublist.length) {
      props.setSelectedSublist(props.sublist[0]);
      props.getlistTasks(props.sublist[0]);
    }
  }, [props.sublist]);

  return (
    <>
      {Object.keys(props.folder).length !== 0 ? (
        props.processing.status
        && props.processing.component === 'user-todosublist' ? (
          <Loader show />
          ) : props.sublist.length ? (
            <DragDropContext onDragEnd={props.onDragEnd}>
              <Droppable droppableId="droppable" type="sublist">
                {(provided) => (
                  <ul
                    className="list-group"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {props.sublist.map((data, index) => (
                      <Draggable
                        key={data.id}
                        draggableId={data.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            className={`list-group-item padding-small ${
                              props.selectedSublist.id === data.id
                                ? 'active'
                                : null
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onClick={() => props.getlistTasks(data)}
                          >
                          <div className="d-flex w-100 justify-content-between">
                              <div className="ml-1">
                                {data.name}

                                <div className="d-block">
                                  (
                                    {data.tasks.total}
                                  {' '}
                                  tasks)
                                </div>
                              </div>
                              {data.reset_frequency ? (
                                <div>
                                  <div>
                                      <i className="fas fa-redo-alt" />
                                  </div>
                                  <div>{data.reset_frequency}</div>
                                </div>
                              ) : null}
                              <div>
                                <span
                                  onClick={() => {
                                    props.setSelectedSublist(data);
                                    setSublistModal(true);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                </span>
                                <span {...provided.dragHandleProps}>
                                  <i className="fas fa-bars" />
                                </span>
                              </div>
                          </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="text-center">
              <Heading>This Folder does not have any List.</Heading>
            </div>
          )
      ) : null}
      <SublistModal
        show={sublistModal}
        close={setSublistModal}
        list={props.selectedSublist}
        getTasks={getTasks}
        folder={props.folder}
      />
    </>
  );
}

export default List;
