import styled from 'styled-components';

export const FeedMedia = styled.div`
  width: 99%;
  height: 565px;
  border: ${(props) => (props.dark ? 'none' : '1px solid whitesmoke')};
  border-radius: 5px;
  background-color: transparent;
  position: relative;
`;
