import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { loadMoreData } from 'src/redux/profileRedux';
import LoadMore from 'src/components/LoadMore';
import { addDefaultSrc } from 'src/utils/helper';

function Activities(props) {
  const [userActivities, setUserActivities] = useState([]);
  const { activities } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (Object.keys(activities).length > 0 && activities.data.length > 0) {
      setUserActivities([...userActivities, ...activities.data]);
    }
  }, [activities]);

  return (
    <>
      {processing.status && processing.component === 'user-profile' ? (
        <Loader show />
      ) : userActivities.length ? (
        <LoadMore
          data={userActivities}
          meta={activities.meta.pagination}
          getMore={() => props.dispatch(
            loadMoreData({
              url: activities.meta.pagination.links.next,
              type: 'user-log',
            }),
          )}
          target="scroll-activities"
        >
          {userActivities.map((activity) => (
            <div className="media" key={activity.id}>
              <span>
                <img
                  className="rounded-circle lazyloaded blur-up"
                  src={activity.avatar ? activity.avatar : ProfilePictureIcon}
                  alt="#"
                  width="30px"
                  height="30px"
                  onError={addDefaultSrc}
                />
              </span>
              <div className="media-body">
                <span
                  className="f-16 f-w-600"
                  style={{ marginBottom: '0px' }}
                >
                  {activity.date_time}
                </span>
                <p style={{ marginBottom: '0px' }}>{activity.description}</p>
                <span
                  className="f-14 f-w-600"
                  style={{ marginBottom: '0px' }}
                >
                  {activity.title}
                </span>
              </div>
            </div>
          ))}
        </LoadMore>
      ) : (
        <Loader show />
      )}
    </>
  );
}

export default Activities;
