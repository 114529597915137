import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch } from 'react-redux';
import { PaginationSection } from './styles';

function Paginator(props) {
  const [pageNumbers, setPageNumbers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const pages = [];
    for (let i = 1; i <= props.pagination.total_pages; i++) {
      pages.push(i);
    }
    setPageNumbers(pages);
  }, [props.pagination]);

  const paginate = (page) => {
    dispatch(props.onPaginate(page));
  };

  return (
    <PaginationSection className="justify-content-center d-flex m-4 pb-3">
      <div>
        <Pagination>
          {pageNumbers.length ? (
            <>
              <Pagination.First
                onClick={() => paginate(1)}
                disabled={props.pagination.total_pages === 1}
              />

              {pageNumbers.map((number) => (
                <Pagination.Item
                  active={number === props.pagination.current_page}
                  key={number}
                  onClick={() => paginate(number)}
                >
                  <span
                    className={`${
                      number === props.pagination.current_page
                        ? 'activePage'
                        : 'page'
                    }`}
                  >
                    {number}
                  </span>
                </Pagination.Item>
              ))}

              <Pagination.Last
                onClick={() => paginate(pageNumbers.length)}
                disabled={props.pagination.total_pages === 1}
              />
            </>
          ) : null}
        </Pagination>
      </div>
    </PaginationSection>
  );
}

export default Paginator;
