import React, { useEffect, useState } from 'react';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import { USER_PROFILE } from 'src/constants/routes';
import { addDefaultSrc } from 'src/utils/helper';

function UserPanel(props) {
  const { user } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (Object.keys(user).length) {
      setloading(false);
    } else {
      setloading(true);
    }
  }, [user]);
  const linkedAccount = props.linkedAccountState
    ? props.accounts.filter(
      (account) => account.id.toString()
          === localStorage.getItem('impersonate-user').toString(),
    )
    : [];

  const viewUser = props.viewUserState
    ? JSON.parse(localStorage.getItem('view-user'))
    : {};

  return (
    <div>
      {(processing.status && processing.component === 'user-profile')
      || loading ? (
        <div className="sidebar-user text-center">
          <div
            className="mx-auto my-3"
            style={{ width: '60px', height: '60px', borderRadius: '50%' }}
          >
            <img
              className="rounded-circle lazyloaded blur-up"
              src={ProfilePictureIcon}
              onError={addDefaultSrc}
              alt="#"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        ) : (
          <div className="sidebar-user text-center">
            <div
              className="mx-auto"
              style={{ width: '60px', height: '60px', borderRadius: '50%' }}
            >
              <img
                className="rounded-circle lazyloaded blur-up"
                src={
                props.hubState
                  ? props.hub.avatar
                    ? props.hub.avatar
                    : ProfilePictureIcon
                  : props.linkedAccountState && linkedAccount.length
                    ? linkedAccount[0].original_avatar
                      ? linkedAccount[0].original_avatar
                      : ProfilePictureIcon
                    : props.viewUserState
                      ? viewUser.avatar
                        ? viewUser.avatar
                        : ProfilePictureIcon
                      : user.avatar
                        ? user.avatar
                        : ProfilePictureIcon
              }
                onError={addDefaultSrc}
                alt="#"
                width="100%"
                height="100%"
              />
              {!props.hubState
              && !props.linkedAccountState
              && !props.viewUserState && (
                <div className="profile-edit">
                  <Link to={USER_PROFILE}>
                    <Edit />
                  </Link>
                </div>
              )}
            </div>
            <h6 className="mt-3 f-14">
              {props.hubState ? (
                props.hub.name ? (
                  props.hub.name
                ) : (
                  '-'
                )
              ) : props.linkedAccountState && linkedAccount.length ? (
                <>
                  {linkedAccount[0].first_name ? linkedAccount[0].first_name : ''}
                  {' '}
                  {linkedAccount[0].last_name ? linkedAccount[0].last_name : ''}
                </>
              ) : props.viewUserState ? (
                <div>{viewUser.name ? viewUser.name : ''}</div>
              ) : (
                <>
                  {user.first_name ? user.first_name : ''}
                  {' '}
                  {user.last_name ? user.last_name : ''}
                </>
              )}
            </h6>
            <p>
              {props.hubState
                ? props.hub.category
                  ? `${props.hub.category} Hub`
                  : ''
                : props.linkedAccountState && linkedAccount.length
                  ? linkedAccount[0].roles[Object.keys(linkedAccount[0].roles)[0]]
                  : props.viewUserState
                    ? viewUser.role
                    : user.roles[Object.keys(user.roles)[0]]
                      ? user.roles[Object.keys(user.roles)[0]]
                      : 'User'}
            </p>
          </div>
        )}
    </div>
  );
}

export default UserPanel;
