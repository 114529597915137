import React, { useState, useEffect } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import { getUserFeeds, getUnseenFeedCount } from 'src/redux/feedsRedux';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from 'src/components/Breadcrumb';
import Feed from './Sections/feed';
import AddFeed from './Sections/addFeed';

function Feeds() {
  const [feedState, setFeedState] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const { userFeeds, unseenFeedCount } = useSelector(
    (state) => state.FeedsReducer,
  );

  useEffect(() => {
    dispatch(getUserFeeds(feedState));
  }, []);

  useEffect(() => {
    if (userFeeds.length > 0 && feedState === null) {
      dispatch(getUnseenFeedCount());
    }
  }, [userFeeds]);

  const filterFeeds = (type) => {
    dispatch(getUserFeeds(type));
    setFeedState(type);
  };
  return (
    <>
      <Breadcrumb parent="User-Feeds" title="Feeds" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '38vh' }}>
              <div className="card-header">
                <h5>Feed</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group">
                    <li
                      className={`list-group-item ${
                        feedState === null ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds(null)}
                    >
                      <div>All Posts</div>
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'hub' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('hub')}
                    >
                      Hubs
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'networks' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('networks')}
                    >
                      Networks
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'favourite' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('favourite')}
                    >
                      Favourites
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'public' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('public')}
                    >
                      Public
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'support' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('support')}
                    >
                      Liviit
                    </li>
                    <li
                      className={`list-group-item ${
                        feedState === 'my-upload' ? 'active disable' : null
                      }`}
                      onClick={() => filterFeeds('my-upload')}
                    >
                      Your Posts
                    </li>
                  </ul>
                </ScrollableSection>
              </div>
            </div>
            <div className="card" style={{ height: '38vh' }}>
              <div className="card-header">
                <h5>Share</h5>
              </div>
              <div className="card-body add-feed-container">
                <ScrollableSection>
                  <AddFeed feedState={feedState} setIsAdded={setIsAdded} />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5>
                      {feedState
                        ? feedState.charAt(0).toUpperCase()
                          + feedState.substring(1, feedState.length)
                        : 'All'}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '72vh' }}>
                <ScrollableSection id="scroll-feed">
                  <Feed
                    feeds={userFeeds}
                    feedState={feedState}
                    isAdded={isAdded}
                    unseenFeedCount={unseenFeedCount}
                  />
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feeds;
