import React, { useState, useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { ScrollableSection } from 'src/components/StyledComponents';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import { getDashboardHubs, getHubMembers } from 'src/redux/hubRedux';
import { getFilteredHubs, getHubCategories } from 'src/utils/helper';
import { ListContainer } from './styles';
import HubList from './Sections/hubList';
import HubInfo from './Sections/hubInfo';

function UserHubs() {
  const [hub, setHub] = useState({});
  const [category, setCategory] = useState('Favourites');
  const { dashboardHubs, hubProcessing, hubMembers } = useSelector(
    (state) => state.HubsReducer,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (dashboardHubs.length === 0) {
      dispatch(getDashboardHubs('user-hubs'));
    } else {
      dashboardHubs.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }, [dashboardHubs]);
  useEffect(() => {
    if (hub.id) {
      dispatch(getHubMembers(hub.id));
    }
  }, [hub]);

  return (
    <>
      <Breadcrumb parent="Hubs" title="Hubs" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Hubs</h5>
              </div>
              <ListContainer className="card-body">
                <ScrollableSection>
                  {hubProcessing.status
                  && hubProcessing.component === 'user-hubs' ? (
                    <Loader show />
                    ) : (
                      <HubList
                        setCategory={setCategory}
                        category={category}
                        state="category"
                        categories={getHubCategories(dashboardHubs)}
                      />
                    )}
                </ScrollableSection>
              </ListContainer>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>{category}</h5>
              </div>
              <ListContainer className="card-body">
                <ScrollableSection>
                  {hubProcessing.status
                  && hubProcessing.component === 'user-hubs' ? (
                    <Loader show />
                    ) : (
                      <HubList
                        setHub={setHub}
                        hub={hub}
                        state="hubs"
                        hubs={getFilteredHubs(dashboardHubs, category)}
                      />
                    )}
                </ScrollableSection>
              </ListContainer>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-body">
                <ScrollableSection>
                  <HubInfo
                    hub={hub}
                    members={hubMembers}
                    hubProcessing={hubProcessing}
                  />
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserHubs;
