import styled from 'styled-components';

export const InputField = styled.input`
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : null)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  width: 100%;
  &:focus {
    border-color: #2671e1;
    box-shadow: inset 0 1px 1px #2671e1, 0 0 8px #2671e1;
  }
  @media (max-width: 760px) {
    font-size: 15px;
  }
`;
