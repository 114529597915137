import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const SliderButton = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${themeColor};
  border-radius: 50%;
  color: ${themeColor};
  cursor: pointer;
  margin: 5px;
  &:hover {
    background-color: ${themeColor};
    color: white;
  }
`;
export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
`;
