import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Mail, Phone, Video } from 'react-feather';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector, useDispatch } from 'react-redux';
import { actionHandler, addDefaultSrc } from 'src/utils/helper';
import { USER_MESSAGES } from 'src/constants/routes';
import * as enums from 'src/CometChatWorkspace/src/util/enums';
import { sendReport } from 'src/redux/reportRedux';
import { toggleContactBlock } from 'src/redux/profileRedux';
import { ReportModal } from 'src/components/Modal';
import { Button } from '../Button';
import { ProfilePic, Shape } from './styles';

function UserCardModal(props) {
  const [showReportModal, setShowReportModal] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.ProfileReducer);
  const { theme } = useSelector((state) => state.ThemeReducer);
  const report = (payload) => {
    payload.append('resource_type', 'user');
    payload.append('resource_id', props.user.id);
    dispatch(sendReport(payload));
    setShowReportModal(false);
  };
  return (
    <Modal
      show={props.show}
      onHide={() => {}}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="card">
          <Shape
            color={
              theme.useMobileTheme
                ? user.theme && user.theme.mobile
                  ? user.theme.mobile.header_color
                  : ['#000', '#000']
                : [theme.color_primary, theme.color_secondary]
            }
          />
          <div
            className="text-center w-100"
            style={{ marginTop: '-50px', zIndex: '1' }}
          >
            <ProfilePic>
              <img
                className="rounded-circle lazyloaded"
                src={
                  props.user.original_avatar
                    ? props.user.original_avatar
                    : ProfilePictureIcon
                }
                alt="icon"
                width="100%"
                height="100%"
                onError={addDefaultSrc}
              />
            </ProfilePic>
            {props.user.is_blocked ? (
              <div className="my-2 text-danger">
                You have blocked this user.
              </div>
            ) : null}
            <div className="my-2 h5">
              {props.user.name ? props.user.name : ''}
            </div>
            <div className="my-2">
              {props.user.username ? `@${props.user.username}` : '@-'}
            </div>
            <div className="my-2">
              {props.user.email ? props.user.email : ''}
            </div>
            {localStorage.getItem('impersonate-user') === null
            && !props.user.is_blocked ? (
              <div className="btn">
                <Mail
                  className="mx-2"
                  onClick={() => {
                    props.navigate({
                      pathname: USER_MESSAGES,
                      state: {
                        contact: {
                          id: props.user.id.toString(),
                          type: 'user',
                        },
                      },
                    });
                  }}
                />
                <Phone
                  className="mx-2"
                  onClick={() => {
                    actionHandler(enums.ACTIONS.INITIATE_AUDIO_CALL, {
                      type: 'user',
                      id: `${props.user.id}`,
                    });
                    props.setShow(false);
                  }}
                />
                <Video
                  className="mx-2"
                  onClick={() => {
                    actionHandler(enums.ACTIONS.INITIATE_VIDEO_CALL, {
                      type: 'user',
                      id: `${props.user.id}`,
                    });
                    props.setShow(false);
                  }}
                />
              </div>
              ) : null}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Button
            variant="solid"
            onClick={() => {
              dispatch(
                toggleContactBlock({
                  id: props.user.id,
                  email: props.user.email,
                }),
              );
              props.setShow(false);
            }}
            value={props.user.is_blocked ? 'Unblock' : 'Block'}
          />
          <Button
            variant="solid"
            onClick={() => {
              props.setShow(false);
            }}
            value="Close"
          />
          <Button
            variant="solid"
            onClick={() => {
              setShowReportModal(true);
            }}
            value="Report"
          />
        </div>
        <ReportModal
          show={showReportModal}
          close={setShowReportModal}
          title="User"
          report={report}
        />
      </Modal.Body>
    </Modal>
  );
}

export default UserCardModal;
