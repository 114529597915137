// actions
export const getNetworks = () => ({
  type: 'GET_NETWORKS',
});

export const getAllNetworks = (payload) => ({
  type: 'GET_ALL_NETWORKS',
  payload,
});

export const loadMoreNetworks = (payload) => ({
  type: 'LOAD_MORE_NETWORKS',
  payload,
});

export const deleteNetwork = (payload) => ({
  type: 'DELETE_NETWORK',
  payload,
});

export const saveNetworks = (payload) => ({
  type: 'SAVE_NETWORKS',
  payload,
});

export const saveAllNetworks = (payload) => ({
  type: 'SAVE_ALL_NETWORKS',
  payload,
});

export const getRequestNetworks = () => ({
  type: 'GET_REQUEST_NETWORKS',
});
export const addRequestNetworks = (payload) => ({
  type: 'POST_REQUEST_NETWORKS',
  payload,
});
export const saveRequestNetworks = (payload) => ({
  type: 'SAVE_REQUEST_NETWORKS',
  payload,
});
export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_REQUEST_NETWORKS',
  payload,
});

// store
const initialState = {
  networks: [],
  allNetworks: [],
  requestNetworks: {
    data: [],
    meta: {},
  },
  metaDataNetworks: {},
};

// NetworkReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_NETWORKS':
      return { ...state, networks: action.payload };
    case 'SAVE_ALL_NETWORKS':
      return {
        ...state,
        allNetworks: action.payload.data,
        metaDataNetworks: action.payload.meta.pagination,
      };
    case 'SAVE_REQUEST_NETWORKS':
      return {
        ...state,
        requestNetworks: {
          data: action.payload.data,
          meta: action.payload.meta.pagination,
        },
      };

    default:
      return { ...state };
  }
};
