export const themeConfig = {
  layout_type: 'ltr',
  layout_wrapper: 'default',
  layout_bodyWrapper: 'default',
  layout_sidebarsettings: 'default-sidebar',
  layout_version: 'light',
  color_primary: 'black',
  color_secondary: '#1ea6ec',
  color_color: 'color-1',
  color_mixlayout: 'default',
  color_sidebarbg: 'primary-sidebar',
};
export class ConfigDB {
  static data = {
    settings: {
      layout_type: 'ltr',
      sidebar: {
        wrapper: 'default',
        bodyWrapper: 'default',
      },
      sidebar_setting: 'default-sidebar',
      sidebar_backround: 'dark-sidebar',
    },
    color: {
      layout_version: localStorage.getItem('layout_version')
        ? localStorage.getItem('layout_version')
        : 'light',
      color: localStorage.getItem('color')
        ? localStorage.getItem('color')
        : 'color-1',
      primary_color: localStorage.getItem('primary_color')
        ? localStorage.getItem('primary_color')
        : '#4466f2',
      secondary_color: localStorage.getItem('secondary_color')
        ? localStorage.getItem('secondary_color')
        : '#1ea6ec',
      mix_layout: 'default',
    },
    router_animation: 'fadeIn',
  };
}

export default ConfigDB;
