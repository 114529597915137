import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImageContainer } from 'src/containers/Dashboard/styles';
import { Heading } from 'src/components/StyledComponents';
import Loader from 'src/components/Loader';
import FsLightbox from 'fslightbox-react';
import 'react-image-lightbox/style.css';

function Vault(props) {
  const [files, setfiles] = useState([]);
  const [images, setImage] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { vaultFiles, vaultProcessing } = useSelector(
    (state) => state.VaultReducer,
  );

  useEffect(() => {
    if (vaultFiles.length) {
      setfiles([...files, ...vaultFiles]);
    }
  }, [vaultFiles]);

  useEffect(() => {
    setImage(
      files.filter((file) => (
        file.original_url.split('.').pop() === 'jpg'
          || file.original_url.split('.').pop() === 'jpeg'
          || file.original_url.split('.').pop() === 'png'
          || file.is_video
      )),
    );
  }, [files]);

  return (
    <>
      {vaultProcessing.status && vaultProcessing.component === 'user-vault' ? (
        <Loader show />
      ) : images.length ? (
        <div>
          {
            // eslint-disable-next-line
            images.map((file, index) => {
              if (
                !['pdf', 'docx', 'xlsx'].includes(
                  file.original_url.split('.').pop(),
                )
              ) {
                return (
                  <ImageContainer
                    key={index}
                    dark={props.theme.layout_version === 'dark-only'}
                  >
                    <img
                      src={file.thumbnail}
                      alt="Error in Loading Feed"
                      onClick={() => {
                        setPhotoIndex(index + 1);
                        setToggler(!toggler);
                      }}
                    />
                  </ImageContainer>
                );
              }
            })
          }
        </div>
      ) : (
        <Heading className="text-center h4 mt-4">You have No Files.</Heading>
      )}
      {images.length ? (
        <FsLightbox
          toggler={toggler}
          slide={photoIndex}
          sources={images.map((file) => {
            if (file.is_video) return `${file.original_url.slice(0, -3)}mp4`;
            return file.original_url;
          })}
        />
      ) : null}
    </>
  );
}

export default Vault;
