import React, { useState } from 'react';
import {
  Calendar,
  PlusCircle,
  CheckCircle,
  Copy,
  Video,
  HardDrive,
} from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import AddFeedModal from 'src/containers/PostModals/feed';
import AddListModal from 'src/containers/PostModals/list';
import AddVaultModal from 'src/containers/PostModals/vault';
import AddFileModal from 'src/containers/PostModals/files';
import AddReminderModal from 'src/containers/PostModals/reminder';
import PlusButton from 'src/components/PlusButton';
import { Menu, MenuContainer, Menuitem } from './styles';

function RadialMenu({ active, setActive }) {
  const [modalShow, setModalShow] = useState(false);
  const [state, setState] = useState('');
  const menu = [
    {
      id: 1,
      icon: PlusCircle,
      name: 'Post',
      action: () => {
        setState('Post');
        setModalShow(true);
        setActive(false);
      },
    },
    {
      id: 2,
      icon: Calendar,
      name: 'Event',
      action: () => {
        setState('Event');
        setModalShow(true);
        setActive(false);
      },
    },

    {
      id: 3,
      icon: CheckCircle,
      name: 'List',
      action: () => {
        setState('List');
        setModalShow(true);
        setActive(false);
      },
    },

    {
      id: 4,
      icon: Copy,
      name: 'Image',
      action: () => {
        setState('Image');
        setModalShow(true);
        setActive(false);
      },
    },

    {
      id: 5,
      icon: Video,
      name: 'Video',
      action: () => {
        setState('Video');
        setModalShow(true);
        setActive(false);
      },
    },

    {
      id: 6,
      icon: HardDrive,
      name: 'File',
      action: () => {
        setState('File');
        setModalShow(true);
        setActive(false);
      },
    },
  ];

  const renderModal = () => {
    switch (state) {
      case 'Post':
        return (
          <AddFeedModal
            show={modalShow}
            close={setModalShow}
            title="Share a Feed"
          />
        );
      case 'Event':
        return (
          <AddReminderModal
            show={modalShow}
            close={setModalShow}
            title="New Event"
          />
        );
      case 'List':
        return (
          <AddListModal
            show={modalShow}
            close={setModalShow}
            title="Create New List"
          />
        );
      case 'Image':
        return (
          <AddVaultModal
            show={modalShow}
            close={setModalShow}
            title="Add Image to Vault"
            type="img"
          />
        );
      case 'Video':
        return (
          <AddVaultModal
            show={modalShow}
            close={setModalShow}
            title="Add Video to Vault"
            type="video"
          />
        );
      case 'File':
        return (
          <AddFileModal
            show={modalShow}
            close={setModalShow}
            title="Create New File or Folder"
          />
        );
      default:
        return null;
    }
  };

  return (
    <MenuContainer active={active}>
      <Menu active={active}>
        <div className="toggle" onClick={() => setActive(!active)}>
          <PlusButton width="60" height="60" />
        </div>
        {menu.map((item, index) => (
          <div key={item.id}>
            <Menuitem
              active={active}
              count={index.toString()}
              className="menu-item bg-white text-dark"
              onClick={() => item.action()}
              id={`tool${item.id}`}
            >
              <item.icon />
            </Menuitem>
            <UncontrolledTooltip placement="top" target={`tool${item.id}`}>
              {item.name}
            </UncontrolledTooltip>
          </div>
        ))}
      </Menu>
      {renderModal()}
    </MenuContainer>
  );
}

export default RadialMenu;
