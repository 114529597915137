import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { getAllHubs } from 'src/redux/hubRedux';
import { Members, Member } from 'src/containers/Directory/styles';
import {
  HScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';

const formatedData = (data) => {
  const obj = {};
  data.forEach((hub) => {
    if (!Object.keys(obj).includes(hub.category.toString())) {
      obj[hub.category] = data.filter((element) => element.category === hub.category && element.is_public);
    }
  });
  return obj;
};

function Hubs(props) {
  const [hubLoading, sethubLoading] = useState(true);
  const { hubs } = useSelector((state) => state.HubsReducer);
  const [hubData, sethubData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(hubs);
    if (hubs.length === 0) dispatch(getAllHubs('user-hubs'));
  }, [hubs]);
  useEffect(() => {
    if (hubs.length) {
      sethubData(formatedData(hubs));
      sethubLoading(false);
    } else {
      sethubLoading(true);
    }
  }, [hubs]);

  return hubLoading ? (
    <Loader show />
  ) : (
    <>
      {Object.keys(hubData).map((hub, i) => (hubData[hub].length ? (
        <div className="my-2" key={i}>
          <h5 className="font-weight-bold text-dark">
            {' '}
            {hubData[hub][0].category.charAt(0).toUpperCase()
                + hubData[hub][0].category.slice(1)}
          </h5>
          <HScrollableSection className="d-flex">
            {hubData[hub].map((data, index) => (
              <div
                className="card mx-2"
                style={{
                  minWidth: '70%',
                  boxShadow: '1px 5px 20px 0 rgb(0 0 0 / 30%)',
                  cursor: 'pointer',
                }}
                key={index}
                onClick={() => props.navigate(`/hub/${data.id}`)}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-1">
                      <ProfilePic>
                        <img
                          className="rounded-circle lazyloaded blur-up"
                          src={
                                data.avatar ? data.avatar : ProfilePictureIcon
                              }
                          onError={addDefaultSrc}
                          alt="#"
                          width="38px"
                          height="38px"
                        />
                      </ProfilePic>
                    </div>
                    <div className="col-8">
                      <div className="pl-2">
                        <h6>{data.name}</h6>
                        <div>
                          @
                          {data.alias}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <span>{data.user_role}</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Members>
                      {data.members.map((person, nextIndex) => (
                        <Member key={nextIndex}>
                          <ProfilePic style={{ margin: 'auto' }}>
                            <img
                              src={
                                      person.user_avatar
                                        ? person.user_avatar
                                        : ProfilePictureIcon
                                    }
                              onError={addDefaultSrc}
                              alt="icon"
                              className="rounded-circle mx-auto"
                              width="38px"
                              height="38px"
                            />
                          </ProfilePic>
                          <div className="pl-1 text-center">
                            {person.user_name.split(' ')[0]}
                          </div>
                        </Member>
                      ))}
                    </Members>
                  </div>
                </div>
              </div>
            ))}
          </HScrollableSection>
        </div>
      ) : null))}
    </>
  );
}

export default Hubs;
