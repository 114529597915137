import React, { useEffect, useState } from 'react';
import { FileText } from 'react-feather';
import Loader from 'src/components/Loader';
import { File } from 'src/containers/Files/styles';

function FileContent(props) {
  const [files, setfiles] = useState([]);

  useEffect(() => {
    setfiles([]);
  }, [props.selectedFolder]);

  useEffect(() => {
    if (props.vaultFiles.length) {
      setfiles(
        // eslint-disable-next-line
        props.vaultFiles.filter((data) => {
          if (
            ['pdf', 'docx', 'xlsx'].includes(data.original_url.split('.').pop())
          ) return data;
        }),
      );
    }
  }, [props.vaultFiles]);

  return (
    <div
      className="container py-2"
      style={{ borderTop: '4px solid lightgrey' }}
    >
      {props.processing.status
        && props.processing.component === 'user-vault' ? (
          <Loader show />
        ) : files.length ? (
          <div>
            {
              // eslint-disable-next-line
              files.map((data, index) => {
                return (
                  <div className="" key={data.id}>
                    <File
                      className={`${
                        props.file && props.file.id === data.id ? 'h4' : 'h5'
                      } d-block my-2`}
                      onClick={() => props.setFile(data)}
                    >
                      <i className="far fa-file-alt" />
                      {' '}
                      {data.original_url.split('/').pop()}
                    </File>
                  </div>
                );
              })
            }
          </div>
        ) : (
          <div className="mt-4 pt-4 d-flex justify-content-center w-100">
            <div style={{ textAlign: 'center' }}>
              <FileText size={80} alignmentBaseline="center" />
              <div className="h4 mt-2 ml-4">No Files Found</div>
            </div>
          </div>
        )}
    </div>
  );
}

export default FileContent;
