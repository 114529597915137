import React, { useState, useEffect } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { ScrollableSection } from 'src/components/StyledComponents';
import PlusButton from 'src/components/PlusButton';
import Loader from 'src/components/Loader';
import { ModalButton } from 'src/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTopTracks,
  getSearchedTracks,
  saveCurrentTrack,
} from 'src/redux/audioPlayerRedux';
import {
  getHubDetails,
  getHubPlaylists,
  createHubPlaylist,
  removeHubPlaylist,
} from 'src/redux/hubRedux';
import Player from 'src/containers/AudioPlayer/Sections/player';
import { Trash2 } from 'react-feather';
import TrackList from './Sections/trackList';
import Playlist from './Sections/playlist';
import { DeleteModal } from './Sections/addTrackModals';

function HubMusicPlayer(props) {
  const [musicState, setMusicState] = useState(
    props.location.state && props.location.state.playlist.id
      ? 'playlist'
      : 'all',
  );

  const [selectedPlaylist, setSelectedPlaylist] = useState(
    props.location.state && props.location.state.playlist.id
      ? props.location.state.playlist
      : null,
  );

  const [modalShow, setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedPlaylist, setDeletedPlaylist] = useState({});
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const {
    tracks, tracksProcessing, currentTrack, isPlaying, audioref,
  } = useSelector((state) => state.AudioPlayerReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  const { hubPlaylists, hubPlaylistTracks } = useSelector(
    (state) => state.HubsReducer,
  );
  const { theme } = useSelector((state) => state.ThemeReducer);

  useEffect(() => {
    if (hubPlaylists.length === 0) {
      dispatch(getHubDetails(props.match.params.id));
      dispatch(getHubPlaylists({ id: props.match.params.id }));
    }

    if (tracks.length === 0) dispatch(getTopTracks());
  }, []);

  const searchTracks = (e) => {
    dispatch(getSearchedTracks(e.target.value));
  };

  const addPlaylist = async (e) => {
    e.preventDefault();
    await dispatch(createHubPlaylist([props.match.params.id, { name }]));
    setModalShow(false);
    setName('');
  };

  const deletePlaylist = async () => {
    await dispatch(
      removeHubPlaylist({ id: props.match.params.id, p_id: deletedPlaylist.id }),
    );
    setDeleteModal(false);
  };

  const setCurrentTrack = (track, queue) => {
    if (track.type === 'track') {
      audioref.current.pause();
      window.Napster.player.play(track.id);
      dispatch(
        saveCurrentTrack({
          track,
          queue: tracks,
        }),
      );
    }
    if (track.provider === 'napster') {
      audioref.current.pause();
      window.Napster.player.play(track.metadata[0].id);
      dispatch(
        saveCurrentTrack({
          track:
            musicState === 'playlist'
              ? {
                ...track,
                playlist: selectedPlaylist,
                hub_id: props.match.params.id,
              }
              : track,
          queue: queue || hubPlaylistTracks,
        }),
      );
    }
    if (track.provider === 'local') {
      window.Napster.player.pause();
      dispatch(
        saveCurrentTrack({
          track:
            musicState === 'playlist'
              ? {
                ...track,
                playlist: selectedPlaylist,
                hub_id: props.match.params.id,
              }
              : track,
          queue: queue || hubPlaylistTracks,
        }),
      );
    }
  };

  const renderComponent = () => {
    switch (musicState) {
      case 'all':
        return (
          <TrackList
            currentTrack={currentTrack}
            isPlaying={isPlaying}
            tracks={tracks}
            loading={tracksProcessing}
            search={searchTracks}
            setTrack={setCurrentTrack}
            state={musicState}
          />
        );

      case 'playlist':
        return (
          <Playlist
            currentTrack={currentTrack}
            isPlaying={isPlaying}
            playlist={selectedPlaylist}
            tracks={tracks}
            search={searchTracks}
            setTrack={setCurrentTrack}
            {...props}
          />
        );

      default:
        return (
          <TrackList
            currentTrack={currentTrack}
            isPlaying={isPlaying}
            tracks={tracks}
            loading={tracksProcessing}
            search={searchTracks}
            setTrack={setCurrentTrack}
            state={musicState}
          />
        );
    }
  };

  return (
    <>
      <Breadcrumb parent="Music" title="Music" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Music</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <PlusButton onClick={() => setModalShow(true)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    <li
                      className={`list-group-item ${
                        musicState === 'all' ? 'active' : null
                      }`}
                      onClick={() => setMusicState('all')}
                    >
                      <div>All Songs</div>
                    </li>

                    {processing.status
                    && processing.component === 'hub-playlist' ? (
                      <Loader show />
                      ) : hubPlaylists.length ? (
                        hubPlaylists.map((playlist, index) => (
                          <li
                            key={index}
                            className={`list-group-item mt-1 ${
                              selectedPlaylist
                              && musicState === 'playlist'
                              && selectedPlaylist.id === playlist.id
                                ? 'active'
                                : null
                            }`}
                          >
                            <div
                              onClick={() => {
                                setMusicState('playlist');
                                setSelectedPlaylist(playlist);
                              }}
                            >
                              {playlist.name}
                            </div>
                            <div className="d-inline">
                              <Trash2
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeletedPlaylist(playlist);
                                }}
                              />
                            </div>
                          </li>
                        ))
                      ) : null}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-4">{renderComponent()}</div>
          <div className="col-md-5">
            <Player
              track={currentTrack}
              setTrack={setCurrentTrack}
              theme={theme}
              tracks={musicState === 'all' ? tracks : hubPlaylistTracks}
            />
          </div>
        </div>
      </div>
      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Playlist Name"
        submit={addPlaylist}
        value={name}
        setValue={setName}
      />
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        delete={deletePlaylist}
        track={deletedPlaylist}
        type="playlist"
      />
    </>
  );
}

export default HubMusicPlayer;
