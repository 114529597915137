import { axiosInstance, baseUrl } from '.';

export const postReport = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}abuse-reports`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const fetchReports = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}admin/abuse-reports${
      payload
        ? `?${payload.category ? `category=${payload.category}` : ''}&&${
          payload.resource_type
            ? `resource_type=${payload.resource_type}`
            : ''
        }&&${payload.status ? `status=${payload.status}` : ''}&&${
          payload.page ? `page=${payload.page}` : ''
        }`
        : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export const updateReportAction = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}admin/abuse-reports/${payload[0]}`,
      payload[1],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};
