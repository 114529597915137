import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'src/components/Loader';

function LoadMore({
  getMore, data, children, meta, target,
}) {
  useEffect(() => {
    if (meta.current_page === meta.total_pages) {
      sethasMore(false);
    }
  }, []);

  const [hasMore, sethasMore] = useState(true);

  const fetchMoreData = () => {
    if (meta.current_page === meta.total_pages) {
      sethasMore(false);
      return;
    }

    getMore();
  };

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<Loader show={hasMore} />}
      scrollableTarget={target}
    >
      {children}
    </InfiniteScroll>
  );
}

export default LoadMore;
