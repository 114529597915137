import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader(props) {
  return (
    <div>
      {props.show ? (
        <div className="m-4 p-4 text-center">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : null}
    </div>
  );
}

export default Loader;
