import {
  axiosAuth, axiosInstance, baseUrl, getHeaderData,
} from '.';

// login request
export const authenticateUser = async ({ login, password }) => {
  const response = await axiosAuth.post(`${baseUrl}login`, { login, password });
  return response.data;
};

// reset password
export const resetPassword = async (payload) => {
  const response = await axiosAuth.post(`${baseUrl}password/forgot`, payload);
  return response.data.data;
};

// refresh token
export const refreshUserToken = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosAuth.post(`${baseUrl}refresh`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  }
};

// FCM token
export const updateFcmToken = async (pushToken) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosAuth.put(`${baseUrl}fcm-token`, pushToken, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Device-Id': localStorage.getItem('deviceId'),
      },
    });

    return response;
  }
};

// log out current user
export const logOut = async () => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(`${baseUrl}logout`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Device-Id': localStorage.getItem('deviceId'),
    },
  });
};

// get admin images
export const fetchAdminImages = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}global-settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...getHeaderData(),
      },
    });
    return response.data;
  }
};

// update admin images
export const postAdminImages = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(`${baseUrl}admin/global-settings`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...getHeaderData(),
      },
    });
  }
};
