import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import InputField from 'src/components/InputField';
import { Button } from 'src/components/Button';
import { Heading } from './styles';

function ModalButton({
  show, close, title, submit, setValue, value,
}) {
  return (
    <Modal
      show={show}
      onHide={() => close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <Heading className="font-weight-bold h4">{title}</Heading>
      </Modal.Header>
      <Modal.Body>
        <form className="w-100 mt-2" onSubmit={submit}>
          <div className="form-group">
            <InputField
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="form-control form-control-lg"
              id="email"
              placeholder="Type here"
              required
              fontSize="18px"
            />
          </div>

          <div className="text-center">
            <Button
              type="submit"
              variant="solid"
              padding="8px 30px"
              value="Ok"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

ModalButton.defaultProps = {
  value: '',
  show: false,
  close: () => {},
  title: '',
  submit: () => {},
  setValue: () => {},
};

ModalButton.propTypes = {
  value: PropTypes.string,
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  submit: PropTypes.func,
  setValue: PropTypes.func,
};
export default ModalButton;
