import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const CalendarSection = styled.div`
  .rbc-calendar {
    height: 100% !important;
  }
  .rbc-toolbar button {
    border: 1px solid ${themeColor} !important;
  }
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: ${themeColor} !important;
  }

  .rbc-event {
    padding: 0px !important;
    border: none !important;
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-event,
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content {
    background-color: white !important;
  }

  .rbc-show-more {
    background-color: ${themeColor} !important;
  }
  .rbc-today {
    background-color: ${(props) => (props.view === 'month'
    ? `${themeColor} !important`
    : `${`${themeColor}5e`} !important`)};
  }
  .rbc-date-cell.rbc-now {
    font-weight: bold;
    color: white;
  }
  .rbc-event-custom-title {
    display: ${(props) => (props.view === 'agenda' ? 'block' : 'initial')};
  }
  text-align: ${(props) => (props.view === 'agenda' ? 'center' : 'left')};
  }
  font-size:${(props) => (props.view === 'agenda' ? '16px' : '14px')};;
`;

export const Remainder = styled.div`
  border-bottom: 1px solid lightgrey;
  color: grey;
`;

export const RemainderPeople = styled.div`
  max-width: 100px;
  margin-right: 10px;
  @media (max-width: 1180px) {
    max-width: 50px;
  }
`;

export const Members = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

export const Member = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  text-decoration: none;
`;

export const ProfilePic = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
`;
