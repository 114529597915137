import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAllUsers,
  fetchSearchedUsers,
  fetchUsersWithPage,
  userVerification,
  fetchAllAdminUsers,
  deleteAdminUsers,
  fetchAllOnlineUsers,
  fetchUsersStats,
  fetchAdminLogs,
  deleteUser,
} from 'src/api/users';
import { toast } from 'react-toastify';
import {
  saveUsers,
  verifiedUser,
  saveAllAdminUsers,
  saveOnlineUsers,
  saveUserStats,
  saveAdminLogs,
} from 'src/redux/usersRedux';
import { processing, setError } from 'src/redux/serviceRedux';
import { loadMoreData } from 'src/api';

function* getAllUsers() {
  try {
    yield put(processing({ status: true, component: 'user-management' }));
    const response = yield call(fetchAllUsers);
    yield put(saveUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-management' }));
    yield put(setError(false));
  }
}

function* getAllAdminUsers() {
  try {
    yield put(processing({ status: true, component: 'all-user-management' }));
    const response = yield call(fetchAllAdminUsers);
    yield put(saveAllAdminUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'all-user-management' }));
    yield put(setError(false));
  }
}

function* getOnlineUsers(action) {
  try {
    yield put(processing({ status: true, component: 'all-user-management' }));
    const response = yield call(fetchAllOnlineUsers, action.payload);
    yield put(saveOnlineUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'all-user-management' }));
    yield put(setError(false));
  }
}

function* getUserStats(action) {
  try {
    yield put(processing({ status: true, component: 'all-user-management' }));
    const response = yield call(fetchUsersStats, action.payload);
    yield put(saveUserStats(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'all-user-management' }));
    yield put(setError(false));
  }
}

function* getAdminLogs(action) {
  try {
    yield put(processing({ status: true, component: 'admin-logs' }));
    const response = yield call(fetchAdminLogs, action.payload);
    yield put(saveAdminLogs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-logs' }));
    yield put(setError(false));
  }
}

function* getMoreAdminLogs(action) {
  try {
    yield put(processing({ status: true, component: 'admin-logs-page' }));
    const response = yield call(loadMoreData, action.payload);
    yield put(saveAdminLogs(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-logs-page' }));
    yield put(setError(false));
  }
}

function* getSearchedUsers(action) {
  try {
    yield put(processing({ status: true, component: 'user-management' }));
    const response = yield call(fetchSearchedUsers, action.payload);
    if (action.payload.type === 'public') yield put(saveUsers(response));
    else yield put(saveAllAdminUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-management' }));
    yield put(setError(false));
  }
}

function* getUsersWithPage(action) {
  try {
    yield put(processing({ status: true, component: 'user-management-page' }));
    const response = yield call(fetchUsersWithPage, action.payload);
    yield put(saveAllAdminUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-management-page' }));
    yield put(setError(false));
  }
}

function* deleteUserforAdmin(action) {
  try {
    yield put(processing({ status: true, component: 'user-management' }));
    yield call(deleteAdminUsers, action.payload.id);
    const response = yield call(fetchUsersWithPage, action.payload.page);
    yield put(saveAllAdminUsers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-management' }));
    yield put(setError(false));
  }
}

function* getVefication(action) {
  try {
    yield put(processing({ status: true, component: 'verification' }));
    const response = yield call(userVerification, action.payload);
    yield put(verifiedUser(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'verification' }));
    yield put(setError(false));
  }
}

function* deleteUserAccount() {
  try {
    yield put(processing({ status: true, component: 'delete-user' }));
    yield call(deleteUser);
    localStorage.clear();
    window.location.reload();
  } catch (error) {
    yield put(setError(true));
    toast.error('Account could not be deleted successfully!');
  } finally {
    yield put(processing({ status: false, component: 'delete-user' }));
    yield put(setError(false));
  }
}

export function* watchUsersSaga() {
  yield takeLatest('GET_ALL_USERS', getAllUsers);
  yield takeLatest('GET_ALL_ADMIN_USERS', getAllAdminUsers);
  yield takeLatest('GET_SEARCHED_USERS', getSearchedUsers);
  yield takeLatest('GET_PAGE_USERS', getUsersWithPage);
  yield takeLatest('VERIFY_USER', getVefication);
  yield takeLatest('DELETE_USER', deleteUserforAdmin);
  yield takeLatest('GET_ONLINE_USERS', getOnlineUsers);
  yield takeLatest('GET_USER_STATS', getUserStats);
  yield takeLatest('GET_ADMIN_LOGS', getAdminLogs);
  yield takeLatest('LOAD_MORE_ADMIN_LOGS', getMoreAdminLogs);
  yield takeLatest('DELETE_USER_ACCOUNT', deleteUserAccount);
}
