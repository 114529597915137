import React from 'react';
import { Controls, Widget } from 'src/containers/AudioPlayer/styles';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import { useSelector, useDispatch } from 'react-redux';
import { saveCurrentTrack } from 'src/redux/audioPlayerRedux';
import Marquee from 'react-fast-marquee';
import { USER_MUSIC } from 'src/constants/routes';

function PlayerWidget(props) {
  const {
    currentTrack, isPlaying, queue, audioref,
  } = useSelector(
    (state) => state.AudioPlayerReducer,
  );
  const dispatch = useDispatch();
  const playPauseResume = (track) => {
    if (track.id) {
      if (track.type === 'track' || track.provider === 'napster') {
        if (isPlaying) {
          window.Napster.player.pause();
        } else {
          window.Napster.player.resume();
        }
      } else if (isPlaying) {
        audioref.current.pause();
      } else {
        audioref.current.play();
      }
    }
  };

  const nextPrev = (cmd, track) => {
    if (track.id) {
      const index = queue.map((e) => e.id).indexOf(track.id);
      if (cmd === 'next') {
        if (index !== queue.length - 1) {
          if (queue[index + 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[index + 1].id);
          } else if (queue[index + 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[index + 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[index + 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[index + 1],
            }),
          );
        } else {
          if (queue[0].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[0].id);
          } else if (queue[0].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[0].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[0],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[0],
            }),
          );
        }
      } else if (cmd === 'prev') {
        if (index !== 0) {
          if (queue[index - 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[index - 1].id);
          } else if (queue[index - 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[index - 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[index - 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[index - 1],
            }),
          );
        } else {
          if (queue[queue.length - 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[queue.length - 1].id);
          } else if (queue[queue.length - 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[queue.length - 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[queue.length - 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[queue.length - 1],
            }),
          );
        }
      }
    } else {
      return '';
    }
  };

  return (
    <Widget
      imageUrl={
          currentTrack.name
            ? currentTrack.provider === 'local'
              ? MusicDisc
              : `https://api.napster.com/imageserver/v2/albums/${
                currentTrack.provider === 'napster'
                  ? currentTrack.metadata[0].albumId
                  : currentTrack.albumId
              }/images/200x200.jpg`
            : MusicDisc
        }
      className="notification-dropdown onhover-show-div p-2 d-flex justify-content-between"
    >
      <div
        style={{ maxWidth: '350px' }}
        className="font-weight-bold text-truncate w-100"
      >
        {currentTrack.playlist ? (
          <div className="bg-light text-dark text-center">
            <h3
              onClick={() => props.navigate({
                pathname: currentTrack.hub_id
                  ? `hub/${currentTrack.hub_id}/music`
                  : USER_MUSIC,
                state: {
                  playlist: currentTrack.playlist,
                },
              })}
            >
              {currentTrack.playlist.name}
            </h3>
          </div>
        ) : (
          <></>
        )}

        <div className="my-3">
          <Marquee
            speed="100"
            pauseOnHover
            gradientColor={[255, 255, 255]}
            gradientWidth="130px"
          >
            <h3 className="text-dark  font-italic ">
              {currentTrack.name ? currentTrack.name : 'Music'}
            </h3>
          </Marquee>
        </div>

        <Controls style={{ margin: '7em 0' }}>
          <div className="buttons">
            <div
              className="ctrl-button button-md"
              onClick={() => nextPrev('prev', currentTrack)}
            >
              <i className="fas fa-step-backward" aria-hidden="true" />
              <span className="sr-only">Previous Music</span>
            </div>
            <div
              className="ctrl-button button-lg"
              onClick={() => playPauseResume(
                currentTrack.provider === 'napster'
                  ? currentTrack.metadata[0]
                  : currentTrack,
              )}
            >
              {isPlaying ? (
                <>
                  <i className="fas fa-pause fa-lg" aria-hidden="true" />
                  <span className="sr-only">Pause</span>
                </>
              ) : (
                <>
                  <i className="fas fa-play fa-lg" aria-hidden="true" />
                  <span className="sr-only">Play</span>
                </>
              )}
            </div>
            <div
              className="ctrl-button button-md"
              onClick={() => nextPrev('next', currentTrack)}
            >
              <i className="fas fa-step-forward" />
              <span className="sr-only">Next Music</span>
            </div>
          </div>
        </Controls>
      </div>
    </Widget>
  );
}

export default PlayerWidget;
