import React, { useState } from 'react';
import { ProfilePic } from 'src/containers/Dashboard/styles';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { addDefaultSrc } from 'src/utils/helper';
import { UserCardModal } from 'src/components/Modal';
import { Heading } from 'src/components/StyledComponents';

function Contacts() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({});
  const { allContacts } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  return (
    <>
      {processing.status && processing.component === 'allcontacts' ? (
        <Loader show />
      ) : allContacts.length ? (
        <div className="container-fluid">
          <div className="row">
            {allContacts.map((data, index) => (
              <div
                key={index}
                className="col-4"
                style={{ cursor: 'pointer' }}
              >
                <ProfilePic style={{ margin: 'auto' }}>
                  <img
                    className="rounded-circle lazyloaded blur-up"
                    src={data.avatar ? data.avatar : ProfilePictureIcon}
                    onError={addDefaultSrc}
                    alt="#"
                    width="38px"
                    height="38px"
                    onClick={() => {
                      setUser(data);
                      setShowModal(true);
                    }}
                  />
                </ProfilePic>
                <div className="text-center mb-1">
                  {data.name.split(' ')[0]}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Heading className="text-center h4 mt-4">You have No Contacts.</Heading>
      )}

      <UserCardModal
        show={showModal}
        setShow={setShowModal}
        user={user}
      />
    </>
  );
}

export default Contacts;
