import styled from 'styled-components';

export const ChatInput = styled.input`
  border: none;
  width: 90%;
  border-bottom: 1px solid black;
  $:focus {
    outline: none;
    border: none;
  }
`;
export const ProfilePic = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
`;
