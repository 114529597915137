// actions
export const getPaymentPlans = (payload) => ({
  type: 'GET_PAYMENT_PLANS',
  payload,
});

export const savePaymentPlans = (payload) => ({
  type: 'SAVE_PAYMENT_PLANS',
  payload,
});

export const subscribeToPlan = (payload) => ({
  type: 'SUBSCRIBE_TO_PLAN',
  payload,
});

export const upgradeSubscriptionPlan = (payload) => ({
  type: 'UPGRADE_SUBSCRIPTION_PLAN',
  payload,
});

export const processSubscriptionPlans = (payload) => ({
  type: 'PROCESSING_SUBSCRIPTION_PLANS',
  payload,
});

const initialState = {
  plans: [],
  plansProcessing: { status: false, component: '' },
};

// subscriptionPlanReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PAYMENT_PLANS':
      return {
        ...state,
        plans: action.payload.data,
      };
    case 'PROCESSING_SUBSCRIPTION_PLANS':
      return { ...state, plansProcessing: action.payload };
    default:
      return { ...state };
  }
};
