import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Dropzone from 'react-dropzone-uploader';
import { Heading } from './styles';
import 'react-dropzone-uploader/dist/styles.css';

function ModalUpload({
  addFile, close, show, size, title, accept,
}) {
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });
  const handleChangeStatus = () => {
  };
  const handleSubmit = (files, allFiles) => {
    addFile(files);
    allFiles.forEach((f) => f.remove());
    close(false);
  };
  return (
    <Modal
      show={show}
      onHide={() => close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <Heading className="font-weight-bold h4">{title || ''}</Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form
            className="dropzone digits"
            id="singleFileUpload"
            onSubmit={handleSubmit}
          >
            <div className="dz-message needsclick">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={size}
                multiple
                canCancel={false}
                inputContent="Drop A File"
                accept={accept || 'image/*,audio/*,video/*,'}
                styles={{
                  dropzone: { width: 400, height: 200 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ModalUpload.defaultProps = {
  show: false,
  title: '',
  size: '',
  accept: '',
  addFile: () => {},
  close: () => {},

};

ModalUpload.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.number,
  accept: PropTypes.string,
  addFile: PropTypes.func,
  close: PropTypes.func,
};

export default ModalUpload;
