import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalButton, ModalUpload } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFolders,
  addFolder,
  uploadFileInFolder,
} from 'src/redux/filesRedux';
import Loader from 'src/components/Loader';
import { ScrollableSection } from 'src/components/StyledComponents';

function AddFileModal(props) {
  const [state, setState] = useState('folder');
  const [modalShow, setModalShow] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const { folders } = useSelector((state) => state.FilesReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  const addFolders = async (e) => {
    e.preventDefault();
    await dispatch(addFolder({ name }));
    setModalShow(false);
    setName('');
    await dispatch(getAllFolders());
    props.close(false);
  };

  const addFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file[0].file);

    formData.set('folder_id', selectedFolder.id);

    await dispatch(uploadFileInFolder(formData));
    props.close(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="font-weight-bold h4">{props.title}</div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="d-flex justify-content-around">
            <div
              className={
                state === 'folder' ? 'h5 border-bottom font-weight-bold' : 'h6'
              }
              style={{ cursor: 'pointer' }}
              onClick={() => setState('folder')}
            >
              Create Folder
            </div>
            <div
              className={
                state === 'vault' ? 'h5 border-bottom font-weight-bold' : 'h6'
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setState('vault');
                dispatch(getAllFolders());
              }}
            >
              Add File
            </div>
          </div>
          {state === 'folder' && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '15vh' }}
            >
              <Button
                value="Click here to create a Folder"
                variant="solid"
                onClick={() => setModalShow(true)}
                padding="10px"
              />
              <ModalButton
                show={modalShow}
                close={setModalShow}
                title="Please Enter Folder Name"
                submit={addFolders}
                value={name}
                setValue={setName}
              />
            </div>
          )}
          {state === 'vault' && (
            <div className="card">
              <div className="card-body" style={{ height: '40vh' }}>
                <h6>Select a Folder in which you want to add File.</h6>
                <div className="row h-100">
                  <div className="col-sm-6 h-100">
                    <ScrollableSection>
                      <ul className="list-group">
                        {' '}
                        {processing.status
                        && processing.component === 'user-files' ? (
                          <Loader show />
                          ) : folders.length ? (
                            folders.map((data, index) => (data.is_folder ? (
                              <li
                                className={`list-group-item ${
                                  selectedFolder.id === data.id
                                    ? 'active'
                                    : null
                                }`}
                                key={index}
                                onClick={() => setSelectedFolder(data)}
                              >
                                <div className="w-100">
                                  {data.name.charAt(0).toUpperCase()
                                    + data.name.substring(1, data.name.length)}
                                </div>
                              </li>
                            ) : (
                              <></>
                            )))
                          ) : null}
                      </ul>
                    </ScrollableSection>
                  </div>
                  <div className="col-sm-6 h-100">
                    {selectedFolder.id && (
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <Button
                          value="Click here to Upload File"
                          variant="solid"
                          onClick={() => setModalShow(true)}
                          padding="10px"
                        />
                        <ModalUpload
                          show={modalShow}
                          close={setModalShow}
                          size={1}
                          accept={'.pdf,.docx,.xlsx,image/*'}
                          addFile={addFile}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}

export default AddFileModal;
