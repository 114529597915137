import { all } from 'redux-saga/effects';
import { watchAuthSaga } from './authSaga';
import { watchFeedsSaga } from './feedsSaga';
import { watchHubsSaga } from './hubSaga';
import { watchProfileSaga } from './profileSaga';
import { watchRemaindersSaga } from './remaindersSaga';
import { watchTodoSaga } from './todoSaga';
import { watchUsersSaga } from './usersSaga';
import { watchVaultSaga } from './vaultSaga';
import { watchNetworksSaga } from './networkSaga';
import { watchChatSaga } from './chatSaga';
import { watchPagesSaga } from './pageSaga';
import { watchBannedWordsSaga } from './bannedWordsSaga';
import { watchFilesSaga } from './filesSaga';
import { watchAudioPlayerSaga } from './audioPlayerSaga';
import { watchReportsSaga } from './reportSaga';
import { watchSubscriptionSaga } from './subscriptionSaga';

export default function* rootSagas() {
  yield all([
    watchAuthSaga(),
    watchFeedsSaga(),
    watchHubsSaga(),
    watchProfileSaga(),
    watchRemaindersSaga(),
    watchTodoSaga(),
    watchUsersSaga(),
    watchVaultSaga(),
    watchNetworksSaga(),
    watchChatSaga(),
    watchPagesSaga(),
    watchBannedWordsSaga(),
    watchFilesSaga(),
    watchAudioPlayerSaga(),
    watchReportsSaga(),
    watchSubscriptionSaga(),
  ]);
}
