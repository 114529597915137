import React, { useState, useEffect } from 'react';
import {
  ScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserTodoList,
  getTodoSublist,
  getListMembers,
  addNewList,
  reorderList,
  getTasks,
  addNewTask,
} from 'src/redux/todoRedux';
import { ModalButton, AddTaskModal } from 'src/components/Modal';
import Breadcrumb from 'src/components/Breadcrumb';
import PlusButton from 'src/components/PlusButton';
import { reorder } from 'src/utils/helper';
import Tasks from './Sections/tasks';
import ListModal from './Sections/listModal';
import List from './Sections/list';
import Folders from './Sections/folders';
import { Member, Members } from './styles';

function Lists(props) {
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedSublist, setSelectedSublist] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [listModalShow, setListModalShow] = useState(false);
  const [addTaskModalShow, setAddTaskModalShow] = useState(false);
  const [name, setName] = useState('');
  const [taskname, setTaskName] = useState('');
  const [count, setCount] = useState(1);

  const {
    userTodos, sublist, listMembers, listProcessing,
  } = useSelector(
    (state) => state.TodoReducer,
  );
  const { tasks } = useSelector((state) => state.TodoReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (userTodos.length === 0) dispatch(getUserTodoList());
  }, []);

  useEffect(() => {
    if (props?.location?.state && props?.location?.state.item) {
      dispatch(getTodoSublist(props?.location?.state.item.id));
      dispatch(getListMembers(props?.location?.state.item.id));
      setSelectedFolder(props?.location?.state.item);
    } else if (userTodos && userTodos.length > 0) {
      dispatch(getTodoSublist(userTodos[0].id));
      dispatch(getListMembers(userTodos[0].id));
      setSelectedFolder(userTodos[0]);
    }
  }, [userTodos]);

  useEffect(() => {
    setSelectedSublist({});
  }, [selectedFolder]);

  const getSublist = (list) => {
    dispatch(getTodoSublist(list.id));
    dispatch(getListMembers(list.id));
    setSelectedFolder(list);
  };

  const getlistTasks = (list) => {
    if (list) {
      dispatch(getTasks(list.id));
      setSelectedSublist(list);
    }
  };

  const addDefaultSrc = (e) => {
    e.target.src = ProfilePictureIcon;
  };

  const addSublist = async (e) => {
    e.preventDefault();
    await dispatch(
      addNewList({ name, parent_todo_id: selectedFolder.id }),
    );
    setModalShow(false);
    setName('');
    await dispatch(getTodoSublist(selectedFolder.id));
  };

  const addTask = async (e) => {
    e.preventDefault();

    const payload = {
      id: selectedSublist.id,
      tasks: [taskname],
      check_count: count.key,
    };
    await dispatch(addNewTask(payload));
    setAddTaskModalShow(false);
    setTaskName('');
    setCount(1);
    await dispatch(getTasks(payload.id));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    if (result.type === 'folders') {
      const items = await reorder(
        userTodos,
        result.source.index,
        result.destination.index,
      );
      dispatch(reorderList(['folder', { todos: items }]));
    }
    if (result.type === 'sublist') {
      const items = await reorder(
        sublist,
        result.source.index,
        result.destination.index,
      );
      dispatch(
        reorderList(['sublist', { todos: items }, selectedFolder.id]),
      );
      getSublist(selectedFolder);
    }
    if (result.type === 'tasks') {
      const items = await reorder(
        tasks,
        result.source.index,
        result.destination.index,
      );

      dispatch(
        reorderList(['tasks', { tasks: items }, selectedSublist.id]),
      );

      getlistTasks(selectedSublist);
    }
  };

  return (
    <>
      <Breadcrumb parent="Lists" title="Lists" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Folders
              processing={listProcessing}
              folders={userTodos}
              getSublist={getSublist}
              addList={addNewList}
              getLists={getUserTodoList}
              selected={selectedFolder}
              onDragEnd={onDragEnd}
            />
          </div>
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">
                      {Object.keys(selectedFolder).length !== 0
                        ? selectedFolder.name.charAt(0).toUpperCase()
                          + selectedFolder.name.substring(
                            1,
                            selectedFolder.name.length,
                          )
                        : 'Lists'}
                      <span className="ml-2">
                        {selectedFolder.is_private ? (
                          <i className="fas fa-lock" />
                        ) : null}
                      </span>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      {localStorage.getItem('impersonate-user') === null
                      && localStorage.getItem('view-user') === null ? (
                        <>
                          {Object.keys(selectedFolder).length !== 0 ? (
                            <div className="d-flex">
                              <h6
                                className="mx-2 btn p-0"
                                onClick={() => setListModalShow(true)}
                              >
                                <i className="fas fa-user-plus" />
                              </h6>
                              <h6
                                className="mx-2 btn p-0"
                                onClick={() => setModalShow(true)}
                              >
                                <i className="fas fa-pencil-alt" />
                              </h6>
                            </div>
                          ) : null}
                        </>
                        ) : null}
                    </div>
                  </div>
                </div>
                {Object.keys(selectedFolder).length !== 0 ? (
                  processing.status
                  && processing.component
                    === 'user-todosublist' ? null : listMembers.length ? (
                      <Members>
                        {listMembers.map((person, nextIndex) => (
                          <Member key={nextIndex}>
                            <ProfilePic
                              width="35px"
                              height="35px"
                              style={{ margin: '0px 5px' }}
                            >
                              <img
                                src={
                                  person.avatar
                                    ? person.avatar
                                    : ProfilePictureIcon
                                }
                                onError={addDefaultSrc}
                                alt="icon"
                                className="rounded-circle mx-auto"
                                width="38px"
                                height="38px"
                              />
                            </ProfilePic>
                            <div className=" mt-1 h6">{person.first_name}</div>
                          </Member>
                        ))}
                      </Members>
                    ) : null
                ) : null}
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <List
                    folder={selectedFolder}
                    processing={processing}
                    sublist={sublist}
                    getSublist={getSublist}
                    selectedSublist={selectedSublist}
                    setSelectedSublist={setSelectedSublist}
                    onDragEnd={onDragEnd}
                    getlistTasks={getlistTasks}
                  />
                  <ModalButton
                    show={modalShow}
                    close={setModalShow}
                    title="Please Enter List Name"
                    submit={addSublist}
                    value={name}
                    setValue={setName}
                  />
                  {Object.keys(selectedFolder).length !== 0 ? (
                    <ListModal
                      show={listModalShow}
                      close={setListModalShow}
                      list={selectedFolder}
                      members={listMembers}
                    />
                  ) : null}
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                {selectedSublist.name ? (
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="align-abjust">
                        {selectedSublist.name ? selectedSublist.name : ''}
                      </h5>
                    </div>
                    <div className="col-sm-6">
                      <div className="pull-right right-header">
                        {localStorage.getItem('impersonate-user') === null
                        && localStorage.getItem('view-user') === null ? (
                          <PlusButton
                            onClick={() => {
                              setAddTaskModalShow(true);
                            }}
                          />
                          ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card-body">
                {selectedSublist.name ? (
                  <ScrollableSection>
                    <Tasks
                      processing={processing}
                      onDragEnd={onDragEnd}
                      list={selectedSublist}
                    />
                  </ScrollableSection>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTaskModal
        show={addTaskModalShow}
        close={setAddTaskModalShow}
        title="Enter Task Name"
        submit={addTask}
        value={taskname}
        setValue={setTaskName}
        showCount
        count={count}
        setCount={setCount}
      />
    </>
  );
}

export default Lists;
