import React from 'react';
import { useSelector } from 'react-redux';

function Information(props) {
  const { user } = useSelector((state) => state.ProfileReducer);
  return (
    <>
      <h5 style={{ fontWeight: '600' }}>DETAILS</h5>
      <div className="py-2" style={{ borderTop: '4px solid lightgrey' }}>
        {props.file ? (
          <table>
            <tr>
              <td>Name:</td>
              <td>{props.file.name}</td>
            </tr>
            <tr>
              <td>File Type:</td>
              <td>{props.file.name.split('.').pop().toUpperCase()}</td>
            </tr>
            <tr>
              <td>Path:</td>
              <td>{props.file.paths ? props.file.paths : '-'}</td>
            </tr>
            <tr>
              <td>Uploaded On:</td>
              <td>{props.file.uploaded_on ? props.file.uploaded_on : '-'}</td>
            </tr>
            <tr>
              <td>Tags:</td>
              <td>{props.file.tags ? props.file.tags : '-'}</td>
            </tr>
            <tr>
              <td>Owner:</td>
              <td>{user.username ? user.username : '-'}</td>
            </tr>
          </table>
        ) : null}
      </div>
    </>
  );
}

export default Information;
