import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import EventCard from 'src/components/EventCard';

export const format = (data, isToday, date) => {
  data.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
  let arr = [];
  data.forEach((r) => {
    const ob = {
      id: r.id,
      hub_id: r.hub_id,
      title: r.title,
      start: new Date(
        new Date(r.start_time).getFullYear(),
        new Date(r.start_time).getMonth(),
        new Date(r.start_time).getDate(),
        new Date(r.start_time).getHours(),
        new Date(r.start_time).getMinutes(),
        0,
      ),
      end: new Date(
        new Date(r.end_time).getFullYear(),
        new Date(r.end_time).getMonth(),
        new Date(r.end_time).getDate(),
        new Date(r.end_time).getHours(),
        new Date(r.end_time).getMinutes(),
        0,
      ),
      members: r.members,
      ...r,
    };
    arr.push(ob);
  });
  if (!isToday) {
    // eslint-disable-next-line
    arr = arr.filter((event) => {
      if (
        event.start.getDate() === date.getDate()
        && event.start.getMonth() === date.getMonth()
        && event.start.getFullYear() === date.getFullYear()
      ) return event;
    });
  }

  return arr;
};

function Remainders(props) {
  const [reminders, setreminders] = useState([]);
  const { userRemainders, reminderProcessing } = useSelector(
    (state) => state.RemaindersReducer,
  );
  const { dashboardHubs } = useSelector((state) => state.HubsReducer);
  const { user } = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    if (userRemainders.length) {
      setreminders(format(userRemainders, true));
    }
  }, [userRemainders]);

  let noTodayEvents = true;
  let noUpcomingEvents = true;
  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  };

  return (
    <div>
      {reminderProcessing.status
        && reminderProcessing.component === 'user-remainders' ? (
          <Loader show />
        ) : isToday(props.startDate) ? (
          <>
            <h6 className="text-center">Today</h6>
            {reminders.length ? (
              // eslint-disable-next-line
              reminders.map((remainder, index) => {
                if (isToday(new Date(remainder.end))) {
                  noTodayEvents = false;
                  return (
                    <EventCard
                      key={index}
                      event={userRemainders[index]}
                      hubs={dashboardHubs}
                      user={user}
                    />
                  );
                }
                if (noTodayEvents && index === reminders.length - 1) {
                  return (
                    <div className="text-muted text-center mb-2">
                      <em>No Events</em>
                    </div>
                  );
                }
              })
            ) : (
              <div className="text-muted text-center mb-2">
                <em>No Events</em>
              </div>
            )}
            <h6 className="text-center">Upcoming</h6>
            {reminders.length ? (
              // eslint-disable-next-line
              reminders.map((remainder, index) => {
                if (
                  new Date(remainder.start) > new Date()
                  && !isToday(new Date(remainder.start))
                ) {
                  noUpcomingEvents = false;
                  return (
                    <EventCard
                      key={index}
                      event={userRemainders[index]}
                      hubs={dashboardHubs}
                      user={user}
                    />
                  );
                }
                if (noUpcomingEvents && index === reminders.length - 1) {
                  return (
                    <div className="text-muted text-center">
                      <em>No Events</em>
                    </div>
                  );
                }
              })
            ) : (
              <div className="text-muted text-center">
                <em>No Events</em>
              </div>
            )}
          </>
        ) : format(userRemainders, isToday(props.startDate), props.startDate)
          .length ? (
            format(userRemainders, isToday(props.startDate), props.startDate).map(
              (reminder, index) => (
                <EventCard
                  key={index}
                  event={reminder}
                  hubs={dashboardHubs}
                  user={user}
                />
              ),
            )
          ) : (
            <div className="text-muted text-center">
              <em>No Events</em>
            </div>
          )}
    </div>
  );
}

export default Remainders;
