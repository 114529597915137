import styled from 'styled-components';

export const ProfilePic = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: white;
  box-shadow: 1px 1px 10px 1px;
  margin: auto;
  .rotate-image {
    animation: rotation 8s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const ControlButton = styled.button`
  cursor: pointer;
  background: white;
  outline: none;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 8px 12px;
  box-shadow: inset -6px -6px 12px rgba(0, 0, 0, 0.8),
    inset 6px 6px 12px rgba(255, 255, 255, 0.4);
`;

export const RangeInput = styled.input`
  width: 100%
  height: 5px;
  -webkit-appearance: none;
  margin: 10px 0;
  &:focus {
  outline: none;
}
::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #2ca6de;
  background: #2ca6de;
  border-radius: 1px;
  border: 0px solid #2ca6de;
}
::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #2ca6de;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #b7d5e7;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
&:focus::-webkit-slider-runnable-track {
  background: #2ca6de;
}
::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #2ca6de;
  background: #2497e3;
  border-radius: 1px;
  border: 0px solid #2ca6de;
}
::-moz-range-thumb {
  box-shadow: 0px 0px 0px #2ca6de;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #b7d5e7;
  cursor: pointer;
}
::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
::-ms-fill-lower {
  background: #2497e3;
  border: 0px solid #2ca6de;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #2ca6de;
}
::-ms-fill-upper {
  background: #2ca6de;
  border: 0px solid #2ca6de;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #2ca6de;
}
::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #2ca6de;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #b7d5e7;
  cursor: pointer;
}
&:focus::-ms-fill-lower {
  background: #b7d5e7;
}
&:focus::-ms-fill-upper {
  background: #b7d5e7;
}
`;

export const Widget = styled.div`
  width: 350px;
  height: 400px;
  background-size: 100% 100%;
  opacity: 0.75;
  background-image: ${(props) => (props.imageUrl ? `url(${props.imageUrl})` : null)};
`;

export const Controls = styled.div`
  .buttons {
    margin: 20px 0px;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .ctrl-button {
    border-radius: 50%;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 10px #d4d4d4, -5px -5px 10px #ffffff;
    font-size: x-large;
    cursor: pointer;
  }

  .ctrl-button:hover {
    background: radial-gradient(
      ellipse at center,
      rgba(12, 11, 23, 0.4) 0%,
      rgba(86, 76, 132, 0.1) 60%,
      rgba(255, 255, 255, 0.2) 100%
    );
    i {
      transform: scale(0.9);
    }
  }

  .button-sm {
    width: 30px;
    height: 30px;
    opacity: 0.75;
  }

  .button-md {
    width: 45px;
    height: 45px;
    opacity: 0.85;
  }
  .button-lg {
    width: 70px;
    height: 70px;
    box-shadow: inset 6px 6px 12px #c8c8c8, inset -6px -6px 12px #ffffff;
  }
  .active-shuffle {
    background-color: black;
    color: white;
  }
  svg:focus {
    outline: none;
  }
`;
