export const SUBSCRIPTION_PLANS = {
  Starter: {
    features: [
      '5 Hubs',
      '10 Hubs Members',
      '10GB Vault Storage',
      'Private Feeds',
      'Integrated Calendar',
      'Hub Messaging',
      'Hub Audio Chat',
      'Hub Video Chat',
      '1 Networks*',
      '3 Pages*',
    ],
    id: 'free',
    name: 'Starter',
    amount: 0,
    currency: 'aud',
    interval: 'month',
    trial_days: Infinity,
  },
  Everyday: {
    features: [
      '10 Hubs',
      '20 Hubs Members',
      '200GB Vault Storage',
      'Private Feeds',
      'Integrated Calendar',
      'Hub Messaging',
      'Hub Audio Chat',
      'Hub Video Chat',
      'N/A Networks*',
      '3 Pages*',
    ],
  },
  Premium: {
    features: [
      '20 Hubs',
      '50 Hubs Members',
      '500GB Vault Storage',
      'Private Feeds',
      'Integrated Calendar',
      'Hub Messaging',
      'Hub Audio Chat',
      'Hub Video Chat',
      'N/A Networks*',
      '3 Pages*',
    ],
  },
  Advanced: {
    features: [
      '50 Hubs',
      '100 Hubs Members',
      '2TB Vault Storage',
      'Private Feeds',
      'Integrated Calendar',
      'Hub Messaging',
      'Hub Audio Chat',
      'Hub Video Chat',
      'N/A Networks*',
      '3 Pages*',
    ],
  },
};

export const PlanPrice = {
  Starter: { dollar: '0', cents: '0' },
  Everyday: { dollar: '3', cents: '49' },
  Premium: { dollar: '7', cents: '49' },
  Advanced: { dollar: '13', cents: '49' },
};

export const NetworkPrice = {
  trial: { dollar: '00', cents: '00' },
  basic: { dollar: '25', cents: '00' },
  medium: { dollar: '100', cents: '00' },
  enterprise: { dollar: '200', cents: '00' },
};

export const BasicPlan = [
  {
    content1: '5',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '10',
    content2: 'Hub Members',
    value: true,
    optional: false,
  },
  {
    content1: '10GB',
    content2: 'Vault Storage',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Private Feeds',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Integrated Calendar',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Messaging',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Audio Chat',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Video Chat',
    value: true,
    optional: false,
  },
  {
    content1: '1',
    content2: 'Networks',
    value: true,
    optional: true,
  },
  {
    content1: '3',
    content2: 'Pages',
    value: true,
    optional: true,
  },
];

export const AdvancedPlan = [
  {
    content1: '10',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '20',
    content2: 'Hub Members',
    value: true,
    optional: false,
  },
  {
    content1: '200GB',
    content2: 'Vault Storage',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Private Feeds',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Integrated Calendar',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Messaging',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Audio Chat',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Video Chat',
    value: true,
    optional: false,
  },
  {
    content1: 'N/A',
    content2: 'Networks',
    value: false,
    optional: true,
  },
  {
    content1: '3',
    content2: 'Pages',
    value: true,
    optional: true,
  },
];

export const PremiumPlan = [
  {
    content1: '20',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '50',
    content2: 'Hub Members',
    value: true,
    optional: false,
  },
  {
    content1: '500GB',
    content2: 'Vault Storage',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Private Feeds',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Integrated Calendar',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Messaging',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Audio Chat',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Video Chat',
    value: true,
    optional: false,
  },
  {
    content1: 'N/A',
    content2: 'Networks',
    value: false,
    optional: true,
  },
  {
    content1: '3',
    content2: 'Pages',
    value: true,
    optional: true,
  },
];

export const EnterprisePlan = [
  {
    content1: '50',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '100',
    content2: 'Hub Members',
    value: true,
    optional: false,
  },
  {
    content1: '2TB',
    content2: 'Vault Storage',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Private Feeds',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Integrated Calendar',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Messaging',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Audio Chat',
    value: true,
    optional: false,
  },
  {
    content1: '',
    content2: 'Hub Video Chat',
    value: true,
    optional: false,
  },
  {
    content1: 'N/A',
    content2: 'Networks',
    value: false,
    optional: true,
  },
  {
    content1: '3',
    content2: 'Pages',
    value: true,
    optional: true,
  },
];

export const TrialNetworkPlan = [
  {
    content1: '10',
    content2: 'Members',
    value: true,
    optional: false,
  },
  {
    content1: '5',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '100GB',
    content2: 'Storage',
    value: true,
    optional: false,
  },
];

export const BasicNetworkPlan = [
  {
    content1: '10',
    content2: 'Members',
    value: true,
    optional: false,
  },
  {
    content1: '5',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '100GB',
    content2: 'Storage',
    value: true,
    optional: false,
  },
];

export const MediumNetworkPlan = [
  {
    content1: '20',
    content2: 'Members',
    value: true,
    optional: false,
  },
  {
    content1: '10',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '500GB',
    content2: 'Storage',
    value: true,
    optional: false,
  },
];

export const EnterpriseNetworkPlan = [
  {
    content1: '50',
    content2: 'Members',
    value: true,
    optional: false,
  },
  {
    content1: '20',
    content2: 'Hubs',
    value: true,
    optional: false,
  },
  {
    content1: '2TB',
    content2: 'Storage',
    value: true,
    optional: false,
  },
];

export const DropdownData = {
  countOptions: [...Array(10).keys()].map((x) => ({
    key: x + 1,
    value: x,
  })),

  reportReasonOptions: [
    {
      key: 'spam',
      value: 'Spam',
    },
    {
      key: 'scam',
      value: 'Scam',
    },
    {
      key: 'illegal',
      value: 'Illegal',
    },
    {
      key: 'violence',
      value: 'Violence',
    },
    {
      key: 'pornography',
      value: 'Pornography',
    },
    {
      key: 'other',
      value: 'Other',
    },
  ],
  reportTypeOptions: [
    {
      key: 'user',
      value: 'Users',
    },
    {
      key: 'feed',
      value: 'Feeds',
    },
  ],
  contactsTypeOptions: [
    { key: 'direct_family', value: 'Direct Family' },
    { key: 'extended_family', value: 'Extended Family' },
    { key: 'friends', value: 'Friends' },
    { key: 'community', value: 'Community' },
    { key: 'work', value: 'Work' },
    { key: 'social', value: 'Social' },
    { key: 'favourite', value: 'Favourite' },
  ],
  ageOptions: [
    { key: 'G', value: 'G' },
    { key: 'PG', value: 'PG' },
    { key: '15+', value: '15+' },
    { key: '18+', value: '18+' },
  ],
  reminderTypeOptions: [
    { key: 'event', value: 'Event' },
    { key: 'meeting', value: 'Meeting' },
    { key: 'appointment', value: 'Appointment' },
    { key: 'goal', value: 'Goal' },
    { key: 'alarm', value: 'Alarm' },
    { key: 'note', value: 'Note' },
    { key: 'task', value: 'Task' },
    { key: 'session', value: 'Session' },
  ],
  reminderRepeatOptions: [
    { key: 'never', value: 'Never' },
    { key: 'daily', value: 'Daily' },
    { key: 'hourly', value: 'Hourly' },
    { key: 'weekly', value: 'Weekly' },
    { key: 'weekdays', value: 'Weekdays' },
    { key: 'monthly', value: 'Monthly' },
    { key: 'qurterly', value: 'Qurterly' },
    { key: 'yearly', value: 'Yearly' },
  ],
  reportStatusOptions: [
    { key: 'no_objection', value: 'No Objection' },
    { key: 'hidden', value: 'Hidden' },
    { key: 'blocked', value: 'Blocked' },
    { key: 'pending', value: 'Pending' },
  ],
};
