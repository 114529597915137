import styled from 'styled-components';

export const ProfilePic = styled.div`
  width: 38px;
  height: 38px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
  cursor: pointer;
  position: relative;
  .dot {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => (props.online ? 'green' : 'black')};
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  .field {
    vertical-align: initial;
    padding: 5px 0px;
    font-weight: bold;
    color: black;
    font-size: 16px;
    width: 30%;
  }
  .value {
    vertical-align: initial;
    padding: 5px 0px;
    font-size: 16px;
  }
`;
