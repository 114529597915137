import React, { useEffect } from 'react';
import { Heading } from 'src/components/StyledComponents';

function NetworkList(props) {
  useEffect(() => {
    if (props.state === 'networks' && props.networks.length) props.setNetwork(props.networks[0]);
  }, []);

  return props.state === 'category' ? (
    props.categories.length ? (
      <ul className="list-group mt-2">
        {props.categories.map((category, index) => (
          <li
            key={index}
            className={`list-group-item ${
              category === props.category ? 'active' : null
            }`}
            onClick={() => props.setCategory(category)}
          >
            <span>
              {category[0].toUpperCase() + category.slice(1, category.length)}
            </span>
          </li>
        ))}
      </ul>
    ) : (
      <Heading className="text-center h4 mt-4">
        You have not joined any network.
      </Heading>
    )
  ) : props.networks.length ? (
    <ul className="list-group mt-2">
      {props.networks.map((network, index) => (
        <li
          key={index}
          className={`list-group-item ${
            network.id === props.network.id ? 'active' : null
          }`}
          onClick={() => props.setNetwork(network)}
        >
          <span>{network.name}</span>
        </li>
      ))}
    </ul>
  ) : (
    <Heading className="text-center h4 mt-4">
      You have not joined any network.
    </Heading>
  );
}

export default NetworkList;
