import { call, put, takeLatest } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import * as ROUTES from 'src/constants/routes';
import {
  authenticateUser,
  fetchAdminImages,
  logOut,
  postAdminImages,
  refreshUserToken,
  resetPassword,
  updateFcmToken,
} from 'src/api/auth';
import {
  loginSuccess,
  loginFail,
  logOutSuccess,
  refreshTokenSuccess,
} from 'src/redux/authRedux';
import { processing, setError } from 'src/redux/serviceRedux';
import { saveAdminImages } from 'src/redux/themeRedux';

function* checkLogin(action) {
  try {
    yield put(processing({ status: true, component: 'login' }));
    const response = yield call(authenticateUser, action.payload);
    yield put(loginSuccess(response.data));
    action.payload.navigate(ROUTES.USER_DASHBOARD);
  } catch (error) {
    if (error?.message) {
      yield put(loginFail(error.message));
    } else {
      const err = error.response.data.error;
      yield put(loginFail(err));
    }
  } finally {
    yield put(processing({ status: false, component: 'login' }));
  }
}

function* resetPasswordRequest(action) {
  try {
    yield put(processing({ status: true, component: 'admin-images' }));
    const response = yield call(resetPassword, action.payload);
    Swal.fire(response.message);
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Email',
      text: `Liviit Account does not exist with the email "${action.payload.email}"`,
    });
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'fcm' }));
  }
}

function* refreshToken() {
  try {
    yield put(processing({ status: true, component: 'login' }));
    const response = yield call(refreshUserToken);
    yield put(refreshTokenSuccess(response.data));
    window.location.reload();
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'login' }));
  }
}

function* logOutUser(action) {
  try {
    yield put(processing(true));
    yield call(logOut);
    yield put(logOutSuccess());
    action.payload.push(ROUTES.MAIN_ROUTE);
    window.location.reload();
  } catch (error) {
    localStorage.clear();
    window.location.reload();
    yield put(setError(true));
  } finally {
    yield put(processing(false));
    yield put(setError(false));
  }
}

function* fcmToken(action) {
  try {
    yield put(processing({ status: true, component: 'fcm' }));
    yield call(updateFcmToken, action.payload);
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'fcm' }));
  }
}

function* getAdminImages() {
  try {
    yield put(processing({ status: true, component: 'admin-images' }));
    const response = yield call(fetchAdminImages);
    yield put(saveAdminImages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'fcm' }));
  }
}

function* updateAdminImages(action) {
  try {
    yield put(processing({ status: true, component: 'admin-images' }));
    yield call(postAdminImages, action.payload);
    const response = yield call(fetchAdminImages);
    yield put(saveAdminImages(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'fcm' }));
  }
}

export function* watchAuthSaga() {
  yield takeLatest('LOGIN_REQUEST', checkLogin);
  yield takeLatest('REQUEST_LOGOUT', logOutUser);
  yield takeLatest('REFRESH_TOKEN', refreshToken);
  yield takeLatest('UPDATE_FCM_TOKEN', fcmToken);
  yield takeLatest('GET_ADMIN_IMAGES', getAdminImages);
  yield takeLatest('ADD_ADMIN_IMAGES', updateAdminImages);
  yield takeLatest('RESET_PASSWORD', resetPasswordRequest);
}
