import LogoIcon from 'src/assets/images/livit/Liviit_logo.png';
// Static User Management data
export const userData = [
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },

  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
  {
    profilePicture: LogoIcon,
    name: 'Aditya Aditya',
    username: 'adityakumar',
    memberType: 'Member Type',
    admin: 'Admins',
    email: 'Email Address',
    lastLogin: 'LAst Log In',
    timeLoggedIn: 'Time Logged In',
    location: 'Location',
    ipAddress: 'IP Address',
    status: 'Joined',
    userStatus: 'Verified',
  },
];
// Static Hub Management data
export const familyHubData = [
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [{ displayPic: LogoIcon, name: 'Matt' }],
  },
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
    ],
  },
];

export const friendsHubData = [
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
    ],
  },
];
export const sportsHubData = [
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
      { displayPic: LogoIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: LogoIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [{ displayPic: LogoIcon, name: 'Matt' }],
  },
];
export const formatedData = (data) => {
  const familyHubData = data.filter((hub) => hub.category === 'family');
  const schoolHubData = data.filter((hub) => hub.category === 'school');
  const socialHubData = data.filter((hub) => hub.category === 'social');
  const friendsHubData = data.filter((hub) => hub.category === 'friends');
  const sportsHubData = data.filter((hub) => hub.category === 'sports');
  const hubData = [
    familyHubData,
    schoolHubData,
    socialHubData,
    friendsHubData,
    sportsHubData,
  ];
  return hubData;
};
