import ConfigDB, { themeConfig } from 'src/utils/customizer/config';

// actions
export const changeTheme = (payload) => ({
  type: 'CHANGE_THEME',
  payload,
});

export const saveTheme = (payload) => ({
  type: 'SAVE_THEME',
  payload,
});

export const getAdminImages = () => ({
  type: 'GET_ADMIN_IMAGES',
});

export const saveAdminImages = (payload) => ({
  type: 'SAVE_ADMIN_IMAGES',
  payload,
});

export const addAdminImages = (payload) => ({
  type: 'ADD_ADMIN_IMAGES',
  payload,
});

const initialState = {
  theme: themeConfig,
  customizer: ConfigDB.data,
  configData: {},
  layout: 'ltr',
  sidebar_types: {},
  settings: '',
  color: {},
  adminImages: [null, null, null, null, null],
  mix_layout: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_THEME':
      return { ...state, theme: action.payload };

    case 'ADD_COSTOMIZER':
      return { ...state, loading: false, customizer: ConfigDB.data };

    case 'ADD_LAYOUT':
      state.customizer.settings.layout_type = action.payload;
      const layoutUpdate = state.customizer.settings.layout_type;
      return { ...state, loading: true, layout: layoutUpdate };

    case 'ADD_SIDEBAR_TYPES':
      state.customizer.settings.sidebar = action.payload;
      const sidebarTypeUpdate = state.customizer.settings.sidebar;

      return { ...state, loading: true, sidebar_types: sidebarTypeUpdate };

    case 'ADD_SIDEBAR_SETTINGS':
      state.customizer.settings.sidebar_setting = action.payload;
      const settingsUpdate = state.customizer.settings.sidebar_setting;
      return { ...state, loading: true, settings: settingsUpdate };

    case 'ADD_COLOR':
      const colors = action.payload;
      state.customizer.color.primary_color = colors.primary_color;
      state.customizer.color.secondary_color = colors.secondary_color;
      state.customizer.color.color = colors.color;
      state.customizer.color.layout_version = colors.layout_version;
      return { ...state, color: colors, loading: true };

    case 'ADD_MIXlAYOUT':
      const mix_layout = action.payload;
      state.customizer.color.mix_layout = mix_layout;
      return { ...state, mix_layout, loading: true };
    case 'SAVE_ADMIN_IMAGES':
      return {
        ...state,
        adminImages: action.payload.data.sidebar_bg_image_preset,
      };

    default:
      return { ...state };
  }
};
