import React, { useState, useEffect } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeTheme,
  getAdminImages,
  saveTheme,
} from 'src/redux/themeRedux';
import { SliderPicker } from 'react-color';
import { ModalUpload } from 'src/components/Modal';
import { Plus } from 'react-feather';
import Swal from 'sweetalert2';
import { SidebarImageContainer } from 'src/components/StyledComponents/';
import { CustomImageContainer } from './styles';

function ThemeCustomizer(props) {
  const [modalShow, setModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [showColorPicker, setShowColorPicker] = useState({
    context: '',
    status: false,
  });
  const [colorState, setColorState] = useState('#fff');

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.ProfileReducer);
  const { theme, adminImages } = useSelector((state) => state.ThemeReducer);

  useEffect(() => {
    if (Object.keys(user).length) {
      if (user.theme && user.theme.web) {
        const obj = user.theme.web;
        dispatch(
          saveTheme({
            ...user.theme.web,
            useMobileTheme: obj.useMobileTheme ? obj.useMobileTheme : false,
            layout_type: obj.layout_type ? obj.layout_type : 'ltr',
            layout_wrapper: obj.layout_wrapper ? obj.layout_wrapper : 'default',
            layout_bodyWrapper: obj.layout_bodyWrapper
              ? obj.layout_bodyWrapper
              : 'default',
            layout_version: obj.layout_version ? obj.layout_version : 'light',
            color_primary: obj.color_primary ? obj.color_primary : '#000',
            color_secondary: obj.color_secondary ? obj.color_secondary : '#000',
            color_color: obj.color_color ? obj.color_color : 'color-1',
            color_mixlayout: obj.color_mixlayout
              ? obj.color_mixlayout
              : 'default',
            color_sidebarbg: obj.color_sidebarbg
              ? obj.color_sidebarbg
              : 'dark-sidebar',
            sidebar_bg_image: obj.sidebar_bg_image
              ? obj.sidebar_bg_image
              : null,
          }),
        );
      }
      dispatch(getAdminImages());
    }
  }, [user]);

  useEffect(() => {
    // set layout_type
    document.body.setAttribute('main-theme-layout', theme.layout_type);
    document.documentElement.dir = theme.layout_type;

    // set sidebar wrapper
    document.querySelector('.page-wrapper').className = `page-wrapper ${theme.layout_wrapper}`;
    document.querySelector('.page-body-wrapper').className = `page-body-wrapper ${theme.layout_bodyWrapper}`;

    // set colors
    document.body.className = theme.color_mixlayout;
    document.documentElement.className = theme.color_color;
    document.body.classList.add(theme.layout_version);

    // set sidebar background
    document.querySelectorAll('.sidebar-bg-settings li').forEach((item) => {
      item.classList.remove('active');
    });

    document.documentElement.style.setProperty(
      '--bg-imgCustom',
      `${
        theme.sidebar_bg_image
          ? `url(https://res.cloudinary.com/liviit/image/upload/${theme.sidebar_bg_image}`
          : '#000'
      }`,
    );
  }, [theme]);

  const handleColorChange = (color, context) => {
    setColorState(color.hex);
    colorChangeTheme(context === 'light' ? 'light-6' : 'dark-6');
  };

  const closeCustomizer = () => {
    document.querySelector('.customizer-contain').classList.remove('open');
    document.querySelector('.customizer-links').classList.remove('open');
  };

  const setCustomBackground = (files) => {
    if (files.length) {
      if (files[0].file.size > 14648000) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Image Size',
          text: 'Image Size can not be greater than 14MB.',
        });
        return;
      }

      const formData = new FormData();
      formData.append('sidebar_bg_image', files[0].file);
      formData.append('color_sidebarbg', 'bg-img-custom');
      formData.append('platform', 'web');

      dispatch(changeTheme(formData));
    }
  };
  const handleSidebarColor = (e) => {
    document.querySelectorAll('.sidebar-bg-settings li').forEach((item) => {
      item.classList.remove('active');
    });

    e.target.classList.add('active');
    const payload = {
      theme: {
        color_sidebarbg: e.target.classList.value,
      },
      platform: 'web',
    };

    dispatch(changeTheme(payload));
  };

  const handleSidebarImage = (value) => {
    document.querySelectorAll('.sidebar-bg-settings li').forEach((item) => {
      item.classList.remove('active');
    });
    const payload = {
      theme: {
        color_sidebarbg: value,
      },
      platform: 'web',
    };

    dispatch(changeTheme(payload));
  };

  const handleLayout = (layout_type) => {
    document.querySelectorAll('.main-layout li').forEach((item) => {
      item.classList.remove('active');
    });
    document.body.setAttribute('main-theme-layout', layout_type);
    document.documentElement.dir = layout_type;
    const payload = {
      theme: {
        layout_type,
      },
      platform: 'web',
    };
    dispatch(changeTheme(payload));
  };

  const handleCustomizerMix = (e) => {
    e.preventDefault();
    document.querySelectorAll('.customizer-mix li').forEach((item) => {
      item.classList.remove('active');
    });
    document.body.className = e.currentTarget.getAttribute('data-attr');
    e.currentTarget.classList.add('active');
    const payload = {
      theme: {
        color_mixlayout: e.currentTarget.getAttribute('data-attr'),
      },
      platform: 'web',
    };
    dispatch(changeTheme(payload));
  };

  const handleSidebarType = (e, layout_wrapper, layout_bodyWrapper) => {
    e.preventDefault();
    document.querySelectorAll('.sidebar-type li').forEach((item) => {
      item.classList.remove('active');
    });
    document.querySelector('.page-wrapper').className = `page-wrapper ${layout_wrapper}`;
    document.querySelector('.page-body-wrapper').className = `page-body-wrapper ${layout_bodyWrapper}`;
    e.currentTarget.classList.add('active');
    const payload = {
      theme: {
        layout_wrapper,
        layout_bodyWrapper,
      },
      platform: 'web',
    };
    dispatch(changeTheme(payload));
  };

  const colorChangeTheme = (value) => {
    if (value === 'light-1') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-1',
          color_primary: '#FFBE0B',
          color_secondary: '#b88e1c',
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'light-2') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-2',
          color_primary: '#FB5607',
          color_secondary: '#bd5422',
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'light-3') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-3',
          color_primary: '#FF006E',
          color_secondary: '#e6639b',
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'light-4') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-4',
          color_primary: '#8338EC',
          color_secondary: '#936dc9',
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'light-5') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-5',
          color_primary: '#3A86FF',
          color_secondary: '#396cbd',
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'light-6') {
      document.body.classList.remove('dark-only');
      document.body.classList.add('light');
      const payload = {
        theme: {
          color_color: 'color-6',
          color_primary: colorState,
          color_secondary: colorState,
          layout_version: 'light',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-1') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      const payload = {
        theme: {
          color_color: 'color-1',
          color_primary: 'grey',
          color_secondary: 'grey',
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-2') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      const payload = {
        theme: {
          color_color: 'color-2',
          color_primary: '#FB5607',
          color_secondary: '#bd5422',
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-3') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      const payload = {
        theme: {
          color_color: 'color-3',
          color_primary: '#FF006E',
          color_secondary: '#e6639b',
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-4') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      const payload = {
        theme: {
          color_color: 'color-4',
          color_primary: '#8338EC',
          color_secondary: '#936dc9',
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-5') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      const payload = {
        theme: {
          color_color: 'color-5',
          color_primary: '#3A86FF',
          color_secondary: '#396cbd',
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
    if (value === 'dark-6') {
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');

      const payload = {
        theme: {
          color_color: 'color-6',
          color_primary: colorState,
          color_secondary: colorState,
          layout_version: 'dark-only',
        },
        platform: 'web',
      };
      dispatch(changeTheme(payload));
    }
  };

  const toggleMobileTheme = () => {
    const payload = {
      theme: {
        useMobileTheme: !theme.useMobileTheme,
      },
      platform: 'web',
    };
    dispatch(changeTheme(payload));
  };

  return (
    <>
      <div className="customizer-links" />
      <div className="customizer-contain">
        <div className="tab-content" id="c-pills-tabContent">
          <div className="customizer-header">
            <i className="icon-close" onClick={closeCustomizer} />
            <h5>
              {props.activeTab1 === '2' ? 'THEME PICKER' : 'LAYOUT PICKER'}
            </h5>
            <div>
              <input
                className="checkbox_animated"
                id="chk-ani"
                type="checkbox"
                onChange={() => toggleMobileTheme()}
                checked={!!theme.useMobileTheme}
              />
              <span className="text-dark"> Use Mobile App Theme</span>
            </div>
          </div>
          <div className="customizer-body custom-scrollbar">
            <TabContent activeTab={props.activeTab1}>
              <TabPane tabId="1">
                <h6 className="">Layout Type</h6>
                <ul className="layout-grid customizer-mix">
                  <li
                    className="color-layout"
                    data-attr="light-only"
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-light sidebar" />
                        <li className="bg-light body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className="color-layout active"
                    data-attr=""
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-light body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className="color-layout"
                    data-attr="dark-body-only"
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-light sidebar" />
                        <li className="bg-dark body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className="color-layout"
                    data-attr="dark-sidebar-body-mix"
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-dark body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className="color-layout"
                    data-attr="dark-header-sidebar-mix"
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-dark">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-light body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    className="color-layout"
                    data-attr="dark-only"
                    onClick={handleCustomizerMix}
                  >
                    <div className="header bg-dark">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-dark body"> </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <h6 className="">Sidebar Type</h6>
                <ul className="sidebar-type layout-grid">
                  <li
                    data-attr="normal-sidebar"
                    className="active"
                    onClick={(e) => handleSidebarType(e, 'default', 'default')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-light body" />
                      </ul>
                    </div>
                  </li>
                  <li
                    data-attr="compact-sidebar"
                    onClick={(e) => handleSidebarType(e, 'compact-wrapper', 'sidebar-icon')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar compact" />
                        <li className="bg-light body" />
                      </ul>
                    </div>
                  </li>
                  <li
                    data-attr="compact-icon-sidebar"
                    onClick={(e) => handleSidebarType(e, 'compact-page', 'sidebar-hover')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar compact-icon" />
                        <li className="bg-light body"> </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    data-attr="horizontal_sidebar"
                    className="horizontal_sidebar"
                    onClick={(e) => handleSidebarType(e, 'horizontal_sidebar', ' ')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar horizontal-menu" />
                        <li className="bg-light body"> </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <h6>L/R Sidebar</h6>
                <ul className="main-layout layout-grid">
                  <li
                    data-attr="ltr"
                    className={`${theme.layout_type === 'ltr' ? 'active' : ''}`}
                    onClick={() => handleLayout('ltr')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-dark sidebar" />
                        <li className="bg-light body">
                          <span className="badge badge-dark">LTRLayout</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    data-attr="rtl"
                    className={`${theme.layout_type === 'rtl' ? 'active' : ''}`}
                    onClick={() => handleLayout('rtl')}
                  >
                    <div className="header bg-light">
                      <ul>
                        <li />
                        <li />
                        <li />
                      </ul>
                    </div>
                    <div className="body">
                      <ul>
                        <li className="bg-light body">
                          <span className="badge badge-dark">RTLLayout</span>
                        </li>
                        <li className="bg-dark sidebar" />
                      </ul>
                    </div>
                  </li>
                </ul>
              </TabPane>

              <TabPane tabId="2">
                <div
                  style={
                    theme.useMobileTheme
                      ? { pointerEvents: 'none', backgroundColor: 'lightgrey' }
                      : {}
                  }
                >
                  <h6>Light Layout</h6>
                  <ul className="layout-grid customizer-color my-3">
                    <li
                      className="color-layout "
                      data-attr="light-1"
                      data-primary="#FFBE0B"
                      data-secondary="#b88e1c"
                      onClick={() => colorChangeTheme('light-1')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout "
                      data-attr="light-2"
                      data-primary="#FB5607"
                      data-secondary="#bd5422"
                      onClick={() => colorChangeTheme('light-2')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="light-3"
                      data-primary="#FF006E"
                      data-secondary="#e6639b"
                      onClick={() => colorChangeTheme('light-3')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="light-4"
                      data-primary="#8338EC"
                      data-secondary="#936dc9"
                      onClick={() => colorChangeTheme('light-4')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="light-5"
                      data-primary="#3A86FF"
                      data-secondary="#396cbd"
                      onClick={() => colorChangeTheme('light-5')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="light-6"
                      data-primary="#3949ab"
                      data-secondary="#4fc3f7"
                      onClick={() => setShowColorPicker({
                        context: 'light',
                        status: !showColorPicker.status,
                      })}
                    >
                      <div />
                    </li>
                  </ul>
                  {showColorPicker.context === 'light'
                  && showColorPicker.status ? (
                    <div className="my-2">
                      <SliderPicker
                        color={colorState}
                        onChangeComplete={(color) => {
                          handleColorChange(color, 'light');
                        }}
                      />
                    </div>
                    ) : null}

                  <h6 className="">Dark Layout</h6>
                  <ul className="layout-grid customizer-color dark my-3">
                    <li
                      className="color-layout"
                      data-attr="dark-1"
                      data-primary="grey"
                      data-secondary="grey"
                      onClick={() => colorChangeTheme('dark-1')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="dark-2"
                      data-primary="#FB5607"
                      data-secondary="#bd5422"
                      onClick={() => colorChangeTheme('dark-2')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="dark-3"
                      data-primary="#FF006E"
                      data-secondary="#e6639b"
                      onClick={() => colorChangeTheme('dark-3')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="dark-4"
                      data-primary="#8338EC"
                      data-secondary="#936dc9"
                      onClick={() => colorChangeTheme('dark-4')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="dark-5"
                      data-primary="#3A86FF"
                      data-secondary="#396cbd"
                      onClick={() => colorChangeTheme('dark-5')}
                    >
                      <div />
                    </li>
                    <li
                      className="color-layout"
                      data-attr="dark-6"
                      data-primary="#3949ab"
                      data-secondary="#4fc3f7"
                      onClick={() => setShowColorPicker({
                        context: 'dark',
                        status: !showColorPicker.status,
                      })}
                    >
                      <div />
                    </li>
                  </ul>
                  {showColorPicker.context === 'dark'
                  && showColorPicker.status ? (
                    <div className="my-2">
                      <SliderPicker
                        color={colorState}
                        onChangeComplete={(color) => handleColorChange(color, 'dark')}
                      />
                    </div>
                    ) : null}
                  <h6 className="">Sidebar Background</h6>
                  <Nav tabs className="nav-pills nav-primary nac-pills">
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLink
                        className={activeTab === '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                      >
                        Color
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLink
                        className={activeTab === '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                      >
                        Pattern
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLink
                        className={activeTab === '3' ? 'active' : ''}
                        onClick={() => setActiveTab('3')}
                      >
                        Image
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="sidebar-main-bg-setting"
                  >
                    <TabPane tabId="1">
                      <ul className="sidebar-bg-settings">
                        <li
                          className="bg-dark active"
                          data-attr="dark-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-white light-sidebar"
                          data-attr="light-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-color1"
                          data-attr="color1-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-color2"
                          data-attr="color2-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-color3"
                          data-attr="color3-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-color4"
                          data-attr="color4-sidebar"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className="bg-color5"
                          data-attr="color5-sidebar"
                          onClick={handleSidebarColor}
                        />
                      </ul>
                    </TabPane>
                    <TabPane tabId="2">
                      <ul className="sidebar-bg-settings">
                        <li
                          className=" bg-pattern1"
                          data-attr="sidebar-pattern1"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className=" bg-pattern2"
                          data-attr="sidebar-pattern2"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className=" bg-pattern3"
                          data-attr="sidebar-pattern3"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className=" bg-pattern4"
                          data-attr="sidebar-pattern4"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className=" bg-pattern5"
                          data-attr="sidebar-pattern5"
                          onClick={handleSidebarColor}
                        />
                        <li
                          className=" bg-pattern6"
                          data-attr="sidebar-pattern6"
                          onClick={handleSidebarColor}
                        />
                      </ul>
                    </TabPane>
                    <TabPane tabId="3">
                      <ul className="sidebar-bg-settings">
                        {adminImages
                          && adminImages.map((image, index) => (
                            <SidebarImageContainer
                              key={index}
                              image={image}
                              className={`bg-img-${index + 1}`}
                              data-attr={`sidebar-img${index + 1}`}
                              onClick={() => handleSidebarImage(`bg-img-${index + 1}`)}
                            />
                          ))}
                        <CustomImageContainer image={theme.sidebar_bg_image}>
                          <div
                            className="bg-img-custom"
                            data-attr="sidebar-img-custom"
                            onClick={handleSidebarColor}
                            style={{ width: '100%', height: '100%' }}
                          />
                          <div id="add-custom-img">
                            <Plus onClick={() => setModalShow(true)} />
                          </div>
                        </CustomImageContainer>
                      </ul>
                    </TabPane>
                  </TabContent>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <ModalUpload
        show={modalShow}
        close={setModalShow}
        size={1}
        addFile={setCustomBackground}
        accept={'image/*'}
        title="Select Your Image"
      />
    </>
  );
}

export default ThemeCustomizer;
