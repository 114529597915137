import React, { useState, useEffect } from 'react';
import PlusButton from 'src/components/PlusButton';
import Loader from 'src/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHubPlaylistTracks,
  updateHubPlaylist,
  addHubPlaylistTracks,
  reorderHubTracks,
} from 'src/redux/hubRedux';
import { ScrollableSection } from 'src/components/StyledComponents';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import { ModalButton } from 'src/components/Modal';
import {
  Trash2, PlayCircle, RotateCw, Shuffle,
} from 'react-feather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from 'src/utils/helper';
import PlayingGif from 'src/assets/images/livit/playing.gif';
import { UncontrolledTooltip } from 'reactstrap';
import { Controls } from 'src/containers/AudioPlayer/styles';
import _ from 'lodash';
import { saveCurrentTrack } from 'src/redux/audioPlayerRedux';
import { DeleteModal, SelectModal } from './addTrackModals';

function Playlist(props) {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState(props.playlist.name);
  const [nameModalShow, setNameModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedTrack, setDeletedTrack] = useState({});
  const [shuffle, setShuffle] = useState(false);
  const dispatch = useDispatch();
  const { processing } = useSelector((state) => state.ServiceReducer);
  const { tracksProcessing, audioref } = useSelector(
    (state) => state.AudioPlayerReducer,
  );
  const { hubPlaylistTracks } = useSelector((state) => state.HubsReducer);

  useEffect(() => {
    dispatch(
      getHubPlaylistTracks({
        id: props.match.params.id,
        p_id: props.playlist.id,
      }),
    );
    setName(props.playlist.name);
  }, [props.playlist]);

  const addTrack = (provider, file) => {
    if (provider === 'local') {
      const payload = new FormData();
      payload.append('files[0][file]', file[0].file);
      payload.set('provider', provider);
      payload.set('playlist_id', props.playlist.id);
      dispatch(
        addHubPlaylistTracks([
          props.match.params.id,
          props.playlist.id,
          payload,
        ]),
      );
    } else if (file.length) {
      const tracks = file.map((track) => ({
        name: track.name,
        metadata: [
          {
            type: track.type,
            id: track.id,
            playbackSeconds: track.playbackSeconds,
            isStreamable: track.isStreamable,
            name: track.name,
            shortcut: track.shortcut,
            artistName: track.artistName,
            albumName: track.albumName,
            albumId: track.albumId,
            previewURL: track.previewURL,
          },
        ],
      }));
      const payload = {
        provider,
        tracks,
        playlist_id: props.playlist.id,
      };

      dispatch(
        addHubPlaylistTracks([
          props.match.params.id,
          props.playlist.id,
          payload,
        ]),
      );
    }
  };

  const removeTrack = async () => {
    await dispatch(
      updateHubPlaylist([
        props.match.params.id,
        props.playlist.id,
        { to_remove: [deletedTrack.id] },
      ]),
    );
    setDeleteModal(false);
  };

  const changePlaylistName = async (e) => {
    e.preventDefault();
    await dispatch(
      updateHubPlaylist([props.match.params.id, props.playlist.id, { name }]),
    );
    setNameModalShow(false);
  };

  const startPlaylist = (cmd) => {
    if (hubPlaylistTracks.length) {
      if (cmd === 'restart') {
        props.setTrack(hubPlaylistTracks[0]);
      }
      if (cmd === 'reset') {
        if (props.currentTrack.id) {
          if (props.currentTrack.provider === 'local') {
            audioref.current.currentTime = 0;
          } else {
            window.Napster.player.seek(0);
          }
        } else {
          props.setTrack(hubPlaylistTracks[0]);
        }
      }
      if (cmd === 'shuffle') {
        if (!shuffle) {
          setShuffle(!shuffle);
          const shuffledQueue = _.shuffle(hubPlaylistTracks);
          props.setTrack(shuffledQueue[0], shuffledQueue);
        } else {
          setShuffle(!shuffle);
          dispatch(saveCurrentTrack({ queue: hubPlaylistTracks }));
        }
      }
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = await reorder(
      hubPlaylistTracks,
      result.source.index,
      result.destination.index,
    );
    await dispatch(
      reorderHubTracks([
        props.match.params.id,
        props.playlist.id,
        { sequence: items },
      ]),
    );
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-sm-10 d-flex">
              <h5
                className="align-abjust"
                style={{ cursor: 'pointer' }}
                onClick={() => setNameModalShow(true)}
              >
                {props.playlist && name ? name : 'Playlist'}
              </h5>
            </div>

            <div className="col-sm-2">
              <div className="pull-right right-header">
                <PlusButton onClick={() => setModalShow(true)} />
              </div>
            </div>
          </div>

          <Controls>
            <div className="buttons">
              <div
                className="ctrl-button button-sm"
                onClick={() => startPlaylist('restart')}
              >
                <PlayCircle id="tool-restart" />
                <UncontrolledTooltip placement="bottom" target="tool-restart">
                  Play/Restart
                </UncontrolledTooltip>
              </div>
              <div
                className="ctrl-button button-sm"
                onClick={() => startPlaylist('reset')}
              >
                <RotateCw id="tool-reset" />
                <UncontrolledTooltip placement="bottom" target="tool-reset">
                  Reset
                </UncontrolledTooltip>
              </div>
              <div
                className={`ctrl-button button-sm ${
                  shuffle ? 'active-shuffle' : ''
                }`}
                onClick={() => startPlaylist('shuffle')}
              >
                <Shuffle id="tool-shuffle" />
                <UncontrolledTooltip placement="bottom" target="tool-shuffle">
                  Shuffle
                </UncontrolledTooltip>
              </div>
            </div>
          </Controls>
        </div>
        <div className="card-body">
          <ScrollableSection>
            {processing.status
            && processing.component === 'hub-playlist-tracks' ? (
              <Loader show />
              ) : hubPlaylistTracks.length ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" type="playlist">
                    {(provided) => (
                      <ul
                        className="list-group mt-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {hubPlaylistTracks.map((track, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (track.provider === 'local' ? (
                              <li
                                key={index}
                                className={`list-group-item ${
                                  props.currentTrack.id === track.id
                                    ? 'active disable'
                                    : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="d-flex"
                                  onClick={() => props.setTrack(track)}
                                >
                                  <div>
                                    <img
                                      src={
                                            props.isPlaying
                                            && props.currentTrack.id === track.id
                                              ? PlayingGif
                                              : MusicDisc
                                          }
                                      alt="#track art"
                                      height="70px"
                                      width="70px"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <h6>{track.name}</h6>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <Trash2
                                    onClick={() => {
                                      setDeleteModal(true);
                                      setDeletedTrack(track);
                                    }}
                                  />
                                </div>
                              </li>
                            ) : (
                              <li
                                key={index}
                                className={`list-group-item ${
                                  props.currentTrack.id === track.id
                                    ? 'active disable'
                                    : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="d-flex"
                                  onClick={() => props.setTrack(track)}
                                >
                                  <div>
                                    <img
                                      src={
                                            props.isPlaying
                                            && props.currentTrack.id === track.id
                                              ? PlayingGif
                                              : `https://api.napster.com/imageserver/v2/albums/${track.metadata[0].albumId}/images/70x70.jpg`
                                          }
                                      alt="#track art"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <h6>{track.metadata[0].name}</h6>
                                    <p>{track.metadata[0].artistName}</p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <Trash2
                                    onClick={() => {
                                      setDeleteModal(true);
                                      setDeletedTrack(track);
                                    }}
                                  />
                                </div>
                              </li>
                            ))}
                          </Draggable>
                        ))}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="text-center">
                  <h5>No Music in this Playlist</h5>
                </div>
              )}
          </ScrollableSection>
        </div>
      </div>
      <SelectModal
        modalShow={modalShow}
        modalClose={setModalShow}
        uploadFile={addTrack}
        loading={tracksProcessing}
        {...props}
      />
      <ModalButton
        show={nameModalShow}
        close={setNameModalShow}
        title="Update Playlist Name"
        submit={changePlaylistName}
        value={name}
        setValue={setName}
      />
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        delete={removeTrack}
        track={deletedTrack}
        type="track"
      />
    </>
  );
}

export default Playlist;
