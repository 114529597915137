export const getStreamToken = () => ({
  type: 'GET_STREAM_TOKEN',
});

export const saveToken = (payload) => ({
  type: 'SAVE_STREAM_TOKEN',
  payload,
});

export const setGroupChannel = (payload) => ({
  type: 'SET_GROUP_CHANNEL',
  payload,
});

export const setChannel = (payload) => ({
  type: 'SET_CHANNEL',
  payload,
});

export const getFavouriteGroup = (payload) => ({
  type: 'GET_FAV_GROUP',
  payload,
});

export const saveFavouriteGroup = (payload) => ({
  type: 'SAVE_FAV_GROUP',
  payload,
});

export const toggleFavGroup = (payload) => ({
  type: 'TOGGLE_FAV_GROUP',
  payload,
});

// store
const initialState = {
  streamChatToken: null,
  groupChannel: null,
  channel: null,
  favouriteGroup: [],
};

// chatReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_STREAM_TOKEN':
      return { ...state, streamChatToken: action.payload };
    case 'SET_GROUP_CHANNEL':
      return { ...state, groupChannel: action.payload };
    case 'SET_CHANNEL':
      return { ...state, channel: action.payload };
    case 'SAVE_FAV_GROUP':
      return { ...state, favouriteGroup: action.payload };
    default:
      return { ...state };
  }
};
