import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import Iframe from 'react-iframe';
import { Heading } from 'src/components/StyledComponents';

function Files() {
  const { files, filesProcessing } = useSelector((state) => state.FilesReducer);
  const isImage = (file) => (
    file.name.split('.').pop().toUpperCase() === 'JPG'
      || file.name.split('.').pop().toUpperCase() === 'PNG'
      || file.name.split('.').pop().toUpperCase() === 'JPEG'
  );

  return (
    <>
      {filesProcessing.status
      && filesProcessing.component === 'user-dashboard-files' ? (
        <Loader show />
        ) : files.length ? (
          <div className="container-fluid">
            <div className="row">
              {files.map((file) => {
                if (isImage(file)) {
                  return (
                    <div
                      className="col-6"
                      key={file.id}
                      onClick={() => window.open(file.path, '_blank').focus()}
                    >
                      <img
                        src={file.path}
                        alt="Error Loading in File"
                        width="100%"
                        height="100px"
                        className="mt-2"
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className="col-6"
                    key={file.id}
                    onClick={() => window.open(file.path, '_blank').focus()}
                  >
                    <Iframe
                      url={file.path}
                      width="100%"
                      height="100px"
                      display="initial"
                      position="relative"
                      id="preview-frame"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Heading className="text-center h4 mt-4">You have No Files.</Heading>
        )}
    </>
  );
}

export default Files;
