// actions
export const processing = (payload) => ({
  type: 'PROCESSING',
  payload,
});

export const setSideBarColor = (payload) => ({
  type: 'SET_COLOR',
  payload,
});

export const setError = (payload) => ({
  type: 'SET_ERROR',
  payload,
});

export const getWeather = (payload) => ({
  type: 'GET_WEATHER',
  payload,
});
export const saveWeather = (payload) => ({
  type: 'SAVE_WEATHER',
  payload,
});

// store
const initialState = {
  processing: { status: false, component: '' },
  bgColor: ['#000', '#000'],
  error: false,
  weatherData: {},
};

// serviceReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'PROCESSING':
      return { ...state, processing: action.payload };
    case 'SET_COLOR':
      return { ...state, bgColor: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SAVE_WEATHER':
      return { ...state, weatherData: action.payload };
    default:
      return { ...state };
  }
};
