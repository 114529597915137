import React from 'react';
import { RangeInput } from 'src/containers/AudioPlayer/styles';

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculateTotalValue(track) {
    if (track && track.type === 'track') {
      const length = track.playbackSeconds;
      const minutes = Math.floor(length / 60);
      let seconds = track.playbackSeconds % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    } if (track && track.provider === 'local') {
      const length = this.props.totalTime;
      const minutes = Math.floor(length / 60);
      let seconds = Math.floor(this.props.totalTime % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    }
    return '0:00';
  }

  normalizeTime = (t) => {
    if (this.props.selectedTrack) {
      if (t === undefined) {
        return '0:00';
      }
      const time = Math.floor(t);
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      const timeout = `${minutes}:${seconds}`;
      return timeout;
    }
    return '0:00';
  };

  seek = (event) => {
    if (this.props.playing) {
      if (
        this.props.selectedTrack
        && this.props.selectedTrack.type === 'track'
      ) {
        window.Napster.player.seek(Math.floor(event.target.value));
        return event.target.value;
      }
      if (
        this.props.selectedTrack
          && this.props.selectedTrack.provider === 'local'
      ) {
        this.props.seekTo(event.target.value);
      }
    }
  };

  render() {
    return (
      <div className="text-dark">
        <RangeInput
          type="range"
          id="progress"
          min={0}
          max={this.props.totalTime}
          step="any"
          onChange={this.seek}
          value={this.props.currentTime}
        />
        <div className="text-center">
          {` ${this.normalizeTime(
            this.props.currentTime,
          )}/${this.calculateTotalValue(this.props.selectedTrack)}`}
        </div>
      </div>
    );
  }
}
