import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchFolder,
  fetchAllFolders,
  postFolder,
  postFile,
  deleteFolder,
  deleteFile,
} from 'src/api/files';
import {
  processFiles,
  saveAllFiles,
  saveAllFolders,
  saveFolderData2,
  saveFolderData3,
} from 'src/redux/filesRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getAllFolders() {
  try {
    yield put(processing({ status: true, component: 'user-files' }));
    const response = yield call(fetchAllFolders);
    yield put(saveAllFolders(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-files' }));
    yield put(setError(false));
  }
}

function* getFolderFiles(action) {
  try {
    yield put(processing({ status: true, component: 'user-files-inner' }));
    const response = yield call(fetchFolder, action.payload);
    if (action.payload.level === 2) yield put(saveFolderData2(response));
    else yield put(saveFolderData3(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-files-inner' }));
    yield put(setError(false));
  }
}

function* getOnlyFiles() {
  try {
    yield put(
      processFiles({ status: true, component: 'user-dashboard-files' }),
    );
    const response = yield call(fetchAllFolders);
    const files = [];
    for (let i = 0; i < response.data.length; i++) {
      if (files.length > 5) {
        break;
      }
      if (!response.data[i].is_folder) {
        files.push(response.data[i]);
      } else {
        const OuterResponse = yield call(fetchFolder, {
          id: response.data[i].id,
        });
        for (let j = 0; j < OuterResponse.length; j++) {
          if (files.length > 5) {
            break;
          }
          if (!OuterResponse[j].is_folder) {
            files.push(OuterResponse[j]);
          } else {
            const InnerResponse = yield call(fetchFolder, {
              id: OuterResponse[j].id,
            });
            for (let k = 0; k < InnerResponse.length; k++) {
              if (files.length > 5) {
                break;
              } else {
                files.push(InnerResponse[k]);
              }
            }
          }
        }
      }
    }
    yield put(saveAllFiles(files));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processFiles({ status: false, component: 'user-dashboard-files' }),
    );
    yield put(setError(false));
  }
}

function* createFolder(action) {
  try {
    yield put(
      processing({
        status: true,
        component: action.payload.id ? 'user-files-inner' : 'user-files',
      }),
    );
    yield call(postFolder, action.payload);
    if (action.payload.id) {
      const response = yield call(fetchFolder, { id: action.payload.id });
      yield put(saveFolderData2(response));
    } else {
      const response = yield call(fetchAllFolders);
      yield put(saveAllFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processing({
        status: false,
        component: action.payload.id ? 'user-files-inner' : 'user-files',
      }),
    );
    yield put(setError(false));
  }
}

function* addNewFile(action) {
  try {
    yield put(
      processing({
        status: true,
        component:
          action.payload.get('level') > 1 ? 'user-files-inner' : 'user-files',
      }),
    );
    yield call(postFile, action.payload);
    if (action.payload.get('level') > 1) {
      const response = yield call(fetchFolder, {
        id: action.payload.get('folder_id'),
      });
      if (action.payload.get('level') === '2') {
        yield put(saveFolderData2(response));
      } else yield put(saveFolderData3(response));
    } else {
      const response = yield call(fetchAllFolders);
      yield put(saveAllFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processing({
        status: false,
        component:
          action.payload.get('level') > 1 ? 'user-files-inner' : 'user-files',
      }),
    );
    yield put(setError(false));
  }
}

function* removeFolder(action) {
  try {
    yield put(
      processing({
        status: true,
        component: action.payload.level > 1 ? 'user-files-inner' : 'user-files',
      }),
    );
    yield call(deleteFolder, action.payload);
    if (action.payload.level > 1) {
      const response = yield call(fetchFolder, {
        id: action.payload.folder_id,
      });
      yield put(saveFolderData2(response));
    } else {
      const response = yield call(fetchAllFolders);
      yield put(saveAllFolders(response));
    }
  } catch (error) {
    yield put(
      setError({
        status: true,
        message:
          "The Folder can not be Deleted. It isn't Empty or there is some internal server error. Please Try Again.",
      }),
    );
  } finally {
    yield put(
      processing({
        status: false,
        component: action.payload.level > 1 ? 'user-files-inner' : 'user-files',
      }),
    );
    yield put(setError(false));
  }
}

function* removeFile(action) {
  try {
    yield put(
      processing({
        status: true,
        component: action.payload.level > 1 ? 'user-inner-files' : 'user-files',
      }),
    );
    yield call(deleteFile, action.payload);
    if (action.payload.level > 1) {
      const response = yield call(fetchFolder, {
        id: action.payload.folder_id,
      });
      action.payload.level === 2
        ? yield put(saveFolderData2(response))
        : yield put(saveFolderData3(response));
    } else {
      const response = yield call(fetchAllFolders);
      yield put(saveAllFolders(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(
      processing({
        status: false,
        component: action.payload.level > 1 ? 'user-inner-files' : 'user-files',
      }),
    );
    yield put(setError(false));
  }
}

export function* watchFilesSaga() {
  yield takeLatest('GET_ALL_FOLDERS', getAllFolders);
  yield takeLatest('GET_ONLY_FILES', getOnlyFiles);
  yield takeLatest('GET_FOLDER_DATA', getFolderFiles);
  yield takeLatest('CREATE_FOLDER', createFolder);
  yield takeLatest('UPLOAD_FILE', addNewFile);
  yield takeLatest('REMOVE_FOLDER', removeFolder);
  yield takeLatest('REMOVE_FILE', removeFile);
}
