// actions
export const loginNapsterRequest = (payload) => ({
  type: 'LOGIN_NAPSTER_REQUEST',
  payload,
});

export const loginNapsterSuccess = (payload) => ({
  type: 'LOGIN_NAPSTER_SUCCESS',
  payload,
});

export const getTopTracks = () => ({
  type: 'GET_TOP_TRACKS',
});

export const getSearchedTracks = (payload) => ({
  type: 'GET_SEARCHED_TRACKS',
  payload,
});

export const saveTracks = (payload) => ({
  type: 'SAVE_TRACKS',
  payload,
});

export const getPlaylists = (payload) => ({
  type: 'GET_PLAYLISTS',
  payload,
});

export const savePlaylists = (payload) => ({
  type: 'SAVE_PLAYLISTS',
  payload,
});

export const createPlaylist = (payload) => ({
  type: 'CREATE_PLAYLIST',
  payload,
});
export const updatePlaylist = (payload) => ({
  type: 'UPDATE_PLAYLIST',
  payload,
});

export const removeTrack = (payload) => ({
  type: 'DELETE_TRACK',
  payload,
});

export const removePlaylist = (payload) => ({
  type: 'DELETE_PLAYLIST',
  payload,
});

export const sharePlaylist = (payload) => ({
  type: 'SHARE_PLAYLIST',
  payload,
});

export const getPlaylistTracks = (payload) => ({
  type: 'GET_PLAYLIST_TRACKS',
  payload,
});

export const addPlaylistTracks = (payload) => ({
  type: 'ADD_PLAYLIST_TRACKS',
  payload,
});

export const savePlayListTracks = (payload) => ({
  type: 'SAVE_PLAYLIST_TRACKS',
  payload,
});

export const getMyTracks = () => ({
  type: 'GET_MY_TRACKS',
});
export const saveMyTracks = (payload) => ({
  type: 'SAVE_MY_TRACKS',
  payload,
});
export const saveCurrentTrack = (payload) => ({
  type: 'SAVE_CURRENT_TRACK',
  payload,
});

export const reorderTracks = (payload) => ({
  type: 'REORDER_TRACKS',
  payload,
});

export const processTracks = (payload) => ({
  type: 'PROCESSING_TRACKS',
  payload,
});

// store
const initialState = {
  accessToken: '',
  refreshToken: '',
  tracks: [],
  playlists: [],
  playlistTracks: [],
  myTracks: [],
  tracksProcessing: { status: false, component: '' },
  isPlaying: false,
  currentTrack: {},
  queue: [],
  audioref: { current: null },
  currentTime: 0,
  totalTime: 0,
};

// audioPlayerReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_NAPSTER_SUCCESS':
      localStorage.setItem(
        'napster_token_expire_in',
        new Date(Date.now() + action.payload.expires_in * 1000),
      );
      return {
        ...state,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
      };
    case 'SAVE_TRACKS':
      return {
        ...state,
        tracks: action.payload,
      };
    case 'SAVE_PLAYLISTS':
      return {
        ...state,
        playlists: action.payload,
      };
    case 'SAVE_PLAYLIST_TRACKS':
      return {
        ...state,
        playlistTracks: action.payload,
      };
    case 'SAVE_MY_TRACKS':
      return {
        ...state,
        myTracks: action.payload,
      };
    case 'SAVE_CURRENT_TRACK':
      return {
        ...state,
        currentTrack: action.payload.track
          ? action.payload.track
          : state.currentTrack,
        isPlaying:
          action.payload.isPlaying !== undefined
            ? action.payload.isPlaying
            : state.isPlaying,
        queue: action.payload.queue ? action.payload.queue : state.queue,
        audioref: action.payload.ref ? action.payload.ref : state.audioref,
        currentTime: action.payload.currentTime
          ? action.payload.currentTime
          : state.currentTime,
        totalTime: action.payload.totalTime
          ? action.payload.totalTime
          : state.totalTime,
      };
    case 'PROCESSING_TRACKS':
      return { ...state, tracksProcessing: action.payload };

    default:
      return { ...state };
  }
};
