import React, { useState, useEffect } from 'react';
import * as PlusButton from 'src/components/PlusButton';
import Loader from 'src/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPlaylistTracks,
  updatePlaylist,
  addPlaylistTracks,
  reorderTracks,
  saveCurrentTrack,
  sharePlaylist,
} from 'src/redux/audioPlayerRedux';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import {
  ScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import { ModalButton } from 'src/components/Modal';
import {
  PlayCircle, RotateCw, Share2, Shuffle, Trash2,
} from 'react-feather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addDefaultSrc, reorder } from 'src/utils/helper';
import PlayingGif from 'src/assets/images/livit/playing.gif';
import { UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { Button } from 'src/components/Button';
import { Controls } from 'src/containers/AudioPlayer/styles';
import { DeleteModal, SelectModal } from './addTrackModals';

function Playlist(props) {
  const [modalShow, setModalShow] = useState(false);
  const [contacModalShow, setContactModalShow] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [name, setName] = useState(props.playlist.name);
  const [nameModalShow, setNameModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedTrack, setDeletedTrack] = useState({});
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const {
    playlistTracks, tracksProcessing, currentTrack, audioref,
  } = useSelector((state) => state.AudioPlayerReducer);

  const { allContacts } = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    dispatch(getPlaylistTracks(props.playlist.id));
    setName(props.playlist.name);
  }, [props.playlist]);

  const addTrack = (provider, file) => {
    if (provider === 'local') {
      const payload = new FormData();
      file.forEach((data, index) => {
        payload.append(`files[${index}][file]`, data.file);
      });
      payload.set('provider', provider);
      payload.set('playlist_id', props.playlist.id);
      dispatch(addPlaylistTracks([props.playlist.id, payload]));
    } else if (file.length) {
      const tracks = file.map((track) => ({
        name: track.name,
        metadata: [
          {
            type: track.type,
            id: track.id,
            playbackSeconds: track.playbackSeconds,
            isStreamable: track.isStreamable,
            name: track.name,
            shortcut: track.shortcut,
            artistName: track.artistName,
            albumName: track.albumName,
            albumId: track.albumId,
            previewURL: track.previewURL,
          },
        ],
      }));
      const payload = {
        provider,
        tracks,
        playlist_id: props.playlist.id,
      };

      dispatch(addPlaylistTracks([props.playlist.id, payload]));
    }
  };

  const removeTrack = async () => {
    await dispatch(
      updatePlaylist([props.playlist.id, { to_remove: [deletedTrack.id] }]),
    );
    setDeleteModal(false);
  };

  const changePlaylistName = async (e) => {
    e.preventDefault();
    await dispatch(updatePlaylist([props.playlist.id, { name }]));
    setNameModalShow(false);
  };

  const startPlaylist = (cmd) => {
    if (playlistTracks.length) {
      if (cmd === 'restart') {
        props.setTrack(playlistTracks[0]);
      }
      if (cmd === 'reset') {
        if (currentTrack.id) {
          if (currentTrack.provider === 'local') {
            audioref.current.currentTime = 0;
          } else {
            window.Napster.player.seek(0);
          }
        } else {
          props.setTrack(playlistTracks[0]);
        }
      }
      if (cmd === 'shuffle') {
        if (!shuffle) {
          setShuffle(!shuffle);
          const shuffledQueue = _.shuffle(playlistTracks);
          props.setTrack(shuffledQueue[0], shuffledQueue);
        } else {
          setShuffle(!shuffle);
          dispatch(saveCurrentTrack({ queue: playlistTracks }));
        }
      }
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = await reorder(
      playlistTracks,
      result.source.index,
      result.destination.index,
    );
    if (!props.playlist.is_global) {
      await dispatch(reorderTracks([props.playlist.id, { sequence: items }]));
    }
  };

  const changeUsers = (member) => {
    if (!users.includes(member.id)) {
      setUsers([...users, member.id]);
    } else {
      setUsers((prevUsers) => prevUsers.filter((user) => user !== member.id));
    }
  };

  const share = () => {
    dispatch(
      sharePlaylist({ id: props.playlist.id, users: { user_ids: users } }),
    );
    setUsers([]);
    setContactModalShow(false);
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-sm-10 d-flex">
              <h5
                className="align-abjust"
                style={{ cursor: 'pointer' }}
                onClick={() => setNameModalShow(true)}
              >
                {props.playlist && name ? name : 'Playlist'}
              </h5>
            </div>
            <div className="col-sm-2">
              <div className="pull-right right-header">
                {!props.playlist.is_global ? (
                  <PlusButton onClick={() => setModalShow(true)} />
                ) : null}
              </div>
            </div>
          </div>
          <Controls>
            <div className="buttons">
              <div
                className="ctrl-button button-sm"
                onClick={() => startPlaylist('restart')}
              >
                <PlayCircle id="tool-restart" />
                <UncontrolledTooltip placement="bottom" target="tool-restart">
                  Play/Restart
                </UncontrolledTooltip>
              </div>
              <div
                className="ctrl-button button-sm"
                onClick={() => startPlaylist('reset')}
              >
                <RotateCw id="tool-reset" />
                <UncontrolledTooltip placement="bottom" target="tool-reset">
                  Reset
                </UncontrolledTooltip>
              </div>
              <div
                className={`ctrl-button button-sm ${
                  shuffle ? 'active-shuffle' : ''
                }`}
                onClick={() => startPlaylist('shuffle')}
              >
                <Shuffle id="tool-shuffle" />
                <UncontrolledTooltip placement="bottom" target="tool-shuffle">
                  Shuffle
                </UncontrolledTooltip>
              </div>
              {!props.playlist.is_global ? (
                <div
                  className="ctrl-button button-sm"
                  onClick={() => setContactModalShow(true)}
                >
                  <Share2 id="tool-share" />
                  <UncontrolledTooltip placement="bottom" target="tool-share">
                    Share
                  </UncontrolledTooltip>
                </div>
              ) : null}
            </div>
          </Controls>
        </div>
        <div className="card-body">
          <ScrollableSection>
            {tracksProcessing.status
            && tracksProcessing.component === 'playlist-tracks' ? (
              <Loader show />
              ) : playlistTracks.length ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" type="playlist">
                    {(provided) => (
                      <ul
                        className="list-group mt-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {playlistTracks.map((track, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (track.provider === 'local' ? (
                              <li
                                className={`list-group-item ${
                                  props.currentTrack.id === track.id
                                    ? 'active disable'
                                    : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="d-flex"
                                  onClick={() => props.setTrack(track)}
                                >
                                  <div>
                                    <img
                                      src={
                                            props.isPlaying
                                            && props.currentTrack.id === track.id
                                              ? PlayingGif
                                              : MusicDisc
                                          }
                                      alt="#track art"
                                      height="70px"
                                      width="70px"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <h6>{track.name}</h6>
                                  </div>
                                </div>
                                {!props.playlist.is_global ? (
                                  <div className="py-2">
                                    <Trash2
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setDeletedTrack(track);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </li>
                            ) : (
                              <li
                                className={`list-group-item ${
                                  props.currentTrack.id === track.id
                                    ? 'active disable'
                                    : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className="d-flex"
                                  onClick={() => props.setTrack(track)}
                                >
                                  <div>
                                    <img
                                      src={
                                            props.isPlaying
                                            && props.currentTrack.id === track.id
                                              ? PlayingGif
                                              : `https://api.napster.com/imageserver/v2/albums/${track.metadata[0].albumId}/images/70x70.jpg`
                                          }
                                      alt="#track art"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <h6>{track.metadata[0].name}</h6>
                                    <p>{track.metadata[0].artistName}</p>
                                  </div>
                                </div>
                                {!props.playlist.is_global ? (
                                  <div className="py-2">
                                    <Trash2
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setDeletedTrack(track);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </li>
                            ))}
                          </Draggable>
                        ))}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="text-center">
                  <h5>No Music in this Playlist</h5>
                </div>
              )}
          </ScrollableSection>
        </div>
      </div>
      <SelectModal
        modalShow={modalShow}
        modalClose={setModalShow}
        uploadFile={addTrack}
        loading={tracksProcessing}
        {...props}
      />
      <ModalButton
        show={nameModalShow}
        close={setNameModalShow}
        title="Update Playlist Name"
        submit={changePlaylistName}
        value={name}
        setValue={setName}
      />
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        delete={removeTrack}
        track={deletedTrack}
        type="track"
      />
      <Modal
        show={contacModalShow}
        onHide={() => setContactModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: 'none' }} closeButton>
          <div className="font-weight-bold h4">Select Users</div>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div
              className="card-body text-align-center justify-content-center"
              style={{ height: '400px' }}
            >
              <ScrollableSection>
                <ul className="list-group">
                  {allContacts.length ? (
                    allContacts.map((contact, index) => (
                      <li
                        className="list-group-item justify-content-start"
                        key={index}
                      >
                        <input
                          className="checkbox_animated"
                          type="checkbox"
                          checked={users.includes(contact.id)}
                          onChange={() => changeUsers(contact)}
                        />

                        <ProfilePic width="25px" height="25px">
                          <img
                            className="rounded-circle lazyloaded blur-up"
                            style={{ width: '100%', height: '100%' }}
                            src={
                                contact.avatar
                                  ? contact.avatar
                                  : ProfilePictureIcon
                              }
                            onError={addDefaultSrc}
                            alt="icon"
                          />
                          {' '}
                        </ProfilePic>
                        <div className="ml-1">{contact.name}</div>
                      </li>
                    ))
                  ) : (
                    <div>You do not have any contacts to share.</div>
                  )}
                </ul>
              </ScrollableSection>
              <div className="text-center">
                <Button
                  value="Share"
                  variant="solid"
                  onClick={() => share()}
                  margin="5px 0px"
                  dissabled={!users.length}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Playlist;
