import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnseenFeedCount, getUserFeeds } from 'src/redux/feedsRedux';
import { getRemainders } from 'src/redux/remaindersRedux';
import { getUserTodoList } from 'src/redux/todoRedux';
import { clearHub, getAllHubs, getDashboardHubs } from 'src/redux/hubRedux';
import { getAllVaultFiles, getAllVaultFolders } from 'src/redux/vaultRedux';
import DatePicker from 'react-datepicker';
import { ScrollableSection } from 'src/components/StyledComponents';
import * as ROUTES from 'src/constants/routes';
import Breadcrumb from 'src/components/Breadcrumb';
import { ExternalLink } from 'react-feather';
import { getAllFiles } from 'src/redux/filesRedux';
import { getAllContacts } from 'src/redux/profileRedux';
import { useNavigate } from 'react-router';
import Feed from './Sections/feed';
import { FeedFilter, FeedButton, Section } from './styles';
import Todos from './Sections/todo';
import Vault from './Sections/vault';
import Remainders from './Sections/remainders';
import Activities from './Sections/activities';
import Hubs from './Sections/hubs';
import Contacts from './Sections/contacts';
import Weather from './Sections/weather';
import OnlineUsers from './Sections/onlineUsers';
import Files from './Sections/files';

function UserDashboard() {
  const [feedType, setfeedType] = useState('all');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearHub());
    dispatch(getAllVaultFiles());
    dispatch(getRemainders());
    dispatch(getAllVaultFolders());
    dispatch(getUserTodoList());
    dispatch(getAllHubs('user-hubs'));
    dispatch(getDashboardHubs());
    dispatch(getAllFiles());
    dispatch(getAllContacts());
  }, []);

  useEffect(() => {
    dispatch(getUserFeeds(feedType));
    dispatch(getUnseenFeedCount());
  }, [feedType]);

  const [startDate, setStartDate] = useState(new Date());

  const { theme } = useSelector((state) => state.ThemeReducer);
  const { unseenFeedCount } = useSelector((state) => state.FeedsReducer);

  const handleChange = (date) => {
    setStartDate(date);
  };

  const month = [
    'January',
    'Februaury',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Dashboard" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 xl-100">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-11">
                    <h5>Calendar</h5>
                  </div>
                  <div className="col-1">
                    <ExternalLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(ROUTES.USER_CALENDAR)}
                    />
                  </div>
                </div>
              </div>
              <div className=" card-body cal-date-widget">
                <div className="row">
                  <div
                    className="col-xl-6 col-xs-12 col-md-6 col-sm-6"
                    style={{ height: '300px' }}
                  >
                    <ScrollableSection id="scroll">
                      <div className="cal-info text-center w-100">
                        <div className="d-flex justify-content-around">
                          <h2 style={{ color: 'grey' }}>
                            {new Date(startDate).getDate()}
                          </h2>
                          <div className="mt-2">
                            <div className="b-b-dark pb-2">
                              {month[new Date(startDate).getMonth()]}
                            </div>
                            <div className="pt-2">
                              {new Date(startDate).getFullYear()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <Remainders startDate={startDate} />
                      </div>
                    </ScrollableSection>
                  </div>
                  <div className="col-xl-6 col-xs-12 col-md-6 col-sm-6">
                    <div className="cal-datepicker">
                      <div
                        className="datepicker-here float-sm-right"
                        data-language="en"
                      >
                        <DatePicker
                          className="form-control digits"
                          selected={startDate}
                          onChange={handleChange}
                          inline
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5 className="align-abjust">Activity</h5>
                  </div>
                  <Section
                    className="card-body recent-notification"
                    height="33vh"
                    smheight="290px"
                  >
                    <ScrollableSection id="scroll-activities">
                      <Activities dispatch={dispatch} />
                    </ScrollableSection>
                  </Section>
                </div>
              </div>
              <div className="col-md-6">
                <Weather />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-4">
                    <h5 className="align-abjust">Feed</h5>
                  </div>
                  <div className="col-sm-4 text-center" />
                  <div className="col-sm-4">
                    <div className="pull-right right-header">
                      <FeedFilter>
                        <FeedButton
                          selected={feedType === 'all'}
                          onClick={() => setfeedType('all')}
                        >
                          All
                        </FeedButton>
                        <FeedButton
                          selected={feedType === 'public'}
                          onClick={() => setfeedType('public')}
                        >
                          Public
                        </FeedButton>
                        <FeedButton>
                          <ExternalLink
                            style={{
                              cursor: 'pointer',
                              width: '20px',
                              height: '20px',
                            }}
                            onClick={() => navigate(ROUTES.USER_FEEDS)}
                          />
                        </FeedButton>
                      </FeedFilter>
                    </div>
                  </div>
                </div>
              </div>
              <Section className="card-body" height="76vh" smheight="710px">
                <ScrollableSection id="scroll-feeds">
                  <Feed
                    feedType={feedType}
                    theme={theme}
                    unseenFeedCount={unseenFeedCount}
                  />
                </ScrollableSection>
              </Section>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Hubs</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.USER_HUBS)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '20vh' }}>
                <ScrollableSection>
                  <Hubs />
                </ScrollableSection>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Contacts</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.USER_CONTACTS)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '20vh' }}>
                <ScrollableSection>
                  <Contacts />
                </ScrollableSection>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h5 className="align-abjust">Online Users</h5>
              </div>
              <div className="card-body" style={{ height: '20vh' }}>
                <OnlineUsers />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" style={{ height: '45vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Lists</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.USER_LISTS)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll">
                  <Todos route={ROUTES.USER_LISTS} />
                </ScrollableSection>
              </div>
            </div>
            <div className="card" style={{ height: '45vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Files</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.USER_FILES)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <Files />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-7">
                    <h5 className="align-abjust">Vault</h5>
                  </div>
                  <div className="col-sm-5">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(ROUTES.USER_VAULT)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '85vh' }}>
                <ScrollableSection id="scroll">
                  <Vault theme={theme} />
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDashboard;
