import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: fixed;
  bottom: ${(props) => (props.active ? '-145px' : '25px')};
  right: ${(props) => (props.active ? '-135px' : '35px')};
  z-index: 1000;
`;

export const Menu = styled.div`
  position: relative;
  width: ${(props) => (props.active ? '400px' : '60px')};
  height: ${(props) => (props.active ? '400px' : '60px')};
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .toggle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    transition: transform 1.25s;
    transform: ${(props) => (props.active ? 'rotate(315deg)' : '')};
  }
`;

export const Menuitem = styled.li`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  transform-origin: 180px;
  transition: 0.5s;
  transition-delay: ${(props) => (props.count ? `calc(0.1s * ${props.count})` : '')};
  transform: ${(props) => (props.count && props.active
    ? `rotate(calc(135deg /6 * ${props.count - 0.3}))`
    : 'rotate(0deg) translateX(180px)')};
  svg {
    width: 30px;
    height: 30px;
    transform: ${(props) => (props.count && props.active
    ? `rotate(calc(-135deg /6 * ${props.count - 0.3}))`
    : '')};
  }
  &:hover {
    cursor: pointer;
  }
`;
