import React from 'react';
import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <div className="container">
        <div
          className="d-flex justify-content-between"
          style={{ padding: '30px 0px 30px' }}
        >
          <div>Copyright 2021. All right reserved.</div>
          <div>
            <a
              style={{ textDecoration: 'none', color: 'white' }}
              href="https://www.liviit.com/terms-of-use"
            >
              <span className="terms">Terms of Use</span>
            </a>
            <a
              style={{ textDecoration: 'none', color: 'white' }}
              href="https://www.liviit.com/privacy-policy"
            >
              <span className="terms ml-2">Privacy Policy</span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
