import React from 'react';
import sad from 'src/assets/images/other-images/sad.png';
import { USER_DASHBOARD } from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { themeColor } from 'src/utils/theme';

function Error404() {
  return (
    <div className="page-wrapper">
      <div className="error-wrapper">
        <div className="container">
          <img className="img-100" src={sad} alt="" />
          <div className="error-heading">
            <h2 className="headline" style={{ color: themeColor }}>
              404
            </h2>
          </div>
          <div className="col-md-8 offset-md-2">
            <p className="sub-content">
              The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.
            </p>
          </div>
          <div>
            <Link to={USER_DASHBOARD} className="btn btn-danger-gradien">
              {' '}
              Back To Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error404;
