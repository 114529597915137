import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import {
  getAllAdminHubs,
  loadMoreHubs,
  deleteHub,
  getAdminHubMembers,
} from 'src/redux/hubRedux';
import { InfoSection } from 'src/containers/AdminDashboard/styles';
import {
  ScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';
import { DeleteModal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import Breadcrumb from 'src/components/Breadcrumb';
import LoadMore from 'src/components/LoadMore';
import { defaultGradient } from 'src/utils/theme';

const categories = [
  'all',
  'business',
  'community',
  'class',
  'clubs',
  'family',
  'friends',
  'group',
  'project',
  'school',
  'social',
  'sports',
  'support',
  'team',
  'work',
  'other',
];

function AdminHubs() {
  const [hubLoading, sethubLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState({});
  const [hubCategory, setHubCategory] = useState('all');
  const [hubData, sethubData] = useState([]);

  const { allHubs, hubMembers } = useSelector((state) => state.HubsReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAdminHubs());
    sethubLoading(true);
    return () => sethubData([]);
  }, []);

  useEffect(() => {
    if (Object.keys(allHubs).length > 0 && allHubs.data.length > 0) {
      if (allHubs.meta.pagination.current_page === 1) {
        sethubData(allHubs.data);
        setSelectedHub(allHubs.data[0]);
        dispatch(getAdminHubMembers(allHubs.data[0].id));
        sethubLoading(false);
      } else {
        sethubData([...hubData, ...allHubs.data]);
        sethubLoading(false);
      }
    }
  }, [allHubs]);

  useEffect(() => {
    if (hubCategory === 'all') {
      sethubData([]);
      dispatch(getAllAdminHubs());
    } else {
      sethubData([]);
      dispatch(getAllAdminHubs({ category: hubCategory }));
    }
  }, [hubCategory]);

  const onHubClick = () => {
    setSelectedHub({
      ...selectedHub,
      type: 'Hub',
      username: selectedHub.alias,
    });
    setShowDeleteModal(true);
  };

  const getHub = (hub) => {
    setSelectedHub(hub);
    dispatch(getAdminHubMembers(hub.id));
  };

  const onDelete = (id) => {
    dispatch(deleteHub({ id, page: allHubs.meta.pagination.current_page }));
  };
  const getMoreHubs = () => {
    if (hubCategory === 'all') {
      dispatch(
        loadMoreHubs({ page: allHubs.meta.pagination.current_page + 1 }),
      );
    } else {
      dispatch(
        loadMoreHubs({
          page: allHubs.meta.pagination.current_page + 1,
          category: hubCategory,
        }),
      );
    }
  };

  return (
    <>
      <Breadcrumb parent="Admin" title="Hubs" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Categories</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    {categories.map((category, index) => (
                      <li
                        key={index}
                        className={`list-group-item ${
                          hubCategory === category ? 'active' : null
                        }`}
                        onClick={() => setHubCategory(category)}
                      >
                        <span className="text-capitalize">{category}</span>
                      </li>
                    ))}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>{hubCategory}</h5>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll-admin-hubs">
                  {hubLoading
                  || (processing.status
                    && processing.component === 'admin-hubs') ? (
                      <Loader show />
                    ) : hubData.length ? (
                      <ul className="list-group mt-2">
                        <LoadMore
                          data={hubData}
                          getMore={() => getMoreHubs()}
                          target="scroll-admin-hubs"
                          meta={allHubs.meta.pagination}
                        >
                          {hubData.map((hub, index) => (
                            <li
                              key={index}
                              className={`list-group-item ${
                                selectedHub && selectedHub.id === hub.id
                                  ? 'active'
                                  : null
                              }`}
                              onClick={() => getHub(hub)}
                            >
                              <span>{hub.name}</span>
                            </li>
                          ))}
                        </LoadMore>
                      </ul>
                    ) : (
                      <Loader show />
                    )}
                </ScrollableSection>
                <DeleteModal
                  show={showDeleteModal}
                  close={setShowDeleteModal}
                  obj={selectedHub}
                  deleteFn={onDelete}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-body">
                <ScrollableSection>
                  {processing.status
                  && processing.component === 'admin-hub-members' ? (
                    <Loader show />
                    ) : selectedHub && Object.keys(selectedHub).length ? (
                      <InfoSection className="socialprofile">
                        <div
                          style={{
                            backgroundImage: `linear-gradient(${
                              selectedHub.theme
                            && selectedHub.theme.mobile
                            && selectedHub.theme.mobile.header_color
                                ? selectedHub.theme.mobile.header_color[0]
                                : defaultGradient[0]
                            },${
                              selectedHub.theme
                            && selectedHub.theme.mobile
                            && selectedHub.theme.mobile.header_color
                                ? selectedHub.theme.mobile.header_color[1]
                                : defaultGradient[1]
                            })`,
                            height: '200px',
                            borderRadius: '8px',
                          }}
                        />

                        <div className="avatar">
                          <div className="row w-100">
                            <div className="col-5 pr-0">
                              <div className="w-100 d-flex justify-content-around">
                                <Button
                                  variant="outline"
                                  value={
                                  selectedHub.is_public ? 'Public' : 'Private'
                                }
                                  padding="1px 8px"
                                  margin="0px 0px 0px 8px"
                                />
                                <Button
                                  variant="outline"
                                  value={
                                  selectedHub.classification[0].toUpperCase()
                                  + selectedHub.classification.slice(
                                    1,
                                    selectedHub.classification.length,
                                  )
                                }
                                  padding="1px 8px"
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <img
                                src={
                                selectedHub.original_avatar
                                  ? selectedHub.original_avatar
                                  : ProfilePictureIcon
                              }
                                alt="#"
                                onError={addDefaultSrc}
                                className="bg-white"
                              />
                            </div>
                            <div className="col-5 pr-0">
                              <div className="w-100 d-flex justify-content-around">
                                <Button
                                  variant="outline"
                                  value={
                                  selectedHub.category[0].toUpperCase()
                                  + selectedHub.category.slice(
                                    1,
                                    selectedHub.category.length,
                                  )
                                }
                                  padding="1px 8px"
                                />
                                <Button
                                  variant="outline"
                                  value="Delete"
                                  padding="1px 8px"
                                  onClick={() => onHubClick(selectedHub)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-around">
                          <div>
                            <h2>{selectedHub.name}</h2>
                            <h6 className="text-center">
                              (@
                              {selectedHub.alias}
                              )
                            </h6>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row">
                            {hubMembers.map((person, index) => {
                              if (person.hub_role === 'creator') {
                                return (
                                  <div className="col-12" key={index}>
                                    <div className="card">
                                      <div className="card-header">
                                        <div className="row">
                                          <div className="col-sm-6 d-flex">
                                            <ProfilePic>
                                              <img
                                                className="rounded-circle"
                                                src={
                                                person.original_avatar
                                                  ? person.original_avatar
                                                  : ProfilePictureIcon
                                              }
                                                alt="#"
                                                width="100%"
                                                height="100%"
                                                onError={addDefaultSrc}
                                              />
                                            </ProfilePic>
                                            <div className="ml-2">
                                              <h6>{person.name}</h6>
                                              <div>
                                                @
                                                {person.username}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6">
                                            <div className="pull-right right-header">
                                              <h6>
                                                {person.hub_role.toUpperCase()}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                            {hubMembers.map((person, index) => {
                              if (person.hub_role === 'admin') {
                                return (
                                  <div className="col-12" key={index}>
                                    <div className="card">
                                      <div className="card-header">
                                        <div className="row">
                                          <div className="col-sm-6 d-flex">
                                            <ProfilePic>
                                              <img
                                                className="rounded-circle"
                                                src={
                                                person.original_avatar
                                                  ? person.original_avatar
                                                  : ProfilePictureIcon
                                              }
                                                alt="#"
                                                width="100%"
                                                height="100%"
                                                onError={addDefaultSrc}
                                              />
                                            </ProfilePic>
                                            <div className="ml-2">
                                              <h6>{person.name}</h6>
                                              <div>
                                                @
                                                {person.username}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6">
                                            <div className="pull-right right-header">
                                              <h6>
                                                {person.hub_role.toUpperCase()}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                            {hubMembers.map((person, index) => {
                              if (person.hub_role === 'member') {
                                return (
                                  <div className="col-12" key={index}>
                                    <div className="card">
                                      <div className="card-header">
                                        <div className="row">
                                          <div className="col-sm-6 d-flex">
                                            <ProfilePic>
                                              <img
                                                className="rounded-circle"
                                                src={
                                                person.original_avatar
                                                  ? person.original_avatar
                                                  : ProfilePictureIcon
                                              }
                                                alt="#"
                                                width="100%"
                                                height="100%"
                                                onError={addDefaultSrc}
                                              />
                                            </ProfilePic>
                                            <div className="ml-2">
                                              <h6>{person.name}</h6>
                                              <div>
                                                @
                                                {person.username}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6">
                                            <div className="pull-right right-header">
                                              <h6>
                                                {person.hub_role.toUpperCase()}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                            {hubMembers.map((person, index) => {
                              if (person.hub_role === 'viewer') {
                                return (
                                  <div className="col-12" key={index}>
                                    <div className="card">
                                      <div className="card-header">
                                        <div className="row">
                                          <div className="col-sm-6 d-flex">
                                            <ProfilePic>
                                              <img
                                                className="rounded-circle"
                                                src={
                                                person.original_avatar
                                                  ? person.original_avatar
                                                  : ProfilePictureIcon
                                              }
                                                alt="#"
                                                width="100%"
                                                height="100%"
                                                onError={addDefaultSrc}
                                              />
                                            </ProfilePic>
                                            <div className="ml-2">
                                              <h6>{person.name}</h6>
                                              <div>
                                                @
                                                {person.username}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6">
                                            <div className="pull-right right-header">
                                              <h6>
                                                {person.hub_role.toUpperCase()}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                        <div className="customers social-group">
                          <ul>
                            {hubMembers.map((person, index) => (
                              <li className="d-inline-block" key={index}>
                                <img
                                  className="group-image rounded-circle"
                                  src={
                                    person.original_avatar
                                      ? person.original_avatar
                                      : ProfilePictureIcon
                                  }
                                  alt="#"
                                  id={`tool${person.id}`}
                                  onError={addDefaultSrc}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`tool${person.id}`}
                                >
                                  {person.name}
                                </UncontrolledTooltip>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </InfoSection>
                    ) : (
                      <Loader show />
                    )}
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHubs;
