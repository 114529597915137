import { takeLatest, put, call } from 'redux-saga/effects';
import {
  fetchPlans,
  postSubscriptionPlan,
  postUpgradeSubscriptionPlan,
} from 'src/api/payment';
import { toast } from 'react-toastify';
import {
  processSubscriptionPlans,
  savePaymentPlans,
} from 'src/redux/subscriptionRedux';
import { setError } from 'src/redux/serviceRedux';
import { fetchUserDetails } from 'src/api/profile';
import { saveUserDetails } from 'src/redux/profileRedux';

function* getPaymentPlans() {
  try {
    yield put(
      processSubscriptionPlans({
        status: true,
        component: 'subscription-plans',
      }),
    );
    const data = yield call(fetchPlans);
    yield put(savePaymentPlans(data));
  } catch (error) {
    toast.error('Something went wrong! Please try again later!');
    yield put(setError(true));
  } finally {
    yield put(
      processSubscriptionPlans({
        status: false,
        component: 'subscription-plans',
      }),
    );
    yield put(setError(false));
  }
}

function* upgradeSubscriptionPlan(action) {
  try {
    yield put(
      processSubscriptionPlans({
        status: true,
        component: 'upgrade-subscription',
      }),
    );
    yield call(postUpgradeSubscriptionPlan, action.payload.data);
    const user = yield call(fetchUserDetails);
    const planId = action.payload.data.plan_id;
    yield put(saveUserDetails(user));
    action.payload.cb(true);
    toast.success(planId === 'free' ? 'Switched to free plan successfully!' : 'Plan upgraded successfully!');
  } catch (error) {
    yield put(setError(true));
    action.payload.cb(false);
    toast.error('Something went wrong! Please try again later!');
  } finally {
    yield put(
      processSubscriptionPlans({
        status: false,
        component: 'upgrade-subscription',
      }),
    );
    yield put(setError(false));
  }
}

function* subscribeToPlan(action) {
  try {
    yield put(
      processSubscriptionPlans({ status: true, component: 'plan-subscription' }),
    );
    yield call(postSubscriptionPlan, action.payload.data);
    const user = yield call(fetchUserDetails);
    yield put(saveUserDetails(user));
    action.payload.cb(true);
    toast.success('Plan subscribed successfully!');
  } catch (error) {
    yield put(setError(true));
    const errorMessage = error?.response?.data?.error?.[0];
    toast.error(errorMessage ?? 'Something went wrong! Please try again later');
    action.payload.cb(false);
  } finally {
    yield put(
      processSubscriptionPlans({
        status: false,
        component: 'plan-subscription',
      }),
    );
    yield put(setError(false));
  }
}

export function* watchSubscriptionSaga() {
  yield takeLatest('GET_PAYMENT_PLANS', getPaymentPlans);
  yield takeLatest('SUBSCRIBE_TO_PLAN', subscribeToPlan);
  yield takeLatest('UPGRADE_SUBSCRIPTION_PLAN', upgradeSubscriptionPlan);
}
