import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

const Heading = styled.h2`
  font-weight: bolder;
`;
const Header = styled.span`
  font-size: 18px;
  color: #787878;
`;
const Name = styled.h1`
  color: ${themeColor};
  font-family: system-ui;
  font-weight: 700;
  margin-top: 150px;
  font-size: xxx-large;
`;

export { Heading, Header, Name };
