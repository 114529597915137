/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  createRef, useState, useEffect, useRef,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CometChat } from '@cometchat-pro/chat';
import { actionHandler } from 'src/utils/helper';
import { getUserDetails } from 'src/redux/profileRedux';
import { requestLogout } from 'src/redux/authRedux';
import { Buffer } from 'buffer';
import {
  CometChatIncomingCall,
  CometChatIncomingDirectCall,
  CometChatOutgoingCall,
  CometChatOutgoingDirectCall,
} from 'src/CometChatWorkspace/src';
import { CometChatContextProvider } from 'src/CometChatWorkspace/src/util/CometChatContext';
import {
  loginNapsterRequest,
  saveCurrentTrack,
} from 'src/redux/audioPlayerRedux';
import {
  AUTH_KEY,
  NAPSTER_API_KEY,
  NAPSTER_API_SECRET,
} from 'src/constants/creds';
import RadialMenu from 'src/components/RadialMenu';
import RightSidebar from './common/sidebar-component/right-sidebar';
import ThemeCustomizer from './common/sidebar-component/theme-customizer';
import Loader from './LoadingOverlay';
import Sidebar from './common/sidebar-component/sidebar';
import Header from './common/header-component/header';
import 'react-toastify/dist/ReactToastify.css';

export const outgoingCallRef = createRef();
export const outgoingDirectCallRef = createRef();

function AppLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [adminState, setadminState] = useState(
    location.pathname.includes('/admin'),
  );
  const [hubState, setHubState] = useState(location.pathname.includes('/hub'));
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab1, setActiveTab1] = useState('1');
  const { user } = useSelector((state) => state.ProfileReducer);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [showRadialMenu, setShowRadialMenu] = useState(false);
  const [isCometLoggedIn, setCometLoggedIn] = useState(false);
  const [next, setNext] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const {
    accessToken,
    refreshToken,
    currentTrack,
    queue,
  } = useSelector(
    (state) => state.AudioPlayerReducer,
  );

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) dispatch(saveCurrentTrack({ ref }));
  }, [ref]);
  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector('.right-sidebar').classList.add('show');
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector('.right-sidebar').classList.remove('show');
    }
  }

  useEffect(() => {
    if (props.coords) {
      if (!localStorage.getItem('location-coords')) {
        localStorage.setItem(
          'location-coords',
          JSON.stringify({
            latitude: props.coords.latitude,
            longitude: props.coords.longitude,
          }),
        );
      }
    }
  }, [props.coords]);

  useEffect(() => {
    if (Object.keys(user).length) {
      CometChat.login(user.id, AUTH_KEY).then(
        (user) => {
          setCometLoggedIn(true);
          console.log('CometChat:: Login Successful', { user });
        },
        (error) => {
          console.log('Login failed with exception:', { error });
        },
      );

      if (localStorage.getItem('napster_token') === null) {
        const napsterToken = Buffer.from(
          `${NAPSTER_API_KEY}:${NAPSTER_API_SECRET}`,
          'utf8',
        ).toString('base64');
        if (localStorage.getItem('napster.member.accessToken') === null) {
          dispatch(loginNapsterRequest(napsterToken));
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (accessToken || localStorage.getItem('napster.member.accessToken')) {
      window.Napster.init({
        consumerKey: NAPSTER_API_KEY,
        isHTML5Compatible: true,
      });
      window.Napster.player.on('ready', () => {
        window.Napster.member.set({
          accessToken:
            accessToken || localStorage.getItem('napster.member.accessToken'),
          refreshToken:
            refreshToken || localStorage.getItem('napster.member.refreshToken'),
        });
      });
      window.Napster.player.on('playtimer', (e) => {
        dispatch(
          saveCurrentTrack({
            currentTime: e.data.currentTime,
            totalTime: e.data.totalTime,
          }),
        );
      });
      window.Napster.player.on('playevent', (e) => {
        if (e.data.code === 'PlayComplete') {
          setNext(true);
        } else {
          dispatch(
            saveCurrentTrack({
              isPlaying: e.data.playing,
            }),
          );
        }
      });
      window.Napster.player.on('playsessionexpired', () => {
        setSessionExpired(true);
      });
      window.Napster.player.on('error', (e) => {
        console.log(`Error --> ${e}`);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (Object.keys(user).length === 0) dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    setHubState(location.pathname.includes('/hub'));
  }, [hubState, navigate, user]);

  useEffect(() => {
    if (next) {
      const index = queue.map((q) => q.id).indexOf(currentTrack.id);
      if (index !== queue.length - 1) {
        if (queue[index + 1].type === 'track') {
          ref.current.pause();
          window.Napster.player.play(queue[index + 1].id);
        } else if (queue[index + 1].provider === 'napster') {
          ref.current.pause();
          window.Napster.player.play(queue[index + 1].metadata[0].id);
        } else window.Napster.player.pause();
        dispatch(
          saveCurrentTrack({
            track: currentTrack.playlist
              ? {
                ...queue[index + 1],
                playlist: currentTrack.playlist,
                hub_id: currentTrack.hub_id,
              }
              : queue[index + 1],
          }),
        );
      } else {
        if (queue[0].type === 'track') {
          ref.current.pause();
          window.Napster.player.play(queue[0].id);
        } else if (queue[0].provider === 'napster') {
          ref.current.pause();
          window.Napster.player.play(queue[0].metadata[0].id);
        } else window.Napster.player.pause();
        dispatch(
          saveCurrentTrack({
            track: currentTrack.playlist
              ? {
                ...queue[0],
                playlist: currentTrack.playlist,
                hub_id: currentTrack.hub_id,
              }
              : queue[0],
          }),
        );
      }
      setNext(false);
    }
    if (sessionExpired) {
      window.Napster.player.play(
        currentTrack.type === 'track'
          ? currentTrack.id
          : currentTrack.metadata[0].id,
      );
      setSessionExpired(false);
    }
  }, [next, sessionExpired]);

  const { processing, error } = useSelector((state) => state.ServiceReducer);

  const logOut = async () => {
    await CometChat.logout().then(
      () => {
        console.log('Logout completed successfully');
      },
      (error) => {
        console.log('Logout failed with exception:', { error });
      },
    );
    dispatch(requestLogout(navigate));

    await setShowLoader(true);
  };

  const notify = () => {
    toast.error(`${error.message ? error.message : 'Error'}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onLoadedMetadata = () => {
    dispatch(
      saveCurrentTrack({
        totalTime: Math.floor(ref.current.duration),
      }),
    );
    ref.current.play();
  };

  const onTimeUpdate = () => {
    if (ref.current.ended) {
      setNext(true);
    }
    dispatch(
      saveCurrentTrack({
        currentTime: ref.current.currentTime,
        totalTime: Math.floor(ref.current.duration),
        isPlaying: !ref.current.paused,
      }),
    );
  };

  const displayLoader = () => {
    setShowLoader(true);
  };

  return (
    <Loader show={processing && showLoader}>
      <div className="page-wrapper">
        {!hubState
        && !adminState
        && !localStorage.getItem('impersonate-user') ? (
          <RadialMenu active={showRadialMenu} setActive={setShowRadialMenu} />
          ) : null}
        <div className="page-body-wrapper">
          <Header
            logOut={logOut}
            adminState={adminState}
            setadminState={setadminState}
            hubState={hubState}
            displayLoader={displayLoader}
            setHubState={setHubState}
            {...props}
            setActiveTab1={setActiveTab1}
            isNewMessage={isNewMessage}
            showRightSidebar={showRightSidebar}
          />
          <Sidebar adminState={adminState} hubState={hubState} {...props} />
          {isCometLoggedIn ? (
            <RightSidebar
              navigate={navigate}
              setIsNewMessage={setIsNewMessage}
              showRightSidebar={showRightSidebar}
            />
          ) : null}

          <div className="page-body">
            <CometChatContextProvider>
              <CometChatIncomingCall actionGenerated={() => {}} />
              <CometChatIncomingDirectCall actionGenerated={() => {}} />
              <CometChatOutgoingCall
                ref={(el) => outgoingCallRef === el}
                actionGenerated={(action, messages) => actionHandler(action, messages)}
              />
              <CometChatOutgoingDirectCall
                ref={(el) => outgoingDirectCallRef === el}
                actionGenerated={(action, messages) => actionHandler(action, messages)}
              />
            </CometChatContextProvider>
            {' '}
            {props.children}
          </div>
          {hubState ? null : <ThemeCustomizer activeTab1={activeTab1} />}
          <audio
            src={currentTrack?.path}
            ref={ref}
            onLoadedMetadata={onLoadedMetadata}
            onTimeUpdate={onTimeUpdate}
          />
        </div>
      </div>
      {error && error.status && error.message && notify()}
      <ToastContainer />
    </Loader>
  );
}
export default AppLayout;
