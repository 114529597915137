// actions
export const getUserDetails = (payload) => ({
  type: 'GET_USER_DETAILS',
  payload,
});

export const saveUserDetails = (payload) => ({
  type: 'SAVE_USER_DETAILS',
  payload,
});

export const saveContacts = (payload) => ({
  type: 'SAVE_CONTACTS',
  payload,
});

export const saveLinkedAccounts = (payload) => ({
  type: 'SAVE_LINKED_ACCOUNTS',
  payload,
});

export const saveUserActivities = (payload) => ({
  type: 'SAVE_ACTIVITIES',
  payload,
});

export const addContact = (payload) => ({
  type: 'ADD_CONTACT',
  payload,
});

export const toggleContact = (payload) => ({
  type: 'TOGGLE_CONTACT',
  payload,
});

export const getContact = (payload) => ({
  type: 'GET_CONTACTS',
  payload,
});
export const changeContact = (payload) => ({
  type: 'UPDATE_CONTACT',
  payload,
});

export const getNotifications = () => ({
  type: 'GET_NOTIFICATIONS',
  payload: 'notification',
});
export const saveNotifications = (payload) => ({
  type: 'SAVE_NOTIFICATIONS',
  payload,
});
export const checkNotification = (payload) => ({
  type: 'CHECK_NOTIFICATION',
  payload,
});

export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_NOTIFICATION',
  payload,
});

export const loadMoreContacts = (payload) => ({
  type: 'LOAD_MORE_CONTACTS',
  payload,
});

export const updateUserDetails = (payload) => ({
  type: 'UPDATE_USER',
  payload,
});

export const getAllContacts = (payload) => ({
  type: 'GET_ALL_CONTACTS',
  payload,
});

export const saveAllContacts = (payload) => ({
  type: 'SAVE_ALL_CONTACTS',
  payload,
});

export const toggleContactBlock = (payload) => ({
  type: 'TOGGLE_CONTACT_BLOCK',
  payload,
});

export const saveToggledContact = (payload) => ({
  type: 'SAVE_TOGGLED_CONTACT',
  payload,
});

// store
const initialState = {
  user: {},
  contacts: [],
  toggledContact: {},
  linkedAccounts: [],
  activities: [],
  notifications: [],
  meta: {},
  contactsMeta: {},
  allContacts: [],
};

// profileReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_USER_DETAILS':
      return { ...state, user: { ...action.payload, theme: null } }; // ignore theme configuration
    case 'SAVE_CONTACTS':
      return {
        ...state,
        contacts: action.payload.data,
        contactsMeta: action.payload.meta.pagination,
      };
    case 'SAVE_ALL_CONTACTS':
      return {
        ...state,
        allContacts: action.payload,
      };
    case 'SAVE_LINKED_ACCOUNTS':
      return { ...state, linkedAccounts: action.payload };
    case 'SAVE_ACTIVITIES':
      return { ...state, activities: action.payload };
    case 'SAVE_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload.data,
        meta: action.payload.meta.pagination,
      };
    case 'SAVE_TOGGLED_CONTACT':
      return { ...state, toggledContact: action.payload.data[0] };
    default:
      return { ...state };
  }
};
