import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const SearchText = styled.div`
  color: ${themeColor};
  font-size: 12px;
`;

export const HubName = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
export const HubDesc = styled.div`
  font-size: 10px;
`;

export const Hubtitle = styled.div`
  background: #f6f6f6;
`;

export const Contact = styled.div`
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

export const Members = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

export const Member = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  text-decoration: none;
`;
export const Cell = styled.td`
  padding: 10px 6px;
`;
export const MembersList = styled.div`
  max-width: 210px;
  @media (max-width: 1350px) {
    max-width: 180px;
  }
  @media (max-width: 1300px) {
    max-width: 160px;
  }
  @media (max-width: 1200px) {
    max-width: 100px;
  }
`;

export const InfoSection = styled.div`
  position: relative;
  .avatar {
    margin-top: -50px;
    display: flex;
    justify-content: space-around;
  }
  .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid whitesmoke;
  }
  .group-image {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 20%);
    transition: 0.5s;
  }
  .customers {
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .customers li {
    border: none;
    background-color: none;
  }
  .customers li:hover {
    background-color: transparent;
  }
`;

export const InfoSectionNetwork = styled.div`
  position: relative;
  .avatar {
    margin-top: -50px;
    display: flex;
    justify-content: space-around;
  }
  .avatar button {
    border: none;
    color: #555;
  }
  .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid whitesmoke;
  }
  .group-image {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 20%);
    transition: 0.5s;
  }
  .customers {
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .customers li {
    border: none;
    background-color: none;
  }
  .customers li:hover {
    background-color: transparent;
  }
`;
