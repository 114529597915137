import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalUpload } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import TextField from 'src/components/InputField';
import { ScrollableSection } from 'src/components/StyledComponents';
import Loader from 'src/components/Loader';

export function TrackModal(props) {
  const [allSongs, setAllSongs] = useState([]);
  const [addedSongs, setAddedSongs] = useState([]);

  useEffect(() => {
    setAllSongs(props.tracks);
  }, [props.tracks]);
  const add = (data) => {
    setAddedSongs([...addedSongs, allSongs.filter((m) => m.id === data.id)[0]]);
    setAllSongs(allSongs.filter((m) => m.id !== data.id));
  };

  const remove = (data) => {
    setAddedSongs(addedSongs.filter((m) => m.id !== data.id));
    setAllSongs([...allSongs, data]);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setAddedSongs([]);
        props.close(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="card" style={{ height: '60vh' }}>
                <div className="card-header">
                  <TextField
                    type="search"
                    placeholder="Search..."
                    borderRadius="30px"
                    fontSize="16px"
                    padding="7px 10px"
                    onKeyUp={props.search}
                  />
                </div>
                <div className="card-body">
                  <ScrollableSection>
                    {props.loading.status
                    && props.loading.component === 'music' ? (
                      <Loader show />
                      ) : allSongs.length ? (
                        <ul className="list-group mt-2">
                          {allSongs.map((track) => (
                            <li key={track.id} className="list-group-item">
                              <div className="w-100 d-flex justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      src={`https://api.napster.com/imageserver/v2/albums/${track.albumId}/images/70x70.jpg`}
                                      alt="#track art"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <h6>{track.name}</h6>
                                    <p>{track.artistName}</p>
                                  </div>
                                </div>

                                <div>
                                  <Button
                                    variant="outlined"
                                    value="Add"
                                    onClick={() => add(track)}
                                    padding="0px 10px"
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="text-center">
                          <h2>No Music here</h2>
                        </div>
                      )}
                  </ScrollableSection>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card" style={{ height: '60vh' }}>
                <div className="card-body">
                  <ScrollableSection>
                    {addedSongs.length ? (
                      <ul className="list-group mt-2">
                        {addedSongs.map((track) => (
                          <li key={track.id} className="list-group-item">
                            <div className="w-100 d-flex justify-content-between">
                              <div>
                                <img
                                  src={`https://api.napster.com/imageserver/v2/albums/${track.albumId}/images/70x70.jpg`}
                                  alt="#track art"
                                />
                              </div>
                              <div>
                                <h6>{track.name}</h6>
                                <p>{track.artistName}</p>
                              </div>
                              <div>
                                <Button
                                  variant="outlined"
                                  value="Remove"
                                  onClick={() => remove(track)}
                                  padding="0px 10px"
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </ScrollableSection>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              variant="solid"
              value="Save"
              onClick={() => {
                props.uploadFile('napster', addedSongs);
                props.close(false);
                setAddedSongs([]);
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function SelectModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [trackModalShow, setTrackModalShow] = useState(false);
  return (
    <>
      <Modal
        show={props.modalShow}
        onHide={() => props.modalClose(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none' }} closeButton>
          <h4 className="font-weight-bolder">Select Option</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body">
            <div className="d-flex justify-content-around py-4">
              <div>
                <Button
                  variant="solid"
                  value="Upload from Device"
                  onClick={() => {
                    setModalShow(true);
                    props.modalClose(false);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="solid"
                  value="Select Track"
                  onClick={() => {
                    setTrackModalShow(true);
                    props.modalClose(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalUpload
        show={modalShow}
        close={setModalShow}
        size={1}
        addFile={(file) => props.uploadFile('local', file)}
        title="Select a file from your device"
        accept="audio/*"
      />
      <TrackModal show={trackModalShow} close={setTrackModalShow} {...props} />
    </>
  );
}

export function DeleteModal(props) {
  return (
    <Modal
      show={props.deleteModal}
      onHide={() => props.setDeleteModal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <h4 className="font-weight-bolder">
          Delete
          {' '}
          {props.type === 'track' ? props.track.name : 'Playlist'}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <div className="d-flex justify-content-around py-2">
            <div>
              <Button
                variant="solid"
                value={
                  props.type === 'track' ? 'Delete Track' : 'Delete Playlist'
                }
                onClick={() => {
                  props.delete();
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
