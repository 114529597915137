import { call, put, takeLatest } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import {
  authenticateNapster,
  fetchTrendingTracks,
  fetchSearchedTracks,
  fetchPlaylists,
  postPlaylist,
  fetchPlaylistTracks,
  postTrackInPlaylist,
  updatePlaylist,
  fetchMyMusic,
  deleteTrack,
  deletePlaylist,
  reorderPlaylistTracks,
  sharePlaylist,
} from 'src/api/audioPlayer';
import {
  loginNapsterSuccess,
  processTracks,
  saveTracks,
  savePlaylists,
  savePlayListTracks,
  saveMyTracks,
} from 'src/redux/audioPlayerRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* checkNapsterLogin(action) {
  try {
    yield put(processing({ status: true, component: 'napster' }));
    const response = yield call(authenticateNapster, action.payload);
    yield put(loginNapsterSuccess(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'napster' }));
    yield put(setError(false));
  }
}

function* getTopTracks() {
  try {
    yield put(processTracks({ status: true, component: 'music' }));
    const response = yield call(fetchTrendingTracks);
    yield put(saveTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'music' }));
    yield put(setError(false));
  }
}

function* getSearchedTracks(action) {
  try {
    yield put(processTracks({ status: true, component: 'music' }));
    const response = yield call(fetchSearchedTracks, action.payload);
    yield put(saveTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'music' }));
    yield put(setError(false));
  }
}

function* getPlaylists(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist' }));
    const response = yield call(fetchPlaylists, action.payload);
    yield put(savePlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist' }));
    yield put(setError(false));
  }
}

function* addPlaylist(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist' }));
    yield call(postPlaylist, action.payload);
    const response = yield call(
      fetchPlaylists,
      action.payload.is_global ? 'admin' : '',
    );
    yield put(savePlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist' }));
    yield put(setError(false));
  }
}

function* removePlaylist(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist' }));
    yield call(
      deletePlaylist,
      action.payload.is_global ? action.payload.id : action.payload,
    );
    const response = yield call(
      fetchPlaylists,
      action.payload.is_global ? 'admin' : '',
    );
    yield put(savePlaylists(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist' }));
    yield put(setError(false));
  }
}

function* playlistShare(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist' }));
    yield call(sharePlaylist, action.payload);
    Swal.fire('Playlist Shared Successfully.');
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist' }));
    yield put(setError(false));
  }
}

function* changePlaylist(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist-tracks' }));
    yield call(updatePlaylist, action.payload);
    const response = yield call(fetchPlaylistTracks, action.payload[0]);
    yield put(savePlayListTracks(response));
    const response2 = yield call(
      fetchPlaylists,
      action.payload[2] === 'admin' ? 'admin' : '',
    );
    yield put(savePlaylists(response2));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist-tracks' }));
    yield put(setError(false));
  }
}

function* getPlaylistTracks(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist-tracks' }));
    const response = yield call(fetchPlaylistTracks, action.payload);
    yield put(savePlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist-tracks' }));
    yield put(setError(false));
  }
}

function* addTrackInPlaylist(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist-tracks' }));
    yield call(postTrackInPlaylist, action.payload[1]);
    const response = yield call(fetchPlaylistTracks, action.payload[0]);
    yield put(savePlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist-tracks' }));
    yield put(setError(false));
  }
}

function* getMyTracks() {
  try {
    yield put(processTracks({ status: true, component: 'music' }));
    const response = yield call(fetchMyMusic);
    yield put(saveMyTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'music' }));
    yield put(setError(false));
  }
}

function* removeTrack(action) {
  try {
    yield put(processTracks({ status: true, component: 'music' }));
    yield call(deleteTrack, action.payload);
    const response = yield call(fetchMyMusic);
    yield put(saveMyTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'music' }));
    yield put(setError(false));
  }
}

function* reorderTracks(action) {
  try {
    yield put(processTracks({ status: true, component: 'playlist-tracks' }));
    yield call(reorderPlaylistTracks, action.payload);
    const response = yield call(fetchPlaylistTracks, action.payload[0]);
    yield put(savePlayListTracks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processTracks({ status: false, component: 'playlist-tracks' }));
    yield put(setError(false));
  }
}

export function* watchAudioPlayerSaga() {
  yield takeLatest('LOGIN_NAPSTER_REQUEST', checkNapsterLogin);
  yield takeLatest('GET_TOP_TRACKS', getTopTracks);
  yield takeLatest('GET_SEARCHED_TRACKS', getSearchedTracks);
  yield takeLatest('GET_PLAYLISTS', getPlaylists);
  yield takeLatest('CREATE_PLAYLIST', addPlaylist);
  yield takeLatest('UPDATE_PLAYLIST', changePlaylist);
  yield takeLatest('GET_PLAYLIST_TRACKS', getPlaylistTracks);
  yield takeLatest('ADD_PLAYLIST_TRACKS', addTrackInPlaylist);
  yield takeLatest('GET_MY_TRACKS', getMyTracks);
  yield takeLatest('DELETE_TRACK', removeTrack);
  yield takeLatest('DELETE_PLAYLIST', removePlaylist);
  yield takeLatest('REORDER_TRACKS', reorderTracks);
  yield takeLatest('SHARE_PLAYLIST', playlistShare);
}
