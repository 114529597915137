import React from 'react';
import Navbar from 'src/components/Navbar';
import Footer from 'src/components/Footer';
import BannerIcon from 'src/assets/images/livit/contact.png';
import InputField from 'src/components/InputField';
import { Button } from 'src/components/Button';
import {
  Heading, Name, TextAreaField, Header,
} from './styles';

function ContactUs() {
  return (
    <>
      <Navbar />
      <div className="bg-white">
        <div className="row">
          <div className="col-sm-6 text-center">
            <Name>Contact Us</Name>
          </div>
          <div className="col-sm-6">
            <img
              src={BannerIcon}
              alt="banner"
              width="85%"
              style={{ margin: '30px 0px' }}
            />
          </div>
        </div>
        <div className="container text-center">
          <Heading>Connect With Us</Heading>
          <Header>Drop us a message and Let us know how we can help</Header>
          <Header>We will get back to you as soon as possible.</Header>
          <form className="mb-4">
            <div className="row mt-5" style={{ padding: '0px 130px' }}>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <InputField
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <InputField
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <InputField
                    type="email"
                    className="form-control form-control-lg"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <InputField
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <TextAreaField
                    className="form-control form-control-lg"
                    rows="4"
                    placeholder="Message"
                  />
                </div>
              </div>
            </div>
            <div className="float-right" style={{ paddingRight: '130px' }}>
              <Button value="Submit" variant="outline" />
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
