import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Main = styled.div`
  .select {
    cursor: pointer;
  }
  .dropdown-menu {
    border: 1px solid ${themeColor} !important;
    background-color: white !important;
    padding: 0px !important;
  }
  .item a {
    padding: 0px 5px !important;
    border-bottom: 1px solid grey;
  }
`;
