import { CometChat } from '@cometchat-pro/chat';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import * as enums from 'src/CometChatWorkspace/src/util/enums';
import { outgoingCallRef, outgoingDirectCallRef } from 'src/components/app';

export const goFull = () => {
  if (
    (document.fullScreenElement && document.fullScreenElement !== null)
    || (!document.mozFullScreen && !document.webkitIsFullScreen)
  ) {
    if (document.documentElement.requestFullScreen) {
      document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen(
        Element.ALLOW_KEYBOARD_INPUT,
      );
    }
  } else if (document.cancelFullScreen) {
    document.cancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
};

export const addDefaultSrc = (e) => {
  e.target.src = ProfilePictureIcon;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const newSequence = result.map((r) => r.id);
  return newSequence;
};

const audioCall = (callObject) => {
  if (callObject.type === CometChat.RECEIVER_TYPE.GROUP) {
    const sessionID = callObject.type === CometChat.ACTION_TYPE.TYPE_GROUP
      ? callObject.id
      : null;
    outgoingDirectCallRef.startCall(sessionID, 'audio');

    return;
  }
  const call = new CometChat.Call(
    callObject.id,
    CometChat.CALL_TYPE.AUDIO,
    callObject.type,
  );
  CometChat.initiateCall(call)
    .then((outgoingCall) => {
      outgoingCallRef.startCall(outgoingCall);
    })
    .catch((error) => alert(`${error}here`));
};

const videoCall = (callObject) => {
  /*
  * Direct calling for groups
  */
  if (callObject.type === CometChat.RECEIVER_TYPE.GROUP) {
    const sessionID = callObject.type === CometChat.ACTION_TYPE.TYPE_GROUP
      ? callObject.id
      : null;
    outgoingDirectCallRef.startCall(sessionID, 'video');

    return;
  }

  /*
  * Default calling for one-on-one
  */

  const call = new CometChat.Call(
    callObject.id,
    CometChat.CALL_TYPE.VIDEO,
    callObject.type,
  );
  CometChat.initiateCall(call)
    .then((outgoingCall) => {
      outgoingCallRef.startCall(outgoingCall);
    })
    .catch(() => alert('SOMETHING_WENT_WRONG'));
};

export const actionHandler = (action, callObject) => {
  switch (action) {
    case enums.INCOMING_CALL_RECEIVED:
    case enums.INCOMING_CALL_CANCELLED:
    case enums.OUTGOING_CALL_ACCEPTED:
    case enums.OUTGOING_CALL_REJECTED:
      break;

    case enums.ACTIONS.INITIATE_AUDIO_CALL:
      audioCall(callObject);
      break;
    case enums.ACTIONS.INITIATE_VIDEO_CALL:
      videoCall(callObject);
      break;

    case enums.ACTIONS.OUTGOING_CALL_ACCEPTED:
    case enums.ACTIONS.USER_JOINED_CALL:
    case enums.ACTIONS.USER_LEFT_CALL:
    case enums.ACTIONS.OUTGOING_CALL_ENDED:
    case enums.ACTIONS.OUTGOING_CALL_REJECTED:
    case enums.ACTIONS.OUTGOING_CALL_CANCELLED:
    case enums.ACTIONS.INCOMING_CALL_ACCEPTED:
    case enums.ACTIONS.INCOMING_CALL_ENDED:
    case enums.ACTIONS.INCOMING_CALL_REJECTED:
    case enums.ACTIONS.DIRECT_CALL_ENDED:
    case enums.ACTIONS.DIRECT_CALL_ERROR:
      break;
    case enums.ACTIONS.ERROR:
      break;
    case enums.ACTIONS.INFO:
      break;
    default:
      break;
  }
};

export const getHubCategories = (hubs) => {
  if (hubs.length === 0) return [];
  const categories = ['Favourites', 'All'];
  hubs.forEach((hub) => {
    if (!categories.includes(hub.category)) categories.push(hub.category);
  });

  return categories;
};

export const getFilteredHubs = (hubs, category) => {
  if (hubs.length === 0) return [];
  let filteredHubs = [];
  if (category === 'All') filteredHubs = hubs;
  else if (category === 'Favourites') {
    hubs.forEach((hub) => {
      if (hub.is_favourite) filteredHubs.push(hub);
    });
  } else {
    hubs.forEach((hub) => {
      if (hub.category.toLowerCase() === category.toLowerCase()) filteredHubs.push(hub);
    });
  }
  return filteredHubs;
};

export const getNetworkCategories = (networks) => {
  if (networks.length === 0) return [];
  const categories = ['All'];
  networks.forEach((network) => {
    if (!categories.includes(network.category)) categories.push(network.category);
  });

  return categories;
};

export const getFilteredNetworks = (networks, category) => {
  if (networks.length === 0) return [];
  let filteredNetworks = [];
  if (category === 'All') filteredNetworks = networks;
  else {
    networks.forEach((network) => {
      if (network.category.toLowerCase() === category.toLowerCase()) filteredNetworks.push(network);
    });
  }
  return filteredNetworks;
};

export const getFormatedAdminLogs = (log) => {
  if (log.type === 'resource_created') {
    if (log.sub_type === 'user') {
      return {
        name: `@${log.resource.alias ? log.resource.alias : log.resource.name}`,
        content: 'has joined Liviit.',
      };
    } if (log.sub_type === 'hub') {
      return {
        name: `@${
          log.resource.created_by.username
            ? log.resource.created_by.username
            : log.resource.created_by.name
        }`,
        content: `has created a ${log.resource.name} Hub.`,
      };
    } if (log.sub_type === 'network') {
      return {
        name: `@${
          log.resource.created_by.username
            ? log.resource.created_by.username
            : log.resource.created_by.name
        }`,
        content: `has created a ${log.resource.name} Network.`,
      };
    } if (log.sub_type === 'page') {
      return {
        name: `@${
          log.resource.created_by.username
            ? log.resource.created_by.username
            : log.resource.created_by.name
        }`,
        content: `has created a ${log.resource.name} Page.`,
      };
    }
    return 'hyehey';
  }
  return null;
};

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
