import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import { Heading, ScrollableSection } from 'src/components/StyledComponents';
import { useSelector, useDispatch } from 'react-redux';
import { getHubDetails } from 'src/redux/hubRedux';
import Loader from 'src/components/Loader';
import EventCard from 'src/components/EventCard';
import Breadcrumb from 'src/components/Breadcrumb';
import { CalendarSection } from './styles';
import { format } from './events';

const localizer = momentLocalizer(moment);
const allViews = Object.keys(Views).map((k) => Views[k]);

function HubCalender(props) {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const { hubRemainders, hubs, hub } = useSelector(
    (state) => state.HubsReducer,
  );

  const { user } = useSelector((state) => state.ProfileReducer);

  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (hubRemainders.length === 0) {
      dispatch(getHubDetails(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (hubRemainders.length) {
      setEvents(format(hubRemainders));
    }
  }, [hubRemainders]);

  let noEvents = true;

  return (
    <>
      <Breadcrumb parent="Hub Calendar" title={hub ? hub.alias : ''} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Reminders</h5>
              </div>
              <div className="card-body">
                <ScrollableSection height="90%">
                  {processing.status && processing.component === 'hub' ? (
                    <Loader show />
                  ) : events.length ? (
                    // eslint-disable-next-line
                    events.map((remainder, index) => {
                      if (new Date(remainder.end) >= new Date()) {
                        noEvents = false;
                        return (
                          <EventCard
                            event={hubRemainders[index]}
                            hubs={hubs}
                            user={user}
                          />
                        );
                      }
                      if (noEvents && index === events.length - 1) {
                        return (
                          <Heading className="text-center h4 mt-4">
                            You have no Upcoming Events.
                          </Heading>
                        );
                      }
                    })
                  ) : (
                    <Heading className="text-center h4 mt-4">
                      You have no Upcoming Events.
                    </Heading>
                  )}
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-sm-9">
            <div className="card" style={{ height: '80vh' }}>
              <CalendarSection className="card-body">
                <Calendar
                  localizer={localizer}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  views={allViews}
                  events={hubRemainders.length ? events : []}
                  eventOverlap
                  dragRevertDuration={500}
                  dragScroll
                  showMultiDayTimes
                  step={60}
                  startAccessor="start"
                  endAccessor="end"
                />
              </CalendarSection>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubCalender;
