// actions
export const getUserTodoList = () => ({
  type: 'GET_USER_TODO_LIST',
});
export const saveUserTodoList = (payload) => ({
  type: 'SAVE_USER_TODO_LIST',
  payload,
});
export const getTodoSublist = (payload) => ({
  type: 'GET_TODO_SUBLIST',
  payload,
});
export const saveTodoSublist = (payload) => ({
  type: 'SAVE_TODO_SUBLIST',
  payload,
});
export const getTasks = (payload) => ({
  type: 'GET_TASKS',
  payload,
});
export const saveTasks = (payload) => ({
  type: 'SAVE_TASKS',
  payload,
});
export const getListMembers = (payload) => ({
  type: 'GET_LIST_MEMBERS',
  payload,
});
export const saveListMembers = (payload) => ({
  type: 'SAVE_LIST_MEMBERS',
  payload,
});
export const addNewList = (payload) => ({
  type: 'ADD_NEW_LIST',
  payload,
});
export const addNewTask = (payload) => ({
  type: 'ADD_NEW_TASK',
  payload,
});
export const updateTask = (payload) => ({
  type: 'UPDATE_TASK',
  payload,
});

export const updateTodoList = (payload) => ({
  type: 'UPDATE_LIST',
  payload,
});

export const removeTask = (payload) => ({
  type: 'DELETE_TASK',
  payload,
});

export const removeMember = (payload) => ({
  type: 'DELETE_MEMBER',
  payload,
});
export const toggleTask = (payload) => ({
  type: 'TOGGLE_TASK',
  payload,
});
export const reorderList = (payload) => ({
  type: 'REORDER_LIST',
  payload,
});
export const processLists = (payload) => ({
  type: 'PROCESSING_LISTS',
  payload,
});

// store
const initialState = {
  userTodos: [],
  sublist: [],
  tasks: [],
  listMembers: [],
  listProcessing: { status: false, component: '' },
};

// todoReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_USER_TODO_LIST':
      return { ...state, userTodos: action.payload };
    case 'SAVE_TODO_SUBLIST':
      return { ...state, sublist: action.payload };
    case 'SAVE_TASKS':
      return { ...state, tasks: action.payload };
    case 'SAVE_LIST_MEMBERS':
      return { ...state, listMembers: action.payload };
    case 'PROCESSING_LISTS':
      return { ...state, listProcessing: action.payload };
    default:
      return { ...state };
  }
};
