import React from 'react';
import { CometChatConversationList } from 'src/CometChatWorkspace/src';
import { USER_MESSAGES } from 'src/constants/routes';
import { Button } from 'src/components/Button';

function RightSidebar(props) {
  const handleClick = (item) => {
    props.navigate({
      pathname: USER_MESSAGES,
      state: {
        conversation: item,
      },
    });
    props.showRightSidebar();
  };
  return (
    <div className="right-sidebar" id="right_side_bar">
      <div
        style={{
          position: 'absolute',
          top: '20px',
          right: '12px',
        }}
      >
        <Button
          variant="outlined"
          value="View All"
          padding="0px 10px"
          margin="0px 10px 8px 0px"
          fontSize="14px"
          onClick={() => {
            props.navigate(USER_MESSAGES);
            props.showRightSidebar();
          }}
        />
        <i
          className="icon-close"
          onClick={props.showRightSidebar}
          style={{
            cursor: 'pointer',
            fontSize: '18px',
            fontWeight: 'bolder',
            color: 'black',
          }}
        />
      </div>

      <CometChatConversationList
        pushToScreen={handleClick}
        setIsNewMessage={props.setIsNewMessage}
      />
    </div>
  );
}

export default RightSidebar;
