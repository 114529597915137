import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { addDefaultSrc } from 'src/utils/helper';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { Button } from 'src/components/Button';
import { ProfilePic } from './styles';

function DeleteModal({
  obj, show, close, deleteFn,
}) {
  const [confirmModal, setConfirmModal] = useState(false);
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          close(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="pb-0" style={{ border: 'none' }} closeButton />
        <Modal.Body>
          <div className="card">
            <div
              className="text-center w-100"
              style={{ marginTop: '-10px', zIndex: '1' }}
            >
              <ProfilePic>
                <img
                  className="rounded-circle lazyloaded"
                  src={obj.avatar ? obj.avatar : ProfilePictureIcon}
                  alt="icon"
                  width="100%"
                  height="100%"
                  onError={addDefaultSrc}
                />
              </ProfilePic>
              <div className="my-2 h5">{obj.name ? obj.name : ''}</div>
              <div className="my-2">
                {obj.username ? `@${obj.username}` : '@-'}
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              variant="solid"
              onClick={() => {
                close(false);
                setConfirmModal(true);
              }}
              value="Delete"
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmModal}
        onHide={() => {
          setConfirmModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="pb-0" style={{ border: 'none' }} closeButton />
        <Modal.Body>
          <div className="card">
            <h4>
              Are you sure you want to delete this
              {obj.type}
              ?
            </h4>
            <div className="d-flex justify-content-around">
              <Button
                variant="solid"
                value="Yes"
                onClick={() => {
                  deleteFn(obj.id);
                  setConfirmModal(false);
                }}
              />
              <Button
                variant="solid"
                value="No"
                onClick={() => setConfirmModal(false)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

DeleteModal.defaultProps = {
  obj: '',
  show: false,
  close: () => {},
  deleteFn: () => {},
};

DeleteModal.propTypes = {
  obj: PropTypes.string,
  show: PropTypes.bool,
  close: PropTypes.func,
  deleteFn: PropTypes.func,
};

export default DeleteModal;
