export const getReports = (payload) => ({
  type: 'GET_REPORTS',
  payload,
});

export const saveReports = (payload) => ({
  type: 'SAVE_REPORTS',
  payload,
});

export const sendReport = (payload) => ({
  type: 'SEND_REPORT',
  payload,
});
export const sendReportStatus = (payload) => ({
  type: 'REPORT_STATUS',
  payload,
});

export const setReportAction = (payload) => ({
  type: 'SET_REPORT_ACTION',
  payload,
});

const initialState = {
  reports: { data: [], meta: {} },
  reportStatus: null,
};

// reportReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_REPORTS':
      return { ...state, reports: action.payload };
    case 'REPORT_STATUS':
      return { ...state, reportStatus: action.payload };
    default:
      return { ...state };
  }
};
