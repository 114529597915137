import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InputField } from 'src/components/InputField/styles';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { Heading, ProfilePic } from 'src/components/StyledComponents';
import { Button } from 'src/components/Button';
import { getContact } from 'src/redux/profileRedux';
import { updateTodoList, removeMember } from 'src/redux/todoRedux';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { addDefaultSrc } from 'src/utils/helper';

function ListModal(props) {
  const [edit, setEdit] = useState(false);
  const [listName, setListName] = useState(props.list.name);
  const [members, setMembers] = useState([]);
  const [addedMembers, setaddedMembers] = useState([]);
  const { contacts } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setMembers(contacts);
  }, [contacts]);

  const search = (e) => {
    dispatch(getContact(e.target.value));
    setMembers(contacts);
  };
  const contactState = () => {
    dispatch(getContact());
  };

  const add = (data) => {
    setaddedMembers([
      ...addedMembers,
      members.filter((m) => m.id === data.id)[0],
    ]);
    setMembers(members.filter((m) => m.id !== data.id));
  };

  const remove = (data) => {
    setaddedMembers(addedMembers.filter((m) => m.id !== data.id));
    setMembers([...members, data]);
  };

  const update = () => {
    const emails = addedMembers.map((m) => m.email);
    const payload = [props.list.id, { contacts: emails, name: listName }];
    dispatch(updateTodoList(payload));
    setMembers([]);
    setaddedMembers([]);
    props.close(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="w-100 d-flex justify-content-center">
          <div className={`${edit ? 'd-none' : 'd-flex'}`}>
            <Heading>
              <span className="h4">{listName}</span>
            </Heading>
            <div className="btn my-0 py-0" onClick={() => setEdit(true)}>
              <i className="fas fa-pencil-alt" />
            </div>
          </div>
          <div className={`${edit ? 'd-flex' : 'd-none'}`}>
            <InputField
              type="text"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              placeholder="list name.."
            />
            <div className="btn my-0 py-0" onClick={() => setEdit(false)}>
              <i className="fas fa-save" />
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-2">
          <div className="row">
            <div className="col-7">
              <div className="row mt-2">
                <div className="col-5">
                  <InputField
                    type="text"
                    onKeyUp={search}
                    placeholder="Search from Contacts.."
                  />
                </div>
                <div className="col-2">
                  <span className="text-danger">or</span>
                </div>
                <div className="col-5" onClick={contactState}>
                  <span className="text-danger btn m-0 p-0">
                    Get from Contacts
                  </span>
                </div>
              </div>
              <hr />
              <div className="p-3">
                {processing.status && processing.component === 'contacts' ? (
                  <Loader show />
                ) : members.length ? (
                  members.map((person, index) => (
                    <div
                      className="d-flex justify-content-between px-3 mb-2"
                      key={index}
                    >
                      <div className="d-flex">
                        <ProfilePic
                          width="35px"
                          height="35px"
                          style={{ margin: '0px 5px' }}
                        >
                          <img
                            src={
                                person.avatar
                                  ? person.avatar
                                  : ProfilePictureIcon
                              }
                            onError={addDefaultSrc}
                            alt="icon"
                            className="rounded-circle mx-auto"
                            width="38px"
                            height="38px"
                          />
                        </ProfilePic>
                        <div className="mx-2 mt-2">{person.name}</div>
                      </div>
                      <div className="mr-3 mt-1">
                        <Button
                          variant="outlined"
                          value="add"
                          padding="0px 3px"
                          fontSize="12px"
                          onClick={() => add(person)}
                        />
                      </div>
                    </div>
                  ))
                ) : null}
              </div>
            </div>
            <div className="col-5 text-center p-2">
              <Heading className="text-center">Members</Heading>
              {props.members.length
                ? props.members.map((person, nextIndex) => (
                  <div
                    key={nextIndex}
                    className="d-flex justify-content-between mb-2"
                  >
                    <div className="d-flex">
                      <ProfilePic>
                        <img
                          src={
                                person.avatar
                                  ? person.avatar
                                  : ProfilePictureIcon
                              }
                          onError={addDefaultSrc}
                          alt="icon"
                          className="rounded-circle mx-auto"
                          width="38px"
                          height="38px"
                        />
                      </ProfilePic>
                      <div className="ml-2 mt-2">{person.first_name}</div>
                    </div>
                    <div className="mr-3 mt-1">
                      <Button
                        variant="outlined"
                        value="remove"
                        padding="0px 3px"
                        fontSize="12px"
                        dissabled={
                              processing.status
                              && processing.component === 'user-todosublist'
                            }
                        onClick={() => dispatch(
                          removeMember({
                            id: props.list.id,
                            userId: person.id,
                          }),
                        )}
                      />
                    </div>
                  </div>
                ))
                : null}
              <hr />
              {addedMembers.length
                ? addedMembers.map((person, index) => (
                  <div
                    className="d-flex justify-content-between mb-2"
                    key={index}
                  >
                    <div className="d-flex">
                      <ProfilePic>
                        <img
                          src={
                                person.avatar
                                  ? person.avatar
                                  : ProfilePictureIcon
                              }
                          onError={addDefaultSrc}
                          alt="icon"
                          className="rounded-circle mx-auto"
                          width="38px"
                          height="38px"
                        />
                      </ProfilePic>
                      <div className="ml-2 mt-2">{person.name}</div>
                    </div>
                    <div className="mr-3 mt-1">
                      <Button
                        variant="outlined"
                        value="X"
                        padding="0px 3px"
                        fontSize="12px"
                        onClick={() => remove(person)}
                      />
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button
            type="submit"
            value="Update"
            variant="solid"
            onClick={update}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ListModal;
