import {
  Home,
  UserPlus,
  Chrome,
  FileText,
  Image,
  Calendar,
  MessageSquare,
  List,
  DollarSign,
  Users,
  Globe,
  Music,
  MapPin,
  Sliders,
  ShoppingBag,
  AlertOctagon,
} from 'react-feather';
import * as ROUTES from 'src/constants/routes';

export const MENUITEMS = [
  {
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    path: ROUTES.USER_DASHBOARD,
    active: false,
    bookmark: true,
  },
  {
    title: 'Feeds',
    icon: Chrome,
    type: 'link',
    path: ROUTES.USER_FEEDS,
    active: false,
  },
  localStorage.getItem('impersonate-user') === null
  && localStorage.getItem('view-user') === null
    ? {
      title: 'Chat',
      icon: MessageSquare,
      type: 'link',
      path: ROUTES.USER_MESSAGES,
      active: false,
      bookmark: true,
    }
    : {},

  {
    title: 'Calendar',
    icon: Calendar,
    type: 'link',
    path: ROUTES.USER_CALENDAR,
    active: false,
    bookmark: true,
  },
  {
    title: 'Lists',
    icon: List,
    type: 'link',
    path: ROUTES.USER_LISTS,
    active: false,
  },
  {
    title: 'Files',
    icon: FileText,
    type: 'link',
    path: ROUTES.USER_FILES,
    active: false,
  },
  {
    title: 'Vault',
    icon: Image,
    type: 'link',
    path: ROUTES.USER_VAULT,
    active: false,
  },
  {
    title: 'Music',
    icon: Music,
    type: 'link',
    path: ROUTES.USER_MUSIC,
    active: false,
  },
  localStorage.getItem('impersonate-user') === null
  && localStorage.getItem('view-user') === null
    ? {
      title: 'Contacts',
      icon: UserPlus,
      type: 'link',
      path: ROUTES.USER_CONTACTS,
      active: false,
    }
    : {},
  localStorage.getItem('impersonate-user') === null
  && localStorage.getItem('view-user') === null
    ? {
      title: 'Hubs',
      icon: Users,
      type: 'link',
      path: ROUTES.USER_HUBS,
      active: false,
    }
    : {},
  localStorage.getItem('impersonate-user') === null
  && localStorage.getItem('view-user') === null
    ? {
      title: 'Networks',
      icon: Globe,
      type: 'link',
      path: ROUTES.USER_NETWORKS,
      active: false,
    }
    : {},
];

export const HUBMENUITEMS = (hub) => [
  {
    title: 'Hub Home',
    icon: Home,
    type: 'link',
    path: `/hub/${hub.id}`,
    active: false,
  },
  {
    title: 'Calendar',
    icon: Calendar,
    type: 'link',
    path: `/hub/${hub.id}/calendar`,
    active: false,
  },
  {
    title: 'Lists',
    icon: List,
    type: 'link',
    path: `/hub/${hub.id}/lists`,
    active: false,
  },

  {
    title: 'Vault',
    icon: Image,
    type: 'link',
    path: `/hub/${hub.id}/vault`,
    active: false,
  },
  {
    title: 'Music',
    icon: Music,
    type: 'link',
    path: `/hub/${hub.id}/music`,
    active: false,
  },
  {
    title: 'Files',
    icon: FileText,
    type: 'link',
    path: `/hub/${hub.id}/files`,
    active: false,
  },
  hub.category === 'family' && hub.subcategory === 'direct'
    ? {
      title: 'Location',
      icon: MapPin,
      type: 'link',
      path: `/hub/${hub.id}/location`,
      active: false,
    }
    : {},
  {
    title: 'Return To Dashboard',
    icon: null,
    type: 'link',
    path: ROUTES.USER_DASHBOARD,
    active: false,
  },
];

export const ADMINMENUITEMS = [
  {
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    path: ROUTES.ADMIN_OVERVIEW,
    active: true,
  },
  {
    title: 'Reports',
    icon: AlertOctagon,
    type: 'link',
    path: ROUTES.ADMIN_REPORTS,
    active: false,
  },

  {
    title: 'Users',
    icon: UserPlus,
    type: 'link',
    path: ROUTES.ADMIN_USERS,
    active: false,
  },
  {
    title: 'Hubs',
    icon: Users,
    type: 'link',
    path: ROUTES.ADMIN_HUBS,
    active: false,
  },
  {
    title: 'Networks',
    icon: Globe,
    type: 'link',
    path: ROUTES.ADMIN_NETWORKS,
    active: false,
  },
  {
    title: 'Pages',
    icon: ShoppingBag,
    type: 'link',
    path: ROUTES.ADMIN_PAGES,
    active: false,
  },

  {
    title: 'Global Playlist',
    icon: Music,
    type: 'link',
    path: ROUTES.ADMIN_MUSIC,
    active: false,
  },
  {
    title: 'Theme Customizer',
    icon: Sliders,
    type: 'link',
    path: ROUTES.ADMIN_THEME,
    active: false,
  },
  {
    title: 'Banned Words',
    icon: DollarSign,
    type: 'link',
    path: ROUTES.ADMIN_BANNED_WORDS,
    active: false,
  },
];
