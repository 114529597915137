import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';

export const messageData = [
  {
    key: 1,
    avatar: ProfilePictureIcon,
    name: 'Amelia',
    message: 'Hi there..',
    newMessage: true,
    time: '10:09 AM',
    date: '10/14/2020',
  },
  {
    key: 2,
    avatar: ProfilePictureIcon,
    name: 'Lucy',
    message: 'Hi there..',
    newMessage: false,
    time: '10:09 AM',
    date: '10/14/2020',
  },
  {
    key: 3,
    avatar: ProfilePictureIcon,
    name: 'Aditya',
    message: 'Hi there..',
    newMessage: false,
    time: '10:09 AM',
    date: '10/14/2020',
  },
  {
    key: 4,
    avatar: ProfilePictureIcon,
    name: 'Matt',
    message: 'Hi there..',
    newMessage: false,
    time: '10:09 AM',
    date: '10/14/2020',
  },
  {
    key: 5,
    avatar: ProfilePictureIcon,
    name: 'Hugh',
    message: 'Hi there..',
    newMessage: false,
    time: '10:09 AM',
    date: '10/14/2020',
  },
  {
    key: 6,
    avatar: ProfilePictureIcon,
    name: 'Testing',
    message: 'Hi there..',
    newMessage: false,
    time: '10:09 AM',
    date: '10/14/2020',
  },
];

export const groupMessageData = [
  {
    id: 1,
    name: 'Dad and the Kids',
    members: [{ id: 1, avatar: ProfilePictureIcon, m_name: 'Hugh' }],
    avatar: ProfilePictureIcon,
  },
  {
    id: 2,
    name: 'Liviit Chat',
    members: [
      { id: 1, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 2, avatar: ProfilePictureIcon, m_name: 'Lucy' },
      { id: 3, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 4, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 5, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 6, avatar: ProfilePictureIcon, m_name: 'Hugh' },
    ],
    avatar: ProfilePictureIcon,
  },
  {
    id: 3,
    name: 'BHSG',
    members: [
      { id: 1, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 2, avatar: ProfilePictureIcon, m_name: 'Lucy' },
      { id: 3, avatar: ProfilePictureIcon, m_name: 'Hugh' },
      { id: 4, avatar: ProfilePictureIcon, m_name: 'Hugh' },
    ],
    avatar: ProfilePictureIcon,
  },
  {
    id: 4,
    name: 'Matt & Ness',
    members: [
      { id: 1, avatar: ProfilePictureIcon, m_name: 'Matt' },
      { id: 2, avatar: ProfilePictureIcon, m_name: 'Ness' },
    ],
    avatar: ProfilePictureIcon,
  },
];

export const listsData = {
  list1: [
    {
      id: 1,
      title: 'List1',
      task_name: 'Task1',
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      title: 'List2',
      task_name: 'Task2',
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ],
  list2: [
    {
      id: 3,
      title: 'List1',
      task_name: 'Task1',
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 4,
      title: 'List2',
      task_name: 'Task2',
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ],
};

export const contactsData = {
  a: [
    {
      id: 1,
      name: 'Aditya',
      username: 'aditya',
      is_favourite: false,
    },
    {
      id: 2,
      name: 'Aditya',
      username: 'aditya',
      is_favourite: false,
    },
    {
      id: 3,
      name: 'Aditya',
      username: 'aditya',
      is_favourite: true,
    },
    {
      id: 4,
      name: 'Aditya',
      username: 'aditya',
      is_favourite: false,
    },
  ],
  b: [
    {
      id: 1,
      name: 'Boss',
      username: 'boss',
      is_favourite: false,
    },
    {
      id: 2,
      name: 'Boss',
      username: 'boss',
      is_favourite: false,
    },
    {
      id: 3,
      name: 'Boss',
      username: 'boss',
      is_favourite: false,
    },
  ],
  h: [
    {
      id: 1,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: false,
    },
    {
      id: 2,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: false,
    },
    {
      id: 3,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: false,
    },
    {
      id: 4,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: true,
    },
    {
      id: 5,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: true,
    },
    {
      id: 6,
      name: 'Hugh',
      username: 'Hugh',
      is_favourite: false,
    },
  ],
};

export const familyHubData = [
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [{ displayPic: ProfilePictureIcon, name: 'Matt' }],
  },
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Robertson Family Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
    ],
  },
];

export const friendsHubData = [
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Friends Hub',
    hubDesc: 'chathub',
    members: [
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
    ],
  },
];
export const sportsHubData = [
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Sports Hub',
    hubDesc: 'Famhub',
    members: [
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
      { displayPic: ProfilePictureIcon, name: 'Matt' },
    ],
  },
  {
    profilePicture: ProfilePictureIcon,
    hubName: 'Cricket Hub',
    hubDesc: 'Famhub',
    members: [{ displayPic: ProfilePictureIcon, name: 'Matt' }],
  },
];

export const VaultFolderData = [
  {
    id: 1,
    name: 'Holiday 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
  {
    id: 2,
    name: 'Summer 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
  {
    id: 3,
    name: 'Winter 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
  {
    id: 4,
    name: 'Vacation 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
  {
    id: 5,
    name: 'Holiday 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
  {
    id: 6,
    name: 'Holiday 2018',
    avatar:
      'https://i.insider.com/5df126b679d7570ad2044f3e?width=1000&format=jpeg&auto=webp',
  },
];

export const listFolders = [
  {
    name: 'Matt Todo',
    is_private: true,
    list_count: 4,
  },
  {
    name: 'Hugh Todo',
    is_private: false,
    list_count: 2,
  },
  {
    name: 'Hugh List',
    is_private: false,
    list_count: 4,
  },
  {
    name: 'Matt Todo',
    is_private: true,
    list_count: 4,
  },
  {
    name: 'Matt Todo',
    is_private: true,
    list_count: 6,
  },
  {
    name: 'Matt Todo',
    is_private: false,
    list_count: 3,
  },
  {
    name: 'Matt Todo',
    is_private: false,
    list_count: 4,
  },
];

export const taskLists = [
  {
    id: 1,
    name: 'Household bills',
    repeat: 'Monthly',
    tasks: [
      {
        name: 'bill 1',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'bill 2',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
    ],
  },
  {
    id: 2,
    name: 'Cleaning',
    repeat: 'Daily',
    tasks: [
      {
        name: 'mop',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'vacuum',
        date: 'Mon, 31 Aug, 2020',
        is_completed: true,
      },
      {
        name: 'bins',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
    ],
  },
  {
    id: 3,
    name: 'Shoping',
    repeat: 'Monthly',
    tasks: [
      {
        name: 'mop',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'mop',
        date: 'Mon, 31 Aug, 2020',
        is_completed: true,
      },
      {
        name: 'mop',
        date: 'Mon, 31 Aug, 2020',
        is_completed: true,
      },
      {
        name: 'mop',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
    ],
  },
  {
    id: 4,
    name: 'Workout',
    repeat: 'Daily',
    tasks: [
      {
        name: 'Exercise',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'Running',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'Exercise 2',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'Exercise 4',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
      {
        name: 'Workout 4',
        date: 'Mon, 31 Aug, 2020',
        is_completed: false,
      },
    ],
  },
];
