import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  li {
    height: 90%;
    width: 100%;
    border: 1px solid #f6f7fb;
    border-radius: 5px;
    display: flex;
    margin-right: 9px;
    box-shadow: 0 0 11px -2px rgb(128 128 128 / 40%);
    background-size: cover !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    #add-img {
      text-align: center;
      display: none;
      width: 100%;
      background: transparent;
      z-index: 1000;
      height: 40%;
    }
  }
  li:hover {
    transform: scale(1.003);
    #add-img {
      transform: scale(0.9);
      display: initial;
      font-weight: bold;
      background-color: #101010 !important;
      color: #fff;
      background: radial-gradient(
        ellipse at center,
        rgba(12, 11, 23, 0.4) 0%,
        rgba(86, 76, 132, 0.1) 60%,
        rgba(255, 255, 255, 0.2) 100%
      );
    }
  }
`;
