// Comet Chat
export const APP_ID = process.env.REACT_APP_APP_ID;
export const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;

// Napster
export const NAPSTER_API_KEY = process.env.REACT_APP_NAPSTER_API_KEY;
export const NAPSTER_API_SECRET = process.env.REACT_APP_NAPSTER_API_SECRET;
export const NAPSTER_USERNAME = process.env.REACT_APP_NAPSTER_USERNAME;
export const NAPSTER_PASSWORD = process.env.REACT_APP_NAPSTER_PASSWORD;

// Google Map
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
