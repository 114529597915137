import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFolders,
  getFolderData,
  uploadFileInFolder,
  addFolder,
  removeFile,
  removeFolder,
  saveFolderData2,
  saveFolderData3,
} from 'src/redux/filesRedux';
import Breadcrumb from 'src/components/Breadcrumb';
import { SectionFolders, SectionPreview } from 'src/containers/Files/Sections';

function Files() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [parentFolder, setParentFolder] = useState(null);
  const { folders, folderDataLevel2, folderDataLevel3 } = useSelector(
    (state) => state.FilesReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (folders.length === 0) {
      dispatch(getAllFolders());
    }
  }, []);

  const getFolder = (folder, level) => {
    if (level === 2) dispatch(saveFolderData2([]));
    if (level === 3) dispatch(saveFolderData3([]));
    if (folder.is_folder) {
      dispatch(getFolderData({ level, id: folder.id }));
    } else {
      setSelectedFile(folder);
    }
  };

  return (
    <>
      <Breadcrumb parent="Files" title="Files" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <SectionFolders
              processing={processing}
              folders={folders}
              getFolder={getFolder}
              addFolder={addFolder}
              getFolders={getAllFolders}
              selected={selectedFile}
              outerFolderData={folderDataLevel2}
              innerFolderData={folderDataLevel3}
              uploadFile={uploadFileInFolder}
              deleteFolder={removeFolder}
              deleteFile={removeFile}
              state="parent"
              setParentFolder={setParentFolder}
              parentFolder={parentFolder}
            />
          </div>
          <div className="col-md-3">
            <SectionFolders
              processing={processing}
              folders={folders}
              getFolder={getFolder}
              addFolder={addFolder}
              getFolders={getAllFolders}
              selected={selectedFile}
              outerFolderData={folderDataLevel2}
              innerFolderData={folderDataLevel3}
              uploadFile={uploadFileInFolder}
              deleteFolder={removeFolder}
              deleteFile={removeFile}
              parentFolder={parentFolder}
            />
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Preview</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <i
                        className="fas fa-external-link-alt"
                        onClick={() => (selectedFile
                          ? window.open(selectedFile.path, '_blank').focus()
                          : console.log())}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <SectionPreview file={selectedFile} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Files;
