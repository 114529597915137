import styled from 'styled-components';

export const UserImage = styled.div`
  height: 140px;
  border-radius: 50%;
  .pro {
    border: 1px solid grey;
    padding: 5px;
  }
`;
