import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { getNetworks } from 'src/redux/networkRedux';
import {
  HScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';

const formatedData = (data) => {
  const obj = {};
  data.forEach((network) => {
    if (!Object.keys(obj).includes(network.category.toString())) {
      obj[network.category] = data.filter((element) => element.category === network.category && element.is_public);
    }
  });
  return obj;
};

function Networks() {
  const [networkData, setNetworkData] = useState({});
  const dispatch = useDispatch();
  const { networks } = useSelector((state) => state.NetworkReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (networks.length === 0) dispatch(getNetworks());
    else setNetworkData(formatedData(networks));
  }, [networks]);

  return processing.status && processing.component === 'network' ? (
    <Loader show />
  ) : (
    Object.keys(networkData).map((network, i) => (networkData[network].length ? (
      <div className="my-2" key={i}>
        <h5 className="font-weight-bold text-dark">
          {' '}
          {networkData[network][0].category.charAt(0).toUpperCase()
              + networkData[network][0].category.slice(1)}
        </h5>
        <HScrollableSection className="d-flex">
          {networkData[network].map((data, index) => (
            <div
              className="card mx-2"
              style={{
                minWidth: '70%',
                boxShadow: '1px 5px 20px 0 rgb(0 0 0 / 30%)',
                cursor: 'pointer',
              }}
              key={index}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-1">
                      <ProfilePic>
                        <img
                          className="rounded-circle lazyloaded blur-up"
                          src={data.avatar ? data.avatar : ProfilePictureIcon}
                          onError={addDefaultSrc}
                          alt="#"
                          width="38px"
                          height="38px"
                        />
                      </ProfilePic>
                  </div>
                  <div className="col-8">
                      <div className="pl-2">
                        <h6>{data.name}</h6>
                        <div>
                            @
                            {data.username ? data.username : '-'}
                        </div>
                      </div>
                  </div>
                  <div className="col-3" />
                </div>
              </div>
            </div>
          ))}
        </HScrollableSection>
      </div>
    ) : null))
  );
}

export default Networks;
