import React from 'react';
import { HeaderButton } from './styles';

function PlusButton(props) {
  return (
    <HeaderButton
      width={props.width ? props.width : null}
      height={props.height ? props.height : null}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <i className="fas fa-plus" />
    </HeaderButton>
  );
}

export default PlusButton;
