import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const TextAreaField = styled.textarea`
  width: 100%;
  &:focus {
    border-color: ${themeColor};
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  .field {
    vertical-align: initial;
    padding: 5px 0px;
    font-weight: bold;
    color: black;
    font-size: 16px;
    width: 30%;
  }
  .value {
    vertical-align: center;
    padding: 5px 0px;
    font-size: 16px;
    max-width: 515px;
    @media (max-width: 992px) {
      max-width: 515px;
    }
    @media (max-width: 576px) {
      max-width: 305px;
    }
  }
`;
