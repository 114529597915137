import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'src/components/Button';
import SelectMenu from 'src/components/DropDown';
import { addReminders } from 'src/redux/remaindersRedux';
import { getAllContacts } from 'src/redux/profileRedux';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'src/components/InputField';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  HScrollableSection,
  ScrollableSection,
  ProfilePic,
} from 'src/components/StyledComponents';
import PlusButton from 'src/components/PlusButton';
import { addDefaultSrc } from 'src/utils/helper';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { DropdownData } from 'src/utils/data';
import { StyledTable, TextAreaField } from './styles';

function AddReminderModal(props) {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('');
  const [allDay, setAllDay] = useState(false);
  const [type, setType] = useState(DropdownData.reminderTypeOptions[0]);
  const [repeat, setRepeat] = useState(DropdownData.reminderRepeatOptions[0]);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(startDate);
  const [location, setLocation] = useState('');
  const [desc, setDesc] = useState('');
  const [sendInvite, setSendInvite] = useState(false);
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();
  const { allContacts } = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    dispatch(getAllContacts());
  }, []);

  const createEvent = () => {
    if (endDate < startDate) {
      alert('Oops,Invalid End time of the Event!!');
      return;
    }
    let members = [];
    if (users.length) {
      members = users.map((user) => user.email);
    }

    const payload = {
      sync_id: localStorage.getItem('deviceId'),
      type: type.key,
      title,
      is_all_day: allDay,
      description: desc,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      repeat: repeat.key,
      location,
      members,
      send_invite: sendInvite,
    };
    dispatch(addReminders(payload));
    props.close(false);
  };
  const changeMembers = (member) => {
    if (!users.includes(member)) {
      setUsers([...users, member]);
    } else {
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== member.id));
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="font-weight-bold h4">{props.title}</div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="container-fluid">
            <StyledTable>
              <tr>
                <td className="field">Title</td>
                <td className="value">
                  <TextField
                    type="text"
                    placeholder="Title..."
                    borderRadius="30px"
                    fontSize="16px"
                    padding="7px 10px"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">All Day Event</td>
                <td className="value">
                  <Switch
                    onChange={() => setAllDay(!allDay)}
                    checked={allDay}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">Type</td>
                <td className="value">
                  <SelectMenu
                    selected={type}
                    setSelected={setType}
                    options={DropdownData.reminderTypeOptions}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">Repeat</td>
                <td className="value">
                  <SelectMenu
                    selected={repeat}
                    setSelected={setRepeat}
                    options={DropdownData.reminderRepeatOptions}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">Start</td>
                <td className="value">
                  <DatePicker
                    className="form-control digits"
                    showPopperArrow={false}
                    selected={startDate}
                    showTimeSelect
                    onChange={(e) => {
                      setStartDate(e);
                      if (allDay) {
                        const date = new Date(e);
                        date.setHours(23);
                        date.setMinutes(59);
                        setEndDate(date);
                      }
                    }}
                    minDate={new Date()}
                    dateFormat="y-MM-dd, H:mm aa"
                  />
                </td>
              </tr>
              <tr>
                <td className="field">End</td>
                <td className="value">
                  <DatePicker
                    className="form-control digits"
                    showPopperArrow={false}
                    selected={endDate}
                    showTimeSelect
                    onChange={(e) => {
                      setEndDate(e);
                    }}
                    minDate={startDate}
                    dateFormat="y-MM-dd, H:mm aa"
                    disabled={allDay}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">Members</td>
                <td className="value">
                  <HScrollableSection>
                    {users.length ? (
                      <div className="d-flex w-100">
                        {users.map((user, index) => (
                          <div
                            className="p-1 border rounded d-flex mx-2"
                            key={index}
                          >
                            <ProfilePic width="22px" height="22px">
                              <img
                                className="rounded-circle lazyloaded blur-up"
                                style={{ width: '100%', height: '100%' }}
                                src={
                                    user.avatar
                                      ? user.avatar
                                      : ProfilePictureIcon
                                  }
                                onError={addDefaultSrc}
                                alt="icon"
                              />
                              {' '}
                            </ProfilePic>
                            <div>{user.username}</div>
                          </div>
                        ))}
                        <PlusButton onClick={() => setModalShow(true)} />
                      </div>
                    ) : (
                      <PlusButton onClick={() => setModalShow(true)} />
                    )}
                  </HScrollableSection>
                </td>
              </tr>
              <tr>
                <td className="field">Location</td>
                <td className="value">
                  <TextField
                    type="text"
                    placeholder="Location..."
                    borderRadius="30px"
                    fontSize="16px"
                    padding="7px 10px"
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                  />
                </td>
              </tr>
              <tr>
                <td className="field">Details</td>
                <td className="value">
                  <TextAreaField
                    className="form-control form-control-sm"
                    rows="3"
                    placeholder="Description..."
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    onChange={() => setSendInvite(!sendInvite)}
                    checked={sendInvite}
                  />
                  Enable Invitation Request
                </td>
              </tr>
            </StyledTable>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              value="Create"
              variant="solid"
              dissabled={title.length === 0}
              onClick={createEvent}
              margin="0px 5px"
            />
          </div>

          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header style={{ border: 'none' }} closeButton>
              <div className="font-weight-bold h4">Select Users</div>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div
                  className="card-body text-align-center justify-content-center"
                  style={{ height: '400px' }}
                >
                  <ScrollableSection>
                    <ul className="list-group">
                      {allContacts.length ? (
                        allContacts.map((contact, index) => (
                          <li
                            className={`list-group-item justify-content-start ${
                              users.includes(contact) ? 'active' : ''
                            }`}
                            key={index}
                          >
                            <input
                              className="checkbox_animated"
                              type="checkbox"
                              checked={users.includes(contact)}
                              onChange={() => changeMembers(contact)}
                            />

                            <ProfilePic width="25px" height="25px">
                              <img
                                className="rounded-circle lazyloaded blur-up"
                                style={{ width: '100%', height: '100%' }}
                                src={
                                    contact.avatar
                                      ? contact.avatar
                                      : ProfilePictureIcon
                                  }
                                onError={addDefaultSrc}
                                alt="icon"
                              />
                              {' '}
                            </ProfilePic>
                            <div className="ml-1">{contact.name}</div>
                          </li>
                        ))
                      ) : (
                        <div>You do not have any contacts to invite.</div>
                      )}
                    </ul>
                  </ScrollableSection>
                  <div className="text-center">
                    <Button
                      value="Save"
                      variant="solid"
                      onClick={() => setModalShow(false)}
                      margin="5px 0px"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </Modal.Body>
    </Modal>
  );
}

export default AddReminderModal;
