import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import {
  getAllPages,
  deletePage,
  getRequestPages,
  addRequestPages,
  loadMorePages,
  getPageMembers,
} from 'src/redux/pageRedux';
import { Cell, InfoSection } from 'src/containers/AdminDashboard/styles';
import {
  ProfilePic,
  ScrollableSection,
  Heading,
} from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';
import { DeleteModal } from 'src/components/Modal';
import LoadMore from 'src/components/LoadMore';
import Breadcrumb from 'src/components/Breadcrumb';
import { Button } from 'src/components/Button';
import { MapPin } from 'react-feather';

const categories = [
  {
    key: 'places',
    value: 'Places',
  },
  {
    key: 'people',
    value: 'People',
  },
  {
    key: 'brand',
    value: 'Brand',
  },
  {
    key: 'business',
    value: 'Business',
  },
  {
    key: 'media',
    value: 'Media',
  },
  {
    key: 'groups',
    value: 'Groups',
  },
  {
    key: 'community',
    value: 'Community',
  },
  {
    key: 'support',
    value: 'Support',
  },
  {
    key: 'bommunity',
    value: 'Community',
  },
  {
    key: 'clubs',
    value: 'Clubs',
  },
];

function AdminPages() {
  const [pageData, setPageData] = useState({});
  const [pageCategory, setPageCategory] = useState('all');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState({});
  const [requestPageData, setRequestPageData] = useState([]);

  const dispatch = useDispatch();

  const { processing } = useSelector((state) => state.ServiceReducer);
  const {
    pages, requestPages, metaDataPages, pageMembers,
  } = useSelector(
    (state) => state.PageReducer,
  );

  useEffect(() => {
    dispatch(getAllPages());
    return () => {
      setPageData([]);
      setRequestPageData([]);
    };
  }, []);

  useEffect(() => {
    if (pages.length > 0) {
      if (metaDataPages.current_page === 1) {
        setPageData(pages);
        setSelectedPage(pages[0]);
        dispatch(getPageMembers(pages[0].id));
      } else {
        setPageData([...pageData, ...pages]);
      }
    } else {
      setPageData([]);
    }
  }, [pages]);

  useEffect(() => {
    if (pageCategory === 'all') {
      setPageData([]);
      dispatch(getAllPages());
    } else if (pageCategory === 'request') {
      dispatch(getRequestPages());
    } else {
      setPageData([]);
      dispatch(getAllPages({ category: pageCategory }));
    }
  }, [pageCategory]);

  useEffect(() => {
    if (requestPages.data.length > 0) {
      if (requestPages.meta.current_page === 1) {
        setRequestPageData(requestPages.data);
      } else {
        setPageData([...requestPageData, ...requestPages.data]);
      }
    }
  }, [requestPages]);

  const onPageClick = () => {
    setSelectedPage({
      ...selectedPage,
      type: 'Page',
      username: selectedPage.alias,
    });
    setShowDeleteModal(true);
  };

  const getPage = (page) => {
    setSelectedPage(page);
    dispatch(getPageMembers(page.id));
  };

  const onDelete = (id) => {
    dispatch(deletePage({ id }));
  };

  const onAction = (cmd, id) => {
    const payload = {
      data: { action: cmd },
      id,
    };
    dispatch(addRequestPages(payload));
    setRequestPageData([]);
  };

  const getMorePages = () => {
    if (pageCategory === 'all') {
      dispatch(loadMorePages({ page: metaDataPages.current_page + 1 }));
    } else {
      dispatch(
        loadMorePages({
          page: metaDataPages.current_page + 1,
          category: pageCategory,
        }),
      );
    }
  };

  return (
    <>
      <Breadcrumb parent="Admin" title="Pages" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Categories</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    <li
                      className={`list-group-item ${
                        pageCategory === 'all' ? 'active' : null
                      }`}
                      onClick={() => setPageCategory('all')}
                    >
                      <span>All</span>
                    </li>
                    <li
                      className={`list-group-item ${
                        pageCategory === 'request' ? 'active' : null
                      }`}
                      onClick={() => setPageCategory('request')}
                    >
                      <div>Requests</div>
                      {requestPageData.length ? (
                        <div className="badge badge-pill badge-dark ml-1">
                          {requestPageData.length}
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                    {categories.map((category, index) => (
                      <li
                        key={index}
                        className={`list-group-item ${
                          pageCategory === category ? 'active' : null
                        }`}
                        onClick={() => setPageCategory(category.key)}
                      >
                        <span>{category.value}</span>
                      </li>
                    ))}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          {pageCategory === 'request' ? (
            <div className="col-sm-9">
              <div className="card" style={{ height: '80vh' }}>
                <div className="card-body">
                  {processing.status
                  && processing.component === 'request-page' ? (
                    <Loader show />
                    ) : requestPageData.length ? (
                      <div className="table-responsive pb-2 h-100">
                        <table className="table">
                          <thead>
                            <tr style={{ fontSize: '10px' }}>
                              <th scope="col" />
                              <th scope="col">NAME</th>
                              <th scope="col">USERNAME</th>
                              <th scope="col">CLASSIFICATION</th>
                              <th scope="col">CATEGORY</th>
                              <th scope="col">LOCATION</th>
                              <th scope="col">CREATED BY</th>
                              <th scope="col">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requestPageData.map((data, index) => (
                              <tr key={index} style={{ fontSize: '12px' }}>
                                <Cell>
                                  <ProfilePic>
                                    <img
                                      src={
                                        data.avatar
                                          ? data.avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="icon"
                                      onError={addDefaultSrc}
                                      className="rounded-circle mx-auto"
                                      width="38px"
                                      height="38px"
                                    />
                                  </ProfilePic>
                                </Cell>
                                <Cell>
                                  <div>
                                    <div>{data.name ? data.name : '-'}</div>
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>{data.alias ? data.alias : '-'}</div>
                                </Cell>

                                <Cell>
                                  <div>
                                    {data.classification
                                      ? data.classification
                                      : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {data.category ? data.category : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {data.location.address
                                      ? data.location.address
                                      : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className="d-flex">
                                    <ProfilePic>
                                      <img
                                        src={
                                          data.created_by.avatar
                                            ? data.created_by.avatar
                                            : ProfilePictureIcon
                                        }
                                        alt="icon"
                                        onError={addDefaultSrc}
                                        className="rounded-circle mx-auto"
                                        width="38px"
                                        height="38px"
                                      />
                                    </ProfilePic>
                                    <div className="ml-2">
                                      {data.created_by.full_name
                                        ? data.created_by.full_name
                                        : ''}
                                    </div>
                                  </div>
                                </Cell>

                                <Cell>
                                  <div className="d-flex">
                                    <Button
                                      variant="solid"
                                      fontSize="12px"
                                      value="Approve"
                                      padding="5px 18px"
                                      margin="0px 10px"
                                      onClick={() => onAction('accept', data.id)}
                                    />
                                    <Button
                                      variant="solid"
                                      fontSize="12px"
                                      value="Decline"
                                      padding="5px 18px"
                                      margin="0px 10px"
                                      onClick={() => onAction('reject', data.id)}
                                    />
                                  </div>
                                </Cell>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Heading className="text-center h4 mt-4">
                        No Requests Received Please Check Later.
                      </Heading>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-sm-3">
                <div className="card" style={{ height: '80vh' }}>
                  <div className="card-header">
                    <h5>{pageCategory}</h5>
                  </div>
                  <div className="card-body">
                    <ScrollableSection id="scroll-admin-pages">
                      {processing.status
                      && processing.component === 'user-pages' ? (
                        <Loader show />
                        ) : pageData.length ? (
                          <ul className="list-group mt-2">
                            <LoadMore
                              data={pageData}
                              getMore={() => getMorePages()}
                              target="scroll-admin-pages"
                              meta={metaDataPages}
                            >
                              {pageData.map((page, index) => (
                                <li
                                  key={index}
                                  className={`list-group-item ${
                                    selectedPage && selectedPage.id === page.id
                                      ? 'active'
                                      : null
                                  }`}
                                  onClick={() => getPage(page)}
                                >
                                  <span>{page.name}</span>
                                </li>
                              ))}
                            </LoadMore>
                          </ul>
                        ) : (
                          <Heading className="text-center h4 mt-4">
                            No Page exists with the selected Category.
                          </Heading>
                        )}
                    </ScrollableSection>
                    <DeleteModal
                      show={showDeleteModal}
                      close={setShowDeleteModal}
                      obj={selectedPage}
                      deleteFn={onDelete}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card" style={{ height: '80vh' }}>
                  <div className="card-body">
                    <ScrollableSection>
                      {processing.status
                      && processing.component === 'admin-page-members' ? (
                        <Loader show />
                        ) : selectedPage && Object.keys(selectedPage).length ? (
                          <InfoSection className="socialprofile">
                            <div
                              style={{
                                backgroundImage: `linear-gradient(${
                                  selectedPage.theme
                                    ? selectedPage.theme[0]
                                    : '#000'
                                },${
                                  selectedPage.theme
                                    ? selectedPage.theme[1]
                                    : '#000'
                                })`,
                                height: '200px',
                                borderRadius: '8px',
                              }}
                            />
                            <div className="avatar">
                              <div className="row w-100">
                                <div className="col-5 pr-0">
                                  <div className="w-100 d-flex justify-content-around">
                                    <Button
                                      variant="outline"
                                      value={
                                      selectedPage.is_public
                                        ? 'Public'
                                        : 'Private'
                                    }
                                      padding="1px 8px"
                                      margin="0px 0px 0px 8px"
                                    />
                                    <Button
                                      variant="outline"
                                      value={
                                      selectedPage.classification[0].toUpperCase()
                                      + selectedPage.classification.slice(
                                        1,
                                        selectedPage.classification.length,
                                      )
                                    }
                                      padding="1px 8px"
                                    />
                                  </div>
                                </div>
                                <div className="col-2">
                                  <img
                                    src={
                                    selectedPage.original_avatar
                                      ? selectedPage.original_avatar
                                      : ProfilePictureIcon
                                  }
                                    alt="#"
                                    onError={addDefaultSrc}
                                    className="bg-white"
                                  />
                                </div>
                                <div className="col-5 pr-0">
                                  <div className="w-100 d-flex justify-content-around">
                                    <Button
                                      variant="outline"
                                      value={
                                      selectedPage.category[0].toUpperCase()
                                      + selectedPage.category.slice(
                                        1,
                                        selectedPage.category.length,
                                      )
                                    }
                                      padding="1px 8px"
                                    />
                                    <Button
                                      variant="outline"
                                      value="Delete"
                                      padding="1px 8px"
                                      onClick={() => onPageClick(selectedPage)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-around">
                              <div>
                                <h2>{selectedPage.name}</h2>
                                <h6 className="text-center">
                                  (@
                                  {selectedPage.alias ? selectedPage.alias : '-'}
                                  )
                                </h6>
                              </div>
                            </div>
                            {selectedPage.location
                          && selectedPage.location.address ? (
                            <div className="d-flex justify-content-around my-2">
                              <div>
                                <h6 className="text-center">
                                  <span
                                    className="h6 text-dark"
                                    style={{ verticalAlign: 'text-top' }}
                                  >
                                    <MapPin />
                                  </span>
                                  {' '}
                                  {selectedPage.location.address}
                                </h6>
                              </div>
                            </div>
                              ) : null}

                            <div className="container-fluid">
                              <div className="row">
                                {pageMembers.map((person, index) => {
                                  if (person.role === 'creator') {
                                    return (
                                      <div className="col-12" key={index}>
                                        <div className="card">
                                          <div className="card-header">
                                            <div className="row">
                                              <div className="col-sm-6 d-flex">
                                                <ProfilePic>
                                                  <img
                                                    className="rounded-circle"
                                                    src={
                                                    person.original_avatar
                                                      ? person.original_avatar
                                                      : ProfilePictureIcon
                                                  }
                                                    alt="#"
                                                    width="100%"
                                                    height="100%"
                                                    onError={addDefaultSrc}
                                                  />
                                                </ProfilePic>
                                                <div className="ml-2">
                                                  <h6>
                                                    {`${person.first_name
                                                    } ${
                                                      person.last_name}`}
                                                  </h6>
                                                  <div>
                                                    @
                                                    {person.username}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="pull-right right-header">
                                                  <h6>
                                                    {person.role.toUpperCase()}
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                                {pageMembers.map((person, index) => {
                                  if (person.role === 'follower') {
                                    return (
                                      <div className="col-12" key={index}>
                                        <div className="card">
                                          <div className="card-header">
                                            <div className="row">
                                              <div className="col-sm-6 d-flex">
                                                <ProfilePic>
                                                  <img
                                                    className="rounded-circle"
                                                    src={
                                                    person.original_avatar
                                                      ? person.original_avatar
                                                      : ProfilePictureIcon
                                                  }
                                                    alt="#"
                                                    width="100%"
                                                    height="100%"
                                                    onError={addDefaultSrc}
                                                  />
                                                </ProfilePic>
                                                <div className="ml-2">
                                                  <h6>
                                                    {`${person.first_name
                                                    } ${
                                                      person.last_name}`}
                                                  </h6>
                                                  <div>
                                                    @
                                                    {person.username}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="pull-right right-header">
                                                  <h6>
                                                    {person.role.toUpperCase()}
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            </div>
                          </InfoSection>
                        ) : (
                          <Loader show />
                        )}
                    </ScrollableSection>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminPages;
