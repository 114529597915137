import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import LogoIcon from "src/assets/images/livit/Liviit_logo.png";
import { getHubDetails } from 'src/redux/hubRedux';

import { ScrollableSection } from 'src/components/StyledComponents';
import Breadcrumb from 'src/components/Breadcrumb';
import { ExternalLink } from 'react-feather';
import { CometChatMessages } from 'src/CometChatWorkspace/src';
import PlusButton from 'src/components/PlusButton';
import Feed from './Sections/feed';
import { ChatContainer } from './styles';
import Todos from './Sections/todo';
import Vault from './Sections/vault';
import Remainders from './Sections/remainders';
import HubInformation from './Sections/hubInfo';
import HubFiles from './Sections/files';
import HubFeedModal from './Sections/hubFeedModal';

function HubDashboard(props) {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const { hub } = useSelector((state) => state.HubsReducer);
  const { theme } = useSelector((state) => state.ThemeReducer);
  const [show, setShow] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    dispatch(getHubDetails(id));
  }, [id]);

  const month = [
    'January',
    'Februaury',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <>
      <Breadcrumb parent="Hubs" title={hub ? hub.alias : ''} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '35vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-10">
                    <h5 className="align-abjust">Information</h5>
                  </div>
                  <div className="col-sm-2" />
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <HubInformation hub={hub} />
                </ScrollableSection>
              </div>
            </div>
            <div className="card" style={{ height: '40vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-8">
                    <h5 className="align-abjust">Calendar</h5>
                  </div>
                  <div className="col-sm-4">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.navigate(`/hub/${hub.id}/calendar`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll">
                  <div className="cal-info text-center w-100">
                    <div className="d-flex justify-content-around">
                      <h2 style={{ color: 'grey', fontSize: '80px' }}>
                        {new Date().getDate()}
                      </h2>
                      <div className="mt-4">
                        <div className="b-b-dark pb-2">
                          {month[new Date().getMonth()]}
                        </div>
                        <div className="pt-2">{new Date().getFullYear()}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Remainders />
                  </div>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '78vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">Feed</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <PlusButton
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll-feeds">
                  <Feed theme={theme} isAdded={isAdded} {...props} />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ChatContainer style={{ height: '78vh' }}>
              <CometChatMessages chatWithGroup={`hub_${id}`} hubState />
            </ChatContainer>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-7">
                    <h5 className="align-abjust">Lists</h5>
                  </div>
                  <div className="col-sm-5">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.navigate(`/hub/${hub.id}/lists`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '46vh' }}>
                <ScrollableSection>
                  <Todos route={`/hub/${hub.id}/lists`} {...props} />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-7">
                    <h5 className="align-abjust">Files</h5>
                  </div>
                  <div className="col-sm-5">
                    <div className="pull-right right-header">
                      <ExternalLink
                        onClick={() => props.navigate(`/hub/${hub.id}/files`)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '46vh' }}>
                <ScrollableSection>
                  <HubFiles {...props} />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-7">
                    <h5 className="align-abjust">Vault</h5>
                  </div>
                  <div className="col-sm-5">
                    <div className="pull-right right-header">
                      <ExternalLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.navigate(`/hub/${hub.id}/vault`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '46vh' }}>
                <ScrollableSection>
                  <Vault theme={theme} {...props} />
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HubFeedModal
        show={show}
        setShow={setShow}
        setIsAdded={setIsAdded}
        {...props}
      />
    </>
  );
}

export default HubDashboard;
