import { axiosInstance, baseUrl } from '.';

// Banned Words APIs
export const fetchBannedWords = async () => {
  const token = localStorage.getItem('token');
  let response = { data: { data: null } };
  try {
    response = await axiosInstance.get(`${baseUrl}reserved-words`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  // eslint-disable-next-line no-empty
  } catch (err) {}

  return response.data.data;
};

// Add a new Banned word
export const postBannedWord = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}reserved-words`,
      { word: payload.word },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// update a Banned word
export const putBannedWord = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.put(
    `${baseUrl}reserved-words/${payload.id}`,
    { word: payload.word },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// delete a Banned word
export const delBannedWord = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.delete(
    `${baseUrl}reserved-words/${payload.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};
