import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';
import { HomeContainer } from 'src/containers/LandingPage/Home';
import PrivateRoute from 'src/private_route';
import AboutPage from 'src/containers/LandingPage/About';
import PhilosophyPage from 'src/containers/LandingPage/Philosophy';
import FeaturesPage from 'src/containers/LandingPage/Features';
import ContactPage from 'src/containers/LandingPage/ContactUs';
import TermsOfUse from 'src/containers/LandingPage/TermsOfUse';
import PrivacyPolicy from 'src/containers/LandingPage/PrivacyPolicy';
import Verification from 'src/containers/Verification';
import Subscription from 'src/containers/SubscriptionScreen';
import Overview from 'src/containers/AdminDashboard/Overview';
import AdminReports from 'src/containers/AdminReports';
import AdminUsers from 'src/containers/AdminDashboard/Sections/users';
import AdminHubs from 'src/containers/AdminDashboard/Sections/hubs';
import AdminNetworks from 'src/containers/AdminDashboard/Sections/networks';
import AdminPages from 'src/containers/AdminDashboard/Sections/pages';
import BannedWords from 'src/containers/BannedWords';
import AdminPlaylist from 'src/containers/AdminPlaylist';
import AdminThemeCustomizer from 'src/containers/AdminThemeCustomizer';
import UserDashboard from 'src/containers/Dashboard';
import Feeds from 'src/containers/Feeds';
import Messages from 'src/containers/Messages';
import Calender from 'src/containers/Calender';
import Lists from 'src/containers/Lists';
import Vault from 'src/containers/Vault';
import Contacts from 'src/containers/Contacts';
import { Files } from 'src/containers/Files';
import HubDashboard from 'src/containers/HubHome';
import Directory from 'src/containers/Directory';
import HubCalender from 'src/containers/HubCalendar';
import UserProfile from 'src/containers/UserProfile';
import UserHubs from 'src/containers/Hubs';
import UserNetworks from 'src/containers/Networks';
import MusicPlayer from 'src/containers/AudioPlayer';
import HubLists from 'src/containers/HubLists';
import HubVault from 'src/containers/HubVault';
import HubFiles from 'src/containers/HubFiles';
import HubMusicPlayer from 'src/containers/HubAudioPlayer';
import HubLocation from 'src/containers/HubLocation';
import Error404 from 'src/containers/ErrorPage';
import { ToastContainer } from 'react-toastify';

function privateComponentWrapper(Component) {
  return (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
}

function App() {
  const [isAdmin] = useState(
    localStorage.getItem('user-level') === 'admin',
  );

  return (
    <BrowserRouter>
      <Routes>

        {/* Public Routes */}
        <Route exact path={ROUTES.MAIN_ROUTE} element={<HomeContainer />} />
        <Route exact path={ROUTES.ABOUT_PAGE} element={<AboutPage />} />
        <Route exact path={ROUTES.PHILOSOPHY_PAGE} element={<PhilosophyPage />} />
        <Route exact path={ROUTES.FEATURES_PAGE} element={<FeaturesPage />} />
        <Route exact path={ROUTES.CONTACT_US_PAGE} element={<ContactPage />} />
        <Route exact path={ROUTES.T_C} element={<TermsOfUse />} />
        <Route exact path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route exact path={ROUTES.VERIFICATION} element={<Verification />} />
        <Route exact path={ROUTES.PLANS} element={<Subscription />} />

        {/* Private Routes */}
        <Route
          path={ROUTES.USER_DASHBOARD}
          element={privateComponentWrapper(UserDashboard)}
        />
        <Route
          path={ROUTES.USER_FEEDS}
          element={privateComponentWrapper(Feeds)}
        />
        <Route
          path={ROUTES.USER_MESSAGES}
          element={privateComponentWrapper(Messages)}
        />
        <Route
          path={ROUTES.USER_CALENDAR}
          element={privateComponentWrapper(Calender)}
        />
        <Route
          path={ROUTES.USER_CONTACTS}
          element={privateComponentWrapper(Contacts)}
        />
        <Route
          path={ROUTES.USER_LISTS}
          element={privateComponentWrapper(Lists)}
        />
        <Route
          path={ROUTES.USER_FILES}
          element={privateComponentWrapper(Files)}
        />
        <Route
          path={ROUTES.USER_VAULT}
          element={privateComponentWrapper(Vault)}
        />
        <Route
          path={ROUTES.USER_DIRECTORY}
          element={privateComponentWrapper(Directory)}
        />
        <Route
          path={ROUTES.USER_PROFILE}
          element={privateComponentWrapper(UserProfile)}
        />
        <Route
          path={ROUTES.USER_HUBS}
          element={privateComponentWrapper(UserHubs)}
        />
        <Route
          path={ROUTES.USER_NETWORKS}
          element={privateComponentWrapper(UserNetworks)}
        />
        <Route
          path={ROUTES.USER_MUSIC}
          element={privateComponentWrapper(MusicPlayer)}
        />
        <Route
          path={ROUTES.USER_SETTINGS}
          element={privateComponentWrapper(Subscription)}
        />
        <Route
          path={ROUTES.USER_HUB}
          element={privateComponentWrapper(HubDashboard)}
        />
        <Route
          path={ROUTES.USER_HUB_CALENDAR}
          element={privateComponentWrapper(HubCalender)}
        />
        <Route
          path={ROUTES.USER_HUB_LISTS}
          element={privateComponentWrapper(HubLists)}
        />
        <Route
          path={ROUTES.USER_HUB_VAULT}
          element={privateComponentWrapper(HubVault)}
        />
        <Route
          path={ROUTES.USER_HUB_FILES}
          element={privateComponentWrapper(HubFiles)}
        />
        <Route
          path={ROUTES.USER_HUB_MUSIC}
          element={privateComponentWrapper(HubMusicPlayer)}
        />
        <Route
          path={ROUTES.USER_HUB_LOCATION}
          element={privateComponentWrapper(HubLocation)}
        />
        <Route
          path="*"
          element={privateComponentWrapper(Error404)}
        />

        {/* Admin Routes */}
        {isAdmin && (
          <>
            <Route
              path={ROUTES.ADMIN_OVERVIEW}
              element={privateComponentWrapper(Overview)}
            />
            <Route
              path={ROUTES.ADMIN_REPORTS}
              element={privateComponentWrapper(AdminReports)}
            />
            <Route
              path={ROUTES.ADMIN_USERS}
              element={privateComponentWrapper(AdminUsers)}
            />
            <Route
              path={ROUTES.ADMIN_HUBS}
              element={privateComponentWrapper(AdminHubs)}
            />
            <Route
              path={ROUTES.ADMIN_NETWORKS}
              element={privateComponentWrapper(AdminNetworks)}
            />
            <Route
              path={ROUTES.ADMIN_PAGES}
              element={privateComponentWrapper(AdminPages)}
            />
            <Route
              path={ROUTES.ADMIN_BANNED_WORDS}
              element={privateComponentWrapper(BannedWords)}
            />
            <Route
              path={ROUTES.ADMIN_MUSIC}
              element={privateComponentWrapper(AdminPlaylist)}
            />
            <Route
              path={ROUTES.ADMIN_THEME}
              element={privateComponentWrapper(AdminThemeCustomizer)}
            />
          </>
        )}
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
