import React, { useEffect, useState } from 'react';
import Loader from 'src/components/Loader';
import FsLightbox from 'fslightbox-react';
import 'react-image-lightbox/style.css';
import ReactPlayer from 'react-player/lazy';
import { loadMoreData } from 'src/redux/hubRedux';
import LoadMore from 'src/components/LoadMore';
import { ImageContainer } from 'src/containers/HubVault/styles';
import { Image } from 'react-feather';

function Media(props) {
  const [images, setImage] = useState([]);
  const [files, setfiles] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => () => {
    setImage([]);
  }, []);

  useEffect(() => {
    setfiles([]);
  }, [props.selectedFolder]);

  useEffect(() => {
    if (props.vaultFiles.length > 0) {
      if (props.meta.current_page === 1) {
        setfiles(props.vaultFiles);
      } else {
        setfiles([...files, ...props.vaultFiles]);
      }
    }
  }, [props.vaultFiles]);

  useEffect(() => {
    setImage(
      files.filter((file) => (
        file.original_url.split('.').pop() === 'jpg'
          || file.original_url.split('.').pop() === 'jpeg'
          || file.original_url.split('.').pop() === 'png'
          || file.original_url.split('.').pop() === 'gif'
          || file.is_video
      )),
    );
  }, [files]);

  return (
    <>
      <div className="container">
        {props.processing.status
        && props.processing.component === 'user-vault' ? (
          <Loader show />
          ) : images.length ? (
            <LoadMore
              data={images}
              meta={props.meta}
              getMore={() => props.dispatch(
                loadMoreData({
                  url: props.meta.links.next,
                  type: 'hub-vault',
                }),
              )}
              target="scroll-vault"
            >
              <div>
                {images.map((data, index) => (
                  <ImageContainer
                    key={index}
                    dark={props.theme.layout_version === 'dark-only'}
                  >
                    {!data.is_video ? (
                      <img
                        className="my-auto"
                        src={data.url}
                        alt="Error in Loading Feed"
                        onClick={() => {
                          setPhotoIndex(index + 1);
                          setToggler(!toggler);
                        }}
                      />
                    ) : (
                      <ReactPlayer
                        url={`${data.original_url.slice(0, -3)}mp4`}
                        controls
                        width="100%"
                        height="100%"
                        light={data.thumbnail}
                        onClick={() => {
                          setPhotoIndex(index + 1);
                          setToggler(!toggler);
                        }}
                      />
                    )}
                  </ImageContainer>
                ))}
              </div>
            </LoadMore>
          ) : (
            <div className="mt-4 pt-4 d-flex justify-content-center w-100">
              <div style={{ textAlign: 'center' }}>
                <Image size={100} alignmentBaseline="center" />
                <div className="h4 mt-2 ml-4">No Files Found</div>
              </div>
            </div>
          )}
      </div>
      {files.length ? (
        <FsLightbox
          toggler={toggler}
          slide={photoIndex}
          sources={files.map((file) => {
            if (file.is_video) return `${file.original_url.slice(0, -3)}mp4`;
            return file.original_url;
          })}
        />
      ) : null}
    </>
  );
}

export default Media;
