import React, { useEffect, useState } from 'react';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector, useDispatch } from 'react-redux';
import { User as UserFeather } from 'react-feather';
import Loader from 'src/components/Loader';
import { Button } from 'src/components/Button';
import { UserImage } from 'src/containers/Directory/styles';
import { User, ProfilePic } from 'src/components/StyledComponents';
import Modal from 'react-bootstrap/Modal';
import { addContact } from 'src/redux/profileRedux';
import { addDefaultSrc } from 'src/utils/helper';

const formatedData = (data) => {
  const arr = data.map((d) => {
    d.name = d.name.charAt(0).toUpperCase() + d.name.substring(1, d.name.length);
    return d;
  });
  arr.sort((a, b) => {
    const key1 = a.name;
    const key2 = b.name;
    if (key1 < key2) return -1;
    if (key1 > key2) return 1;
    return 0;
  });
  return arr;
};

function Users(props) {
  const { processing } = useSelector((state) => state.ServiceReducer);
  const [user, setUser] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [contactModal, setContactModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.users.length > 0) setUsersList(formatedData(props.users));
  }, [props.users]);

  return (
    <>
      {processing.status && processing.component === 'user-management' ? (
        <Loader show />
      ) : props.users.length ? (
        usersList.map((data, index) => (
          <div key={index} className="container">
            <div className="row">
              <div className="col-1 px-4 py-2 font-weight-bold h4 text-dark">
                {index === 0 ? (
                  <span>{data.name.charAt(0).toUpperCase()}</span>
                ) : usersList[index].name.charAt(0)
                    !== usersList[index - 1].name.charAt(0) ? (
                      <span>{data.name.charAt(0).toUpperCase()}</span>
                  ) : null}
              </div>
              <div className="col-11 px-4 py-0">
                <User className="d-flex justify-content-between pb-3 px-3 pt-2">
                  <div className="d-flex">
                    <ProfilePic>
                      <img
                        className="rounded-circle lazyloaded blur-up"
                        src={data.avatar ? data.avatar : ProfilePictureIcon}
                        onError={addDefaultSrc}
                        alt="#"
                        width="38px"
                        height="38px"
                      />
                    </ProfilePic>
                    <div className="ml-3">
                      <div className="font-weight-bold h6">{data.name}</div>
                      <div className="text-muted">
                          {`@${
                            data.username.length ? data.username : '-'
                          }`}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mr-3 pt-3 text-dark"
                    onClick={() => {
                      setUser(data);
                      setModalShow(true);
                    }}
                  >
                    <i className="fas fa-user-plus" />
                  </div>
                </User>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="mt-4 pt-4 d-flex justify-content-center w-100">
          <div style={{ textAlign: 'center' }}>
              <UserFeather size={80} alignmentBaseline="center" />
            <div className="h4 mt-2 ml-4">No Users Found</div>
          </div>
        </div>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none' }} closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card hovercard text-center">
                  <div className="cardheader" />

                  <UserImage>
                    <img
                      className="pro rounded-circle"
                      alt=""
                      src={user.avatar ? user.avatar : ProfilePictureIcon}
                      onError={addDefaultSrc}
                      width="135px"
                      height="100%"
                    />
                  </UserImage>

                  <div className="info">
                    <div
                      className="row detail"
                      data-intro="This is the your details"
                    >
                      <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="ttl-info text-left">
                              <h6>
                                <i className="fa fa-envelope mr-2" />
                                Email
                              </h6>
                              <span>{user.email ? user.email : '-'}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-sm-mb-0">
                              <h6>
                                <i className="fa fa-calendar" />
                                DOB
                              </h6>
                              <span>{user.dob ? user.dob : '-'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                        <div className="user-designation">
                          <div className="title">{user.name}</div>
                          <div className="desc mt-2">
                            {user.role
                              ? user.role.charAt(0).toUpperCase()
                                + user.role.substring(1, user.role.length)
                              : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-xs-mt">
                              <h6>Age</h6>
                              <span>{user.age ? user.age : ''}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-sm-mb-0">
                              <h6>Joined On</h6>
                              <span>{user.joined}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Button
                variant="solid"
                value="Add To Contacts"
                dissabled={!user.email}
                onClick={() => {
                  setModalShow(false);
                  setContactModal(true);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={contactModal}
        onHide={() => setContactModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: 'none' }} closeButton>
          Are you confirm to add
          {' '}
          {user.name}
          {' '}
          to your Contacts ?
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button
              variant="solid"
              value="Yes"
              margin="0px 5px"
              padding="0px 10px"
              onClick={() => {
                dispatch(addContact({ email: user.email }));
                setContactModal(false);
              }}
            />
            <Button
              variant="outlined"
              value="Cancel"
              onClick={() => {
                setContactModal(false);
              }}
              padding="0px 5px"
              margin="0px 5px"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Users;
