import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { Heading } from 'src/components/StyledComponents';

function Todos(props) {
  const { hubTodoList } = useSelector((state) => state.HubsReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  return (
    <>
      {processing.status && processing.component === 'hub' ? (
        <Loader show />
      ) : hubTodoList.length ? (
        hubTodoList.map((todo) => (
          <div
            className="d-flex justify-content-between p-2"
            key={todo.id}
            style={{ borderBottom: '1px solid grey', fontSize: '14px' }}
            onClick={() => props.navigate({
              pathname: props.route,
              state: {
                item: todo,
              },
            })}
          >
            <div className="w-50">{todo.name}</div>
            <div>
              {todo.reset_frequency ? (
                <i className="fas fa-redo-alt" />
              ) : null}
            </div>
            <div>
              {todo.tasks.total}
              {' '}
              tasks
            </div>
            <div>
              <i
                className="fas fa-bars"
                onClick={() => props.openCloseSidebar()}
              />
            </div>
          </div>
        ))
      ) : (
        <Heading className="text-center h4 mt-4">
          This hub does not have any list.
        </Heading>
      )}
    </>
  );
}

export default Todos;
