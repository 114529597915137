import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import Navbar from 'src/components/Navbar';
import Footer from 'src/components/Footer';
import LoginImage from 'src/assets/images/livit/login-image.jpg';
import InputField from 'src/components/InputField';
import { loginRequest, resetPasswordRequest } from 'src/redux/authRedux';
import { GOOGLE_RECAPTCHA_KEY } from 'src/constants/creds';
import { IS_DEV } from 'src/constants';
import { ForgetPasswordModal } from 'src/components';
import Marquee from 'react-fast-marquee';
import { ErrorText, LoginButton, Text } from './styles';

function HomePage() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [email, setEmail] = useState('admin@liviit.com');
  const [password, setPassword] = useState('password');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  // Selector
  const { loginError } = useSelector((state) => state.AuthReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue && !IS_DEV && GOOGLE_RECAPTCHA_KEY) {
      alert('Invalid ReCaptcha');
      return;
    }

    if (email.trim === '') {
      setEmailError(true);
      return;
    }
    if (password.length < 6) {
      setPasswordError(true);
      return;
    }
    const payload = {
      login: email,
      password,
      navigate,
    };
    dispatch(loginRequest(payload));
  };

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const sendResetMail = (resetEmail) => {
    dispatch(resetPasswordRequest({ email: resetEmail }));
    setForgotPasswordModal(false);
  };

  return (
    <div className="bg-white">
      <Navbar />
      <div style={{ marginTop: '60px', marginBottom: '100px' }}>
        <div>
          <Marquee speed="100">
            <div
              className="p-1 mb-2 h6"
              style={{
                border: '2px solid rgb(13, 174, 244)',
                borderRadius: '5px',
                fontFamily: '"Nunito",sans-serif',
              }}
            >
              Use Chrome for the Best Liviit Webapp experience with in-browser
              Notifications.
            </div>
          </Marquee>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h1
                className="m-0"
                style={{
                  color: '#0daef4',
                  fontFamily: '`Montserrat`, sans-serif',
                }}
              >
                Web Application Log In
              </h1>
              <Text>
                Liviit is easy for all ages to access and the most secure way to
                connect with friends, family and community. Liviit is safer,
                simpler and it&lsquo;s for everyone.
              </Text>
              <form className="w-100 mt-4" onSubmit={handleSubmit}>
                <div className="form-group">
                  <InputField
                    type="text"
                    className="form-control form-control-lg"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fontSize="18px"
                  />
                  {emailError ? (
                    <Alert onClose={() => setEmailError(false)} dismissible>
                      <ErrorText>**Invalid Email**</ErrorText>
                    </Alert>
                  ) : null}
                </div>
                <div className="form-group">
                  <InputField
                    type="password"
                    className="form-control form-control-lg"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {passwordError ? (
                    <Alert onClose={() => setPasswordError(false)} dismissible>
                      <ErrorText>
                        **Invalid Password! There must be atleast 6 characters**
                      </ErrorText>
                    </Alert>
                  ) : null}
                </div>
                {!IS_DEV && GOOGLE_RECAPTCHA_KEY ? (
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    onChange={onChange}
                  />
                ) : null}
                {loginError ? (
                  <div className="text-center">
                    <ErrorText>{loginError}</ErrorText>
                  </div>
                ) : null}
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <input type="checkbox" />
                    {' '}
                    Keep me Log in
                  </div>
                  <div
                    style={{
                      color: '#e4007b',
                      cursor: 'pointer',
                      fontFamily: '`Montserrat`, sans-serif',
                    }}
                    onClick={() => {
                      setForgotPasswordModal(true);
                    }}
                    onKeyPress={setForgotPasswordModal}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className="mb-2">
                  <LoginButton type="submit" disabled={processing?.status}>
                    {processing?.status ? 'Please Wait...' : 'Log In'}
                  </LoginButton>
                </div>
              </form>
            </div>
            <div className="col-sm-6">
              <div className="w-100">
                <img src={LoginImage} alt="#" width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ForgetPasswordModal show={forgotPasswordModal} onClose={setForgotPasswordModal} onSubmit={sendResetMail} />
    </div>
  );
}

export default HomePage;
