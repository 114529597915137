import React from 'react';
import { Shape } from 'src/components/StyledComponents';
import { ProfilePic, Controls } from 'src/containers/AudioPlayer/styles';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import { useDispatch, useSelector } from 'react-redux';
import { saveCurrentTrack } from 'src/redux/audioPlayerRedux';
import { UncontrolledTooltip } from 'reactstrap';
import ProgressBar from './progressBar';

function Player(props) {
  const {
    currentTrack, isPlaying, queue, currentTime, totalTime, audioref,
  } = useSelector((state) => state.AudioPlayerReducer);
  const dispatch = useDispatch();

  const playPauseResume = (track) => {
    if (track.id) {
      if (track.type === 'track' || track.provider === 'napster') {
        if (isPlaying) {
          window.Napster.player.pause();
        } else {
          window.Napster.player.resume();
        }
      } else if (isPlaying) {
        audioref.current.pause();
      } else {
        audioref.current.play();
      }
    }
  };

  const nextPrev = (cmd, track) => {
    if (track.id) {
      const index = queue.map((e) => e.id).indexOf(track.id);
      if (cmd === 'next') {
        if (index !== queue.length - 1) {
          if (queue[index + 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[index + 1].id);
          } else if (queue[index + 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[index + 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[index + 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[index + 1],
            }),
          );
        } else {
          if (queue[0].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[0].id);
          } else if (queue[0].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[0].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[0],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[0],
            }),
          );
        }
      } else if (cmd === 'prev') {
        if (index !== 0) {
          if (queue[index - 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[index - 1].id);
          } else if (queue[index - 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[index - 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[index - 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[index - 1],
            }),
          );
        } else {
          if (queue[queue.length - 1].type === 'track') {
            audioref.current.pause();
            window.Napster.player.play(queue[queue.length - 1].id);
          } else if (queue[queue.length - 1].provider === 'napster') {
            audioref.current.pause();
            window.Napster.player.play(queue[queue.length - 1].metadata[0].id);
          } else window.Napster.player.pause();
          dispatch(
            saveCurrentTrack({
              track: currentTrack.playlist
                ? {
                  ...queue[queue.length - 1],
                  playlist: currentTrack.playlist,
                  hub_id: currentTrack.hub_id,
                }
                : queue[queue.length - 1],
            }),
          );
        }
      }
    } else {
      return '';
    }
  };

  const seek = (cmd) => {
    if (isPlaying) {
      if (
        currentTrack.type === 'track'
        || currentTrack.provider === 'napster'
      ) {
        const time = cmd === 'forward' ? currentTime + 10 : currentTime - 10;
        window.Napster.player.seek(time);
      } else if (currentTrack.provider === 'local') {
        audioref.current.currentTime = cmd === 'forward'
          ? audioref.current.currentTime + 10
          : audioref.current.currentTime - 10;
      }
    }
  };

  const seekTo = (time) => {
    audioref.current.currentTime = time;
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <Shape
          height="180px"
          color={
            props.theme
              ? [props.theme.color_primary, props.theme.color_secondary]
              : ['#000', '#000']
          }
        />
        <div className="w-100" style={{ marginTop: '-100px', zIndex: '1' }}>
          <ProfilePic>
            <img
              className={`rounded-circle lazyloaded ${
                isPlaying ? 'rotate-image' : ''
              }`}
              src={
                currentTrack.name
                  ? currentTrack.provider === 'local'
                    ? MusicDisc
                    : `https://api.napster.com/imageserver/v2/albums/${
                      currentTrack.provider === 'napster'
                        ? currentTrack.metadata[0].albumId
                        : currentTrack.albumId
                    }/images/200x200.jpg`
                  : MusicDisc
              }
              alt="icon"
              width="100%"
              height="100%"
            />
          </ProfilePic>
        </div>
        <div className="py-2 text-center">
          <h3>{currentTrack ? currentTrack.name : ''}</h3>
        </div>
        <div className="py-1 text-center">
          <h5>
            <i>
              {currentTrack
                ? currentTrack.provider === 'napster'
                  ? currentTrack.metadata[0].artistName
                  : currentTrack.artistName
                : ''}
            </i>
          </h5>
        </div>
        <div className="p-2">
          <ProgressBar
            selectedTrack={
              currentTrack && currentTrack.provider === 'napster'
                ? currentTrack.metadata[0]
                : currentTrack
            }
            currentTime={currentTime}
            totalTime={totalTime}
            playing={isPlaying}
            localref={audioref}
            seekTo={seekTo}
          />
        </div>
        <Controls>
          <div className="buttons">
            <div
              className="ctrl-button button-sm"
              onClick={() => seek('back')}
              id="skip-back"
            >
              <i className="fas fa-backward fa-sm" aria-hidden="true" />
            </div>
            <UncontrolledTooltip placement="bottom" target="skip-back">
              Seek Back to 10 sec
            </UncontrolledTooltip>
            <div
              className="ctrl-button button-md"
              onClick={() => nextPrev('prev', currentTrack)}
              id="move-back"
            >
              <i className="fas fa-step-backward" aria-hidden="true" />
            </div>
            <UncontrolledTooltip placement="bottom" target="move-back">
              Previous
            </UncontrolledTooltip>
            <div
              className="ctrl-button button-lg"
              onClick={() => playPauseResume(
                currentTrack.provider === 'napster'
                  ? currentTrack.metadata[0]
                  : currentTrack,
              )}
              id="play-pause"
            >
              {isPlaying ? (
                <i className="fas fa-pause fa-lg" aria-hidden="true" />
              ) : (
                <i className="fas fa-play fa-lg" aria-hidden="true" />
              )}
            </div>
            <UncontrolledTooltip placement="bottom" target="play-pause">
              {isPlaying ? 'Pause' : 'Play'}
            </UncontrolledTooltip>
            <div
              className="ctrl-button button-md"
              onClick={() => nextPrev('next', currentTrack)}
              id="move-next"
            >
              <i className="fas fa-step-forward" />
            </div>
            <UncontrolledTooltip placement="bottom" target="move-next">
              Next
            </UncontrolledTooltip>
            <div
              className="ctrl-button button-sm"
              onClick={() => seek('forward')}
              id="skip-forward"
            >
              <i className="fas fa-forward fa-sm" aria-hidden="true" />
            </div>
            <UncontrolledTooltip placement="bottom" target="skip-forward">
              Seek Forward to 10 sec
            </UncontrolledTooltip>
          </div>
        </Controls>
      </div>
    </>
  );
}

export default Player;
