import axios from 'axios';
import { API_HOST } from 'src/constants';

export const axiosInstance = axios.create();

export const axiosAuth = axios.create();

export const baseUrl = API_HOST;

export const getHeaderData = () => {
  let headerdata = {};
  const linkedUser = localStorage.getItem('impersonate-user');
  const viewUser = localStorage.getItem('view-user')
    ? JSON.parse(localStorage.getItem('view-user')).id
    : null;
  if (linkedUser) headerdata = { 'Impersonate-As-User': linkedUser };
  if (viewUser) headerdata = { 'View-As-User': viewUser };
  return headerdata;
};

export const loadMoreData = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${payload.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'User-Activity-Type': payload.type ? payload.type : null,
      },
    });
    return response.data;
  }
};

// Get Stream Token
export const fetchStreamToken = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}stream/user/token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data.token;
};

// get favourite group
export const fetchFavouriteGroup = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}user-channels?channel_type=${payload.channel_type}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// toggle favourite group
export const updateFavouriteGroup = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}user-channels/${payload.channel_id}/toggle-favourite`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
