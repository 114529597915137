import React, { useState, useEffect } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHubTodoList,
  getHubTodoSublist,
  getHubDetails,
  addNewHubList,
  getHubTasks,
  addNewHubTask,
} from 'src/redux/hubRedux';
import { ModalButton, AddTaskModal } from 'src/components/Modal';
import Breadcrumb from 'src/components/Breadcrumb';
import PlusButton from 'src/components/PlusButton';
import Tasks from './Sections/tasks';
import List from './Sections/list';
import Folders from './Sections/folders';

function HubLists(props) {
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedSublist, setSelectedSublist] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [addTaskModalShow, setAddTaskModalShow] = useState(false);
  const [name, setName] = useState('');
  const [taskname, setTaskName] = useState('');
  const [count, setCount] = useState(1);

  const { hubTodoList, hubSublist, hub } = useSelector(
    (state) => state.HubsReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);
  useEffect(() => {
    if (hubTodoList.length === 0) dispatch(getHubDetails(props.match.params.id));
    else if (props.location.state && props.location.state.item) {
      dispatch(
        getHubTodoSublist({
          hub_id: props.match.params.id,
          todo_id: props.location.state.item.id,
        }),
      );

      setSelectedFolder(props.location.state.item);
    } else {
      dispatch(
        getHubTodoSublist({
          hub_id: props.match.params.id,
          todo_id: hubTodoList[0].id,
        }),
      );
      setSelectedFolder(hubTodoList[0]);
    }
  }, [hubTodoList]);

  useEffect(() => {
    setSelectedSublist({});
  }, [selectedFolder]);

  const getSublist = (list) => {
    dispatch(
      getHubTodoSublist({ hub_id: props.match.params.id, todo_id: list.id }),
    );
    setSelectedFolder(list);
  };

  const getlistTasks = (list) => {
    dispatch(getHubTasks({ hub_id: props.match.params.id, todo_id: list.id }));
    setSelectedSublist(list);
  };

  const addSublist = async (e) => {
    e.preventDefault();
    await dispatch(
      addNewHubList({
        id: props.match.params.id,
        data: {
          name,
          parent_todo_id: selectedFolder.id,
          invite_all: false,
        },
      }),
    );
    setModalShow(false);
    setName('');
    await dispatch(
      getHubTodoSublist({
        hub_id: props.match.params.id,
        todo_id: selectedFolder.id,
      }),
    );
  };

  const addTask = async (e) => {
    e.preventDefault();

    const payload = {
      hub_id: props.match.params.id,
      todo_id: selectedSublist.id,
      tasks: [taskname],
      check_count: count.key,
    };
    await dispatch(addNewHubTask(payload));
    setAddTaskModalShow(false);
    setTaskName('');
    setCount(1);
    await dispatch(
      getHubTasks({ hub_id: props.match.params.id, todo_id: payload.todo_id }),
    );
  };

  return (
    <>
      <Breadcrumb parent="Hub Lists" title={hub ? hub.alias : ''} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Folders
              processing={processing}
              folders={hubTodoList}
              getSublist={getSublist}
              addList={addNewHubList}
              getLists={getHubTodoList}
              selected={selectedFolder}
              {...props}
            />
          </div>
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">
                      {Object.keys(selectedFolder).length !== 0
                        ? selectedFolder.name.charAt(0).toUpperCase()
                          + selectedFolder.name.substring(
                            1,
                            selectedFolder.name.length,
                          )
                        : 'Lists'}
                      <span className="ml-2">
                        {selectedFolder.is_private ? (
                          <i className="fas fa-lock" />
                        ) : null}
                      </span>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      {Object.keys(selectedFolder).length !== 0 ? (
                        <div className="d-flex">
                          <h6
                            className="mx-2 btn p-0"
                            onClick={() => setModalShow(true)}
                          >
                            <i className="fas fa-pencil-alt" />
                          </h6>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection height="90%">
                  <List
                    folder={selectedFolder}
                    processing={processing}
                    sublist={hubSublist}
                    getSublist={getSublist}
                    selectedSublist={selectedSublist}
                    setSelectedSublist={setSelectedSublist}
                    getlistTasks={getlistTasks}
                    {...props}
                  />
                  <ModalButton
                    show={modalShow}
                    close={setModalShow}
                    title="Please Enter List Name"
                    submit={addSublist}
                    value={name}
                    setValue={setName}
                  />
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                {selectedSublist.name ? (
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="align-abjust">
                        {selectedSublist.name ? selectedSublist.name : ''}
                      </h5>
                    </div>
                    <div className="col-sm-6">
                      <div className="pull-right right-header">
                        <PlusButton
                          onClick={() => {
                            setAddTaskModalShow(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card-body">
                {selectedSublist.name ? (
                  <ScrollableSection height="90%">
                    <Tasks
                      processing={processing}
                      list={selectedSublist}
                      {...props}
                    />
                  </ScrollableSection>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTaskModal
        show={addTaskModalShow}
        close={setAddTaskModalShow}
        title="Enter Task Name"
        submit={addTask}
        value={taskname}
        setValue={setTaskName}
        showCount
        count={count}
        setCount={setCount}
      />
    </>
  );
}

export default HubLists;
