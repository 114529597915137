export const ProfileReducer = (state) => state.ProfileReducer;
export const SubscriptionReducer = (state) => state.SubscriptionReducer;
export const AuthReducer = (state) => state.AuthReducer;
export const FeedsReducer = (state) => state.FeedsReducer;
export const RemaindersReducer = (state) => state.RemaindersReducer;
export const ServiceReducer = (state) => state.ServiceReducer;
export const TodoReducer = (state) => state.TodoReducer;
export const UsersReducer = (state) => state.UsersReducer;
export const VaultReducer = (state) => state.VaultReducer;
export const NetworkReducer = (state) => state.NetworkReducer;
export const ChatReducer = (state) => state.ChatReducer;
export const PageReducer = (state) => state.PageReducer;
export const BannedWordsReducer = (state) => state.BannedWordsReducer;
export const ThemeReducer = (state) => state.ThemeReducer;
export const FilesReducer = (state) => state.FilesReducer;
export const AudioPlayerReducer = (state) => state.AudioPlayerReducer;
export const ReportReducer = (state) => state.ReportReducer;
export const HubsReducer = (state) => state.HubsReducer;
