import getBrowserFingerprint from 'get-browser-fingerprint';
// actions
export const loginRequest = (payload) => ({
  type: 'LOGIN_REQUEST',
  payload,
});

export const loginSuccess = (payload) => ({
  type: 'LOGIN_SUCCESS',
  payload,
});

export const loginFail = (payload) => ({
  type: 'LOGIN_FAIL',
  payload,
});

export const resetPasswordRequest = (payload) => ({
  type: 'RESET_PASSWORD',
  payload,
});

export const requestLogout = (payload) => ({
  type: 'REQUEST_LOGOUT',
  payload,
});

export const logOutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const refreshUserToken = () => ({
  type: 'REFRESH_TOKEN',
});

export const refreshTokenSuccess = (payload) => ({
  type: 'REFRESH_TOKEN_SUCCESS',
  payload,
});

export const updateFcmToken = (payload) => ({
  type: 'UPDATE_FCM_TOKEN',
  payload,
});

// store
const initialState = {
  userToken: '',
  expirein: '',
  loginError: '',
  user_level: '',
};

// authReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('deviceId', getBrowserFingerprint());
      localStorage.setItem(
        'expire_in',
        new Date(Date.now() + action.payload.expires_in * 1000),
      );
      localStorage.setItem('user-level', action.payload.user_level);
      return {
        ...state,
        userToken: action.payload.token,
        expirein: action.payload.expires_in,
        user_level: action.payload.user_level,
        loginError: '',
      };
    case 'REFRESH_TOKEN_SUCCESS':
      console.log(action.payload);
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem(
        'expire_in',
        new Date(Date.now() + action.payload.expires_in * 1000),
      );
      return {
        ...state,
        userToken: action.payload.token,
        expirein: action.payload.expires_in,
        loginError: '',
      };
    case 'LOGIN_FAIL':
      return { ...state, loginError: action.payload };
    case 'LOGOUT_SUCCESS':
      localStorage.clear();
      return initialState;

    default:
      return { ...state };
  }
};
