import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { CheckBox } from 'src/containers/Lists/styles';
import { Heading } from 'src/components/StyledComponents';
import Loader from 'src/components/Loader';
import { toggleTask } from 'src/redux/todoRedux';

function Tasks(props) {
  const { tasks } = useSelector((state) => state.TodoReducer);
  const dispatch = useDispatch();

  return (
    <>
      {props.processing.status
      && props.processing.component === 'user-tasks' ? (
        <Loader show />
        ) : tasks.length ? (
          <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId="droppable" type="tasks">
              {(provided) => (
                <div
                  className="px-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tasks.map((todo, index1) => (
                    <Draggable
                      key={todo.id}
                      draggableId={todo.id.toString()}
                      index={index1}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className="d-flex justify-content-between py-2"
                            style={{
                              borderTop: '1px solid lightgrey',
                            }}
                          >
                            <div>
                              <div>
                                {todo.is_completed ? (
                                  <del>{todo.description}</del>
                                ) : (
                                  <span>{todo.description}</span>
                                )}
                              </div>
                              <div className="text-muted">{todo.date_time}</div>
                            </div>

                            <div {...provided.dragHandleProps}>
                              <i className="fas fa-bars" />
                            </div>
                          </div>
                          <div className="row">
                            {[...Array(todo.check_count).keys()].map(
                              (count, index) => (
                                <div className="col-1 my-2" key={index}>
                                  <CheckBox
                                    onClick={() => dispatch(
                                      toggleTask([
                                        props.list.id,
                                        todo.id,
                                        {
                                          check_position: index,
                                        },
                                      ]),
                                    )}
                                    selected={
                                        !!todo.completed_by[index]
                                      }
                                    image={
                                        todo.completed_by[index]
                                          ? todo.completed_by[index].avatar
                                          : ProfilePictureIcon
                                      }
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="text-center">
            <Heading>This List does not have any Task.</Heading>
          </div>
        )}
    </>
  );
}

export default Tasks;
