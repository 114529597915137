import styled from 'styled-components';

export const ImageContainer = styled.div`
  float: left;
  margin: 5px;
  width: 31%;
  height: 215px;
  border: ${(props) => (props.dark ? 'none' : '1px solid whitesmoke')};
  background-color: transparent;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
  }
`;
