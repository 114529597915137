import React, { useState, useEffect } from 'react';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector, useDispatch } from 'react-redux';
import { UserPlus } from 'react-feather';
import Loader from 'src/components/Loader';
import { User, ProfilePic } from 'src/components/StyledComponents';
import Modal from 'react-bootstrap/Modal';
import { addContact } from 'src/redux/profileRedux';
import { Button } from 'src/components/Button';
import { UserImage } from './styles';
import { formatedData } from './index';

function HubMembers(props) {
  const [contactsData, setContactsData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [addedContact, setAddedContact] = useState({});
  const { hubs } = useSelector((state) => state.HubsReducer);
  const { user } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hubs.length > 0 && Object.keys(user).length > 0) {
      const data = [];
      hubs.forEach((element) => {
        element.members.forEach((member) => {
          let flag = true;
          if (member.user_id !== user.id) {
            data.forEach((contact) => {
              if (contact.user_id === member.user_id) {
                flag = false;
              }
            });
            if (flag) data.push(member);
          }
        });
      });

      data.forEach((member) => {
        let flag = false;
        props.contacts.forEach((contact) => {
          if (member.user_id === contact.id) {
            flag = true;
          }
        });
        member.is_Contact = flag;
      });

      setContactsData(formatedData(data, 'hubs'));
    }
  }, [hubs, user]);

  return (
    <>
      {processing.status && processing.component === 'user-hubs' ? (
        <Loader show />
      ) : contactsData.length ? (
        contactsData.map((data, index) => (
          <div key={index} className="container">
            <div className="row">
              <div className="col-1 px-4 py-2 font-weight-bold h4 text-dark">
                {index === 0 ? (
                  <span>{data.user_name.charAt(0).toUpperCase()}</span>
                ) : contactsData[index].user_name.charAt(0)
                      !== contactsData[index - 1].user_name.charAt(0) ? (
                        <span>{data.user_name.charAt(0).toUpperCase()}</span>
                  ) : null}
              </div>

              <div className="col-11 px-4 py-0">
                <User
                  key={data.id}
                  className="d-flex justify-content-between pb-3 px-3 pt-2"
                >
                  <div className="d-flex">
                    <ProfilePic>
                      <img
                        className="rounded-circle lazyloaded blur-up"
                        src={
                            data.user_avatar
                              ? data.user_avatar
                              : ProfilePictureIcon
                          }
                        onError={props.addDefaultSrc}
                        alt="#"
                        width="38px"
                        height="38px"
                      />
                    </ProfilePic>
                    <div className="ml-3">
                      <div className="font-weight-bold h6">
                          {data.user_name}
                      </div>
                      <div className="text-muted">{`@${data.user_username}`}</div>
                    </div>
                  </div>

                  {!data.is_Contact ? (
                    <div
                      className="mr-3 pt-3 text-dark"
                      onClick={() => {
                        setAddedContact(data);
                        setModalShow(true);
                      }}
                    >
                      <i className="fas fa-user-plus" />
                    </div>
                  ) : null}
                </User>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="mt-4 pt-4 d-flex justify-content-center w-100">
          <div style={{ textAlign: 'center' }}>
              <UserPlus size={100} alignmentBaseline="center" />
              <div className="h4 mt-2 ml-4">No Member Found</div>
          </div>
        </div>
      )}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ border: 'none' }} closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card hovercard text-center">
                  <div className="cardheader" />

                  <UserImage>
                    <img
                      className="pro rounded-circle"
                      alt=""
                      src={
                        addedContact.user_avatar
                          ? addedContact.user_avatar
                          : ProfilePictureIcon
                      }
                      onError={props.addDefaultSrc}
                      width="135px"
                      height="100%"
                    />
                  </UserImage>

                  <div className="info">
                    <div
                      className="row detail"
                      data-intro="This is the your details"
                    >
                      <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="ttl-info text-left">
                              <h6>
                                <i className="fa fa-envelope mr-2" />
                                Email
                              </h6>
                              <span>
                                {addedContact.user_email
                                  ? addedContact.user_email
                                  : '-'}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-sm-mb-0">
                              <h6>
                                <i className="fa fa-calendar" />
                                DOB
                              </h6>
                              <span>
                                {addedContact.dob ? addedContact.dob : '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                        <div className="user-designation">
                          <div className="title">{addedContact.user_name}</div>
                          <div className="desc mt-2">
                            {addedContact.role
                              ? addedContact.role.charAt(0).toUpperCase()
                                + addedContact.role.substring(
                                  1,
                                  addedContact.role.length,
                                )
                              : '-'}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-xs-mt">
                              <h6>Age</h6>
                              <span>
                                {addedContact.user_age
                                  ? addedContact.user_age
                                  : ''}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="ttl-info text-left ttl-sm-mb-0">
                              <h6>Joined On</h6>
                              <span>
                                {addedContact.joined
                                  ? addedContact.joined
                                  : '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Button
                variant="solid"
                value="Add To Contacts"
                dissabled={!user.email}
                onClick={() => {
                  setModalShow(false);
                  setContactModal(true);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={contactModal}
        onHide={() => setContactModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: 'none' }} closeButton>
          Are you confirm to add
          {' '}
          {addedContact.user_name}
          {' '}
          to your Contacts ?
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button
              variant="solid"
              value="Yes"
              margin="0px 5px"
              padding="0px 10px"
              onClick={() => {
                dispatch(addContact({ email: addedContact.user_email }));
                setContactModal(false);
              }}
            />
            <Button
              variant="outlined"
              value="Cancel"
              onClick={() => {
                setContactModal(false);
              }}
              padding="0px 5px"
              margin="0px 5px"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HubMembers;
