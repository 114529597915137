// actions
export const getAllVaultFiles = () => ({
  type: 'GET_ALL_VAULT_FILES',
});

export const saveAllVaultFiles = (payload) => ({
  type: 'SAVE_VAULT_FILES',
  payload,
});

export const addFileInFolder = (payload) => ({
  type: 'ADD_FILE',
  payload,
});

export const addVaultFolder = (payload) => ({
  type: 'ADD_FOLDER',
  payload,
});

export const getAllVaultFolders = () => ({
  type: 'GET_ALL_VAULT_FOLDERS',
});

export const saveAllVaultFolders = (payload) => ({
  type: 'SAVE_ALL_VAULT_FOLDERS',
  payload,
});

export const getFolderFiles = (payload) => ({
  type: 'GET_FOLDER_FILES',
  payload,
});
export const saveVaultFolderFiles = (payload) => ({
  type: 'SAVE_VAULT_FOLDER_FILES',
  payload,
});

export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_VAULT',
  payload,
});

export const processVault = (payload) => ({
  type: 'PROCESSING_VAULT',
  payload,
});

// store
const initialState = {
  vaultFiles: [],
  folderFiles: [],
  vaultFolders: [],
  meta: {},
  foldermeta: {},
  vaultProcessing: { status: false, component: '' },
};

// vaultReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_VAULT_FILES':
      return {
        ...state,
        vaultFiles: action.payload.data,
        meta: action.payload.meta.pagination,
      };
    case 'SAVE_VAULT_FOLDER_FILES':
      return {
        ...state,
        folderFiles: action.payload.data,
        foldermeta: action.payload.meta.pagination,
      };
    case 'SAVE_ALL_VAULT_FOLDERS':
      return { ...state, vaultFolders: action.payload };
    case 'PROCESSING_VAULT':
      return { ...state, vaultProcessing: action.payload };
    default:
      return { ...state };
  }
};
