import React, { useState, useEffect } from 'react';
import {
  ScrollableSection,
  ProfilePic,
  User,
} from 'src/components/StyledComponents';
import InputField from 'src/components/InputField';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  AlertOctagon,
  Edit,
  Mail,
  Phone,
  Video,
  XOctagon,
  UserPlus,
} from 'react-feather';
import Loader from 'src/components/Loader';
import {
  toggleContact,
  loadMoreContacts,
  changeContact,
  getContact,
} from 'src/redux/profileRedux';
import LoadMore from 'src/components/LoadMore';
import Breadcrumb from 'src/components/Breadcrumb';
import { addDefaultSrc, actionHandler } from 'src/utils/helper';
import { Button } from 'src/components/Button';
import { USER_MESSAGES } from 'src/constants/routes';
import * as enums from 'src/CometChatWorkspace/src/util/enums';
import SelectMenu from 'src/components/DropDown';
import { UserCardModal } from 'src/components/Modal';
import { DropdownData } from 'src/utils/data';
import HubMembers from './hubMembers';

export const formatedData = (data, state) => {
  if (state === 'hubs') {
    const arr = data.map((d) => {
      d.user_name = d.user_name.charAt(0).toUpperCase()
        + d.user_name.substring(1, d.user_name.length);
      return d;
    });
    arr.sort((a, b) => {
      const key1 = a.user_name;
      const key2 = b.user_name;
      if (key1 < key2) return -1;
      if (key1 > key2) return 1;
      return 0;
    });
    return arr;
  }
  const arr = data.map((d) => {
    d.name = d.name.charAt(0).toUpperCase() + d.name.substring(1, d.name.length);
    return d;
  });
  arr.sort((a, b) => {
    const key1 = a.name;
    const key2 = b.name;
    if (key1 < key2) return -1;
    if (key1 > key2) return 1;
    return 0;
  });
  return arr;
};

function Contacts(props) {
  const [contactsData, setContactsData] = useState([]);
  const [user, setUser] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [state, setstate] = useState('all');
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { contacts, contactsMeta, toggledContact } = useSelector(
    (state) => state.ProfileReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    dispatch(getContact());
    return () => setContactsData([]);
  }, []);

  useEffect(() => {
    if (contacts.length > 0 && state === 'all') {
      contactsMeta.current_page === 1
        ? setContactsData(contacts)
        : setContactsData([...contactsData, ...formatedData(contacts, state)]);
    }
  }, [contacts]);

  useEffect(() => {
    if (Object.keys(toggledContact).length > 0) {
      const data = [];
      contactsData.forEach((contact) => {
        if (contact.id === toggledContact.id) {
          data.push(toggledContact);
        } else {
          data.push(contact);
        }
      });
      setContactsData(data);
    }
  }, [toggledContact]);

  const searchContacts = (e) => {
    setContactsData([]);
    dispatch(getContact(e.target.value));
  };

  const getHeading = () => {
    if (state === 'fav') return 'Favourites';
    if (state === 'hubs') return 'Hub Members';
    return contactsData.length > 0 ? `${contactsData.length} Contacts` : ' ';
  };

  const updateContacts = (type) => {
    const payload = {
      email: user.email,
      update: { type: type.key },
    };
    setContactsData([]);
    dispatch(changeContact(payload));
    setEdit(false);
  };

  const handleContactClick = (contact) => {
    if (window.innerWidth <= 992) {
      setSelectedUser(contact);
      setShowModal(true);
    }
  };

  return (
    <>
      <Breadcrumb parent="Contacts" title="Contacts" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Contacts</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    <li
                      className={`list-group-item ${
                        state === 'all' ? 'active' : null
                      }`}
                      onClick={() => setstate('all')}
                    >
                      <div>All</div>
                    </li>
                    <li
                      className={`list-group-item mt-1 ${
                        state === 'fav' ? 'active' : null
                      }`}
                      onClick={() => setstate('fav')}
                    >
                      <div>Favourites</div>
                    </li>
                    <li
                      className={`list-group-item mt-1 ${
                        state === 'hubs' ? 'active' : null
                      }`}
                      onClick={() => setstate('hubs')}
                    >
                      <div>Hub Members</div>
                    </li>
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6 d-flex align-items-center">
                    <h5 className="align-abjust">{getHeading()}</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <div
                        className={`form-group mt-2 mb-0 ${
                          state !== 'all' ? 'd-none' : null
                        }`}
                      >
                        <InputField
                          className="form-control"
                          type="text"
                          placeholder="Search..."
                          borderRadius="30px"
                          fontSize="13px"
                          padding="0px 0px 0px 20px"
                          onChange={() => {}}
                          onKeyUp={(e) => searchContacts(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ScrollableSection id="scroll-contacts">
                  {processing.status
                  && (processing.component === 'user-profile'
                    || processing.component === 'contacts') ? (
                      <Loader show />
                    ) : contactsData.length ? (
                      <LoadMore
                        data={contactsData}
                        meta={contactsMeta}
                        getMore={() => dispatch(
                          loadMoreContacts({
                            url: contactsMeta.links.next,
                          }),
                        )}
                        target="scroll-contacts"
                      >
                        {state === 'hubs' ? (
                          <HubMembers
                            addDefaultSrc={addDefaultSrc}
                            contacts={contactsData}
                          />
                        ) : state === 'fav' ? (
                          contactsData.map((data, index) => (
                            data.is_favourite === 1 && (
                            <div key={index} className="container">
                              <div className="row">
                                <div className="col-1 px-2 py-2 font-weight-bold h4 text-dark">
                                  {index === 0 ? (
                                  <span>
                                    {data.name.charAt(0).toUpperCase()}
                                  </span>
                                  ) : contactsData[index].name.charAt(0)
                                      !== contactsData[index - 1].name.charAt(0) ? (
                                        <span>
                                          {data.name.charAt(0).toUpperCase()}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-11 px-4 py-0">
                                  <User
                                    key={data.id}
                                    className="d-flex justify-content-between pb-3 px-2 pt-2"
                                  >
                                  <div
                                    className="d-flex"
                                    style={{ width: '30%' }}
                                  >
                                    <ProfilePic>
                                          <img
                                            className="rounded-circle lazyloaded blur-up"
                                            src={
                                              data.avatar
                                                ? data.avatar
                                                : ProfilePictureIcon
                                            }
                                            onError={addDefaultSrc}
                                            alt="#"
                                            width="38px"
                                            height="38px"
                                          />
                                    </ProfilePic>
                                    <div className="ml-3">
                                          <div className="font-weight-bold h6">
                                            {data.name}
                                          </div>
                                          <div className="text-muted">{`@${data.username}`}</div>
                                    </div>
                                  </div>
                                  <div className="text-center w-25 d-none d-lg-inline-block">
                                    <Mail
                                      className="mx-1"
                                      onClick={() => {
                                        props.navigate({
                                          pathname: USER_MESSAGES,
                                          state: {
                                            contact: {
                                              id: data.id.toString(),
                                              type: 'user',
                                            },
                                          },
                                        });
                                      }}
                                    />
                                    <Phone
                                      className="mx-1"
                                      onClick={() => {
                                        actionHandler(
                                          enums.ACTIONS.INITIATE_AUDIO_CALL,
                                          {
                                            type: 'user',
                                            id: `${data.id}`,
                                          },
                                        );
                                      }}
                                    />
                                    <Video
                                      className="mx-1"
                                      onClick={() => {
                                        actionHandler(
                                          enums.ACTIONS.INITIATE_VIDEO_CALL,
                                          {
                                            type: 'user',
                                            id: `${data.id}`,
                                          },
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="text-right w-25">
                                    <Button
                                      variant="outlined"
                                      className={`${
                                        edit && user === data
                                          ? 'd-none'
                                          : ''
                                      }`}
                                      value={(
                                            <div>
                                              <span>
                                                {data.type ? data.type : 'Type'}
                                              </span>
                                              <Edit
                                                className="mx-1"
                                                width="20px"
                                                height="20px"
                                                style={{ verticalAlign: 'sub' }}
                                              />
                                            </div>
                                          )}
                                      padding="0px 10px"
                                      fontSize="14px"
                                      onClick={() => {
                                        setUser(data);
                                        setEdit(true);
                                      }}
                                    />
                                    <SelectMenu
                                      visibility={
                                            edit && user === data
                                              ? 'visible'
                                              : 'invisible'
                                          }
                                      seleced={
                                            data.type ? data.type : 'Type'
                                          }
                                      setSelected={updateContacts}
                                      options={
                                            DropdownData.contactsTypeOptions
                                          }
                                    />
                                  </div>
                                  <div
                                    className="mr-3 pt-3 text-right"
                                    style={{ width: '20%' }}
                                  >
                                    {data.is_favourite ? (
                                          <span className="ml-1 text-dark">
                                            <i className="fas fa-star" />
                                          </span>
                                    ) : (
                                          <span className="ml-1">
                                            <i className="far fa-star" />
                                          </span>
                                    )}
                                  </div>
                                  </User>
                                </div>
                              </div>
                            </div>
                            )
                          ))
                        ) : (
                          contactsData.map((data, index) => (
                            <div key={index} className="container">
                              <div className="row">
                                {state === 'all' && (
                                <div className="col-1 px-2 py-2 font-weight-bold h4 text-dark">
                                {index === 0 ? (
                                  <span>
                                    {data.name.charAt(0).toUpperCase()}
                                  </span>
                                ) : contactsData[index].name.charAt(0)
                                      !== contactsData[index - 1].name.charAt(0) ? (
                                        <span>
                                          {data.name.charAt(0).toUpperCase()}
                                        </span>
                                  ) : null}
                                {data.is_blocked ? (
                                  <span>
                                    <XOctagon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setSelectedUser(data);
                                        setShowModal(true);
                                      }}
                                    />
                                  </span>
                                ) : null}
                                </div>
                                )}

                                <div className="col-11 px-4 py-0">
                                  <User
                                    key={data.id}
                                    className={`d-flex pb-3 px-2 pt-2 ${
                                      data.is_blocked
                                        ? 'text-muted bg-light'
                                        : ''
                                    }`}
                                    style={
                                      data.is_blocked
                                        ? { pointerEvents: 'none' }
                                        : {}
                                    }
                                  >
                                  <div
                                    className="d-flex"
                                    style={{ width: '30%' }}
                                    onClick={() => handleContactClick(data)}
                                  >
                                    <ProfilePic>
                                      <img
                                        className="rounded-circle lazyloaded blur-up"
                                        src={
                                            data.avatar
                                              ? data.avatar
                                              : ProfilePictureIcon
                                          }
                                        onError={addDefaultSrc}
                                        alt="#"
                                        width="38px"
                                        height="38px"
                                      />
                                    </ProfilePic>
                                    <div className="ml-3">
                                      <div className="font-weight-bold h6">
                                          {data.name}
                                      </div>
                                      <div className="text-muted">{`@${data.username}`}</div>
                                    </div>
                                  </div>
                                  <div className="text-center w-25 d-none d-lg-inline-block">
                                    <Mail
                                      className="mx-1"
                                      onClick={() => {
                                        props.navigate({
                                          pathname: USER_MESSAGES,
                                          state: {
                                            contact: {
                                              id: data.id.toString(),
                                              type: 'user',
                                            },
                                          },
                                        });
                                      }}
                                    />
                                    <Phone
                                      className="mx-1"
                                      onClick={() => {
                                        actionHandler(
                                          enums.ACTIONS.INITIATE_AUDIO_CALL,
                                          {
                                            type: 'user',
                                            id: `${data.id}`,
                                          },
                                        );
                                      }}
                                    />
                                    <Video
                                      className="mx-1"
                                      onClick={() => {
                                        actionHandler(
                                          enums.ACTIONS.INITIATE_VIDEO_CALL,
                                          {
                                            type: 'user',
                                            id: `${data.id}`,
                                          },
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="text-right w-25">
                                    <Button
                                      variant="outlined"
                                      className={`${
                                        edit && user === data ? 'd-none' : ''
                                      }`}
                                      value={(
                                          <div>
                                            <span>
                                              {data.type ? data.type : 'Type'}
                                            </span>
                                            <Edit
                                              className="mx-1"
                                              width="20px"
                                              height="20px"
                                              style={{ verticalAlign: 'sub' }}
                                            />
                                          </div>
                                        )}
                                      padding="0px 10px"
                                      fontSize="14px"
                                      onClick={() => {
                                        setUser(data);
                                        setEdit(true);
                                      }}
                                    />
                                    <SelectMenu
                                      visibility={
                                          edit && user === data
                                            ? 'visible'
                                            : 'invisible'
                                        }
                                      seleced={data.type ? data.type : 'Type'}
                                      setSelected={updateContacts}
                                      options={
                                          DropdownData.contactsTypeOptions
                                        }
                                    />
                                  </div>
                                  <div
                                    className="mr-3 pt-3 text-right"
                                    style={{ width: '20%' }}
                                    onClick={() => {
                                      dispatch(
                                        toggleContact({ email: data.email }),
                                      );
                                    }}
                                  >
                                    {data.is_favourite ? (
                                      <span className="ml-1 text-dark">
                                          <i className="fas fa-star" />
                                      </span>
                                    ) : (
                                        <span className="ml-1">
                                          <i className="far fa-star" />
                                        </span>
                                    )}
                                  </div>
                                  <div className="pt-3">
                                    <span className="ml-1">
                                      <AlertOctagon
                                        size="16"
                                        onClick={() => {
                                          setSelectedUser(data);
                                          setShowModal(true);
                                        }}
                                      />
                                    </span>
                                  </div>
                                  </User>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </LoadMore>
                    ) : (
                      <div className="mt-4 pt-4 d-flex justify-content-center w-100">
                        <div style={{ textAlign: 'center' }}>
                          <UserPlus size={80} alignmentBaseline="center" />
                          <div className="h4 mt-2 ml-4">No Contacts Found</div>
                        </div>
                      </div>
                    )}
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none">
        <UserCardModal
          show={showModal}
          setShow={setShowModal}
          user={selectedUser}
          {...props}
        />
      </div>
    </>
  );
}

export default Contacts;
