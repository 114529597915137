import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  checkNotification,
  loadMoreData,
} from 'src/redux/profileRedux';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import Loader from 'src/components/Loader';
import { Button } from 'src/components/Button';
import { ThemeText, ProfilePic, ScrollableSection } from './styles';
import LoadMore from '../../LoadMore';

function Notifications({ setIsNewNotification }) {
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();
  const { notifications, meta } = useSelector((state) => state.ProfileReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(getNotifications());
      setIsNewNotification(false);
    }
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      setNotificationData([...notificationData, ...notifications]);
      setIsNewNotification(true);
    }
  }, [notifications]);

  const handleNotification = (url, id) => {
    setNotificationData([]);
    dispatch(checkNotification({ url, id }));
  };

  return (
    <div>
      <ul className="notification-dropdown onhover-show-div p-0">
        <li>
          <ThemeText>Notifications</ThemeText>
        </li>
        <ScrollableSection className="mb-2" id="scroll">
          {processing.status && processing.component === 'notifications' ? (
            <li>
              <Loader show />
            </li>
          ) : notificationData.length ? (
            <LoadMore
              data={notificationData}
              getMore={() => dispatch(
                loadMoreData({
                  url: meta.links.next,
                  type: 'notification',
                }),
              )}
              meta={meta}
              target="scroll"
            >
              {notificationData.map((item) => (
                <li key={item.id}>
                  <div className="media">
                      <div className="media-body d-flex justify-content-between">
                        <div className="d-flex">
                          <ProfilePic>
                            <img
                              className="rounded-circle lazyloaded blur-up"
                              src={
                                  item.avatar ? item.avatar : ProfilePictureIcon
                                }
                              alt="#"
                              width="25px"
                              height="25px"
                            />
                          </ProfilePic>
                          <div className=" ml-1">{item.title}</div>
                        </div>
                        <div>{item.date_time}</div>
                      </div>
                  </div>
                  <div className="px-4">{item.description}</div>
                  {item.actions.button ? (
                      <div className="text-center">
                        <Button
                          padding="0px 12px"
                          margin="0px 2px"
                          value="Accept"
                          onClick={() => handleNotification(
                            item.actions.accept_url,
                            item.id,
                          )}
                        />
                        <Button
                          padding="0px 12px"
                          margin="0px 2px"
                          value="Reject"
                          onClick={() => handleNotification(
                            item.actions.reject_url,
                            item.id,
                          )}
                        />
                      </div>
                  ) : null}
                </li>
              ))}
            </LoadMore>
          ) : (
            <li>
              <div className="text-center">No New Notifications</div>
            </li>
          )}
        </ScrollableSection>
      </ul>
    </div>
  );
}

export default Notifications;
