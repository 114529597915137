import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { addDefaultSrc } from 'src/utils/helper';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { useSelector } from 'react-redux';
import {
  ProfilePic, ScrollableSection, StyledTable, Shape,
} from './styles';

function DetailModal(props) {
  const { user } = useSelector((state) => state.ProfileReducer);
  const { theme } = useSelector((state) => state.ThemeReducer);
  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ border: 'none', padding: '5px 15px 0px 0px' }}
        closeButton
      />
      <Modal.Body>
        <div className="text-center">
          <Shape
            color={
              theme.useMobileTheme
                ? user.theme && user.theme.mobile
                  ? user.theme.mobile.header_color
                  : ['#000', '#000']
                : [theme.color_primary, theme.color_secondary]
            }
          />
          <div
            className="text-center mb-2"
            style={{ marginTop: '-40px', zIndex: '1' }}
          >
            <ProfilePic width="60px" height="60px">
              <img
                src={props.imgSrc}
                className="rounded-circle lazyloaded blur-up"
                width="100%"
                height="100%"
                alt="#"
                onError={addDefaultSrc}
              />
            </ProfilePic>
            <h5>{props.getName}</h5>
          </div>
          <h4>{props.event.title}</h4>
          <h5>
            (
            {' '}
            {props.event.recurrence_frequency[0].toUpperCase()
              + props.event.recurrence_frequency.slice(
                1,
                props.event.recurrence_frequency.length,
              )}
            )
          </h5>
        </div>
        <div style={{ height: '300px' }}>
          <ScrollableSection>
            <div className="container-fluid">
              <StyledTable>
                <tbody>
                  <tr>
                    <td className="field">When:</td>
                    <td className="value">
                      {new Date(props.event.start_time).toDateString()}
                      {' '}
                      (
                      {props.getTime(props.event.start_time)}
                      -
                      {props.getTime(props.event.end_time)}
                      )
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Category:</td>
                    <td className="value">
                      {props.event.category[0].toUpperCase()
                        + props.event.category.slice(
                          1,
                          props.event.category.length,
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Description:</td>
                    <td className="value">
                      {props.event.description ? props.event.description : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Location:</td>
                    <td className="value">
                      {props.event.location ? props.event.location : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Tags:</td>
                    <td className="value">
                      {props.event.tags ? props.event.tags : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Members:</td>
                    <td>
                      {props.event.members.map((person) => (
                        <div className="card" key={person.user_id}>
                          <div className="card-header">
                            <div className="row">
                              <div className="col-sm-6 d-flex">
                                <ProfilePic style={{ margin: '0px auto' }}>
                                  <img
                                    className="rounded-circle"
                                    src={
                                        person.user_original_avatar
                                          ? person.user_original_avatar
                                          : ProfilePictureIcon
                                      }
                                    alt="#"
                                    width="32px"
                                    height="32px"
                                    onError={addDefaultSrc}
                                  />
                                </ProfilePic>
                                <div className="ml-2">
                                  <h6>
                                    {`${person.user_first_name
                                    } ${
                                      person.user_last_name}`}
                                  </h6>
                                  <div>
                                    @
                                    {person.user_username}
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="pull-right right-header">
                                  <h6>{person.user_role.toUpperCase()}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </StyledTable>
            </div>
          </ScrollableSection>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DetailModal;
