import {
  NAPSTER_API_KEY,
  NAPSTER_API_SECRET,
  NAPSTER_USERNAME,
  NAPSTER_PASSWORD,
} from 'src/constants/creds';
import { axiosAuth, axiosInstance, baseUrl } from '.';

export const authenticateNapster = async (token) => {
  if (token) {
    const response = await axiosAuth.post(
      `https://api.napster.com/oauth/token?username=${NAPSTER_USERNAME}&password=${NAPSTER_PASSWORD}&grant_type=password`,
      null,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      },
    );
    return response.data;
  }
};

export const refreshNapsterToken = async (token) => {
  if (token) {
    const response = await axiosAuth.post(
      `https://api.napster.com/oauth/access_token?client_id=${NAPSTER_API_KEY}&client_secret=${NAPSTER_API_SECRET}&grant_type=refresh_token&refresh_token=${token}`,
    );

    return response.data;
  }
};

export const fetchTrendingTracks = async () => {
  const token = localStorage.getItem('napster.member.accessToken');
  if (token) {
    const response = await axiosInstance.get(
      'https://api.napster.com/v2.2/tracks/top?limit=100&isStreamableOnly=true',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data.tracks;
  }
};

export const fetchSearchedTracks = async (query) => {
  const token = localStorage.getItem('napster.member.accessToken');
  if (token) {
    const response = await axiosInstance.get(
      `https://api.napster.com/v2.2/search?query=${query}&type=track&per_type_limit=100&isStreamableOnly=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data.search.data.tracks;
  }
};

export const fetchPlaylists = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}playlists${payload === 'admin' ? '?global=true' : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data.data;
  }
};

export const postPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(`${baseUrl}playlists`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}playlists/${payload[0]}${
      payload[1].to_add || payload[1].to_remove ? '/music-list' : ''
    }`,
    payload[1],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const deletePlaylist = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(`${baseUrl}playlists/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const sharePlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}playlists/${payload.id}/share`,
      payload.users,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const fetchPlaylistTracks = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}playlists/${id}/music-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data.data;
  }
};

export const postTrackInPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(`${baseUrl}music`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const reorderPlaylistTracks = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}playlists/${payload[0]}/reorder-music-list`,
      payload[1],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const fetchMyMusic = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}music?provider=local`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  }
};

export const deleteTrack = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(`${baseUrl}music/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
