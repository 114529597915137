import React, { useState } from 'react';
import { Search, Trash2 } from 'react-feather';
import * as TextField from 'src/components/InputField';
import Loader from 'src/components/Loader';
import { ScrollableSection } from 'src/components/StyledComponents';
import MusicDisc from 'src/assets/images/livit/music-disc.png';
import PlayingGif from 'src/assets/images/livit/playing.gif';
import { DeleteModal } from './addTrackModals';

function TrackList(props) {
  const [showSearch, setShowSearch] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedTrack, setDeletedTrack] = useState({});

  const removeTrack = async () => {
    props.deleteMyTrack(deletedTrack);
    setDeleteModal(false);
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h5 className="align-abjust">
                {props.state === 'my-uploads' ? 'My Music' : 'All Songs'}
              </h5>
            </div>
            <div className="col-sm-6">
              <div className="pull-right right-header">
                {props.search ? (
                  <Search
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowSearch(!showSearch)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {showSearch && (
            <TextField
              type="search"
              placeholder="Search..."
              borderRadius="30px"
              fontSize="16px"
              padding="7px 10px"
              onKeyUp={props.search}
            />
          )}
          <ScrollableSection height={showSearch ? '92%' : '100%'}>
            {props.loading.status
            && (props.loading.component === 'music'
              || props.loading.component === 'playlist-tracks') ? (
                <Loader show />
              ) : props.tracks && props.tracks.length ? (
                <ul className="list-group mt-2">
                  {props.tracks.map((track) => (
                    <li
                      key={track.id}
                      className={`list-group-item ${
                        props.currentTrack.id === track.id
                          ? 'active disable'
                          : ''
                      }`}
                    >
                      <div
                        className="d-flex"
                        onClick={() => props.setTrack(track)}
                      >
                        <div>
                          <img
                            src={
                              props.isPlaying
                              && props.currentTrack.id === track.id
                                ? PlayingGif
                                : track.albumId
                                  ? `https://api.napster.com/imageserver/v2/albums/${track.albumId}/images/70x70.jpg`
                                  : MusicDisc
                            }
                            alt="#track art"
                          />
                        </div>
                        <div className="ml-1">
                          <h6>{track.name}</h6>
                          <p>{track.artistName ? track.artistName : ''}</p>
                        </div>
                      </div>
                      {props.state === 'my-uploads' && (
                      <div className="d-inline">
                        <Trash2
                          onClick={() => {
                            setDeleteModal(true);
                            setDeletedTrack(track);
                          }}
                        />
                      </div>
                      )}
                    </li>
                  ))}
                </ul>
              ) : props.state === 'my-uploads' ? (
                <div className="text-center">
                  <h5>No Music here</h5>
                </div>
              ) : (
                <Loader show />
              )}
          </ScrollableSection>
        </div>
      </div>
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        delete={removeTrack}
        track={deletedTrack}
        type="track"
      />
    </>
  );
}

export default TrackList;
