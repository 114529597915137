import React from 'react';
import CountUp from 'react-countup';
import 'chartjs-plugin-datalabels';
import { ExternalLink } from 'react-feather';

function Card({
  heading, count, icon, show,
}) {
  return (
    <>
      <div className="item">
        <div className="card">
          <div className="card-body ecommerce-icons text-center">
            <img src={icon} alt="icon" className="m-auto" />
            <div>
              <span className="text-dark font-weight-bold">{heading}</span>
            </div>
            <h4 className="mb-0">
              <CountUp className="counter" end={Number(count)} />
            </h4>
            <div className="text-dark my-1">
              <ExternalLink
                color="black"
                style={{ cursor: 'pointer' }}
                onClick={() => show()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
