import styled from 'styled-components';

export const SubscriptionContainer = styled.div``;

export const UpgradeButton = styled.button`
 background: ${(props) => (props.disabled ? 'rgba(205, 10, 124, 0.2)' : 'linear-gradient(142deg, #ac1280 0%, #e4007b 100%) !important')};
  border-radius: 5px;
  border-color: unset;
  border-width: unset;
  border-style: unset;
  overflow: hidden;
  color: #ffffff !important;
  font-size: 14px;
  color: white;
  text-transform: none;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 5px;
  padding: 10px 40px 10px 40px;

`;
