import React, { useState } from 'react';
import Loader from 'src/components/Loader';
import {
  Heading,
  ScrollableSection,
} from 'src/components/StyledComponents';
import PlusButton from 'src/components/PlusButton';
import { useDispatch } from 'react-redux';
import { ModalButton } from 'src/components/Modal';
import dragula from 'react-dragula';

function Folders(props) {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useDispatch();

  const addFolder = async (e) => {
    e.preventDefault();
    await dispatch(
      props.addList({
        id: props.match.params.id,
        data: { name, invite_all: false },
      }),
    );
    setModalShow(false);
    setName('');
    await dispatch(props.getLists(props.match.params.id));
  };

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      const options = {};
      dragula([componentBackingInstance], options);
    }
  };

  return (
    <>
      <div className="card" style={{ height: '80vh' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-sm-6">
              <h5 className="align-abjust">Folders</h5>
            </div>
            <div className="col-sm-6">
              <div className="pull-right right-header">
                <PlusButton onClick={() => setModalShow(true)} />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <ScrollableSection height="90%">
            {props.processing.status
            && props.processing.component === 'user-todolist' ? (
              <Loader show />
              ) : props.folders.length ? (
                <ul className="list-group" ref={dragulaDecorator}>
                  {props.folders.map((todo) => (
                    <li
                      className={`list-group-item ${
                        props.selected.id === todo.id ? 'active' : null
                      }`}
                      key={todo.id}
                      onClick={() => props.getSublist(todo)}
                    >
                      <div className="w-50">
                        {todo.name.charAt(0).toUpperCase()
                          + todo.name.substring(1, todo.name.length)}
                      </div>
                      <div>
                        {todo.child_todo.total}
                        {' '}
                        lists
                      </div>
                      <div>
                        <i className="fas fa-bars" />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <Heading className="text-center h4 mt-4">
                  No Todos for this Hub.
                </Heading>
              )}
          </ScrollableSection>
        </div>
      </div>
      <ModalButton
        show={modalShow}
        close={setModalShow}
        title="Please Enter Folder Name"
        submit={addFolder}
        value={name}
        setValue={setName}
      />
    </>
  );
}

export default Folders;
