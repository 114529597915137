import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const HeaderButton = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : '22px')};
  height: ${(props) => (props.height ? `${props.height}px` : '22px')};
  text-align: center;
  background-color: ${themeColor};
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-size: ${(props) => (props.width ? `${0.58 * props.width}px` : `${0.58 * 22}px`)};
  &:hover {
    background-color: lightgrey;
  }
  i {
    vertical-align: text-bottom;
  }
`;
