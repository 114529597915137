import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InputField } from 'src/components/InputField/styles';
import { Heading } from 'src/components/StyledComponents';
import { Button } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateTodoList, removeTask } from 'src/redux/todoRedux';
import Loader from 'src/components/Loader';

function SublistModal(props) {
  const [edit, setEdit] = useState(false);
  const [listName, setListName] = useState(props.list.name);

  const { tasks } = useSelector((state) => state.TodoReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.list.name) setListName(props.list.name);
    if (props.list.id) dispatch(props.getTasks(props.list.id));
  }, [props.list]);

  const update = () => {
    const payload = [props.list.id, { name: listName }, props.folder.id];
    dispatch(updateTodoList(payload));
    props.close(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ border: 'none' }} closeButton>
        <div className="w-100 d-flex justify-content-center">
          <div className={`${edit ? 'd-none' : 'd-flex'}`}>
            <Heading>
              <span className="h4">{listName}</span>
            </Heading>
            <div className="btn my-0 py-0" onClick={() => setEdit(true)}>
              <i className="fas fa-pencil-alt" />
            </div>
          </div>
          <div className={`${edit ? 'd-flex' : 'd-none'}`}>
            <InputField
              type="text"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              placeholder="list name.."
            />
            <div className="btn my-0 py-0" onClick={() => setEdit(false)}>
              <i className="fas fa-save" />
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
              {processing.status && processing.component === 'user-tasks' ? (
                <Loader show />
              ) : tasks.length ? (
                tasks.map((todo, nextIndex) => (
                  <div
                    key={nextIndex}
                    className="d-flex justify-content-between mb-2 mx-auto"
                  >
                    <div className="ml-2 mt-2">{todo.description}</div>

                    <div className="mr-3 mt-1">
                      <Button
                        variant="outlined"
                        value="remove"
                        padding="0px 3px"
                        fontSize="12px"
                        dissabled={
                            processing.status
                            && processing.component === 'user-tasksc'
                          }
                        onClick={() => dispatch(removeTask([props.list.id, todo.id]))}
                      />
                    </div>
                  </div>
                ))
              ) : null}
            </div>
            <div className="col-sm-3" />
          </div>
        </div>
        <div className="text-center">
          <Button variant="solid" value="Update" onClick={update} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SublistModal;
