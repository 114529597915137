import React, { useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle } from 'react-feather';
import {
  ProfilePic,
  ScrollableSection,
} from 'src/components/StyledComponents';
import { getHubDetails } from 'src/redux/hubRedux';
import Breadcrumb from 'src/components/Breadcrumb';
import Map from 'src/components/GoogleMap';
import { GOOGLE_API_KEY } from 'src/constants/creds';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { addDefaultSrc } from 'src/utils/helper';

const rad = (x) => (x * Math.PI) / 180;

const getDistance = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(rad(p1.lat))
      * Math.cos(rad(p2.lat))
      * Math.sin(dLong / 2)
      * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d < 1000
    ? `${parseFloat(d).toFixed(2)} m`
    : `${parseFloat(d / 1000).toFixed(2)} km`;
};

const position = localStorage.getItem('location-coords')
  ? JSON.parse(localStorage.getItem('location-coords'))
  : null;

function HubLocation(props) {
  const [error, setError] = useState(false);
  const [center, setCenter] = useState(position);
  const [markers, setMarkers] = useState([]);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.ProfileReducer);
  const { hub, hubMembers } = useSelector((state) => state.HubsReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    dispatch(getHubDetails(props.match.params.id));
    if (!position) {
      setError(true);
    }
  }, []);
  useEffect(() => {
    if (hubMembers.length) {
      const tempMarkers = [];
      hubMembers.forEach((member) => {
        if (member.user_location) {
          tempMarkers.push({
            id: member.user_id,
            lat: member.user_location.latitude,
            lng: member.user_location.longitude,
            name: member.user_name,
            username: member.user_username ? member.user_username : null,
            avatar: member.user_original_avatar
              ? member.user_original_avatar
              : ProfilePictureIcon,
            role: member.user_role ? member.user_role : null,
          });
        }
      });
      setMarkers(tempMarkers);
    }
  }, [hubMembers]);

  const handleCenterChanged = (location) => {
    if (location) setCenter(location);
  };

  return (
    <>
      <Breadcrumb parent="Hub Location" title={hub ? hub.alias : ''} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Members</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-">
                    {processing.status && processing.component === 'hub' ? (
                      <Loader show />
                    ) : hubMembers.length ? (
                      hubMembers.map((member) => (
                        <li
                          className="list-group-item mt-1"
                          onClick={() => handleCenterChanged(member.user_location)}
                        >
                          <div className="d-flex">
                            <ProfilePic className="mr-1">
                              <img
                                className="rounded-circle lazyloaded blur-up"
                                src={
                                  member.user_original_avatar
                                    ? member.user_original_avatar
                                    : ProfilePictureIcon
                                }
                                onError={addDefaultSrc}
                                alt="#"
                                width="30px"
                                height="30px"
                              />
                            </ProfilePic>
                            <div>
                              <span
                                className="font-weight-bold"
                                style={{ fontSize: '16px' }}
                              >
                                {member.user_name}
                              </span>
                              <p>
                                {member.user_username
                                  ? `@${member.user_username}`
                                  : ''}
                              </p>
                            </div>
                          </div>
                          <div>
                            {member.user_id === user.id ? (
                              'You'
                            ) : member.user_location && position ? (
                              getDistance(
                                {
                                  lat: position.latitude,

                                  lng: position.longitude,
                                },
                                {
                                  lat: member.user_location.latitude,
                                  lng: member.user_location.longitude,
                                },
                              )
                            ) : (
                              <>
                                <AlertTriangle color="red" id="tool-error" />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="tool-error"
                                >
                                  Location Not Found
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                        </li>
                      ))
                    ) : (
                      <Loader show />
                    )}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-body">
                <Map
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  markers={markers}
                  coords={position || props.coords}
                  error={error}
                  center={center}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubLocation;
