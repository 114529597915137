import React from 'react';
import Iframe from 'react-iframe';
import { PreviewFile } from 'src/containers/HubFiles/styles';
import Information from './Info';

function Preview(props) {
  const isImage = () => (
    props.file.name.split('.').pop().toUpperCase() === 'JPG'
      || props.file.name.split('.').pop().toUpperCase() === 'PNG'
      || props.file.name.split('.').pop().toUpperCase() === 'JPEG'
  );
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-12 col-sm-12 h-50">
          <div
            className="py-2"
            style={{ borderTop: '4px solid lightgrey', height: '100%' }}
          >
            {props.file ? (
              <PreviewFile>
                {isImage() ? (
                  <img
                    src={props.file.path}
                    alt="Error Loading in File"
                    width="100%"
                    className="mt-2"
                  />
                ) : (
                  <Iframe
                    url={props.file.path}
                    width="100%"
                    height="100%"
                    display="initial"
                    position="relative"
                    id="preview-frame"
                  />
                )}
              </PreviewFile>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 d-sm-none d-md-inline">
          <Information file={props.file} />
        </div>
      </div>
    </div>
  );
}

export default Preview;
