import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchReports, postReport, updateReportAction } from 'src/api/reports';
import { saveReports, sendReportStatus } from 'src/redux/reportRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getReports(action) {
  try {
    yield put(processing({ status: true, component: 'admin-reports' }));
    const response = yield call(fetchReports, action.payload);
    yield put(saveReports(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-reports' }));
    yield put(setError(false));
  }
}

function* sendReport(action) {
  try {
    yield put(processing({ status: true, component: 'admin-reports' }));
    const response = yield call(postReport, action.payload);
    yield put(sendReportStatus(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-reports' }));
    yield put(setError(false));
  }
}

function* reportAction(action) {
  try {
    yield put(processing({ status: true, component: 'admin-reports' }));
    yield call(updateReportAction, action.payload);
    const response = yield call(fetchReports, null);
    yield put(saveReports(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-reports' }));
    yield put(setError(false));
  }
}

export function* watchReportsSaga() {
  yield takeLatest('GET_REPORTS', getReports);
  yield takeLatest('SEND_REPORT', sendReport);
  yield takeLatest('SET_REPORT_ACTION', reportAction);
}
