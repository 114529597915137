import React, { useState, useEffect } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import InputField from 'src/components/InputField';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllUsers,
  getSearchedUsers,
} from 'src/redux/usersRedux';
import Breadcrumb from 'src/components/Breadcrumb';
import { useLocation } from 'react-router-dom';
import Hubs from './Sections/hubs';
import Users from './Sections/users';
import Networks from './Sections/networks';

function Directory() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tab, settab] = useState('users');
  const { users } = useSelector((state) => state.UsersReducer);

  useEffect(() => {
    if (location.state) {
      settab(
        location.state.state ? location.state.state : 'users',
      );
    }
    return () => {
      settab('users');
    };
  }, [location]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const searching = (e) => {
    if (tab === 'users') {
      dispatch(getSearchedUsers({ value: e.target.value, type: 'public' }));
    }
  };

  return (
    <>
      <Breadcrumb parent="Directory" title={tab} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Menu</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    <li
                      className={`list-group-item ${
                        tab === 'users' ? 'active' : null
                      }`}
                      onClick={() => settab('users')}
                    >
                      <div>Users</div>
                    </li>
                    <li
                      className={`list-group-item mt-1 ${
                        tab === 'hubs' ? 'active' : null
                      }`}
                      onClick={() => settab('hubs')}
                    >
                      <div>Hubs</div>
                    </li>
                    <li
                      className={`list-group-item mt-1 ${
                        tab === 'network' ? 'active' : null
                      }`}
                      onClick={() => settab('network')}
                    >
                      <div>Networks</div>
                    </li>
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">{tab}</h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      <div
                        className={`form-group ${
                          tab !== 'users' ? 'd-none' : null
                        }`}
                      >
                        <InputField
                          className="form-control"
                          type="text"
                          placeholder="Search..."
                          borderRadius="30px"
                          fontSize="13px"
                          padding="0px 0px 0px 20px"
                          onKeyUp={searching}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {tab === 'users' ? (
                  <>
                    <ScrollableSection>
                      <Users users={users} />
                    </ScrollableSection>
                  </>
                ) : null}
                {tab === 'hubs' ? (
                  <ScrollableSection>
                    <Hubs />
                  </ScrollableSection>
                ) : null}

                {tab === 'network' ? (
                  <ScrollableSection>
                    <Networks />
                  </ScrollableSection>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Directory;
