import React, { useState } from 'react';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { addDefaultSrc } from 'src/utils/helper';
import { ProfilePic, Card } from './styles';
import DetailModal from './detailModal';

function EventCard({ event, hubs, user }) {
  const [modalShow, setModalShow] = useState(false);
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getTime = (date) => {
    const hrs = new Date(
      new Date(date).getTime()
        + (330 + new Date(date).getTimezoneOffset()) * 60000,
    ).getHours();
    const mins = new Date(
      new Date(date).getTime()
        + (330 + new Date(date).getTimezoneOffset()) * 60000,
    ).getMinutes();
    return `${hrs > 12 ? hrs - 12 : hrs}:${mins < 10 ? `0${mins}` : mins} ${
      hrs > 12 ? 'PM' : 'AM'
    }`;
  };
  const getSrc = (event) => {
    if (event.hub_id !== null) {
      const hub = hubs.filter((hub) => hub.id === event.hub_id);
      return hub.length ? hub[0].avatar : ProfilePictureIcon;
    }
    return user.avatar ? user.avatar : ProfilePictureIcon;
  };
  const getName = (event) => {
    if (event.hub_id !== null) {
      const hub = hubs.filter((hub) => hub.id === event.hub_id);
      return hub.length ? hub[0].alias : '';
    }
    return user.avatar ? user.first_name : '';
  };
  const getcategory = (event) => {
    if (event.hub_id !== null) {
      const hub = hubs.filter((hub) => hub.id === event.hub_id);
      return hub.length ? hub[0].category : '';
    }
    return '';
  };
  return (
    <>
      <Card onClick={() => setModalShow(true)}>
        <div className="rows">
          <div className="column left">
            <div className="font-weight-bolder my-1 h5">
              {new Date(event.start_time).getDate()}
            </div>
            <div className="my-1 h6">
              {days[new Date(event.start_time).getDay()]}
            </div>
          </div>
          <div className="column middle pl-2">
            <div className="font-weight-bolder my-1">{event.title}</div>
            <div className="my-1">
              {getTime(event.start_time)}
              -
              {getTime(event.end_time)}
            </div>
            <div className="d-flex my-1">
              <div>
                <i className="far fa-bell" />
                <div />
              </div>
              <div className="ml-1">
                <i className="fas fa-redo-alt" />
                {`${` ${event.recurrence_frequency}`}`}
              </div>
            </div>
          </div>
          <div className="column right">
            <ProfilePic>
              <img
                className="rounded-circle lazyloaded blur-up"
                src={getSrc(event)}
                onError={addDefaultSrc}
                alt="#"
                width="30px"
                height="30px"
              />
            </ProfilePic>
            <div style={{ wordBreak: 'break-word', fontSize: '10px' }}>
              {getName(event)}
            </div>
            <div style={{ wordBreak: 'break-word', fontSize: '10px' }}>
              {getcategory(event)}
            </div>
          </div>
        </div>
      </Card>
      <DetailModal
        event={event}
        imgSrc={getSrc(event)}
        getName={getName(event)}
        getTime={getTime}
        days={days}
        show={modalShow}
        close={setModalShow}
      />
    </>
  );
}

export default EventCard;
