import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';

export const CheckBox = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${themeColor};
  border-radius: 50%;
  background-color: white;
  background-image: ${(props) => (props.selected
    ? props.image
      ? `url(${props.image})`
      : `url(${ProfilePictureIcon})`
    : null)};
  color: ${(props) => (props.selected ? 'white' : '')};
  background-size: contain;
  cursor: pointer;
`;

export const Members = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

export const Member = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  text-decoration: none;
`;
