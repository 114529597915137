import Modal from 'react-bootstrap/Modal';
import { PropTypes } from 'prop-types';
import { Heading } from 'src/components/StyledComponents';
import { ProceedButton } from 'src/containers/SubscriptionScreen/PaymentModal/styles';
import { useNavigate } from 'react-router-dom';

function AddAdminToLinkedAccountModal({
  openAddAdminToLinkedAccountModal,
  setOpenAddAdminToLinkedAccountModal,
}) {
  const navigate = useNavigate();

  const handleCancel = () => {
    setOpenAddAdminToLinkedAccountModal(false);
  };
  const handleShow = () => {
    setOpenAddAdminToLinkedAccountModal(false);
    navigate('/profile');
  };
  return (
    <Modal
      show={openAddAdminToLinkedAccountModal}
      size="md"
      onHide={() => {
        setOpenAddAdminToLinkedAccountModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Heading className="font-weight-bold">
          Add Admin To Linked Accounts
        </Heading>
      </Modal.Header>
      <Modal.Body>
        Before deleting your account, you need to add another admin to your
        linked accounts from your mobile device.
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-around">
          <ProceedButton onClick={handleCancel}>Cancel</ProceedButton>
          <ProceedButton onClick={handleShow}>
            Show Linked Accounts
          </ProceedButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AddAdminToLinkedAccountModal.propTypes = {
  openAddAdminToLinkedAccountModal: PropTypes.bool.isRequired,
  setOpenAddAdminToLinkedAccountModal: PropTypes.func.isRequired,
};
export default AddAdminToLinkedAccountModal;
