import React, { useState, useEffect } from 'react';
import { ArrowLeft, Eye } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from 'src/components/Breadcrumb';
import { Button } from 'src/components/Button';
import {
  Heading,
  ProfilePic,
  ScrollableSection,
} from 'src/components/StyledComponents';
import { getReports, setReportAction } from 'src/redux/reportRedux';
import Loader from 'src/components/Loader';
import SelectMenu from 'src/components/DropDown';
import { addDefaultSrc } from 'src/utils/helper';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import ImageSlider from 'src/components/ImageSlider';
import LoadMore from 'src/components/LoadMore';
import { DropdownData } from 'src/utils/data';
import ActionModal from './actionModal';
import { FeedMedia } from './styles';

const typeOptions = [
  { key: 'All', value: 'All' },
  ...DropdownData.reportTypeOptions,
];

const categoryOptions = [
  { key: 'All', value: 'All' },
  ...DropdownData.reportReasonOptions,
];

const statusOptions = [
  { key: 'All', value: 'All' },
  ...DropdownData.reportStatusOptions,
];

function AdminReports() {
  const [reportsData, setReportsData] = useState([]);
  const [type, setType] = useState(typeOptions[0]);
  const [category, setCategory] = useState(categoryOptions[0]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionState, setActionState] = useState(null);

  const dispatch = useDispatch();

  const { reports } = useSelector((state) => state.ReportReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    dispatch(getReports());
    return () => setReportsData([]);
  }, []);

  useEffect(() => {
    if (reports.data.length > 0) {
      reports.meta.pagination.current_page === 1
        ? setReportsData(reports.data)
        : setReportsData([...reportsData, ...reports.data]);
    }
  }, [reports]);
  useEffect(() => {
    if (type || category) {
      setReportsData([]);
      dispatch(
        getReports({
          category: category.key === 'All' ? null : category.key,
          resource_type: type.key === 'All' ? null : type.key,
          status: status.key === 'All' ? null : status.key,
        }),
      );
    }
  }, [type, category, status]);

  const onAction = (action, message) => {
    const payload = {
      action,
    };
    if (message) {
      payload.description = message;
    }
    dispatch(setReportAction([actionState.id, payload]));
    setShowActionModal(false);
    setActionState(null);
  };
  return (
    <>
      {!actionState ? (
        <>
          <Breadcrumb parent="Report" title="Manage" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card" style={{ height: '80vh' }}>
                  <div className="card-header">
                    <div className="d-flex justify-content-end">
                      <div className="d-flex mr-2">
                        <span className="h6">Status: </span>
                        <SelectMenu
                          selected={status}
                          setSelected={setStatus}
                          options={statusOptions}
                          placeholder={status || 'All'}
                        />
                      </div>
                      <div className="d-flex mr-2">
                        <span className="h6">Category: </span>
                        <SelectMenu
                          selected={category}
                          setSelected={setCategory}
                          options={categoryOptions}
                          placeholder={category || 'All'}
                        />
                      </div>
                      <div className="d-flex">
                        <span className="h6">Type: </span>
                        <SelectMenu
                          selected={type}
                          setSelected={setType}
                          options={typeOptions}
                          placeholder={type || 'All'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ScrollableSection id="scroll-admin-reports">
                      {processing.status
                      && processing.component === 'admin-reports' ? (
                        <Loader show />
                        ) : reportsData.length ? (
                          <LoadMore
                            data={reportsData}
                            getMore={() => dispatch(
                              getReports({
                                page: reports.meta.pagination.current_page + 1,
                              }),
                            )}
                            target="scroll-admin-reports"
                            meta={reports.meta.pagination}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Id</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Report Against</th>
                                  <th scope="col">Reported By</th>
                                  <th scope="col">Action</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportsData.map((data, index) => (
                                  <tr key={index}>
                                    <td>
                                      <span>
                                      #
                                      {data.id}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-capitalize">
                                      {data.category}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-capitalize">
                                      {data.resource_type}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                      <ProfilePic>
                                        <img
                                          src={
                                              data.resource_type === 'feed'
                                                ? data.resource_data.created_by
                                                  ?.avatar
                                                  ? `https://res.cloudinary.com/liviit/image/upload/${data?.resource_data?.created_by?.avatar}`
                                                  : ProfilePictureIcon
                                                : data.resource_data.avatar
                                                  ? `https://res.cloudinary.com/liviit/image/upload/${data?.resource_data?.avatar}`
                                                  : ProfilePictureIcon
                                            }
                                          alt="icon"
                                          onError={addDefaultSrc}
                                          className="rounded-circle mx-auto"
                                          width="38px"
                                          height="38px"
                                        />
                                      </ProfilePic>
                                      <div className="p-2">
                                        {data?.resource_type === 'feed'
                                          ? data?.resource_data?.created_by?.name
                                          : data?.resource_data?.name}
                                      </div>
                                      </div>
                                    </td>
                                    <td className="d-flex">
                                      <ProfilePic>
                                      <img
                                        src={
                                            data?.reported_by?.avatar
                                              ? `https://res.cloudinary.com/liviit/image/upload/${data?.reported_by?.avatar}`
                                              : ProfilePictureIcon
                                          }
                                        alt="icon"
                                        onError={addDefaultSrc}
                                        className="rounded-circle mx-auto"
                                        width="38px"
                                        height="38px"
                                      />
                                      </ProfilePic>
                                      <div className="p-2">
                                      {data.reported_by.name}
                                      </div>
                                    </td>
                                    <td>
                                      <Eye
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setActionState(data)}
                                      />
                                    </td>
                                    <td>
                                      <span className="text-capitalize">
                                      {data.admin_action.status}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </LoadMore>
                        ) : (
                          <Heading className="text-center h4 mt-4 w-100">
                            Currently No Reports generated, Please check later.
                          </Heading>
                        )}
                    </ScrollableSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-4" style={{ height: '85vh' }}>
                <div className="card-header">
                  <span onClick={() => setActionState(null)}>
                    <ArrowLeft />
                    <span className="h6" style={{ verticalAlign: 'super' }}>
                      Go Back
                    </span>
                  </span>
                </div>
                <div className="card-body">
                  <ScrollableSection>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-1 d-sm-none d-md-block" />
                        <div className="col-md-10 col-sm-12 border p-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <ProfilePic>
                                <img
                                  className="rounded-circle lazyloaded blur-up"
                                  src={
                                    actionState.resource_type === 'feed'
                                      ? actionState?.resource_data.created_by
                                        ?.avatar
                                        ? `https://res.cloudinary.com/liviit/image/upload/${actionState?.resource_data?.created_by?.avatar}`
                                        : ProfilePictureIcon
                                      : actionState?.resource_data?.avatar
                                        ? `https://res.cloudinary.com/liviit/image/upload/${actionState?.resource_data?.avatar}`
                                        : ProfilePictureIcon
                                  }
                                  onError={addDefaultSrc}
                                  alt="#"
                                  width="36px"
                                  height="36px"
                                />
                              </ProfilePic>
                              <span className="h5">
                                {actionState.resource_type === 'feed'
                                  ? `@${
                                    actionState.resource_data.created_by
                                      .username}`
                                  : `@${actionState.resource_data.username}`}
                              </span>
                            </div>
                            <div>
                              <Button
                                variant="solid"
                                value="Take Action"
                                onClick={() => setShowActionModal(true)}
                              />
                            </div>
                          </div>
                          <div className="container-fluid border my-2 h6">
                            <div className="row">
                              <div className="col-2 border py-2">Status</div>
                              <div className="col-10 border py-2">
                                <span className="text-capitalize">
                                  {actionState.admin_action.status}
                                </span>
                              </div>
                              <div className="col-2 border py-2">
                                Report Type
                              </div>
                              <div className="col-10 border py-2">
                                <span className="text-capitalize">
                                  {actionState.resource_type}
                                </span>
                              </div>
                              <div className="col-2 border py-2">
                                Reported By
                              </div>
                              <div className="col-10 border py-2">
                                {actionState.reported_by.name}
                              </div>
                              <div className="col-2 border py-2">
                                Reported On
                              </div>
                              <div className="col-10 border py-2">
                                {actionState.created_at}
                              </div>
                              <div className="col-2 border py-2">Reason</div>
                              <div className="col-10 border py-2">
                                <span className="text-capitalize">
                                  {actionState.category}
                                </span>
                              </div>
                              <div className="col-2 border py-2">Message</div>
                              <div className="col-10 border py-2">
                                {actionState.description}
                              </div>
                              {actionState.resource_type === 'feed' ? (
                                <>
                                  <div className="col-2 border py-2">
                                    Original Feed
                                  </div>
                                  <div className="col-10 border py-2">
                                    <div className="d-flex mb-2 justify-content-between">
                                      <div className="d-flex">
                                        <ProfilePic>
                                          <img
                                            className="rounded-circle lazyloaded blur-up"
                                            src={
                                              actionState.resource_data
                                                ?.created_by?.avatar
                                                ? `https://res.cloudinary.com/liviit/image/upload/${actionState?.resource_data?.created_by?.avatar}`
                                                : ProfilePictureIcon
                                            }
                                            onError={addDefaultSrc}
                                            alt="#"
                                            width="36px"
                                            height="36px"
                                          />
                                        </ProfilePic>
                                        <div
                                          className="ml-3"
                                          style={{ fontSize: '11px' }}
                                        >
                                          <div>
                                            {
                                              actionState.resource_data
                                                .created_by.name
                                            }
                                          </div>
                                          <div>
                                            <span>
                                              @
                                              {
                                                actionState.resource_data
                                                  .created_by.username
                                              }
                                            </span>
                                            <span>
                                              {' '}
                                              |
                                              {' '}
                                              {
                                                actionState.resource_data
                                                  .created_at
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {actionState.resource_data.caption
                                        ? actionState.resource_data.caption
                                        : ''}
                                    </div>

                                    {actionState.resource_data.medias.length ? (
                                      <FeedMedia>
                                        <ImageSlider
                                          images={
                                            actionState.resource_data.medias
                                          }
                                        />
                                      </FeedMedia>
                                    ) : null}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="col-2 border py-2">
                                Attachment
                              </div>
                              <div className="col-10 border py-2">
                                {actionState.attachment ? (
                                  <img
                                    src={`https://res.cloudinary.com/liviit/image/upload/${actionState.attachment}`}
                                    alt="attachment"
                                    height="600px"
                                    width="500px"
                                  />
                                ) : (
                                  <span>Not Available</span>
                                )}
                              </div>
                              <div className="col-2 border py-2">
                                Last Action At
                              </div>
                              <div className="col-10 border py-2">
                                <span className="text-capitalize">
                                  {actionState.admin_action.taken_at ? (
                                    <>
                                      {actionState.admin_action.taken_at}
                                      {' '}
                                      ( By:
                                      {' '}
                                      <span>
                                        <ProfilePic className="d-inline">
                                          <img
                                            className="rounded-circle lazyloaded blur-up"
                                            src={
                                              actionState.admin_action.taken_by
                                                ?.avatar
                                                ? `https://res.cloudinary.com/liviit/image/upload/${actionState?.admin_action?.taken_by?.avatar}`
                                                : ProfilePictureIcon
                                            }
                                            onError={addDefaultSrc}
                                            alt="#"
                                            width="36px"
                                            height="36px"
                                          />
                                          {
                                            actionState.admin_action.taken_by
                                              .name
                                          }
                                        </ProfilePic>
                                      </span>
                                      )
                                    </>
                                  ) : (
                                    'Pending'
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-1 d-sm-none d-md-block" />
                      </div>
                    </div>
                  </ScrollableSection>
                </div>
              </div>
            </div>
          </div>
          <ActionModal
            show={showActionModal}
            close={setShowActionModal}
            actionState={actionState}
            onAction={onAction}
          />
        </div>
      )}
    </>
  );
}

export default AdminReports;
