import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactPlayer from 'react-player/lazy';
import { SliderButton, StyledImage } from './styles';

export default function ImageSlider({ images }) {
  const [index, setIndex] = useState(0);
  const [pictures, setPictures] = useState([]);

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };

  useEffect(() => {
    setPictures(images);
  }, [images]);

  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  return (
    <>
      {images.length > 1 ? (
        <>
          {images[index].is_video ? (
            <ReactPlayer
              url={`${images[index].originalUrl.slice(0, -3)}mp4`}
              controls
              muted
              playing
              loop
              width="100%"
              height="100%"
            />
          ) : (
            <StyledImage
              alt=""
              src={images[index].image_url}
              onClick={() => setPhotoIndex({
                ...photoIndex,
                index: 0,
                isOpen: true,
              })}
            />
          )}

          <div
            className="d-flex justify-content-center w-100"
            style={{ position: 'absolute', bottom: '0' }}
          >
            <SliderButton onClick={slideLeft} />
            <SliderButton onClick={slideRight} />
          </div>
        </>
      ) : images[index].is_video ? (
        <ReactPlayer
          url={`${images[index].originalUrl.slice(0, -3)}mp4`}
          controls
          muted
          playing
          loop
          width="100%"
          height="100%"
        />
      ) : (
        <StyledImage
          alt=""
          src={images[index].image_url}
          onClick={() => setPhotoIndex({
            ...photoIndex,
            index: 0,
            isOpen: true,
          })}
        />
      )}
      {photoIndex.isOpen && (
        <Lightbox
          mainSrc={pictures[photoIndex.index].image_url}
          imageTitle={`${photoIndex.index + 1}/${images.length}`}
          onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })}
        />
      )}
    </>
  );
}
