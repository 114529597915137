import styled from 'styled-components';

export const MapContainer = styled.div`
  height: 100%;
`;

export const OverlayContainer = styled.div`
  cursor: pointer;
  .popup-tip-anchor {
    height: 0;
    position: absolute;

    width: 200px;
  }

  .popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: /* TIP_HEIGHT= */ 8px;
    left: 0;
  }

  .popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    transform: translate(-50%, 0);
    width: 0;
    height: 0;

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: /* TIP_HEIGHT= */ 8px solid white;
  }

  .popup-bubble-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the info window. */
    background-color: white;
    padding: 3px;
    border-radius: 50%;
    font-family: sans-serif;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
  }
`;
