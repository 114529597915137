import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Heading = styled.span`
  color: ${themeColor};
`;

export const Shape = styled.div`
  position: relative;
  overflow: hidden;
  height: 120px;
  &:before {
    border-radius: 100%;
    position: absolute;
    background-image: ${(props) => `linear-gradient(${props.color[0]},${props.color[1]})`};
    right: -100px;
    left: -100px;
    top: -250px;
    content: "";
    bottom: 0;
  }
`;

export const ProfilePic = styled.div`
  width: 80px;
  height: 80px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
  margin: auto;
`;

export const InfoSection = styled.div`
  position: relative;
  .avatar {
    margin-top: -50px;
    ${
  '' /* display: flex;
    justify-content: space-around; */
}
  }
  .avatar button {
    border: none;
    color: #555;
  }
  .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid whitesmoke;
  }
  .group-image {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 20%);
    transition: 0.5s;
  }
  .customers {
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .customers li {
    border: none;
    background-color: none;
  }
  .customers li:hover {
    background-color: transparent;
  }
`;
