import {
  call, put, takeLatest, delay,
} from 'redux-saga/effects';
import { loadMoreData } from 'src/api';
import {
  fetchUserFeeds,
  postUserFeed,
  postReactionOnFeed,
  deleteReactionOnFeed,
  fetchCommentsOnFeed,
  postCommentsOnFeed,
  deleteCommentsOnFeed,
  fetchUnseenFeedCount,
  updateLastSeenFeed,
} from 'src/api/feeds';
import {
  saveUserFeeds,
  saveComments,
  processFeeds,
  saveUnseenFeedCount,
  getUnseenFeedCount as getUnseenCount,
} from 'src/redux/feedsRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getUserFeeds(action) {
  try {
    yield put(processFeeds({ status: true, component: 'user-feeds' }));
    const response = yield call(fetchUserFeeds, action.payload);
    if (action.payload === null && response.data.length > 0) {
      yield call(updateLastSeenFeed, { id: response.data[0].id });
      const responsecount = yield call(fetchUnseenFeedCount);
      yield put(saveUnseenFeedCount(responsecount));
    }
    yield put(saveUserFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processFeeds({ status: false, component: 'user-feeds' }));
    yield put(setError(false));
  }
}

function* addNewFeed(action) {
  try {
    yield put(processFeeds({ status: true, component: 'user-feeds' }));
    yield call(postUserFeed, action.payload[0]);
    const response = yield call(fetchUserFeeds, action.payload[1]);
    yield put(saveUserFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processFeeds({ status: false, component: 'user-feeds' }));
    yield put(setError(false));
  }
}

function* getMoreData(action) {
  try {
    yield put(processFeeds({ status: true, component: 'user-feed' }));
    const response = yield call(loadMoreData, action.payload);
    yield put(saveUserFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processFeeds({ status: false, component: 'user-feed' }));
    yield put(setError(false));
  }
}

function* addNewReaction(action) {
  try {
    yield put(processing({ status: true, component: 'user-feeds' }));
    yield call(postReactionOnFeed, action.payload);
    const response = yield call(fetchUserFeeds, null);
    yield put(saveUserFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-feeds' }));
    yield put(setError(false));
  }
}

function* removeReaction(action) {
  try {
    yield put(processing({ status: true, component: 'user-feeds' }));
    yield call(deleteReactionOnFeed, action.payload);
    const response = yield call(fetchUserFeeds, null);
    yield put(saveUserFeeds(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-feeds' }));
    yield put(setError(false));
  }
}

function* getComments(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    const response = yield call(fetchCommentsOnFeed, action.payload);
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* addComment(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    yield call(postCommentsOnFeed, action.payload);
    const response = yield call(fetchCommentsOnFeed, { id: action.payload[0] });
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* removeComment(action) {
  try {
    yield put(processing({ status: true, component: 'feed-comment' }));
    yield call(deleteCommentsOnFeed, action.payload);
    const response = yield call(fetchCommentsOnFeed, { id: action.payload.id });
    yield put(saveComments(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feed-comment' }));
    yield put(setError(false));
  }
}

function* getUnseenFeedCount() {
  try {
    yield put(processing({ status: true, component: 'feeds-count' }));
    const response = yield call(fetchUnseenFeedCount);
    yield put(saveUnseenFeedCount(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'feeds-count' }));
    yield put(setError(false));
  }
  yield delay(60000);
  yield put(getUnseenCount());
}

export function* watchFeedsSaga() {
  yield takeLatest('GET_USER_FEEDS', getUserFeeds);
  yield takeLatest('ADD_FEED', addNewFeed);
  yield takeLatest('LOAD_MORE_FEEDS', getMoreData);
  yield takeLatest('ADD_REACTION', addNewReaction);
  yield takeLatest('REMOVE_REACTION', removeReaction);
  yield takeLatest('GET_COMMENTS', getComments);
  yield takeLatest('ADD_COMMENT', addComment);
  yield takeLatest('DELETE_COMMENT', removeComment);
  yield takeLatest('GET_UNSEEN_FEED_COUNT', getUnseenFeedCount);
}
