import React, { useEffect, useState } from 'react';
import Loader from 'src/components/Loader';
import { ChatCard, InfoSection } from 'src/containers/Hubs/styles';
import { ProfilePic } from 'src/components/StyledComponents';
import { UncontrolledTooltip } from 'reactstrap';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import { Button } from 'src/components/Button';
import { useNavigate } from 'react-router-dom';
import { actionHandler, addDefaultSrc } from 'src/utils/helper';
import {
  MessageSquare, Phone, Users, Video,
} from 'react-feather';
import { CometChatMessages } from 'src/CometChatWorkspace/src';
import * as enums from 'src/CometChatWorkspace/src/util/enums';
import { defaultGradient } from 'src/utils/theme';

function HubInfo(props) {
  const [hubMembers, setHubMembers] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.members) {
      setHubMembers(
        props.members.sort((a, b) => {
          const textA = a.user_name.toUpperCase();
          const textB = b.user_name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
      );
    }
    setShowChat(false);
  }, [props.hub, props.members]);

  return (
    <>
      {props.hubProcessing.status
      && props.hubProcessing.component === 'hub-members' ? (
        <Loader show />
        ) : (
          <>
            {Object.keys(props.hub).length && props.members.length ? (
              <InfoSection className="socialprofile">
                <div
                  style={{
                    backgroundImage: `linear-gradient(${
                      props.hub.theme
                    && props.hub.theme.mobile
                    && props.hub.theme.mobile.header_color
                        ? props.hub.theme.mobile.header_color[0]
                        : defaultGradient[0]
                    },${
                      props.hub.theme
                    && props.hub.theme.mobile
                    && props.hub.theme.mobile.header_color
                        ? props.hub.theme.mobile.header_color[1]
                        : defaultGradient[1]
                    })`,
                    height: '200px',
                    borderRadius: '8px',
                  }}
                />

                <div className="avatar">
                  <div className="row w-100">
                    <div className="col-5 pr-0">
                      <div className="w-100 d-flex justify-content-between">
                      <Button
                        variant="outline"
                        value="View"
                        onClick={() => navigate(`/hub/${props.hub.id}`)}
                        padding="1px 8px"
                        margin="0px 0px 0px 8px"
                      />
                      <Button
                        variant="outline"
                        value={props.hub.classification[0].toUpperCase()}
                        padding="1px 8px"
                      />
                      <Button
                        variant="outline"
                        value={
                          props.hub.category[0].toUpperCase()
                          + props.hub.category.slice(1, props.hub.category.length)
                        }
                        padding="1px 8px"
                      />
                      </div>
                    </div>
                    <div className="col-2">
                      <img
                        src={
                        props.hub.original_avatar
                          ? props.hub.original_avatar
                          : ProfilePictureIcon
                      }
                        alt="#"
                        onError={addDefaultSrc}
                        className="bg-white"
                      />
                    </div>
                    <div className="col-5 pr-0">
                      <div className="w-100 d-flex justify-content-around">
                      <Button
                        variant="outline"
                        value={
                          <MessageSquare style={{ verticalAlign: 'bottom' }} />
                        }
                        padding="3px 5px"
                        onClick={() => setShowChat(!showChat)}
                      />

                      <Button
                        variant="outline"
                        value={<Phone style={{ verticalAlign: 'bottom' }} />}
                        padding="3px 5px"
                        onClick={() => actionHandler(enums.ACTIONS.INITIATE_AUDIO_CALL, {
                          type: 'group',
                          id: `hub_${props.hub.id}`,
                        })}
                      />
                      <Button
                        variant="outline"
                        value={<Video style={{ verticalAlign: 'bottom' }} />}
                        padding="3px 5px"
                        onClick={() => actionHandler(enums.ACTIONS.INITIATE_VIDEO_CALL, {
                          type: 'group',
                          id: `hub_${props.hub.id}`,
                        })}
                      />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div>
                    <h2>{props.hub.name}</h2>
                    <h6 className="text-center">
                      (@
                    {props.hub.alias}
                      )
                    </h6>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    {hubMembers.map((person, index) => {
                      if (person.user_role === 'creator') {
                        return (
                        <div className="col-12" key={index}>
                          <div className="card">
                            <div className="card-header">
         <div className="row">
                              <div className="col-sm-6 d-flex">
                                <ProfilePic>
                                    <img
                                      className="rounded-circle"
                                      src={
                                        person.user_original_avatar
                                          ? person.user_original_avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="#"
                                      width="100%"
                                      height="100%"
                                      onError={addDefaultSrc}
                                    />
                                </ProfilePic>
                                <div className="ml-2">
                                    <h6>{person.user_name}</h6>
                                    <div>
                                      @
                                      {person.user_username}
                                    </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="pull-right right-header">
                                    <h6>{person.user_role.toUpperCase()}</h6>
                                </div>
                              </div>
         </div>
                            </div>
                          </div>
                        </div>
                        );
                      }
                      return null;
                    })}
                    {hubMembers.map((person, index) => {
                      if (person.user_role === 'admin') {
                        return (
                        <div className="col-12" key={index}>
                          <div className="card">
                            <div className="card-header">
         <div className="row">
                              <div className="col-sm-6 d-flex">
                                <ProfilePic>
                                    <img
                                      className="rounded-circle"
                                      src={
                                        person.user_original_avatar
                                          ? person.user_original_avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="#"
                                      width="100%"
                                      height="100%"
                                      onError={addDefaultSrc}
                                    />
                                </ProfilePic>
                                <div className="ml-2">
                                    <h6>{person.user_name}</h6>
                                    <div>
                                      @
                                      {person.user_username}
                                    </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="pull-right right-header">
                                    <h6>{person.user_role.toUpperCase()}</h6>
                                </div>
                              </div>
         </div>
                            </div>
                          </div>
                        </div>
                        );
                      }
                      return null;
                    })}
                    {hubMembers.map((person, index) => {
                      if (person.user_role === 'member') {
                        return (
                        <div className="col-12" key={index}>
                          <div className="card">
                            <div className="card-header">
         <div className="row">
                              <div className="col-sm-6 d-flex">
                                <ProfilePic>
                                    <img
                                      className="rounded-circle"
                                      src={
                                        person.user_original_avatar
                                          ? person.user_original_avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="#"
                                      width="100%"
                                      height="100%"
                                      onError={addDefaultSrc}
                                    />
                                </ProfilePic>
                                <div className="ml-2">
                                    <h6>{person.user_name}</h6>
                                    <div>
                                      @
                                      {person.user_username}
                                    </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="pull-right right-header">
                                    <h6>{person.user_role.toUpperCase()}</h6>
                                </div>
                              </div>
         </div>
                            </div>
                          </div>
                        </div>
                        );
                      }
                      return null;
                    })}
                    {hubMembers.map((person, index) => {
                      if (person.user_role === 'viewer') {
                        return (
                        <div className="col-12" key={index}>
                          <div className="card">
                            <div className="card-header">
         <div className="row">
                              <div className="col-sm-6 d-flex">
                                <ProfilePic>
                                    <img
                                      className="rounded-circle"
                                      src={
                                        person.user_original_avatar
                                          ? person.user_original_avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="#"
                                      width="100%"
                                      height="100%"
                                      onError={addDefaultSrc}
                                    />
                                </ProfilePic>
                                <div className="ml-2">
                                    <h6>{person.user_name}</h6>
                                    <div>
                                      @
                                      {person.user_username}
                                    </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="pull-right right-header">
                                    <h6>{person.user_role.toUpperCase()}</h6>
                                </div>
                              </div>
         </div>
                            </div>
                          </div>
                        </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                <div className="customers social-group">
                  <ul>
                    {props.members.map((person, index) => (
                      <li className="d-inline-block" key={index}>
                      <img
                        className="group-image rounded-circle"
                        src={
                            person.user_original_avatar
                              ? person.user_original_avatar
                              : ProfilePictureIcon
                          }
                        alt="#"
                        id={`tool${person.user_id}`}
                        onError={addDefaultSrc}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tool${person.user_id}`}
                      >
                        {person.user_name}
                      </UncontrolledTooltip>
                      </li>
                    ))}
                  </ul>
                </div>
              </InfoSection>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Users size={40} alignmentBaseline="center" />
                <div className="h4 mt-2 ml-4">No Hub</div>
              </div>
            )}
            {showChat ? (
              <ChatCard className="card">
                <div
                  className="w-100 p-2"
                  style={{ backgroundColor: 'lightgrey' }}
                >
                  <div className="close-btn" onClick={() => setShowChat(false)}>
                    <span>X</span>
                  </div>
                </div>
                <CometChatMessages chatWithGroup={`hub_${props.hub.id}`} />
              </ChatCard>
            ) : null}
          </>
        )}
    </>
  );
}

export default HubInfo;
