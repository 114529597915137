import React from 'react';
import Navbar from 'src/components/Navbar';
import Card from 'src/components/Card';
import Footer from 'src/components/Footer';
import BannerIcon from 'src/assets/images/livit/Inner 1.png';
import DashboardIcon from 'src/assets/images/livit/Icon - Dashboard.png';
import CalendarIcon from 'src/assets/images/livit/Icon - Calendar.png';
import FeedIcon from 'src/assets/images/livit/Icon - Feed.png';
import VaultIcon from 'src/assets/images/livit/Icon - Vault.png';
import ChatIcon from 'src/assets/images/livit/Icon - Chat.png';
import { Header } from 'src/containers/LandingPage/About/styles';
import { Heading, Name } from './styles';

export const featuresCards = [
  {
    key: 1,
    title: 'Dashboard',
    about:
      'An easy to navigate private dashboard helps you to manage your daily online activities.',
    image: DashboardIcon,
  },
  {
    key: 2,
    title: 'Calendar',
    about:
      'A multi-purpose calendar that is integrated on your Dashboard to mark important events.',
    image: CalendarIcon,
  },
  {
    key: 3,
    title: 'Feed',
    about:
      'Your Perssonal and Hub feeds keeps you up to date with your connections and their activities.',
    image: FeedIcon,
  },
  {
    key: 4,
    title: 'Multimedia & File Storage',
    about:
      'The custom designed vault safely stores important images, vedios and files.',
    image: VaultIcon,
  },
  {
    key: 5,
    title: 'Messaging & Chat',
    about:
      'You have the option of connecting with friends and family one to one or all together.',
    image: ChatIcon,
  },
];

function Features() {
  return (
    <>
      <Navbar />
      <div className="bg-white">
        <div className="row">
          <div className="col-sm-6 text-center">
            <Name>Features</Name>
          </div>
          <div className="col-sm-6">
            <img src={BannerIcon} alt="banner" width="100%" />
          </div>
        </div>
        <div className="container text-center mt-4 pt-4">
          <Heading>Enjoy the best features of all the popular social</Heading>
          <Heading>media platforms rolled into one.</Heading>
          <Header>
            Liviit is easy for all ages to access and the most secure way to
            connect
          </Header>
          <Header>with friends, family and community.</Header>
          <div className="row mb-4">
            {featuresCards.map((card) => (
              <div key={card.key} className="col-sm-6 mt-5">
                <Card item={card} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Features;
