import React from 'react';
import LiviitLogo from 'src/assets/images/livit/Liviit_logo.png';
import { themeColor } from 'src/utils/theme';
import { Header, Paragraph } from './styles';

function PrivacyPolicy() {
  return (
    <div className="container">
      <Header className="d-flex justify-content-between mt-2 px-2">
        <div>
          <h2>Liviit – Privacy Policy</h2>
        </div>
        <div>
          <img
            src={LiviitLogo}
            alt="icon"
            width="50px"
            height="50px"
            className="rounded-circle"
          />
        </div>
      </Header>
      <h4 className="font-weight-bold" style={{ color: themeColor }}>
        Last updated: September 1, 2020
      </h4>
      <Paragraph>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </Paragraph>
      <Paragraph>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Interpretation and Definitions</u>
      </h3>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Interpretation
      </h5>
      <Paragraph className="mb-2">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions.
        {' '}
      </Paragraph>
      <Paragraph>
        The following definitions shall have the same meaning regardless of
        whether they appear in singular or in plural.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Definitions
      </h5>
      <Paragraph>For the purposes of these Terms and Conditions:</Paragraph>
      <div className="pl-4 ml-4">
        <Paragraph>
          <span className="font-weight-bold">You </span>
          means the individual
          accessing or using the Service, or the company, or other legal entity
          on behalf of which such individual is accessing or using the Service,
          as applicable.
          {' '}
        </Paragraph>
        <Paragraph>
          Under GDPR (General Data Protection Regulation), You can be referred
          to as the Data Subject or as the User as you are the individual using
          the Service.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Company </span>
          (referred to as
          either "the Company", "We", "Us" or "Our" in this Agreement) refers to
          Liviit Australia Pty Ltd, 2/6 Lawson Parade, Highett, Victoria.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Application </span>
          means the
          so\ware program provided by the Company downloaded by You on any
          electronic device, named Liviit
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Affiliate </span>
          means an entity
          that controls, is controlled by or is under common control with a
          party, where "control" means ownership of 50% or more of the shares,
          equity interest or other securities entitled to vote for election of
          directors or other managing authority.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Account </span>
          means a unique
          account created for You to access our Service or parts of our Service
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Service </span>
          refers to the
          Application.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Country </span>
          refers to: Victoria,
          Australia
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Service Provider </span>
          means any
          natural or legal person who processes the data on behalf of the
          Company. It refers to third-party companies or individuals employed by
          the Company to facilitate the Service, to provide the Service on
          behalf of the Company, to perform services related to the Service or
          to assist the Company in analyzing how the Service is used. For the
          purpose of the GDPR, Service Providers are considered Data Processors
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">
            Third-party Social Media Service
            {' '}
          </span>
          {' '}
          means any services or content (including data, information, products
          or services) provided by a third-party that may be displayed, included
          or made available by the Service.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Personal Data </span>
          {' '}
          is any
          information that relates to an identified or identifiable individual.
          {' '}
        </Paragraph>
        <Paragraph>
          For the purposes for GDPR, Personal Data means any information
          relating to You such as a name, an identification number, location
          data, online identifier or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social
          identity.
        </Paragraph>
        <Paragraph>
          For the purposes of the CCPA, Personal Data means any information that
          identifies, relates to, describes or is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with You.
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Device </span>
          means any device that
          can access the Service such as a computer, a cellphone or a digital
          tablet.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Usage Data </span>
          refers to data
          collected automatically, either generated by the use of the Service or
          from the Service infrastructure itself (for example, the duration of a
          page visit).
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Data Controller </span>
          for the
          purposes of the GDPR (General Data Protection Regulation), refers to
          the Company as the legal person which alone or jointly with others
          determines the purposes and means of the processing of Personal Data.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Do Not Track </span>
          (DNT) is a concept that has been promoted by US regulatory
          authorities, in particular the U.S. Federal Trade Commission (FTC),
          for the Internet industry to develop and implement a mechanism for
          allowing internet users to control the tracking of their online
          activities across websites.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Business </span>
          for the purpose of
          the CCPA (California Consumer Privacy Act), refers to the Company as
          the legal entity that collects Consumers' personal information and
          determines the purposes and means of the processing of Consumers'
          personal information, or on behalf of which such information is
          collected and that alone, or jointly with others, determines the
          purposes and means of the processing of consumers' personal
          information, that does business in the State of California.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Consumer </span>
          for the purpose of
          the CCPA (California Consumer Privacy Act), means a natural person who
          is a California resident. A resident, as defined in the law, includes
          (1) every individual who is in the USA for other than a temporary or
          transitory purpose, and (2) every individual who is domiciled in the
          USA who is outside the USA for a temporary or transitory purpose.
          {' '}
        </Paragraph>
        <Paragraph>
          <span className="font-weight-bold">Sale </span>
          for the purpose of the
          CCPA (California Consumer Privacy Act), means selling, renting,
          releasing, disclosing, disseminating, making available, transferring,
          or otherwise communicating orally, in writing, or by electronic or
          other means, a Consumer's Personal information to another business or
          a third party for monetary or other valuable consideration.
          {' '}
        </Paragraph>
      </div>
      <h3 style={{ color: themeColor }}>
        <u>Collecting and Using Your Personal Data</u>
      </h3>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Types of Data Collected
      </h5>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Personal Data
      </h5>
      <Paragraph>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
        <li>Usage Data</li>
      </ul>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Usage Data
      </h5>
      <Paragraph>
        Usage Data is collected automatically when using the Service.
      </Paragraph>
      <Paragraph>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Paragraph>
      <Paragraph>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Information Collected while Using the Application
      </h5>
      <Paragraph>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with your prior permission:
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">Information regarding your location</li>
        <li className="mt-3">
          Information from your Device's phone book (contacts list)
        </li>
        <li className="mt-3">
          Pictures and other information from your Device's camera and photo
          library
        </li>
      </ul>
      <Paragraph>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it be simply
        stored on Your device.
        {' '}
      </Paragraph>
      <Paragraph>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Use of Your Personal Data
      </h5>
      <Paragraph>
        The Company may use Personal Data for the following purposes:
        {' '}
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </li>
        <li className="mt-3">
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </li>
        <li className="mt-3">
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </li>
        <li className="mt-3">
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation
        </li>
        <li className="mt-3">
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </li>
        <li className="mt-3">
          To manage Your requests: To attend and manage Your requests to Us.
          {' '}
        </li>
      </ul>
      <Paragraph>
        We may share your personal information in the following situations:
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, for
          payment processing, to contact You.
        </li>
        <li className="mt-3">
          For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li className="mt-3">
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li className="mt-3">
          With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </li>
        <li className="mt-3">
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Service
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile.
        </li>
      </ul>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Retention of Your Personal Data
      </h5>
      <Paragraph>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
        {' '}
      </Paragraph>
      <Paragraph>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Transfer of Your Personal Data
      </h5>
      <Paragraph>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </Paragraph>
      <Paragraph>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Paragraph>
      <Paragraph>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Disclosure of Your Personal Data
      </h5>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Business Transactions
      </h5>
      <Paragraph>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Law enforcement
      </h5>
      <Paragraph>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Other legal requirements
      </h5>
      <Paragraph>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li className="mt-3">Comply with a legal obligation </li>
        <li className="mt-3">
          Protect and defend the rights or property of the Company
        </li>
        <li className="mt-3">
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li className="mt-3">
          Protect the personal safety of Users of the Service or the public
        </li>
        <li className="mt-3">Protect against legal liability</li>
      </ul>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Security of Your Personal Data
      </h5>
      <Paragraph>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Detailed Information on the Processing of Your Personal Data</u>
      </h3>
      <Paragraph>
        Service Providers have access to Your Personal Data only to perform
        their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Payments
      </h5>
      <Paragraph>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </Paragraph>
      <Paragraph>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </Paragraph>
      <div className="ml-4 pl-4 font-weight-bold">Stripe</div>
      <Paragraph className="ml-4 pl-4 font-weight-bold">
        Their Privacy Policy can be viewed at https://stripe.com/us/privacy
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>GDPR Privacy</u>
      </h3>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Legal Basis for Processing Personal Data under GDPR
      </h5>
      <Paragraph>
        We may process Personal Data under the following conditions:
        {' '}
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li className="mt-3">
          Consent: You have given Your consent for processing Personal Data for
          one or more specific purposes.
          {' '}
        </li>
        <li className="mt-3">
          Performance of a contract: Provision of Personal Data is necessary for
          the performance of an agreement with You and/or for any
          pre-contractual obligations thereof.
        </li>
        <li className="mt-3">
          Legal obligations: Processing Personal Data is necessary for
          compliance with a legal obligation to which the Company is subject.
        </li>
        <li className="mt-3">
          Vital interests: Processing Personal Data is necessary in order to
          protect Your vital interests or of another natural person.
        </li>
        <li className="mt-3">
          Public interests: Processing Personal Data is related to a task that
          is carried out in the public interest or in the exercise of official
          authority vested in the Company.
        </li>
        <li className="mt-3">
          Legitimate interests: Processing Personal Data is necessary for the
          purposes of the legitimate interests pursued by the Company.
        </li>
      </ul>
      <Paragraph>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Your Rights under the GDPR
      </h5>
      <Paragraph>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </Paragraph>
      <Paragraph>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li className="mt-3">
          Request access to Your Personal Data. The right to access, update or
          delete the information We have on You. Whenever made possible, you can
          access, update or request deletion of Your Personal Data directly
          within Your account settings section. If you are unable to perform
          these actions yourself, please contact Us to assist You. This also
          enables You to receive a copy of the Personal Data We hold about You.
          {' '}
        </li>
        <li className="mt-3">
          Request correc5on of the Personal Data that We hold about You. You
          have the right to to have any incomplete or inaccurate information We
          hold about You corrected.
        </li>
        <li className="mt-3">
          Object to processing of Your Personal Data. This right exists where We
          are relying on a legitimate interest as the legal basis for Our
          processing and there is something about Your particular situation,
          which makes You want to object to our processing of Your Personal Data
          on this ground. You also have the right to object where We are
          processing Your Personal Data for direct marketing purposes.
        </li>
        <li className="mt-3">
          Request erasure of Your Personal Data. You have the right to ask Us to
          delete or remove Personal Data when there is no good reason for Us to
          continue processing it.
        </li>
        <li className="mt-3">
          Request the transfer of Your Personal Data. We will provide to You, or
          to a third-party You have chosen, Your Personal Data in a structured,
          commonly used, machine-readable format. Please note that this right
          only applies to automated information which You initially provided
          consent for Us to use or where We used the information to perform a
          contract with You.
        </li>
        <li className="mt-3">
          Withdraw Your consent. You have the right to withdraw Your consent on
          using your Personal Data. If You withdraw Your consent, We may not be
          able to provide You with access to certain specific functionalities of
          the Service.
        </li>
      </ul>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Exercising of Your GDPR Data Protection Rights
      </h5>
      <Paragraph>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </Paragraph>
      <Paragraph>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>CCPA Privacy </u>
      </h3>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Your Rights under the CCPA
      </h5>
      <Paragraph>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
        {' '}
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li className="mt-3">
          The right to notice. You must be properly notified which categories of
          Personal Data are being collected and the purposes for which the
          Personal Data is being used.
          {' '}
        </li>
        <li className="mt-3">
          The right to access / the right to request. The CCPA permits You to
          request and obtain from the Company information regarding the
          disclosure of Your Personal Data that has been collected in the past
          12 months by the Company or its subsidiaries to a third-party for the
          third party's direct marketing purposes.
        </li>
        <li className="mt-3">
          The right to say no to the sale of Personal Data. You also have the
          right to ask the Company not to sell Your Personal Data to third
          parties. You can submit such a request by visiting our "Do Not Sell My
          Personal Information" section or web page.
        </li>
        <li className="mt-3">
          The right to know about Your Personal Data. You have the right to
          request and obtain from the Company information regarding the
          disclosure.
        </li>
        <li className="mt-3">
          The right to delete Personal Data. You also have the right to request
          the deletion of Your Personal Data that have been collected in the
          past 12 months.
        </li>
        <li className="mt-3">
          The right not to be discriminated against. You have the right not to
          be discriminated against for exercising any of Your Consumer's rights,
        </li>
      </ul>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Exercising Your CCPA Data Protection Rights
      </h5>
      <Paragraph>
        In order to exercise any of Your rights under the CCPA, and if you are a
        California resident, You can email or call us or visit our "Do Not Sell
        My Personal Information" section or web page.
      </Paragraph>
      <Paragraph>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving Your verifiable request. The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Do Not Sell My Personal Information
      </h5>
      <Paragraph>
        We do not sell personal information. However, the Service Providers we
        partner with (for example, our advertising partners) may use technology
        on the Service that "sells" personal information as defined by the CCPA
        law.
      </Paragraph>
      <Paragraph>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </Paragraph>
      <Paragraph>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that you use.
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Website
      </h5>
      <Paragraph>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </Paragraph>
      <ul className="pl-4 ml-4">
        <li className="mt-3">From Our "Cookie Consent" notice banner </li>
        <li className="mt-3">Or from Our "CCPA Opt-out" notice banner</li>
        <li className="mt-3">
          Or from Our "Do Not Sell My Personal Information" notice banner
        </li>
        <li className="mt-3">
          Or from Our "Do Not Sell My Personal Information" link
        </li>
      </ul>
      <Paragraph>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Mobile Devices
      </h5>
      <Paragraph>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
        {' '}
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">
          "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on
          Android devices
          {' '}
        </li>
        <li className="mt-3">"Limit Ad Tracking" on iOS devices</li>
      </ul>
      <Paragraph>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>
          "Do Not Track" Policy as Required by California Online Privacy
          Protection Act (CalOPPA)
        </u>
      </h3>
      <Paragraph>
        Our Service does not respond to Do Not Track signals.
        {' '}
      </Paragraph>
      <Paragraph>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Children's Privacy</u>
      </h3>
      <Paragraph>
        The Service may contain content appropriate for children under the age
        of 13. As a parent, you should know that through the Service children
        under the age of 13 may participate in activities that involve the
        collection or use of personal information. We use reasonable efforts to
        ensure that before we collect any personal information from a child, the
        child's parent receives notice of and consents to our personal
        information practices.
      </Paragraph>
      <Paragraph>
        We also may limit how We collect, use, and store some of the information
        of Users between 13 and 18 years old. In some cases, this means We will
        be unable to provide certain functionality of the Service to these
        Users. If We need to rely on consent as a legal basis for processing
        Your information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </Paragraph>
      <Paragraph>
        We may ask a User to verify its date of birth before collecting any
        personal information from them. If the User is under the age of 13, the
        Service will be either blocked or redirected to a parental consent
        process.
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Information Collected from Children Under the Age of 13
      </h5>
      <Paragraph>
        The Company may collect and store persistent identifiers such as cookies
        or IP addresses from Children without parental consent for the purpose
        of supporting the internal operation of the Service.
      </Paragraph>
      <Paragraph>
        We may collect and store other personal information about children if
        this information is submitted by a child with prior parent consent or by
        the parent or guardian of the child.
        {' '}
      </Paragraph>
      <Paragraph>
        The Company may collect and store the following types of personal
        information about a child when submitted by a child with prior parental
        consent or by the parent or guardian of the child :
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">First and/or last name </li>
        <li className="mt-3">Date of birth</li>
        <li className="mt-3">Gender</li>
        <li className="mt-3">Grade level</li>
        <li className="mt-3">Email address</li>
        <li className="mt-3">Telephone number </li>
        <li className="mt-3">Parent's or guardian's name</li>
        <li className="mt-3">Parent's or guardian's email address </li>
      </ul>
      <Paragraph>
        For further details on the information We might collect, You can refer
        to the "Types of Data Collected" section of this Privacy Policy. We
        follow our standard Privacy Policy for the disclosure of personal
        information collected from and about children.
        {' '}
      </Paragraph>
      <h5 className="font-weight-bold mt-4" style={{ color: themeColor }}>
        Parental Access
      </h5>
      <Paragraph>
        A parent who has already given the Company permission to collect and use
        his child personal information can, at any time:
        {' '}
      </Paragraph>
      <ul className="pl-4" style={{ listStyleType: 'disc' }}>
        <li className="mt-3">
          Review, correct or delete the child's personal information
        </li>
        <li className="mt-3">
          Discontinue further collection or use of the child's personal
          information
        </li>
      </ul>
      <Paragraph>
        To make such a request, You can write to Us using the contact
        information provided in this Privacy Policy.
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Your California Privacy Rights (California's Shine the Light law)</u>
      </h3>
      <Paragraph>
        Under California Civil Code Section 179ti (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
        {' '}
      </Paragraph>
      <Paragraph>
        If you'd like to request more information under the California Shine the
        Light law, and if you are a California resident, You can contact Us
        using the contact information provided below.
        {' '}
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 225ti1)
        </u>
      </h3>
      <Paragraph>
        California Business and Professions Code section 225ti1 allow California
        residents under the age of 1ti who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </Paragraph>
      <Paragraph>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </Paragraph>
      <Paragraph>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Links to Other Websites</u>
      </h3>
      <Paragraph>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
        {' '}
      </Paragraph>
      <Paragraph>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Changes to this Privacy Policy </u>
      </h3>
      <Paragraph>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
        {' '}
      </Paragraph>
      <Paragraph>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
        {' '}
      </Paragraph>
      <Paragraph>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Paragraph>
      <h3 style={{ color: themeColor }}>
        <u>Contact Us </u>
      </h3>
      <Paragraph>
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </Paragraph>
      <ul className="ml-4 pl-4 pb-4 mb-4">
        <li>By email: contact@liviit.com </li>
        <li>
          By visiting this page on our website: https://liviit.com/contact
        </li>
        <li>By mail: 2/6 Lawson Parade, Highett, Victoria, 3190, Australia</li>
      </ul>
    </div>
  );
}

export default PrivacyPolicy;
