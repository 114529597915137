import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const ProfilePic = styled.div`
  width: ${(props) => (props.width ? props.width : '32px')};
  height: ${(props) => (props.height ? props.height : '32px')};
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
  margin: auto;
`;

export const Card = styled.div`
  width: 100%;
  border-bottom: 2px solid lightgrey;
  margin-bottom: 4px;
  margin-top: 4px;
  cursor: pointer;
  .column {
    float: left;
    box-sizing: border-box;
  }

  .left {
    width: 15%;
    box-sizing: border-box;
    text-align: center;
  }
  .right {
    width: 25%;
    box-sizing: border-box;
    text-align: center;
  }

  .middle {
    width: 60%;
    box-sizing: border-box;
  }
  .rows:after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const ScrollableSection = styled.div`
  height: 100%;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeColor};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow-y: auto;
  }
  li {
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid lightgrey;
  }
  li:hover {
    background-color: lightgrey;
    color: white;
  }
  .list-group-item.active {
    background-color: lightgrey;
    border-color: lightgrey;
    border-bottom: 4px solid lightgrey;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  .field {
    vertical-align: initial;
    padding: 5px 0px;
    font-weight: bold;
    color: black;
    font-size: 20px;
  }
  .value {
    vertical-align: initial;
    padding: 5px 0px;
    font-size: 18px;
  }
`;

export const Shape = styled.div`
  height: 200px;
  background-image: ${(props) => `linear-gradient(${props.color[0]},${props.color[1]})`};
`;
