import React, { useEffect, useState } from 'react';
import { verifyUser } from 'src/redux/usersRedux';
import { useDispatch, useSelector } from 'react-redux';
import LiviitLogo from 'src/assets/images/livit/Liviit_logo.png';
import LiviitName from 'src/assets/images/livit/Liviit_logo_text_grey.png';
import Loader from 'src/components/Loader';
import { ImageContainer, ImageView } from './styles';

function Verification() {
  const urlPath = window.location.href;
  const verificationCode = urlPath.substr(urlPath.lastIndexOf('/') + 1);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { verificationDetail } = useSelector((state) => state.UsersReducer);
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    dispatch(verifyUser(verificationCode));
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);
  console.log(verificationDetail);
  return (
    <div className="row" style={{ minHeight: '100vh' }}>
      <div className="col-lg-12 h-100">
        <div className="bg-white mt-4 d-flex justify-content-center">
          <ImageContainer>
            <img
              src={LiviitLogo}
              alt="LiviitLogo"
              width="100%"
              style={{ borderRadius: '30%' }}
            />
          </ImageContainer>
        </div>
      </div>
      <div className="col-lg-12 h-100">
        <div
          style={{
            borderWidth: 1,
            borderColor: '#ccc',
            borderStyle: 'double',
            padding: '15px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="bg-white mt-4 mb-4 ml-4 mr-4 d-flex justify-content-center"
        >
          <ImageView>
            <img
              src={LiviitName}
              alt="LiviitLogo"
              width="100%"
              style={{ borderRadius: '30%' }}
            />
          </ImageView>
          {processing.status && processing.component === 'verification' ? (
            <Loader show />
          ) : !verificationDetail ? (
            <div
              className="col-sm-12 d-flex mt-4 text-center text-white"
              style={{
                visibility: loading ? 'hidden' : 'visible',
                backgroundColor: 'rgb(239,218,217)',
                minHeight: '40px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                className="mx-auto"
                style={{
                  fontSize: '17px',
                  color: 'rgb(145,100,100)',
                  marginBottom: 0,
                }}
              >
                Invalid link followed
              </p>
            </div>
          ) : (
            <div
              className="col-sm-12 d-flex mt-4 text-center text-white"
              style={{
                visibility: loading ? 'hidden' : 'visible',
                backgroundColor: 'rgb(221,235,220)',
                minHeight: '40px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                className="mx-auto"
                style={{
                  fontSize: '17px',
                  color: 'rgb(109,135,105)',
                  marginBottom: 0,
                }}
              >
                Your account has been successfully activated now!
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-12 h-100 d-flex justify-content-center">
        <p
          className="mx-auto"
          style={{ fontSize: '12px', color: 'rgba(112,112,112,1)' }}
        >
          2012-2020 Liviit. All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Verification;
