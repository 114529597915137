import styled from 'styled-components';

export const Members = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

export const Member = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  text-decoration: none;
`;

export const UserImage = styled.div`
  height: 140px;
  border-radius: 50%;
  .pro {
    border: 1px solid grey;
    padding: 5px;
  }
`;
