import React, { useEffect, useState } from 'react';
import { ScrollableSection } from 'src/components/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHubVaultAllfiles,
  getHubDetails,
  getHubVaultFolderfiles,
  getAllHubVaultFolders,
  addHubVaultFolder,
  addFileInHubFolder,
} from 'src/redux/hubRedux';
import PlusButton from 'src/components/PlusButton';
import { ModalUpload } from 'src/components/Modal';
import Breadcrumb from 'src/components/Breadcrumb';
import Media from './Sections/vault';
import Folders from './Sections/folders';

function HubVault(props) {
  const [selectedFolder, setSelectedFolder] = useState({ name: 'all' });
  const [modalShow, setModalShow] = useState(false);
  const {
    hubVaultFiles, hubVaultFolders, hub, hubVaultMeta,
  } = useSelector(
    (state) => state.HubsReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);
  const { theme } = useSelector((state) => state.ThemeReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHubDetails(props.match.params.id));
    dispatch(getHubVaultAllfiles(props.match.params.id));
  }, []);

  const getFolder = (folder) => {
    if (folder.name === 'all') {
      dispatch(getHubVaultAllfiles(props.match.params.id));
      setSelectedFolder(folder);
    } else {
      dispatch(
        getHubVaultFolderfiles({
          id: props.match.params.id,
          folderid: folder.id,
        }),
      );
      setSelectedFolder(folder);
    }
  };

  const addFile = (files) => {
    const formData = new FormData();
    files.forEach((data, index) => {
      formData.append(`files[${index}][file]`, data.file);
    });
    formData.set('allow_sharing', true);
    const payload = [props.match.params.id, selectedFolder.id, formData];
    dispatch(addFileInHubFolder(payload));
  };

  return (
    <>
      <Breadcrumb parent="Hub Vault" title={hub ? hub.alias : ''} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Folders
              processing={processing}
              folders={hubVaultFolders}
              getFolder={getFolder}
              addFolder={addHubVaultFolder}
              getFolders={getAllHubVaultFolders}
              selected={selectedFolder}
              vaultFiles={hubVaultFiles}
              {...props}
            />
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 className="align-abjust">
                      {selectedFolder.name.charAt(0).toUpperCase()
                        + selectedFolder.name.substring(
                          1,
                          selectedFolder.name.length,
                        )}
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <div className="pull-right right-header">
                      {selectedFolder.name !== 'all' && (
                        <PlusButton onClick={() => setModalShow(true)} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body" style={{ height: '72vh' }}>
                <ScrollableSection
                  height="95%"
                  className="mt-2"
                  id="scroll-vault"
                >
                  <div>
                    <Media
                      processing={processing}
                      vaultFiles={hubVaultFiles}
                      meta={hubVaultMeta}
                      selectedFolder={selectedFolder}
                      theme={theme}
                      dispatch={dispatch}
                    />
                    <ModalUpload
                      show={modalShow}
                      close={setModalShow}
                      size={10}
                      addFile={addFile}
                    />
                  </div>
                </ScrollableSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubVault;
