import styled from 'styled-components';

export const Word = styled.div`
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

export const InputBox = styled.div`
  cursor: pointer;
  display: flex;
`;
