import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Heading } from 'src/components/StyledComponents';
import { subscribeToPlan } from 'src/redux/subscriptionRedux';
import { PlanPrice } from 'src/utils/data';
import { SubscriptionReducer } from 'src/selectors';
import { Spinner } from 'react-bootstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { ProceedButton } from './styles';

function PaymentModal({
  showPaymentModal,
  selectedSubscriptionPlan,
  setSelectedSubscriptionPlan,
  setShowPaymentModal,
}) {
  const { plansProcessing } = useSelector(SubscriptionReducer);

  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const { name } = selectedSubscriptionPlan;

  const { dollar, cents } = PlanPrice[name];

  const isPaymentActive = plansProcessing.status && plansProcessing.component === 'plan-subscription';

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      toast.error(result.error.message);
    } else {
      dispatch(
        subscribeToPlan({
          data: {
            token: result.token.id,
            plan_id: selectedSubscriptionPlan.id,
            is_mobile: false,
          },
          cb: () => {
            setShowPaymentModal(false);
          },
        }),
      );
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <Modal
      show={showPaymentModal}
      size="md"
      onHide={() => {
        if (isPaymentActive) return;
        setShowPaymentModal(false);
        setSelectedSubscriptionPlan(null);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Heading className="font-weight-bold">Subscription Payment</Heading>
      </Modal.Header>
      <Modal.Body>
        <div className="h6">You have selected</div>
        <div className="h4">
          {`${selectedSubscriptionPlan?.name.toUpperCase()} A$${dollar}.${cents}/month`}
        </div>
        <form className="py-2 mt-3" onSubmit={handleSubmit}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <ProceedButton
            style={{ width: '100%', marginTop: '20px' }}
            isActive={isPaymentActive}
            disabled={!stripe}
            type="submit"
          >
            {isPaymentActive ? (
              <div className="d-flex align-items-center justify-content-center">
                <span>Proceeding...</span>
                <Spinner
                  className="ml-3"
                  size="sm"
                  animation="border"
                  variant="light"
                />
              </div>
            ) : (
              'Proceed'
            )}
          </ProceedButton>
        </form>
      </Modal.Body>
    </Modal>
  );
}

PaymentModal.propTypes = {
  showPaymentModal: PropTypes.bool.isRequired,
  selectedSubscriptionPlan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    trial_days: PropTypes.number.isRequired,
  }).isRequired,
  setShowPaymentModal: PropTypes.func.isRequired,
  setSelectedSubscriptionPlan: PropTypes.func.isRequired,
};

export default PaymentModal;
