// actions
export const getAllHubs = (payload) => ({
  type: 'GET_ALL_HUBS',
  payload,
});

export const getDashboardHubs = (payload) => ({
  type: 'GET_DASHBOARD_HUBS',
  payload,
});

export const getAllAdminHubs = (payload) => ({
  type: 'GET_ALL_ADMIN_HUBS',
  payload,
});

export const deleteHub = (payload) => ({
  type: 'DELETE_HUB',
  payload,
});

export const saveHubs = (payload) => ({
  type: 'SAVE_ALL_HUBS',
  payload,
});
export const saveDashboardHubs = (payload) => ({
  type: 'SAVE_DASHBOARD_HUBS',
  payload,
});

export const saveAdminHubs = (payload) => ({
  type: 'SAVE_ALL_ADMIN_HUBS',
  payload,
});

export const getHubDetails = (payload) => ({
  type: 'GET_HUB_DETAILS',
  payload,
});

export const saveHub = (payload) => ({
  type: 'SAVE_HUB',
  payload,
});
export const saveHubFeeds = (payload) => ({
  type: 'SAVE_HUB_FEEDS',
  payload,
});
export const getHubMembers = (payload) => ({
  type: 'GET_HUB_MEMBERS',
  payload,
});
export const getAdminHubMembers = (payload) => ({
  type: 'GET_ADMIN_HUB_MEMBERS',
  payload,
});
export const saveHubMembers = (payload) => ({
  type: 'SAVE_HUB_MEMBERS',
  payload,
});
export const saveHubRemainders = (payload) => ({
  type: 'SAVE_HUB_REMAINDERS',
  payload,
});
export const saveHubTodoList = (payload) => ({
  type: 'SAVE_HUB_TODOLIST',
  payload,
});
export const saveHubVaultFolders = (payload) => ({
  type: 'SAVE_HUB_VAULT_FOLDERS',
  payload,
});
export const saveHubVaultFiles = (payload) => ({
  type: 'SAVE_HUB_VAULT_FILES',
  payload,
});

export const getHubVaultFolderfiles = (payload) => ({
  type: 'GET_VAULT_FOLDER_FILES',
  payload,
});
export const getHubVaultAllfiles = (payload) => ({
  type: 'GET_VAULT_ALL_FILES',
  payload,
});
export const clearHub = () => ({
  type: 'CLEAR_HUB',
});

export const loadMoreData = (payload) => ({
  type: 'LOAD_MORE_HUB_FEEDS',
  payload,
});

export const loadMoreHubs = (payload) => ({
  type: 'LOAD_MORE_HUBS',
  payload,
});

export const addReaction = (payload) => ({
  type: 'ADD_REACTION_HUB_FEED',
  payload,
});

export const removeReaction = (payload) => ({
  type: 'REMOVE_REACTION_HUB_FEED',
  payload,
});

export const getComments = (payload) => ({
  type: 'GET_COMMENTS_HUB_FEED',
  payload,
});
export const addComment = (payload) => ({
  type: 'ADD_COMMENT_HUB_FEED',
  payload,
});
export const deleteComment = (payload) => ({
  type: 'DELETE_COMMENT_HUB_FEED',
  payload,
});

export const saveComments = (payload) => ({
  type: 'SAVE_COMMENTS',
  payload,
});

export const updateHub = (payload) => ({
  type: 'UPDATE_HUB',
  payload,
});

export const getHubTodoList = (payload) => ({
  type: 'GET_HUB_TODO_LISTS',
  payload,
});

export const getHubTodoSublist = (payload) => ({
  type: 'GET_HUB_TODO_SUBLIST',
  payload,
});
export const saveHubTodoSublist = (payload) => ({
  type: 'SAVE_HUB_TODO_SUBLIST',
  payload,
});
export const getHubTasks = (payload) => ({
  type: 'GET_HUB_TASKS',
  payload,
});
export const saveHubTasks = (payload) => ({
  type: 'SAVE_HUB_TASKS',
  payload,
});
export const addNewHubList = (payload) => ({
  type: 'ADD_NEW_HUB_LIST',
  payload,
});

export const addNewHubTask = (payload) => ({
  type: 'ADD_NEW_HUB_TASK',
  payload,
});

export const updateHubTask = (payload) => ({
  type: 'UPDATE_HUB_TASK',
  payload,
});

export const toggleHubTask = (payload) => ({
  type: 'TOGGLE_HUB_TASK',
  payload,
});

export const updateHubTodoList = (payload) => ({
  type: 'UPDATE_HUB_LIST',
  payload,
});

export const removeHubTask = (payload) => ({
  type: 'DELETE_HUB_TASK',
  payload,
});

export const addHubVaultFolder = (payload) => ({
  type: 'ADD_HUB_FOLDER',
  payload,
});

export const getAllHubVaultFolders = (payload) => ({
  type: 'GET_ALL_HUB_VAULT_FOLDERS',
  payload,
});

export const addFileInHubFolder = (payload) => ({
  type: 'ADD_HUB_FILE',
  payload,
});

export const getAllHubFolders = (payload) => ({
  type: 'GET_ALL_HUB_FOLDERS',
  payload,
});

export const saveAllHubFolders = (payload) => ({
  type: 'SAVE_ALL_HUB_FOLDERS',
  payload,
});

export const uploadHubFileInFolder = (payload) => ({
  type: 'UPLOAD_HUB_FILE',
  payload,
});

export const addHubFolder = (payload) => ({
  type: 'CREATE_HUB_FOLDER',
  payload,
});

export const getHubFolderData = (payload) => ({
  type: 'GET_HUB_FOLDER_DATA',
  payload,
});

export const saveHubFolderData2 = (payload) => ({
  type: 'SAVE_HUB_FOLDER_DATA_2',
  payload,
});

export const saveHubFolderData3 = (payload) => ({
  type: 'SAVE_HUB_FOLDER_DATA_3',
  payload,
});

export const removeHubFolder = (payload) => ({
  type: 'REMOVE_HUB_FOLDER',
  payload,
});

export const removeHubFile = (payload) => ({
  type: 'REMOVE_HUB_FILE',
  payload,
});

export const getAllHubFiles = (payload) => ({
  type: 'GET_ONLY_HUB_FILES',
  payload,
});
export const saveAllHubFiles = (payload) => ({
  type: 'SAVE_ALL_HUB_FILES',
  payload,
});

export const addHubFeed = (payload) => ({
  type: 'ADD_HUB_FEED',
  payload,
});

export const getHubPlaylists = (payload) => ({
  type: 'GET_HUB_PLAYLISTS',
  payload,
});

export const saveHubPlaylists = (payload) => ({
  type: 'SAVE_HUB_PLAYLISTS',
  payload,
});

export const createHubPlaylist = (payload) => ({
  type: 'CREATE_HUB_PLAYLIST',
  payload,
});
export const updateHubPlaylist = (payload) => ({
  type: 'UPDATE_HUB_PLAYLIST',
  payload,
});

export const removeHubPlaylist = (payload) => ({
  type: 'DELETE_HUB_PLAYLIST',
  payload,
});

export const getHubPlaylistTracks = (payload) => ({
  type: 'GET_HUB_PLAYLIST_TRACKS',
  payload,
});

export const addHubPlaylistTracks = (payload) => ({
  type: 'ADD_HUB_PLAYLIST_TRACKS',
  payload,
});

export const saveHubPlayListTracks = (payload) => ({
  type: 'SAVE_HUB_PLAYLIST_TRACKS',
  payload,
});

export const reorderHubTracks = (payload) => ({
  type: 'REORDER_HUB_TRACKS',
  payload,
});

export const updateHubTheme = (payload) => ({
  type: 'UPDATE_HUB_THEME',
  payload,
});

export const saveHubTheme = (payload) => ({
  type: 'SAVE_HUB_THEME',
  payload,
});

export const processHubs = (payload) => ({
  type: 'PROCESSING_HUBS',
  payload,
});

// store
const initialState = {
  dashboardHubs: [],
  hubs: [],
  allHubs: {},
  hub: {},
  hubFeeds: [],
  hubMembers: [],
  hubRemainders: [],
  hubTodoList: [],
  hubSublist: [],
  hubTasks: [],
  hubVaultFolders: [],
  hubVaultFiles: [],
  hubVaultMeta: {},
  hubFeedComments: [],
  hubFeedMeta: {},
  hubFolders: [],
  hubFolderDataLevel2: [],
  hubFolderDataLevel3: [],
  hubFilesmeta: {},
  hubFiles: [],
  hubPlaylists: [],
  hubPlaylistTracks: [],
  hubTheme: {},
  hubProcessing: { status: false, component: '' },
};

// hubReducer
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_ALL_HUBS':
      return { ...state, hubs: action.payload };
    case 'SAVE_DASHBOARD_HUBS':
      return { ...state, dashboardHubs: action.payload };
    case 'SAVE_ALL_ADMIN_HUBS':
      return { ...state, allHubs: action.payload };
    case 'SAVE_HUB':
      return {
        ...state,
        hub: action.payload,
        hubTheme: action.payload.theme,
      };
    case 'SAVE_HUB_FEEDS':
      return {
        ...state,
        hubFeeds: action.payload.data,
        hubFeedMeta: action.payload.meta.pagination,
      };
    case 'SAVE_HUB_THEME':
      return {
        ...state,
        hubTheme: action.payload.theme,
      };
    case 'SAVE_HUB_MEMBERS':
      return { ...state, hubMembers: action.payload };
    case 'SAVE_HUB_REMAINDERS':
      return { ...state, hubRemainders: action.payload };
    case 'SAVE_HUB_TODOLIST':
      return { ...state, hubTodoList: action.payload };
    case 'SAVE_HUB_TODO_SUBLIST':
      return { ...state, hubSublist: action.payload };
    case 'SAVE_HUB_TASKS':
      return { ...state, hubTasks: action.payload };
    case 'SAVE_HUB_VAULT_FOLDERS':
      return { ...state, hubVaultFolders: action.payload };
    case 'SAVE_HUB_VAULT_FILES':
      return {
        ...state,
        hubVaultFiles: action.payload.data,
        hubVaultMeta: action.payload.meta.pagination,
      };
    case 'SAVE_COMMENTS':
      return { ...state, hubFeedComments: action.payload.data };
    case 'SAVE_ALL_HUB_FOLDERS':
      return {
        ...state,
        hubFolders: action.payload.data,
        hubFilesmeta: action.payload.meta.pagination,
      };
    case 'SAVE_HUB_FOLDER_DATA_2':
      return { ...state, hubFolderDataLevel2: action.payload };
    case 'SAVE_HUB_FOLDER_DATA_3':
      return { ...state, hubFolderDataLevel3: action.payload };
    case 'SAVE_ALL_HUB_FILES':
      return { ...state, hubFiles: action.payload };
    case 'SAVE_HUB_PLAYLISTS':
      return {
        ...state,
        hubPlaylists: action.payload,
      };
    case 'SAVE_HUB_PLAYLIST_TRACKS':
      return {
        ...state,
        hubPlaylistTracks: action.payload,
      };
    case 'CLEAR_HUB':
      return {
        ...state,
        hub: {},
        hubFeeds: [],
        hubMembers: [],
        hubRemainders: [],
        hubTodoList: [],
        hubSublist: [],
        hubTasks: [],
        hubVaultFolders: [],
        hubVaultFiles: [],
        hubVaultMeta: {},
        hubFeedComments: [],
        hubFeedMeta: {},
      };
    case 'PROCESSING_HUBS':
      return { ...state, hubProcessing: action.payload };
    default:
      return { ...state };
  }
};
