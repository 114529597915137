import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'src/components/Button';
import SelectMenu from 'src/components/DropDown';

function ActionModal(props) {
  const [action, setAction] = useState(null);
  const [body, setBody] = useState(null);

  const actionOptions = [
    { key: 'no_objection', value: 'No Objection' },
    props.actionState.resource_type === 'feed'
      ? {
        key: 'hide',
        value: 'Hide',
      }
      : { key: 'block', value: 'Hold' },
  ];
  return (
    <Modal
      show={props.show}
      onHide={() => props.close(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="mb-2">
          <SelectMenu
            selected={action}
            setSelected={setAction}
            options={actionOptions}
            placeholder="Action..."
          />
        </div>
        <div className="my-2">
          <textarea
            className="form-control form-control-sm my-2 w-100"
            rows="5"
            placeholder="Reason..."
            onChange={(e) => setBody(e.target.value)}
            value={body}
            required
          />
        </div>

        <div className="my-2 text-center">
          <Button
            variant="outlined"
            value="OK"
            onClick={() => props.onAction(action.key, body)}
            dissabled={!(action && body)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ActionModal;
