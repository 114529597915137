import Modal from 'react-bootstrap/Modal';
import { PropTypes } from 'prop-types';
import { Heading } from 'src/components/StyledComponents';
import { ProceedButton } from 'src/containers/SubscriptionScreen/PaymentModal/styles';
import { deleteUserAccount } from 'src/redux/usersRedux';
import { useDispatch } from 'react-redux';

function DeleteUserConfirmationModal({
  openDeleteUserConfirmationModal,
  setOpenDeleteUserConfirmationModal,
  displayLoader,
}) {
  const dispatch = useDispatch();
  const handleYes = () => {
    dispatch(deleteUserAccount());
    displayLoader();
    setOpenDeleteUserConfirmationModal(false);
  };
  const handleNo = () => {
    setOpenDeleteUserConfirmationModal(false);
  };

  return (
    <Modal
      show={openDeleteUserConfirmationModal}
      size="md"
      onHide={() => {
        setOpenDeleteUserConfirmationModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Heading className="font-weight-bold">Confirm User Deletion</Heading>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete your account?</Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 justify-content-around">
          <ProceedButton onClick={handleNo}>No</ProceedButton>
          <ProceedButton onClick={handleYes}>Yes</ProceedButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

DeleteUserConfirmationModal.propTypes = {
  openDeleteUserConfirmationModal: PropTypes.bool.isRequired,
  setOpenDeleteUserConfirmationModal: PropTypes.func.isRequired,
  displayLoader: PropTypes.func.isRequired,
};

export default DeleteUserConfirmationModal;
