import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Section = styled.div`
  height: ${(props) => (props.height ? props.height : '88vh')};
  @media (max-height: 900px) {
    height: ${(props) => (props.smheight ? props.smheight : '710px')};
  }
`;

export const HeaderButton = styled.div`
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: ${themeColor};
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-size: 13px;
  &:hover {
    background-color: #d60000;
  }
  i {
    vertical-align: text-bottom;
  }
`;

export const ProfilePic = styled.div`
  width: 38px;
  height: 38px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  background: whitesmoke;
  cursor: pointer;
  position: relative;
  .dot {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => (props.online ? 'green' : 'black')};
  }
`;

export const Username = styled.span`
  color: ${themeColor};
`;

export const FeedMedia = styled.div`
  width: 99%;
  height: 540px;
  border: ${(props) => (props.dark ? 'none' : '1px solid whitesmoke')};
  border-radius: 5px;
  background-color: transparent;
  position: relative;
`;

export const Members = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

export const Member = styled.div`
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  text-decoration: none;
`;

export const VaultFolder = styled.div`
  display: inline-block;
  padding: ${(props) => (!props.selected ? '2px' : null)};
  outline: grey 1px solid;
  margin: 3px;
  height: 50px;
  width: 15%;
  position: relative;
  border: ${(props) => (props.selected ? `3px solid ${themeColor}` : null)};
  &:hover {
    border: 3px solid ${themeColor};
    cursor: pointer;
    img {
      opacity: 0.3;
    }
    div {
      color: black;
    }
  }
`;

export const FolderName = styled.div`
  position: absolute;
  left: 8%;
  top: 28%;
  font-size: 10px;
  width: 30px;
  color: white;
`;

export const Info = styled.div`
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  padding: 1px 9px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: lightgrey;
  color: black;
  font-family: cursive;
`;

export const SliderButton = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${themeColor};
  border-radius: 50%;
  color: ${themeColor};
  cursor: pointer;
  margin: 5px;
  &:hover {
    background-color: ${themeColor};
    color: white;
  }
`;

export const Remainder = styled.div`
  border-bottom: 1px solid lightgrey;
  color: grey;
`;

export const RemainderPeople = styled.div`
  max-width: 100px;
  margin-right: 10px;
  @media (max-max-: 1180px) {
    max-width: 50px;
  }
`;

export const FeedFilter = styled.div`
  display: flex;
  margin-right: 10px;
  border: 1px solid ${themeColor};

  border-radius: 8px;
  div:nth-child(3) {
    border-right: none;
  }
  div:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const FeedButton = styled.div`
  cursor: pointer;
  border-right: 1px solid ${themeColor};
  padding: 2px 10px;
  color: ${(props) => (props.selected ? 'white' : 'black')};
  background-color: ${(props) => (props.selected ? `${themeColor}` : null)};
`;

export const ChatInput = styled.input`
  border: none;
  width: 90%;
  border-bottom: 1px solid black;
  $:focus {
    outline: none;
    border: none;
  }
`;

export const EmojiContainer = styled.div`
  background-color: lightgrey;
  border-radius: 8px;
  padding: 2px 2px 0px;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

export const EmojiAdder = styled.div`
  position: relative;
  .emoji {
    display: none;
  }
  &:hover {
    .emoji {
      position: absolute;
      display: flex;
      top: -65px;
      background: white;
      padding: 5px 8px;
      border-radius: 10px;
    }
  }
`;

export const ImageContainer = styled.div`
  float: left;
  margin: 5px;
  width: 45%;
  height: 127px;
  border: ${(props) => (props.dark ? 'none' : '1px solid whitesmoke')};
  background-color: transparent;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
  }
`;

export const CalendarSection = styled.div`
  th {
    border: 1px solid ${themeColor};
  }
  td > div {
    width: 10px;
    height: 10px;
  }
  .mark {
    background-color: ${themeColor};
    color: white;
    text-align: center;
    height: 15px;
    margin: 10%;
    border-radius: 30%;
    top: 13px;
    width: 25px;
  }
`;

export const StyledWeatherCard = styled.div`
  padding-bottom: 0px !important;
  height: ${(props) => (props.height ? props.height : '88vh')};
  @media (max-height: 900px) {
    height: ${(props) => (props.smheight ? props.smheight : '710px')};
  }
  .day:hover,
  .active {
    box-shadow: inset 0 1px 1px #2671e1, 0 0 8px #2671e1;
    cursor: pointer;
  }
`;
