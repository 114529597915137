import React, { useEffect } from 'react';
import { CometChatUI } from 'src/CometChatWorkspace/src';
import Breadcrumb from 'src/components/Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { getAllContacts } from 'src/redux/profileRedux';
import { getAllHubs, getDashboardHubs } from 'src/redux/hubRedux';
import { useNavigate, useLocation } from 'react-router-dom';

function CometChatMessages(props) {
  let userData = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { allContacts, user } = useSelector((state) => state.ProfileReducer);
  const { hubs, dashboardHubs } = useSelector((state) => state.HubsReducer);
  useEffect(() => {
    if (allContacts.length === 0) dispatch(getAllContacts());
    if (hubs.length === 0) {
      dispatch(getAllHubs('user-hubs'));
      dispatch(getDashboardHubs());
    }
  }, [allContacts, hubs]);
  userData = allContacts.map((contact) => contact.id);
  hubs.forEach((hub) => {
    hub.member_ids.forEach((id) => {
      if (!userData.includes(id)) userData.push(id);
    });
  });

  return (
    <>
      <Breadcrumb parent="Chat" title="Chat" />
      <div className="container-fluid" style={{ marginTop: '-20px' }}>
        <div className="card" style={{ height: '80vh' }}>
          <CometChatUI
            contacts={userData}
            user={user}
            hubs={dashboardHubs}
            navigate={navigate}
            location={location}
            chatWith={
              props?.location?.state ? props?.location?.state.conversation : null
            }
            chatWithContact={
              props?.location?.state && props?.location?.state.contact
                ? props?.location?.state?.contact
                : null
            }
            {...props}
          />
        </div>
      </div>
    </>
  );
}

export default CometChatMessages;
