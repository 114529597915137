import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const PaginationSection = styled.div`
  .page {
    color: ${themeColor};
  }
  .activePage {
    color: white;
    background-color: ${themeColor};
  }
  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: ${themeColor};
    border-color: ${themeColor};
    color: white;
  }
  .page-link {
    color: ${themeColor};
  }
`;
