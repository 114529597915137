import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const Folder = styled.li`
  border: none;
  border-bottom: 4px solid lightgrey;
  cursor: pointer;
  padding: 12px 5px;
  i:hover {
    cursor: move;
  }
  .add {
    display: none;
    color: grey;
    position: relative;
  }
  &:hover {
    .add {
      display: initial;
    }
  }
  .delete {
    display: none;
    position: absolute;
    top: 22px;
    right: 12px;
    background: lightgrey;
    color: black;
    padding: 10px;
  }
`;

export const Menu = styled.div`
  display: inline;
  &:hover {
    .delete {
      display: block;
    }
  }
`;

export const ImageContainer = styled.div`
  float: left;
  margin: 5px;
  width: 31%;
  height: 215px;
  border: 1px solid whitesmoke;
  background-color: #f1f1f1;
  position: relative;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
  }
`;

export const File = styled.span`
  cursor: pointer;
  color: ${themeColor};
  &:hover {
    color: grey;
  }
`;

export const PreviewFile = styled.div`
  width: 100%;
  height: 100%;
  .preview-frame img {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;
