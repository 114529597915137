import { axiosInstance, baseUrl, getHeaderData } from '.';

// files api

// get all folders
export const fetchAllFolders = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}folders`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data;
};

// get particular folder
export const fetchFolder = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}folders/${payload.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// create new folder
export const postFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}folders${payload.id ? `/${payload.id}` : ''}`,
      { name: payload.name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// upload new file
export const postFile = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(`${baseUrl}files`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

// delete a folder
export const deleteFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}folders/${payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// delete a file
export const deleteFile = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}files/${payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};
