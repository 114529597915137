import React from 'react';
import { themeColor } from 'src/utils/theme';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { uniqueId } from 'lodash';

LoadingOverlay.propTypes = undefined;
export default function MyLoader({ children, show }) {
  return (
    <LoadingOverlay
      key={uniqueId}
      active={show}
      spinner={<BounceLoader color={themeColor} />}
      text="Please Wait..."
    >
      {children}
    </LoadingOverlay>
  );
}
