import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/Loader';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import {
  getAllNetworks,
  deleteNetwork,
  getRequestNetworks,
  addRequestNetworks,
  loadMoreNetworks,
} from 'src/redux/networkRedux';
import { Cell, InfoSectionNetwork } from 'src/containers/AdminDashboard/styles';
import {
  ScrollableSection,
  ProfilePic,
  Heading,
} from 'src/components/StyledComponents';
import { addDefaultSrc } from 'src/utils/helper';
import { DeleteModal } from 'src/components/Modal';
import LoadMore from 'src/components/LoadMore';
import { Button } from 'src/components/Button';
import Breadcrumb from 'src/components/Breadcrumb';

const categories = [
  {
    key: 'association',
    value: 'Association',
  },
  {
    key: 'business',
    value: 'Business',
  },
  {
    key: 'community',
    value: 'Community',
  },
  {
    key: 'club',
    value: 'Club',
  },
  {
    key: 'organisation',
    value: 'Organisation',
  },
  {
    key: 'social',
    value: 'Social',
  },

  {
    key: 'sporting',
    value: 'Sporting',
  },

  {
    key: 'support',
    value: 'Support',
  },
];

function AdminNetworks() {
  const [networkData, setNetworkData] = useState([]);
  const [networkCategory, setNetworkCategory] = useState('all');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [requestNetworkData, setRequestNetworkData] = useState([]);
  const dispatch = useDispatch();
  const { allNetworks, requestNetworks, metaDataNetworks } = useSelector(
    (state) => state.NetworkReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);

  useEffect(() => {
    dispatch(getAllNetworks());
    return () => {
      setNetworkData([]);
      setRequestNetworkData([]);
    };
  }, []);

  useEffect(() => {
    if (allNetworks.length > 0) {
      if (metaDataNetworks.current_page === 1) {
        setNetworkData(allNetworks);
        setSelectedNetwork(allNetworks[0]);
      } else {
        setNetworkData([...networkData, ...allNetworks]);
      }
    } else {
      setNetworkData([]);
    }
  }, [allNetworks]);

  useEffect(() => {
    if (networkCategory === 'all') {
      setNetworkData([]);
      dispatch(getAllNetworks());
    } else if (networkCategory === 'request') {
      dispatch(getRequestNetworks());
    } else {
      setNetworkData([]);
      dispatch(getAllNetworks({ category: networkCategory }));
    }
  }, [networkCategory]);

  useEffect(() => {
    if (requestNetworks.data.length > 0) {
      if (requestNetworks.meta.current_page === 1) {
        setRequestNetworkData(requestNetworks.data);
      } else {
        setNetworkData([...requestNetworkData, ...requestNetworks.data]);
      }
    }
  }, [requestNetworks]);

  const onNetworkClick = () => {
    setSelectedNetwork({
      ...selectedNetwork,
      type: 'Network',
    });
    setShowDeleteModal(true);
  };

  const onDelete = (id) => {
    dispatch(deleteNetwork({ id }));
  };

  const onAction = (cmd, id) => {
    const payload = {
      data: { action: cmd },
      id,
    };
    dispatch(addRequestNetworks(payload));
    setRequestNetworkData([]);
  };

  const getMoreNetworks = () => {
    if (networkCategory === 'all') {
      dispatch(loadMoreNetworks({ page: metaDataNetworks.current_page + 1 }));
    } else {
      dispatch(
        loadMoreNetworks({
          page: metaDataNetworks.current_page + 1,
          category: networkCategory,
        }),
      );
    }
  };

  return (
    <>
      <Breadcrumb parent="Admin" title="Networks" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <div className="card" style={{ height: '80vh' }}>
              <div className="card-header">
                <h5>Categories</h5>
              </div>
              <div className="card-body">
                <ScrollableSection>
                  <ul className="list-group mt-2">
                    <li
                      className={`list-group-item ${
                        networkCategory === 'all' ? 'active' : null
                      }`}
                      onClick={() => setNetworkCategory('all')}
                    >
                      <span>All</span>
                    </li>
                    <li
                      className={`list-group-item ${
                        networkCategory === 'request' ? 'active' : null
                      }`}
                      onClick={() => setNetworkCategory('request')}
                    >
                      <div>Requests</div>
                      {requestNetworkData.length ? (
                        <div className="badge badge-pill badge-dark ml-1">
                          {requestNetworkData.length}
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                    {categories.map((category, index) => (
                      <li
                        key={index}
                        className={`list-group-item ${
                          networkCategory === category ? 'active' : null
                        }`}
                        onClick={() => setNetworkCategory(category.key)}
                      >
                        <span>{category.value}</span>
                      </li>
                    ))}
                  </ul>
                </ScrollableSection>
              </div>
            </div>
          </div>
          {networkCategory === 'request' ? (
            <div className="col-sm-9">
              <div className="card" style={{ height: '80vh' }}>
                <div className="card-body">
                  {processing.status
                  && processing.component === 'request-network' ? (
                    <Loader show />
                    ) : requestNetworkData.length ? (
                      <div className="table-responsive pb-2 h-100">
                        <table className="table">
                          <thead>
                            <tr style={{ fontSize: '10px' }}>
                              <th scope="col" />
                              <th scope="col">NAME</th>
                              <th scope="col">USERNAME</th>
                              <th scope="col">PUBLIC</th>
                              <th scope="col">CLASSIFICATION</th>
                              <th scope="col">CATEGORY</th>
                              <th scope="col">LOCATION</th>
                              <th scope="col">CREATED BY</th>
                              <th scope="col">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requestNetworkData.map((data, index) => (
                              <tr key={index} style={{ fontSize: '12px' }}>
                                <Cell>
                                  <ProfilePic>
                                    <img
                                      src={
                                        data.avatar
                                          ? data.avatar
                                          : ProfilePictureIcon
                                      }
                                      alt="icon"
                                      onError={addDefaultSrc}
                                      className="rounded-circle mx-auto"
                                      width="38px"
                                      height="38px"
                                    />
                                  </ProfilePic>
                                </Cell>
                                <Cell>
                                  <div>
                                    <div>{data.name ? data.name : '-'}</div>
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>{data.alias ? data.alias : '-'}</div>
                                </Cell>
                                <Cell>
                                  <div>{data.is_public ? 'Yes' : 'No'}</div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {data.classification
                                      ? data.classification
                                      : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {data.category ? data.category : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {data.location ? data.location : '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className="d-flex">
                                    <ProfilePic>
                                      <img
                                        src={
                                          data.created_by.avatar
                                            ? data.created_by.avatar
                                            : ProfilePictureIcon
                                        }
                                        alt="icon"
                                        onError={addDefaultSrc}
                                        className="rounded-circle mx-auto"
                                        width="38px"
                                        height="38px"
                                      />
                                    </ProfilePic>
                                    <div className="ml-2">
                                      {data.created_by.full_name
                                        ? data.created_by.full_name
                                        : ''}
                                    </div>
                                  </div>
                                </Cell>

                                <Cell>
                                  <div className="d-flex">
                                    <Button
                                      variant="solid"
                                      fontSize="12px"
                                      value="Approve"
                                      padding="5px 18px"
                                      margin="0px 10px"
                                      onClick={() => onAction('accept', data.id)}
                                    />
                                    <Button
                                      variant="solid"
                                      fontSize="12px"
                                      value="Decline"
                                      padding="5px 18px"
                                      margin="0px 10px"
                                      onClick={() => onAction('reject', data.id)}
                                    />
                                  </div>
                                </Cell>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Heading className="text-center h4 mt-4">
                        No Requests Received Please Check Later.
                      </Heading>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-sm-3">
                <div className="card" style={{ height: '80vh' }}>
                  <div className="card-header">
                    <h5>{networkCategory}</h5>
                  </div>
                  <div className="card-body">
                    <ScrollableSection id="scroll-admin-networks">
                      {processing.status
                      && processing.component === 'all-network' ? (
                        <Loader show />
                        ) : networkData.length ? (
                          <ul className="list-group mt-2">
                            <LoadMore
                              data={networkData}
                              getMore={() => getMoreNetworks()}
                              target="scroll-admin-networks"
                              meta={metaDataNetworks}
                            >
                              {networkData.map((network, index) => (
                                <li
                                  key={index}
                                  className={`list-group-item ${
                                    selectedNetwork
                                    && selectedNetwork.id === network.id
                                      ? 'active'
                                      : null
                                  }`}
                                  onClick={() => setSelectedNetwork(network)}
                                >
                                  <span>{network.name}</span>
                                </li>
                              ))}
                            </LoadMore>
                          </ul>
                        ) : (
                          <Heading className="text-center h4 mt-4">
                            No Networks exists with the selected Category.
                          </Heading>
                        )}
                    </ScrollableSection>
                    <DeleteModal
                      show={showDeleteModal}
                      close={setShowDeleteModal}
                      obj={selectedNetwork}
                      deleteFn={onDelete}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card" style={{ height: '80vh' }}>
                  <div className="card-body">
                    <ScrollableSection>
                      {selectedNetwork
                      && Object.keys(selectedNetwork).length ? (
                        <InfoSectionNetwork className="socialprofile">
                          <div
                            style={{
                              backgroundImage: `linear-gradient(${
                                selectedNetwork.theme
                                  ? selectedNetwork.theme[0]
                                  : '#000'
                              },${
                                selectedNetwork.theme
                                  ? selectedNetwork.theme[1]
                                  : '#000'
                              })`,
                              height: '200px',
                              borderRadius: '8px',
                            }}
                          />

                          <div className="avatar">
                            <div>
                              <Button
                                variant="outline"
                                value={
                                  selectedNetwork.category[0].toUpperCase()
                                  + selectedNetwork.category.slice(
                                    1,
                                    selectedNetwork.category.length,
                                  )
                                }
                                padding="1px 8px"
                                margin="1px 2px"
                              />
                            </div>
                            <div>
                              <Button
                                variant="outline"
                                value={
                                  selectedNetwork.is_public
                                    ? 'Public'
                                    : 'Private'
                                }
                                padding="1px 8px"
                                margin="1px 2px"
                              />
                            </div>
                            <img
                              src={
                                selectedNetwork.original_avatar
                                  ? selectedNetwork.original_avatar
                                  : ProfilePictureIcon
                              }
                              alt="#"
                              onError={addDefaultSrc}
                              className="bg-white"
                            />
                            <div>
                              <Button
                                variant="outline"
                                value={
                                  selectedNetwork.classification[0].toUpperCase()
                                  + selectedNetwork.classification.slice(
                                    1,
                                    selectedNetwork.classification.length,
                                  )
                                }
                                padding="1px 8px"
                                margin="1px 2px"
                              />
                            </div>
                            <div>
                              <Button
                                variant="outline"
                                value="Delete"
                                padding="1px 8px"
                                margin="1px 2px"
                                onClick={() => onNetworkClick(selectedNetwork)}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-around">
                            <div>
                              <h2>{selectedNetwork.name}</h2>
                              <h6 className="text-center">
                                {selectedNetwork.alias
                                  ? `(@${selectedNetwork.alias})`
                                  : ''}
                              </h6>
                            </div>
                          </div>
                          <div className="container-fluid">
                            <div className="row text-center">
                              <div className="col-6">
                                <h5 className="text-dark">About</h5>
                                <p className="font-size-larger">
                                  {selectedNetwork.bio}
                                </p>
                              </div>
                              <div className="col-6">
                                <h5 className="text-dark">Location</h5>
                                <p>{selectedNetwork.location}</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-4">
                            <div
                              style={{
                                boxShadow: '1px 5px 20px 0 rgb(0 0 0 / 30%)',
                                cursor: 'pointer',
                                padding: '20px',
                              }}
                            >
                              <ProfilePic className="mx-auto">
                                <img
                                  className="rounded-circle"
                                  src={selectedNetwork.created_by.avatar}
                                  alt="#"
                                  width="100%"
                                  height="100%"
                                />
                              </ProfilePic>
                              <div className="text-center mt-2">
                                <h6>Creator</h6>
                              </div>
                            </div>
                          </div>
                        </InfoSectionNetwork>
                        ) : (
                          <Loader show />
                        )}
                    </ScrollableSection>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminNetworks;
