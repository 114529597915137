import { axiosInstance, baseUrl, getHeaderData } from '.';

// Get User Remainders
export const fetchUserRemainders = async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}calendars`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Device-Id': 'web',
      ...getHeaderData(),
    },
  });

  return response.data.data;
};
// Get User Remainders from date
export const fetchUserRemaindersMonthly = async () => {
  const token = localStorage.getItem('token');
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const start_date = `${new Date(
    new Date(year, month, 1).getTime()
        - new Date(year, month, 1).getTimezoneOffset() * 60 * 1000,
  )
    .toISOString()
    .split('T')[0]} 00:00:00`;
  const end_date = `${new Date(
    new Date(year, month + 1, 0).getTime()
        - new Date(year, month, 1).getTimezoneOffset() * 60 * 1000,
  )
    .toISOString()
    .split('T')[0]} 00:00:00`;
  const response = await axiosInstance.get(
    `${baseUrl}calendars?start_date=${start_date}&end_date=${end_date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Device-Id': 'web',
        ...getHeaderData(),
      },
    },
  );

  return response.data.data;
};

// post user reminders
export const postUserReminders = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.post(`${baseUrl}calendars`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Device-Id': 'web',
      ...getHeaderData(),
    },
  });

  return response.data.data;
};
