import styled from 'styled-components';

export const InfoSection = styled.div`
  position: relative;
  .avatar {
    margin-top: -50px;
    display: flex;
    justify-content: space-around;
  }
  .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid whitesmoke;
  }
  .group-image {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 20%);
    transition: 0.5s;
  }
  .customers {
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .customers li {
    border: none;
    background-color: none;
  }
  .customers li:hover {
    background-color: transparent;
  }
`;

export const ListContainer = styled.div`
  padding-top: 0px !important;
  .tab {
    color: black;
    cursor: pointer;
  }
  .active-tab {
    pointer-events: none;
    border-bottom: 2px solid black;
    color: black;
    box-shadow: 1px 1px 3px 5px grey;
  }
`;
