import { axiosInstance, baseUrl, getHeaderData } from '.';

// get all hubs
export const fetchAllHUbs = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(`${baseUrl}hubs`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...getHeaderData(),
      },
    });

    return response.data.data;
  }
};

// get all hubs for admin
export const fetchAllAdminHubs = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/hubs${
        payload && payload.category ? `?category=${payload.category}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  }
};

export const fetchHubsWithPage = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}admin/hubs?page=${payload.page}&&${
        payload.category ? `category=${payload.category}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  }
};

// get hub members for admin
export const fetchAdminHubMembers = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}admin/hubs/${id}/members`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// get hub members
export const fetchHubMembers = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/members`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// get specific hub
export const fetchHub = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...getHeaderData(),
    },
  });

  return response.data.data;
};

// get hub feeds
export const fetchHubFeeds = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/feeds`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// get a list of todo list of a hub
export const fetchHubTodos = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/todos`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

// create hub todo list
export const postHubTodoList = async (payload) => {
  console.log(payload);
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload.id}/todos`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Update Hub TodoList
export const updateHubTodoList = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}hubs/${payload[0]}/todos/${payload[1]}`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// get sublist of hub todo list
export const fetchHubTodoSublists = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${payload.hub_id}/todos/${payload.todo_id}/child`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// Get Hub todo list tasks
export const fetchHubTasks = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${payload.hub_id}/todos/${payload.todo_id}/tasks`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// create hub task
export const postHubTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload.hub_id}/todos/${payload.todo_id}/tasks`,
      { tasks: payload.tasks, check_count: payload.check_count },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// update hub task
export const updateHubTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}hubs/${payload[0]}/todos/${payload[1]}/tasks/${payload[2]}`,
      payload[3],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// toggle hub Task status
export const toggleHubTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}hubs/${payload[0]}/todos/${payload[1]}/tasks/${payload[2]}/mark`,
      payload[3],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Delete a Hub Task
export const deleteHubTask = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}hubs/${payload[0]}/todos/${payload[1]}/tasks/${payload[2]}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// get vault all files of a hub
export const fetchHubVaultFiles = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/vaults/file`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// get vault folders of a hub
export const fetchHubVaultFolders = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${id}/vaults/folder`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// create hub vault folder
export const postHubVaultFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload.id}/vaults/folder`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// Get Hub Remainders
export const fetchHubRemainders = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/calendars`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Device-Id': 'web',
    },
  });

  return response.data.data;
};

export const fetchHubRemaindersMonthly = async (id) => {
  const token = localStorage.getItem('token');
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const start_date = `${new Date(
    new Date(year, month, 1).getTime()
        - new Date(year, month, 1).getTimezoneOffset() * 60 * 1000,
  )
    .toISOString()
    .split('T')[0]} 00:00:00`;
  const end_date = `${new Date(
    new Date(year, month + 1, 0).getTime()
        - new Date(year, month, 1).getTimezoneOffset() * 60 * 1000,
  )
    .toISOString()
    .split('T')[0]} 00:00:00`;
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${id}/calendars?start_date=${start_date}&end_date=${end_date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Device-Id': 'web',
      },
    },
  );

  return response.data.data;
};

// get hub vault folder files
export const fetchHubVaultFolderFiles = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${payload.id}/vaults/folder/${payload.folderid}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// create file in hub folder
export const postFileInHubFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload[0]}/vaults/folder/${payload[1]}/upload`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// post reaction on hub feeds
export const postReactionOnHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}hubs/${payload[0]}/feeds/${payload[1]}/reactions`,
    payload[2],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// remove reaction from feed
export const deleteReactionOnHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.delete(
    `${baseUrl}hubs/${payload.hub_id}/feeds/${payload.id}/reactions/${payload.reaction}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// get comments on feed
export const fetchCommentsOnHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${payload.hub_id}/feeds/${payload.id}/comments`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// post comments on feed
export const postCommentsOnHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}hubs/${payload[0]}/feeds/${payload[1]}/comments`,
    payload[2],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// delete comment from feed
export const deleteCommentsOnHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.delete(
    `${baseUrl}hubs/${payload.hub_id}/feeds/${payload.id}/comments/${payload.commentId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// update a hub
export const updateHubDetails = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.put(
    `${baseUrl}hubs/${payload.id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// delete admin hubs
export const deleteAdminHubs = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(`${baseUrl}admin/hubs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

// get particular folder
export const fetchHubFolder = async (payload) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(
    `${baseUrl}hubs/${payload.hub_id}/folders/${payload.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data.data;
};

// get all folders
export const fetchAllHubFolders = async (id) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(`${baseUrl}hubs/${id}/folders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// create new folder
export const postHubFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload.hub_id}/folders${
        payload.id ? `/${payload.id}` : ''
      }`,
      { name: payload.name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// upload new file
export const postHubFile = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload.hub_id}/files`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// delete a folder
export const deleteHubFolder = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}hubs/${payload.hub_id}/folders/${payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// delete a file
export const deleteHubFile = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}hubs/${payload.hub_id}/files/${payload.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// post user feed
export const postHubFeed = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}hubs/${payload.id}/feeds/gallery-upload`,
    payload.data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// Hub Music
export const fetchHubPlaylists = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}hubs/${payload.id}/playlists`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data.data;
  }
};

export const postHubPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.post(
    `${baseUrl}hubs/${payload[0]}/playlists`,
    payload[1],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updateHubPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  await axiosInstance.put(
    `${baseUrl}hubs/${payload[0]}/playlists/${payload[1]}${
      payload[2].to_add || payload[2].to_remove ? '/music-list' : ''
    }`,
    payload[2],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const deleteHubPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.delete(
      `${baseUrl}hubs/${payload.id}/playlists/${payload.p_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const fetchHubPlaylistTracks = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    const response = await axiosInstance.get(
      `${baseUrl}hubs/${payload.id}/playlists/${payload.p_id}/music-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data.data;
  }
};

export const postTrackInHubPlaylist = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.post(
      `${baseUrl}hubs/${payload[0]}/music`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

export const reorderHubPlaylistTracks = async (payload) => {
  const token = localStorage.getItem('token');
  if (token) {
    await axiosInstance.put(
      `${baseUrl}hubs/${payload[0]}/playlists/${payload[1]}/reorder-music-list`,
      payload[2],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
};

// update hub theme

export const updateHubTheme = async (payload) => {
  const token = localStorage.getItem('token');

  let response;
  if (payload.data instanceof FormData) {
    response = await axiosInstance.post(
      `${baseUrl}hubs/${payload.id}/theme/assets`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } else {
    response = await axiosInstance.put(
      `${baseUrl}hubs/${payload.id}/theme`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  return response.data.data;
};
