import styled from 'styled-components';
import { themeColor } from 'src/utils/theme';

export const HeadlineSection = styled.div`
  position: absolute;
  top: 12%;
  left: 25%;
  right: 25%;
  @media (max-width: 992px) {
    top: 6%;
  }
  @media (max-width: 768px) {
    top: 0%;
    left: 12%;
    right: 12%;
  }
  @media (max-width: 550px) {
    top: 0%;
    left: 0%;
    right: 0%;
  }
`;
export const HubSection = styled.div`
  @media (max-width: 1200px) {
    margin-top: 6%;
  }
  @media (max-width: 800px) {
    margin-top: 0%;
  }
`;

export const LeftSection = styled.div`
  margin: 10% -7% 0% 20%;
  @media (max-width: 1150px) {
    margin: 5% -7% 0% 15%;
  }
  @media (max-width: 1000px) {
    margin: 5% -7% 0% 15%;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
export const LeftSectionMiddle = styled.div`
  margin: 10% 0% 0% 20%;
  @media (max-width: 1150px) {
    margin: 5% 0% 0% 15%;
  }
  @media (max-width: 1000px) {
    margin: 5% 0% 0% 15%;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
export const RightSection = styled.div`
  margin: 10% 20% 0% -7%;
  @media (max-width: 1150px) {
    margin: 5% 15% 0% -7%;
  }
  @media (max-width: 1000px) {
    margin: 5% 15% 0% -7%;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
export const RightSectionMiddle = styled.div`
  margin: 10% 20% 0% 0%;
  @media (max-width: 1150px) {
    margin: 5% 15% 0% 0%;
  }
  @media (max-width: 1000px) {
    margin: 5% 15% 0% 0%;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  width: 60%;
  height: 160px;
  border-radius: 50%;
  box-shadow: -1px 1px 8px 1px;
  margin-top: 20%;
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #787878;
`;
export const Content = styled.span`
  font-size: 17px;
  color: #787878;
`;

export const SocialIcon = styled.span`
  font-size: 25px;
  padding: 5px 12px;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 5px;
`;
export const SsvHeading = styled.h2`
  font-weight: bolder;
  color: #505050;
`;
export const PhilosophySection = styled.div`
  background-color: ${themeColor};
`;
export const IconContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 5px 0px;
`;

export const TickIcon = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${themeColor};
  border-radius: 50%;
  color: white;
  font-size: 20px;
  padding: 2px 4px;
`;
export const DiscoverSection = styled.div`
  background-color: #f8f8f8;
  @media (max-width: 1180px) {
    margin-top: 30px;
  }
`;

export const PartnerSection = styled.div`
  margin-top: -7%;
`;

export const Text = styled.p`
  font-weight: 400;
  line-height: 28px;
  font-size: 16px;
  color: #677294;
  font-family: "Nunito", sans-serif;
`;

export const ErrorText = styled.span`
  font-size: 14px;
  color: #e4007b;
`;

export const LoginButton = styled.button`
  background: linear-gradient(142deg, #ac1280 0%, #e4007b 100%) !important;
  border-radius: 5px;
  border-color: unset;
  border-width: unset;
  border-style: unset;
  overflow: hidden;
  color: #ffffff !important;
  font-size: 14px;
  color: white;
  text-transform: none;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 5px;
  padding: 10px 40px 10px 40px;
  &:hover {
    background: linear-gradient(142deg, #e4007b 0%, #ac1280 100%) !important;
  }
`;
