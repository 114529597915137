import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchNetworks,
  fetchAllNetworks,
  deleteAdminNetworks,
  fetchRequestedNetworks,
  postRequestedNetworks,
  fetchNetworksWithPage,
} from 'src/api/network';
import {
  saveNetworks,
  saveAllNetworks,
  saveRequestNetworks,
} from 'src/redux/networkRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getNetworks() {
  try {
    yield put(processing({ status: true, component: 'network' }));
    const response = yield call(fetchNetworks);
    yield put(saveNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'network' }));
    yield put(setError(false));
  }
}

function* getAllNetworks(action) {
  try {
    yield put(processing({ status: true, component: 'all-network' }));
    const response = yield call(fetchAllNetworks, action.payload);
    yield put(saveAllNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'all-network' }));
    yield put(setError(false));
  }
}

function* loadMoreNetworks(action) {
  try {
    yield put(processing({ status: true, component: 'admin-networks-page' }));
    const response = yield call(fetchNetworksWithPage, action.payload);
    yield put(saveAllNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'admin-networks-page' }));
    yield put(setError(false));
  }
}

function* deleteNetworkForAdmin(action) {
  try {
    yield put(processing({ status: true, component: 'all-network' }));
    yield call(deleteAdminNetworks, action.payload.id);
    const response = yield call(fetchAllNetworks);
    yield put(saveAllNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'all-network' }));
    yield put(setError(false));
  }
}

function* getRequestNetworks() {
  try {
    yield put(processing({ status: true, component: 'request-network' }));
    const response = yield call(fetchRequestedNetworks);
    yield put(saveRequestNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'request-network' }));
    yield put(setError(false));
  }
}
function* addRequestNetworks(action) {
  try {
    yield put(processing({ status: true, component: 'request-network' }));
    yield call(postRequestedNetworks, action.payload);
    const response = yield call(fetchRequestedNetworks);
    yield put(saveRequestNetworks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'request-network' }));
    yield put(setError(false));
  }
}

export function* watchNetworksSaga() {
  yield takeLatest('GET_NETWORKS', getNetworks);
  yield takeLatest('GET_ALL_NETWORKS', getAllNetworks);
  yield takeLatest('LOAD_MORE_NETWORKS', loadMoreNetworks);
  yield takeLatest('DELETE_NETWORK', deleteNetworkForAdmin);
  yield takeLatest('GET_REQUEST_NETWORKS', getRequestNetworks);
  yield takeLatest('POST_REQUEST_NETWORKS', addRequestNetworks);
}
