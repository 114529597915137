import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfilePictureIcon from 'src/assets/images/livit/user_profile_pic.png';
import Loader from 'src/components/Loader';
import {
  FeedMedia, Username, EmojiAdder, EmojiContainer,
} from 'src/containers/HubHome/styles';
import { Heading, ProfilePic } from 'src/components/StyledComponents';
import ImageSlider from 'src/components/ImageSlider';
import {
  loadMoreData,
  addReaction,
  removeReaction,
  getComments,
  addComment,
  deleteComment,
} from 'src/redux/hubRedux';
import Comments from 'src/components/CommentSection';
import LoadMore from 'src/components/LoadMore';
import { addDefaultSrc } from 'src/utils/helper';
import { AlertOctagon } from 'react-feather';
import { ReportModal } from 'src/components/Modal';
import { sendReport } from 'src/redux/reportRedux';

function Feed(props) {
  const [feeds, setFeeds] = useState([]);
  const [showComments, setShowComments] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportedFeed, setReportedFeed] = useState(null);
  const { hubFeeds, hubFeedComments, hubFeedMeta } = useSelector(
    (state) => state.HubsReducer,
  );
  const { processing } = useSelector((state) => state.ServiceReducer);
  const { user } = useSelector((state) => state.ProfileReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (props.isAdded) setFeeds([]);
  }, [props.isAdded]);

  useEffect(() => {
    if (hubFeeds.length) {
      setFeeds([...feeds, ...hubFeeds]);
    }
  }, [hubFeeds]);

  const react = (reaction, id) => {
    const payload = [props.match.params.id, id, { reaction }];
    dispatch(addReaction(payload));
  };

  const remove = (reaction, id) => {
    const payload = {
      hub_id: props.match.params.id,
      id,
      reaction,
    };
    dispatch(removeReaction(payload));
  };

  const getComment = (id) => {
    setShowComments([id]);
    dispatch(getComments({ hub_id: props.match.params.id, id }));
  };

  const closeCommentBox = (id) => {
    setShowComments(showComments.filter((c) => c !== id));
  };

  const addNewComment = (feedId, comment) => {
    const payload = [props.match.params.id, feedId, { comment }];
    dispatch(addComment(payload));
  };

  const deleteUserComment = (feedId, commentId) => {
    dispatch(
      deleteComment({
        hub_id: props.match.params.id,
        id: feedId,
        commentId,
      }),
    );
  };
  const report = (payload) => {
    payload.append('resource_type', 'feed');
    payload.append('resource_id', reportedFeed.id);
    dispatch(sendReport(payload));
    setShowReportModal(false);
    setReportedFeed(null);
  };
  return (
    <>
      {processing.status
      && (processing.component === 'hub'
        || processing.component === 'hub-feeds') ? (
          <Loader show />
        ) : feeds.length ? (
          <LoadMore
            data={feeds}
            meta={hubFeedMeta}
            getMore={() => dispatch(
              loadMoreData({ url: hubFeedMeta.links.next, type: 'hub-feeds' }),
            )}
            target="scroll-hub-feeds"
          >
            {feeds.map((feed) => (
              <div className="mt-4" key={feed.id}>
                <div className="d-flex mb-2 justify-content-between">
                  <div className="d-flex">
                    <ProfilePic>
                      <img
                        className="rounded-circle lazyloaded blur-up"
                        src={
                          feed.creator.avatar
                            ? feed.creator.avatar
                            : ProfilePictureIcon
                        }
                        onError={addDefaultSrc}
                        alt="#"
                        width="30px"
                        height="30px"
                      />
                    </ProfilePic>
                    <div className="ml-3" style={{ fontSize: '11px' }}>
                      <div>{feed.creator.name}</div>
                      <div>
                      <Username>
                        @
                          {feed.creator.user_name}
                      </Username>
                      <span>
                        {' '}
                        |
                          {feed.date_time}
                      </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={user.id === feed.creator.user_id ? 'd-none' : ''}
                  >
                    <AlertOctagon
                      className="mr-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setReportedFeed(feed);
                        setShowReportModal(true);
                      }}
                    />
                  </div>
                </div>
                <div>{feed.caption ? feed.caption : ''}</div>
                {feed.medias.length ? (
                  <FeedMedia dark={props.theme.layout_version === 'dark-only'}>
                    <ImageSlider images={feed.medias} />
                  </FeedMedia>
                ) : null}
                <div className="d-flex pl-2">
                  <EmojiAdder className="m-2">
                    <div className="emoji">
                      {Object.keys(feed.reactions).map((keyName, index) => (
                      <div
                        key={index}
                        className="btn px-1 m-0"
                        onClick={() => react(keyName, feed.id)}
                      >
                        {keyName === 'happy' && (
                          <>
                            <div>Happy</div>
                            <div>
                              <span role="img" aria-label="emoji">
                                😀
                              </span>
                            </div>
                          </>
                        )}
                        {keyName === 'sad' && (
                          <>
                            <div>Sad</div>
                            <div>
                              <span role="img" aria-label="emoji">
                                😥
                              </span>
                            </div>
                          </>
                        )}
                        {keyName === 'thumbs' && (
                          <>
                            <div>Thumbs</div>
                            <div>
                              <span role="img" aria-label="emoji">
                                👍
                              </span>
                            </div>
                          </>
                        )}
                        {keyName === 'heart' && (
                          <>
                            <div>Heart</div>
                            <div>
                              <span role="img" aria-label="emoji">
                                ❤️
                              </span>
                            </div>
                          </>
                        )}
                        {keyName === 'cool' && (
                          <>
                            <div>Cool</div>
                            <div>
                              <span role="img" aria-label="emoji">
                                😎
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      ))}
                    </div>
                    <i
                      className="far fa-laugh"
                      style={{ fontSize: '20px' }}
                    />
                  </EmojiAdder>
                  <div
                    className="m-2 btn p-0"
                    onClick={() => getComment(feed.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      id="undefined"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                    </svg>
                  </div>
                  {Object.keys(feed.reactions).map((keyName, index) => (feed.reactions[keyName] > 0 ? (
                    <EmojiContainer
                      key={index}
                      className="m-2"
                      onClick={() => remove(keyName, feed.id)}
                    >
                      <span>
                      {keyName === 'happy' && '😀'}
                      {keyName === 'sad' && '😥'}
                      {keyName === 'thumbs' && '👍'}
                      {keyName === 'heart' && '❤️'}
                      {keyName === 'cool' && '😎'}
                      </span>
                      {' '}
                      <span className="ml-1">{feed.reactions[keyName]}</span>
                    </EmojiContainer>
                  ) : null))}
                </div>
                {showComments.includes(feed.id) ? (
                  <Comments
                    feedId={feed.id}
                    close={closeCommentBox}
                    comments={hubFeedComments}
                    processing={processing}
                    add={addNewComment}
                    remove={deleteUserComment}
                    addDefaultSrc={addDefaultSrc}
                    userId={user.id}
                  />
                ) : null}
              </div>
            ))}
          </LoadMore>
        ) : (
          <Heading className="text-center h4 mt-4">No Feeds to Show.</Heading>
        )}
      <ReportModal
        show={showReportModal}
        close={setShowReportModal}
        title="Feed"
        report={report}
      />
    </>
  );
}

export default Feed;
