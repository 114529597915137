import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchUserTodoList,
  fetchTodoSublists,
  fetchTasks,
  fetchTodoListMembers,
  postTodoList,
  updateTodoList,
  postTask,
  updateTask,
  toggleTask,
  deleteTask,
  deleteTodoListMember,
  reorderList,
} from 'src/api/todo';
import {
  saveUserTodoList,
  saveTodoSublist,
  saveTasks,
  saveListMembers,
  processLists,
} from 'src/redux/todoRedux';
import { processing, setError } from 'src/redux/serviceRedux';

function* getUserTodoList() {
  try {
    yield put(processLists({ status: true, component: 'user-todolist' }));
    const response = yield call(fetchUserTodoList);
    yield put(saveUserTodoList(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processLists({ status: false, component: 'user-todolist' }));
    yield put(setError(false));
  }
}

function* getTodoSublist(action) {
  try {
    yield put(processing({ status: true, component: 'user-todosublist' }));
    const response = yield call(fetchTodoSublists, action.payload);
    yield put(saveTodoSublist(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-todosublist' }));
    yield put(setError(false));
  }
}
function* getTasks(action) {
  try {
    yield put(processing({ status: true, component: 'user-tasks' }));
    const response = yield call(fetchTasks, action.payload);
    yield put(saveTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-tasks' }));
    yield put(setError(false));
  }
}

function* getTododListMembers(action) {
  try {
    yield put(processing({ status: true, component: 'user-todosublist' }));
    const response = yield call(fetchTodoListMembers, action.payload);
    yield put(saveListMembers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-todosublist' }));
    yield put(setError(false));
  }
}

function* addNewList(action) {
  try {
    yield put(processLists({ status: true, component: 'user-todolist' }));
    yield call(postTodoList, action.payload);
    if (action.payload.parent_todo_id) {
      const response = yield call(
        fetchTodoSublists,
        action.payload.parent_todo_id,
      );
      yield put(saveTodoSublist(response));
    } else {
      const response = yield call(fetchUserTodoList);
      yield put(saveUserTodoList(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processLists({ status: false, component: 'user-todolist' }));
    yield put(setError(false));
  }
}

function* addNewTask(action) {
  try {
    yield put(processing({ status: true, component: 'user-tasks' }));
    yield call(postTask, action.payload);
    const response = yield call(fetchTasks, action.payload.id);
    yield put(saveTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-tasks' }));
    yield put(setError(false));
  }
}

function* changeTasks(action) {
  try {
    yield put(processing({ status: true, component: 'user-tasksc' }));
    yield call(updateTask, action.payload);
    const response = yield call(fetchTasks, action.payload[0]);
    yield put(saveTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-tasksc' }));
    yield put(setError(false));
  }
}

function* markTask(action) {
  try {
    yield put(processing({ status: true, component: 'user-tasksc' }));
    yield call(toggleTask, action.payload);
    const response = yield call(fetchTasks, action.payload[0]);
    yield put(saveTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-tasksc' }));
    yield put(setError(false));
  }
}

function* updateList(action) {
  try {
    yield put(processing({ status: true, component: 'user-todosublist' }));
    yield call(updateTodoList, action.payload);
    if (action.payload[2]) {
      const response = yield call(fetchTodoSublists, action.payload[2]);
      yield put(saveTodoSublist(response));
    } else {
      const response = yield call(fetchTodoListMembers, action.payload[0]);
      yield put(saveListMembers(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-todosublist' }));
    yield put(setError(false));
  }
}

function* sequenceList(action) {
  try {
    yield put(processing({ status: true, component: 'reorder-list' }));
    yield call(reorderList, action.payload);
    if (action.payload[0] === 'folder') {
      const response = yield call(fetchUserTodoList);
      yield put(saveUserTodoList(response));
    }
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'reorder-list' }));
    yield put(setError(false));
  }
}

function* removeTask(action) {
  try {
    yield put(processing({ status: true, component: 'user-tasksc' }));
    yield call(deleteTask, action.payload);
    const response = yield call(fetchTasks, action.payload[0]);
    yield put(saveTasks(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-tasksc' }));
    yield put(setError(false));
  }
}

function* removeMember(action) {
  try {
    yield put(processing({ status: true, component: 'user-todosublist' }));
    yield call(deleteTodoListMember, action.payload);
    const response = yield call(fetchTodoListMembers, action.payload.id);
    yield put(saveListMembers(response));
  } catch (error) {
    yield put(setError(true));
  } finally {
    yield put(processing({ status: false, component: 'user-todosublist' }));
    yield put(setError(false));
  }
}

export function* watchTodoSaga() {
  yield takeLatest('GET_USER_TODO_LIST', getUserTodoList);
  yield takeLatest('GET_TODO_SUBLIST', getTodoSublist);
  yield takeLatest('GET_TASKS', getTasks);
  yield takeLatest('GET_LIST_MEMBERS', getTododListMembers);
  yield takeLatest('ADD_NEW_LIST', addNewList);
  yield takeLatest('ADD_NEW_TASK', addNewTask);
  yield takeLatest('UPDATE_TASK', changeTasks);
  yield takeLatest('UPDATE_LIST', updateList);
  yield takeLatest('DELETE_TASK', removeTask);
  yield takeLatest('DELETE_MEMBER', removeMember);
  yield takeLatest('TOGGLE_TASK', markTask);
  yield takeLatest('REORDER_LIST', sequenceList);
}
